import { computeValue } from "../../../core";
import { isDate, isNil, isNumber, isString } from "../../../util";
const MAX_INT = 2147483647;
const MIN_INT = -2147483648;
const MAX_LONG = Number.MAX_SAFE_INTEGER;
const MIN_LONG = Number.MIN_SAFE_INTEGER;
class TypeConvertError extends Error {
  constructor(message) {
    super(message);
  }
}
function toInteger(obj, expr, options, min, max) {
  const val = computeValue(obj, expr, null, options);
  if (val === true) return 1;
  if (val === false) return 0;
  if (isNil(val)) return null;
  if (isDate(val)) return val.getTime();
  const n = Number(val);
  if (isNumber(n) && n >= min && n <= max) {
    if (!isString(val) || n.toString().indexOf(".") === -1) {
      return Math.trunc(n);
    }
  }
  throw new TypeConvertError(`cannot convert '${val}' to ${max == MAX_INT ? "int" : "long"}`);
}
export { MAX_INT, MAX_LONG, MIN_INT, MIN_LONG, TypeConvertError, toInteger };