import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

import {
  KfiEditDialogData,
  KfiEditDialogService,
} from 'src/app/services/kfi-edit-dialog.service';

@Component({
  selector: 'app-kfi-edit',
  templateUrl: './kfi-edit.component.html',
  styleUrls: ['./kfi-edit.component.scss'],
  standalone: false,
})
export class KfiEditComponent {
  /** Field value to be updated. */
  fieldValue = this.data.fieldValue;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KfiEditDialogData,
    private kfiEditDialogService: KfiEditDialogService,
    private translate: TranslocoService
  ) {}
  /**
   * Cancel the kfi edit dialog.
   */
  cancelDialog() {
    this.kfiEditDialogService.closeDialog();
  }
  /**
   * Confirm the kfi edit dialog.
   */
  confirmDialog() {
    this.kfiEditDialogService.confirmDialog(this.fieldValue);
  }
}
