import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { InboxFiles } from 'models';
import { InboxDocumentOpenRequest, InboxGridData } from 'src/app/models';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';

import { InboxActionsMenuComponent } from '../inbox-actions-menu/inbox-actions-menu.component';

/** Inbox toolbar. */
@Component({
  selector: 'app-inbox-actions-toolbar',
  templateUrl: './inbox-actions-toolbar.component.html',
  styleUrls: ['./inbox-actions-toolbar.component.scss'],
  standalone: false,
})
export class InboxActionsToolbarComponent {
  /** Action Menu Reference. */
  @Input()
  actionMenu: InboxActionsMenuComponent;
  /** Determines if edit mode is enabled. */
  @Input()
  editModeEnabled: boolean;
  /** Event raised when edit mode changes. */
  @Output()
  editModeEnabledChange = new EventEmitter<boolean>();
  /** Event raised when selected rows should be opened. */
  @Output()
  openSelectedDocuments = new EventEmitter();
  /** Event triggered when the refresh button is used. */
  @Output()
  refreshInbox: EventEmitter<any> = new EventEmitter();

  /** Observable value of the active inbox object. */
  inbox$ = this.inboxesQuery.activeInbox$;
  /** Observable of right sidebar visibility. */
  rightSidebarOpen$ = this.applicationQuery.rightSidebarOpen$;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  /**
   * Gets the current grid data object.
   *
   * @returns An inbox grid data object.
   */
  get gridData(): InboxGridData {
    return this.gridStatesService.getOrCreateInboxgridData(
      this.inboxesQuery.activeId
    );
  }

  constructor(
    private logger: NGXLogger,
    private applicationService: ApplicationService,
    private applicationQuery: ApplicationQuery,
    private inboxesQuery: InboxesQuery,
    private gridStatesService: GridSettingsService,
    private layout: LayoutService
  ) {}

  /**
   * No InboxFiles selected.
   *
   * @returns True if none are selected. */
  get isNoneSelected(): boolean {
    return this.selectedInboxFiles.length === 0;
  }

  /**
   * Exactly one InboxFile is selected.
   *
   * @returns True iff one is selected. */
  get isSingleSelection(): boolean {
    return this.selectedInboxFiles.length === 1;
  }

  /**
   * Number of selected InboxFiles.
   *
   * @returns Count.
   */
  get selectedCount(): number {
    return this.selectedInboxFiles.length;
  }

  /**
   * Array of selectedInboxFiles.
   *
   * @returns An array of selected InboxFiles.
   */
  get selectedInboxFiles(): InboxFiles {
    return (this.gridData?.selectedRowNodes ?? []).map((row) => row.data);
  }

  /** Click event for the edit button. */
  onClickToggleEdit(): void {
    this.logger.debug('Toggle edit button clicked.');
    this.editModeEnabled = !this.editModeEnabled;
    this.editModeEnabledChange.emit(this.editModeEnabled);
  }

  /** Click event for the toggle left sidebar button. */
  onClickToggleLeftSidebar(): void {
    this.logger.debug('Toggle left sidebar button clicked.');
    this.applicationService.toggleLeftSidebarNav();
  }

  /**
   * Click handler for the toggle right sidebar button.
   */
  onClickToggleRightSidebar(): void {
    this.logger.debug('Toggle right sidebar button clicked.');
    this.applicationService.toggleRightSidebarOpen();
  }

  /**
   * Click event for the open documents menu button.
   *
   * @param event Event.
   */
  onOpenDocuments(event: InboxDocumentOpenRequest): void {
    this.logger.debug('Open documents button was clicked.');
    this.openSelectedDocuments.emit(event);
  }

  /** Click handler for the refresh inbox button. */
  onRefresh(): void {
    this.logger.debug('Refresh inbox was clicked.');
    this.refreshInbox.emit();
  }
}
