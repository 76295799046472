import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * String value of the error message for the valueIsInList validator.
 *
 * @example formControl.hasError(VALUE_NOT_IN_LIST)
 * @example formControl.getError(VALUE_NOT_IN_LIST)
 */
export const VALUE_NOT_IN_LIST = 'valueNotInList';

/**
 * Data returned in the valueIsInList validator.
 *
 * @example (formControl.getError(VALUE_NOT_IN_LIST) as ValueNotInListError).actualValue
 */
export interface ValueNotInListError {
  /** Value provided. */
  actualValue: string;
  /** Acceptable list values. */
  validValues: string[];
}

/** Custom validators for dropdown. */

/**
 * Checks if the value is in the list.
 *
 * @param listValues List Values or a function that returns list values.
 * @returns Whether the form value exists in the list.
 */
export function valueIsInList(
  listValues: string[] | undefined | (() => string[] | undefined)
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Null is required typing for validation function result, not undefined.
    if (!control.value) return null;

    const validValues =
      typeof listValues === 'function' ? listValues() : listValues;

    // If the list is undefined then it hasn't loaded yet so there is no point in trying to validate.
    if (!validValues) {
      return null;
    }

    // Use case insensitive comparison.
    const controlValue = control.value.toLowerCase();
    const valueIsInList = validValues.find(
      (value) => value.toLowerCase() === controlValue
    );

    if (!valueIsInList) {
      const error: ValueNotInListError = {
        actualValue: control.value,
        validValues: validValues,
      };
      return { valueNotInList: error };
    } else {
      // Null is required typing for validation function result, not undefined.
      return null;
    }
  };
}
