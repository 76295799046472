@if (document(); as document) {
  <div>
    <mat-toolbar>
      <!-- Left side controls -->
      <button mat-icon-button (click)="onClickCloseView()">
        <mat-icon>manage_search</mat-icon>
      </button>
      <span class="spacer"></span>
      <!-- Middle controls -->
      <button
        mat-icon-button
        [matTooltip]="'PREVIOUS' | transloco"
        [disabled]="!canNavigateToPreviousDocument()"
        (click)="onClickPrevious()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      @if (documentOfDocuments(); as documentOfDocuments) {
        <mat-chip-listbox>
          <mat-chip-option>{{ documentOfDocuments }}</mat-chip-option>
        </mat-chip-listbox>
      }
      <button
        mat-icon-button
        [matTooltip]="'NEXT' | transloco"
        [disabled]="!canNavigateToNextDocument()"
        (click)="onClickNext()"
      >
        <mat-icon>arrow_forward</mat-icon>
      </button>
      <span class="spacer"></span>

      <!-- Right side controls -->
      <mat-chip-set>
        <mat-chip [matTooltip]="'UNSAVED_DOCUMENT_MESSAGE' | transloco">{{
          'UNSAVED' | transloco
        }}</mat-chip>
      </mat-chip-set>
      @if (hasNewDocuments()) {
        <button
          mat-icon-button
          [matTooltip]="'OPEN_NEW_DOCUMENTS_DIALOG' | transloco"
          (click)="onClickOpenNewDocumentsDialog()"
        >
          <mat-icon [matBadge]="newPdfDocumentCount()">tab</mat-icon>
        </button>
      }
      <button
        mat-icon-button
        [matTooltip]="'CHANGE_ARCHIVE' | transloco"
        (click)="onClickChangeArchive()"
      >
        <mat-icon>inventory_2</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="disableSave()"
        (click)="onClickSave()"
        [matTooltip]="'SAVE' | transloco"
      >
        <mat-icon>save</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'GSG_MODIFY_DOCUMENT' | transloco"
        (click)="onClickToggleModifyThumbnailer()"
      >
        <mat-icon>mode_edit</mat-icon>
      </button>
    </mat-toolbar>
    <app-document-view-sidebar
      #rightSidebar
      [archiveId]="document.targetArchiveId"
      [documentSecureId]="''"
      [documentId]="0"
      [viewerPageNumber]="pageNumber()"
      [permissions]="documentPermissions()!"
      [uniqueId]="document.id"
      [fieldValues]="documentFields()"
      (indexerFieldFocused)="onIndexerFieldFocused($event)"
    >
      @if (pdfOptions(); as pdfOptions) {
        <div class="thumbnailer-viewer-container">
          @if (shouldShowModifyThumbnailer()) {
            <app-pdf-thumbnail-viewer
              #thumbnailViewer
              *ngIf="pdfViewer && !isDocumentLoading()"
              [pdfBytes]="pdfViewer.pdfBytes"
              [thumbnails]="pdfViewer.thumbnails"
              [hasModifyPermissions]="
                documentPermissions()!.modifyDocuments &&
                documentPermissions()!.modifyDocumentPages
              "
              [appPdfThumbnailerEventHandler]="thumbnailViewer"
              [appPdfThumbnailerEventHandlerArchiveId]="
                document.targetArchiveId
              "
              [appPdfThumbnailerHandlerFields]="documentFields()"
              [appPdfThumbnailerEventHandlerViewer]="pdfViewer"
              (pdfModified)="onPdfModified($event)"
            >
            </app-pdf-thumbnail-viewer>
          }
          <div class="viewer-container" [ngStyle]="viewerContainerStyle">
            <app-pdf-viewer
              #pdfViewer
              [pdf-options]="pdfOptions"
              [go-to-first-page-on-load]="true"
              (loadFailure)="onLoadFailure()"
              (documentLoadingChange)="onDocumentLoadingChange($event)"
            ></app-pdf-viewer>
          </div>
        </div>
        @if (showTableFieldGrid()) {
          <app-table-field-grid
            #tableFieldGrid
            [archiveId]="document.targetArchiveId"
            [editModeEnabled]="
              this.documentPermissions()?.addNewDocuments ?? false
            "
          ></app-table-field-grid>
        }
      }
    </app-document-view-sidebar>
  </div>
} @else {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
