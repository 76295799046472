import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ApplicationQuery } from 'src/app/state/application/application.query';

import { Color } from '../../common/constants';

@UntilDestroy()
@Component({
  selector: 'app-logo-svg',
  templateUrl: './logo-svg.component.svg',
  styleUrls: ['./logo-svg.component.scss'],
  standalone: false,
})
export class LogoSvgComponent implements OnInit {
  /**
   * Size of the logo to display.
   *
   * @default 36
   */
  @Input('logo-size') logoSize: number | string = 36;
  /** Should the logo be displayed without color? */
  @Input() monochrome: boolean | '' = false;
  /**
   * Accent color for dark mode.
   *
   * @default Color.darkAccent
   */
  @Input('accent-color-dark') private accentColorDark = Color.jungleGreen;
  /**
   * Accent color for light mode.
   *
   * @default Color.s9Orange
   */
  @Input('accent-color-light') private accentColorLight = Color.s9Orange;
  /**
   * Primary color for dark mode.
   *
   * @default '#fff'
   */
  @Input('primary-color-dark') private primaryColorDark = '#fff';
  /**
   * Primary color for light mode.
   *
   * @default '#000'
   */
  @Input('primary-color-light') private primaryColorLight = '#fff';

  /** Current logo accent color. */
  accentColor: string;
  /** Current logo primary color. */
  primaryColor: string;

  private darkMode = false;

  constructor(private app: ApplicationQuery) {}

  ngOnInit(): void {
    this.monochrome = this.monochrome !== false;
    this.app.darkMode$.pipe(untilDestroyed(this)).subscribe((result) => {
      this.darkMode = result;
      this.primaryColor = this.darkMode
        ? this.primaryColorDark
        : this.primaryColorLight;
      this.accentColor = this.monochrome
        ? this.primaryColor
        : this.darkMode
          ? this.accentColorDark
          : this.accentColorLight;
    });
  }
}
