import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenuPanel as MenuPanel } from '@angular/material/menu';

/** Describes the add multi value field event. */
export interface AddMultiValueFieldEvent {
  /** If the new value should be added after the current value. If false it prepends. */
  append: boolean;
}

@Component({
  selector: 'app-multi-value-field-menu',
  templateUrl: './multi-value-field-menu.component.html',
  styleUrls: ['./multi-value-field-menu.component.scss'],
  standalone: false,
})
export class MultiValueFieldMenuComponent {
  /** Event emitted when add value is clicked.
   *
   */
  @Output()
  addMultiValue = new EventEmitter<AddMultiValueFieldEvent>();
  /** Mat menu reference. */
  @ViewChild('mvActionMenu')
  matMenu: MenuPanel;
  /** Emits when the value should be removed. */
  @Output()
  removeMultiValue = new EventEmitter();

  /** Menu Position styling. */
  menuRootStyle: {
    /** Left position in px. */
    'left.px': number;
    /** Top position in px. */
    'top.px': number;
  };

  /**
   * Handler for the add multi-value click event.
   *
   * @param append Whether to append. Otherwise prepend.
   */
  onAddMultiValueClicked(append: boolean): void {
    this.addMultiValue.emit({ append });
  }

  /** Handler for the remove multi-value click event. */
  onRemoveMultiValueClick(): void {
    this.removeMultiValue.emit();
  }
}
