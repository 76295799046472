<!-- A hidden div is created to set the position of appearance of the menu-->
<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="actionMenu"
></div>
<mat-menu #actionMenu="matMenu" (closed)="onMenuClosed()">
  @if (!hideOpen) {
    <button mat-menu-item (click)="onClickOpenDocuments()">
      <span transloco="OPEN_DOCUMENTS"></span>
    </button>
  }
  @if (!hideOpenFullViewer) {
    <button mat-menu-item (click)="onClickOpenDocuments(true)">
      <span transloco="OPEN_IN_FULL_VIEWER"></span>
    </button>
  }
  @if (!hideOpen || !hideOpenFullViewer) {
    <mat-divider></mat-divider>
  }
  <button mat-menu-item [disabled]="exportDisabled" (click)="onClickExport()">
    <span transloco="EXPORT"></span>
  </button>
  <button
    mat-menu-item
    [disabled]="exportDisabled"
    (click)="onClickEnhancedExport()"
  >
    <span transloco="ENHANCED_EXPORT"></span>
  </button>
  @if (showDownloadAsCsv) {
    <button
      mat-menu-item
      [disabled]="downloadAsCsvDisabled"
      [matMenuTriggerFor]="downloadCsvMenu"
    >
      <span transloco="DOWNLOAD_AS_CSV"></span>
    </button>
  }
  @if (gseIsLaunchAvailable$ | async) {
    <button
      mat-menu-item
      [matMenuTriggerFor]="emailAnnotationsMenu"
      [disabled]="emailDisabled"
    >
      <span transloco="EMAIL"></span>
    </button>
  }
  <button
    mat-menu-item
    [disabled]="printDisabled"
    [matMenuTriggerFor]="printMenu"
  >
    <span transloco="PRINT"></span>
  </button>
  @if (gseIsLaunchAvailable$ | async) {
    <button
      mat-menu-item
      (click)="onClickLaunchDocument()"
      [disabled]="launchDisabled"
    >
      <span transloco="LAUNCH"></span>
    </button>
  }
  @if (gseIsQuickbooksAvailable$ | async) {
    <button mat-menu-item (click)="onClickSendToQuickbooks()">
      <span transloco="SEND_TO_QUICKBOOKS"></span>
    </button>
  }
  <mat-divider></mat-divider>
  <button mat-menu-item [disabled]="copyDisabled" (click)="onClickCopy()">
    <span transloco="COPY"></span>
  </button>
  @if (!hideMultiple) {
    <button mat-menu-item [disabled]="mergeDisabled" (click)="onClickMerge()">
      <span transloco="MERGE"></span>
    </button>
  }
  @if (hasAdvancedLinks$ | async) {
    <button mat-menu-item [matMenuTriggerFor]="advancedLinksMenu">
      <span transloco="ADVANCED_LINKS"></span>
    </button>
  }
  <button mat-menu-item [disabled]="appendDisabled" (click)="onClickAppend()">
    <span transloco="INSERT_FROM_INBOX"></span>
  </button>
  @if (hasFavoriteSearches$ | async) {
    <button
      mat-menu-item
      [disabled]="appendDisabled"
      (click)="onClickInsertFromSearch()"
    >
      <span transloco="INSERT_FROM_SEARCH"></span>
    </button>
  }
  @if (showModify && modifyDocumentsSupported) {
    <button
      mat-menu-item
      [disabled]="modifyDocumentDisabled"
      (click)="onClickModify()"
    >
      <span transloco="GSG_MODIFY_DOCUMENT"></span>
    </button>
  }
  <button mat-menu-item [disabled]="moveDisabled" (click)="onClickMove()">
    <span transloco="MOVE"></span>
  </button>
  <button mat-menu-item [disabled]="deleteDisabled" (click)="onClickDelete()">
    <span transloco="DELETE"></span>
  </button>
</mat-menu>

<mat-menu #downloadCsvMenu="matMenu">
  <button mat-menu-item (click)="onClickDownloadCsv(false)">
    <span transloco="DEFAULT_CONFIGURATION"></span>
  </button>
  <button mat-menu-item (click)="onClickDownloadCsv(true)">
    <span transloco="ADVANCED_CONFIGURATION"></span>
  </button>
</mat-menu>

<mat-menu #emailAnnotationsMenu="matMenu">
  <button
    mat-menu-item
    [matMenuTriggerFor]="emailFieldsMenu"
    [matMenuTriggerData]="{ includeAnnotations: true }"
  >
    <span transloco="ANNOTATIONS"></span>
  </button>
  @if (showLaunchEmailNoAnnotations) {
    <button
      mat-menu-item
      [matMenuTriggerFor]="emailFieldsMenu"
      [matMenuTriggerData]="{ includeAnnotations: false }"
    >
      <span transloco="NO_ANNOTATIONS"></span>
    </button>
  }
</mat-menu>

<mat-menu #emailFieldsMenu="matMenu">
  <ng-template matMenuContent let-includeAnnotations="includeAnnotations">
    @for (field of archiveFields; track field.id) {
      <button
        mat-menu-item
        (click)="onClickEmail(includeAnnotations, field.id)"
      >
        {{ field.name }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #printMenu="matMenu">
  <button mat-menu-item (click)="onClickPrintDocuments(true)">
    <span transloco="PRINT_WITH_ANNOTATIONS"></span>
  </button>
  <button mat-menu-item (click)="onClickPrintDocuments(false)">
    <span transloco="PRINT_WITHOUT_ANNOTATIONS"></span>
  </button>
</mat-menu>

<mat-menu #advancedLinksMenu="matMenu">
  <ng-template matMenuContent>
    @for (advancedLink of advancedLinks$ | async; track advancedLink.id) {
      <div>
        <button mat-menu-item (click)="onClickAdvancedLink(advancedLink)">
          {{ advancedLink.name }}
        </button>
      </div>
    }
  </ng-template>
</mat-menu>
