import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

import { CSVImportJobQuery } from 'src/app/state/csv-import-jobs/csvimport-job.query';

import { CSVImportJobManagerDialogComponent } from '../csvimport-job-manager-dialog/csvimport-job-manager-dialog.component';

const MIN_JOB_MANAGER_WIDTH = 400;

@Component({
  selector: 'app-csvimport-job-manager-button',
  templateUrl: './csvimport-job-manager-button.component.html',
  styleUrls: ['./csvimport-job-manager-button.component.scss'],
  standalone: false,
})
export class CSVImportJobManagerButtonComponent {
  /** Observable array of jobs. */
  jobs$ = this.csvImportJobQuery.jobs$;
  /** Observable loading state of jobs. */
  jobsLoading$ = this.csvImportJobQuery.loading$;

  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    private csvImportJobQuery: CSVImportJobQuery
  ) {}

  /**
   * Handler for the click event.
   */
  onClick(): void {
    this.logger.debug('CSV import job manager button clicked.');
    this.dialog
      .open(CSVImportJobManagerDialogComponent, {
        minWidth: MIN_JOB_MANAGER_WIDTH,
      })
      .afterClosed()
      .subscribe();
  }
}
