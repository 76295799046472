import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { DATABASE_NAME_SPACING_PATTERN } from '../../common/constants';
import { DatabasesQuery } from '../../state/databases/databases.query';

@Component({
  selector: 'app-database-select',
  templateUrl: './database-select.component.html',
  styleUrls: ['./database-select.component.scss'],
  standalone: false,
})
export class DatabaseSelectComponent implements OnInit {
  /** Pattern to use for matching desired spaces in database names. */
  databaseNameReplacement = DATABASE_NAME_SPACING_PATTERN;
  /** Observable of all available databases. */
  databases$ = this.databasesQuery.databases$;
  /** Observable of the current state of if there is currently a pending load of database data. */
  isDatabasesLoading$ = this.databasesQuery.isLoading$;

  constructor(
    private databasesQuery: DatabasesQuery,
    private logger: NGXLogger,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Check if we are in a reloading state, and should ignore further action.
    const isReloading =
      !!this.router.getCurrentNavigation()?.extras?.state?.reload;
    const isAnotherRouteAlreadyActive = !/\/(\?|$)/i.test(
      this.router.getCurrentNavigation()?.extractedUrl.toString() ?? '/'
    );
    if (isReloading || isAnotherRouteAlreadyActive) return;
    // Redirect if user only has one database.
    this.databases$.subscribe((databases) => {
      if (databases.length === 1) {
        this.logger.debug('Only one available database, auto-redirecting.');
        this.router.navigate(['/db', databases[0].id]);
      }
    });
  }
}
