import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';

import { Field, FieldDataType, List, ListType } from 'models';
import { ListsService } from 'src/app/state/lists/lists.service';

import { FieldBaseComponent } from '../field-components/field-component.base.component';
import { MultiValueFieldMenuComponent } from '../field-components/multi-value-field-menu/multi-value-field-menu.component';

// import {
//   NgxMatDatepickerActions,
//   NgxMatDatepickerApply,
//   NgxMatDatepickerCancel,
//   NgxMatDatepickerClear,
//   NgxMatDatepickerInput,
//   NgxMatDatetimepicker,
// } from '@ngxmc/datetime-picker';

/** Field Component. */
@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  // imports: [
  //   NgxMatDatepickerActions,
  //   NgxMatDatepickerActions,
  //   NgxMatDatepickerApply,
  //   NgxMatDatepickerCancel,
  //   NgxMatDatepickerClear,
  //   NgxMatDatepickerInput,
  //   NgxMatDatetimepicker,
  // ],
  standalone: false,
})
export class FieldComponent implements OnInit {
  /** Form control. */
  @Input('form-control')
  control: UntypedFormControl;
  /** Field. */
  @Input()
  field: Field;
  /** Emits when this field is blurred. The event contains the field that was blurred. */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /** Underlying field component. */
  @ViewChild('fieldComponent')
  fieldComponent: FieldBaseComponent;
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Multi-Value field menu component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;
  /** Type of data stored in a field. */
  fieldDataType = FieldDataType;
  /** An observable list or undefined if there is no list. */
  list$: Observable<List> | undefined;
  /** List type. */
  listType = ListType;

  /**
   * Determines if the field has a list.
   *
   * @returns True when the field has a list.
   */
  get fieldHasList(): boolean {
    return (
      this.field.list.listId > 0 ||
      this.field.list.primary > 0 ||
      this.field.list.secondary > 0
    );
  }

  /**
   * Get form control error message.
   *
   * @returns A string containing the error message.
   */
  get errorMessage(): string {
    if (this.control.hasError('required')) {
      return this.translate.translate('REQUIRED_FIELD');
    }
    if (this.control.hasError('pattern')) {
      return this.regExMessageFromField;
    }
    if (this.control.hasError('matDatetimePickerParse')) {
      return this.translate.translate('INVALID_FIELD_DATE');
    }

    return '';
  }

  /**
   * Get the validation message from the `Field`'s validation regular expression.
   *
   * @returns String containing the validation message, or an empty string if not available.
   */
  get regExMessageFromField(): string {
    return /(?<=\(\?#)(.*?)(?= #\))/.exec(this.field.regex)?.[0] ?? '';
  }

  constructor(
    private list: ListsService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    if (this.field.list.listId > 0) {
      this.list$ = this.list.get(this.field.list.listId);
    }

    if (this.field.systemField) {
      this.control.disable();
    }
  }

  /**
   * Handler for the field blurred event.
   */
  onFieldBlurred(): void {
    this.fieldBlurred.emit(this.field);
  }

  /**
   * Handler for the field focused event.
   */
  onFieldFocused(): void {
    this.fieldFocused.emit(this.field);
  }
}
