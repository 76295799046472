import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  GSEApiScanStatus,
  ScanBytesResult,
  ScanProvider,
  ScanResult,
  ScanStatus,
  ScanUploadRequest,
  UserFriendlyError,
} from 'models';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  catchError,
  defer,
  interval,
  map,
  switchMap,
  tap,
} from 'rxjs';

import { assert } from 'common';
import { GseApiConfig } from './gse-api-config.model';
import { GSE_API_CONFIG } from './gse-api-config.token';
import { HttpErrorHandler } from './handle-error';

/**
 * GlobalSearch Extensions Scan API service.
 */
@Injectable({
  providedIn: 'root',
})
export class GseScanService implements ScanProvider {
  /** @inheritdoc */
  currentScanStatus$: Observable<ScanStatus>;

  private config = {} as GseApiConfig;
  private currentScanStatusSource = new ReplaySubject<ScanStatus>(1);
  private errorHandler: HttpErrorHandler;
  private timeout = 3600;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    @Inject(GSE_API_CONFIG) private config$: Observable<GseApiConfig>
  ) {
    this.config$.subscribe((config) => {
      Object.assign(this.config, config);
    });
    this.currentScanStatus$ = this.currentScanStatusSource.asObservable();
    this.errorHandler = new HttpErrorHandler(this.logger);
  }

  //TODO: do we need this?
  private get extensionsUrl(): string {
    return `${this.config.apiUrl}/api`;
  }

  /** @inheritdoc */
  public complete(
    sessionId: string,
    uploadRequest: ScanUploadRequest
  ): Observable<ScanResult> {
    this.logger.debug('Scan session complete...');
    return this.uploadScan(sessionId, uploadRequest).pipe(
      map((uploadedFilename) => {
        this.logger.debug(
          'Scan uploaded to the viewer cache.',
          uploadedFilename
        );
        this.endScanSession(sessionId, uploadRequest.scanIds);
        this.endSession(sessionId);
        const result: ScanResult = {
          uploadId: uploadedFilename,
        };
        return result;
      })
    );
  }

  /** @inheritdoc */
  completeAndGetBytes(
    sessionId: string,
    scanIds: string[]
  ): Observable<ScanBytesResult> {
    this.logger.debug('Scan session complete. Retrieving scan bytes...');
    const params = new HttpParams()
      .set('sessionid', sessionId)
      .set('responseType', 'bytes')
      .set('scanIds', scanIds.join(','));
    return this.http
      .get(`${this.extensionsUrl}/scan/sessionbytes`, {
        params,
        responseType: 'blob',
      })
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to retrieve the scanned document using GSE.',
            'ERROR_FAILED_TO_RETRIEVE_SCAN',
            errorResponse.error
          )
        ),
        switchMap((response) => {
          const mimeType = response.type;
          // There should be absolutely no way this api call returns anything other than a pdf but just to be sure we assert.
          assert(mimeType === 'application/pdf');
          return defer(() => response.arrayBuffer()).pipe(
            map((arrayBuffer) => ({
              fileType: '.pdf', // We already checked the mime type above so we know this is a pdf.
              fileBytes: new Uint8Array(arrayBuffer),
            })),
            // Cleanup the session once we read the bytes and then return the result.
            switchMap((result) => {
              this.endScanSession(sessionId, scanIds);
              return this.endSession(sessionId).pipe(map(() => result));
            })
          );
        })
      );
  }

  /** @inheritdoc */
  public continue(sessionId: string): Observable<ScanStatus> {
    this.createJob(sessionId)
      .pipe(
        map((scanId) => {
          this.logger.debug('Scan job created. Starting scan.', scanId);
          this.startScan(sessionId, scanId).subscribe();
          return scanId;
        })
      )
      .subscribe((scanId) => {
        this.monitorScanStatus(sessionId, scanId);
      });
    return this.currentScanStatus$;
  }

  /** @inheritdoc */
  public start(cancelExisting?: boolean): Observable<ScanStatus> {
    // ensure that there is no status from a previous scan
    // TODO: This is probably not a good way to try and treat a ReplaySubject as "resettable".
    // I would suggest considering something like this https://stackoverflow.com/a/51147023/7142353 if
    // this is the real behavior wanted. It is not really type correct to have been sending undefined.
    // Undefined upset TS compiler, so this is now an empty cast object.
    this.currentScanStatusSource.next({} as ScanStatus);
    this.getSession(!!cancelExisting).subscribe((sessionId) => {
      this.logger.debug('Scan session created. Starting Scan.', sessionId);
      this.createJob(sessionId)
        .pipe(
          map((scanId) => {
            this.logger.debug('Scan job created. Starting scan.', scanId);
            this.startScan(sessionId, scanId).subscribe();
            return scanId;
          })
        )
        .subscribe((scanId) => {
          this.monitorScanStatus(sessionId, scanId);
        });
    });

    return this.currentScanStatus$;
  }

  private createJob(sessionId: string): Observable<string> {
    const options = {
      params: new HttpParams().set('sessionId', sessionId),
    };

    return this.http
      .get<string>(`${this.extensionsUrl}/scan`, options)
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to create scan job.',
            'ERROR_SCAN_ERROR',
            errorResponse.error
          )
        )
      );
  }

  private endScan(sessionId: string, scanId: string): Observable<void> {
    const options = {
      params: new HttpParams()
        .set('sessionId', sessionId)
        .set('scanID', scanId)
        .set('status', 'end'),
    };

    return this.http
      .get<void>(`${this.extensionsUrl}/scan`, options)
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to end scan job.',
            'ERROR_SCAN_ERROR',
            errorResponse.error
          )
        )
      );
  }

  private endScanSession(sessionId: string, scannedFileIds: string[]): void {
    this.logger.debug('Ending scan session.');
    for (const scannedDocumentId of scannedFileIds) {
      this.endScan(sessionId, scannedDocumentId).subscribe();
    }
  }

  private endSession(sessionId: string): Observable<ScanResult> {
    const options = {
      params: new HttpParams().set('closeExisting', sessionId),
    };

    return this.http
      .get<void>(`${this.extensionsUrl}/session`, options)
      .pipe(
        map(() => {
          this.currentScanStatusSource.next({} as ScanStatus);
          const result: ScanResult = {
            uploadId: 'my-little-upload-id',
          };
          return result;
        })
      )
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to end scan session.',
            'ERROR_SCAN_ERROR',
            errorResponse.error
          )
        )
      );
  }

  private getScannerStatus(
    sessionId: string,
    scanId: string
  ): Observable<GSEApiScanStatus> {
    const options = {
      params: new HttpParams()
        .set('sessionId', sessionId)
        .set('scanID', scanId),
    };

    return this.http
      .get<GSEApiScanStatus>(`${this.extensionsUrl}/scan`, options)
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to retrieve scan session status.',
            'ERROR_SCAN_ERROR',
            errorResponse.error
          )
        )
      );
  }

  private getSession(closeExisting: boolean): Observable<string> {
    const options = {
      params: new HttpParams()
        .set('closeExisting', closeExisting.toString())
        .set('timeout', this.timeout.toString()),
    };

    return this.http
      .get<string>(`${this.extensionsUrl}/session`, options)
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to create scan session.',
            'ERROR_SCAN_ERROR',
            errorResponse.error
          )
        )
      );
  }

  /**
   * Continually queries the scan job until it is no longer "Scanning".
   *
   * @param sessionId Session Id.
   * @param scanId Scan Id.
   */
  private monitorScanStatus(sessionId: string, scanId: string): void {
    // Check for an update in status every 3 seconds.
    const statusCheckSubscription = interval(3000)
      .pipe(
        tap((iteration) => {
          this.logger.debug(`Checking scan status (${iteration}).`);
        }),
        switchMap(() => {
          return this.getScannerStatus(sessionId, scanId);
        }),
        map((apiStatus) => {
          this.logger.debug(`Current scan status:`, apiStatus);
          // Create a status object.
          const status = new GSEApiScanStatus(apiStatus).asScanStatus();
          status.sessionId = sessionId;
          this.currentScanStatusSource.next(status);
          // If the scan is no longer in progress, stop checking for updates.
          if (status.progress.toLowerCase() !== 'scanning') {
            statusCheckSubscription.unsubscribe();
          }
        }),
        catchError((error: any) => {
          this.logger.error(
            'An error occured while update scan status:',
            error
          );
          statusCheckSubscription.unsubscribe();
          return EMPTY;
        })
      )
      .subscribe();
  }

  private startScan(sessionId: string, scanId: string): Observable<void> {
    const options = {
      params: new HttpParams()
        .set('sessionId', sessionId)
        .set('scanID', scanId),
    };

    return this.http
      .post<void>(`${this.extensionsUrl}/scan`, {}, options)
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to start scan job.',
            'ERROR_SCAN_ERROR',
            errorResponse.error
          )
        )
      );
  }

  private uploadScan(
    sessionId: string,
    uploadRequest: ScanUploadRequest
  ): Observable<string> {
    this.logger.debug('Running scan upload to target.', uploadRequest);
    return this.http
      .put<string>(`${this.extensionsUrl}/scan/upload`, uploadRequest, {
        params: new HttpParams().set('sessionid', sessionId),
      })
      .pipe(
        catchError((errorResponse: UserFriendlyError) =>
          this.errorHandler.handleError(
            'Unable to upload the scan using GSE.',
            'ERROR_FILE_UPLOAD_FAILED',
            errorResponse.error
          )
        )
      );
  }
}
