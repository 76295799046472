import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';

import { FavoriteSearch } from 'src/app/state/application/favorite-searches.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

/** Add Favorite Search Dialog. */
@Component({
  selector: 'app-add-favorite-search-dialog',
  templateUrl: './add-favorite-search-dialog.component.html',
  styleUrls: ['./add-favorite-search-dialog.component.scss'],
  standalone: false,
})
export class AddFavoriteSearchDialogComponent implements OnInit {
  /** Favorite form. */
  favoriteForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialogReference<AddFavoriteSearchDialogComponent>,
    private searchesQuery: SearchesQuery,
    private archivesQuery: ArchivesQuery,
    @Inject(MAT_DIALOG_DATA) public searchName: string
  ) {}

  /**
   * Get login form controls.
   *
   * @returns Login form controls.
   */
  get f() {
    return this.favoriteForm.controls;
  }

  ngOnInit(): void {
    this.favoriteForm = this.formBuilder.group({
      searchLabel: [this.searchName, Validators.required],
      showPrompt: [true],
      useCurrentPromptData: [false],
    });
  }

  /** Handler for the form submit event. */
  onSubmit(): void {
    if (this.favoriteForm.invalid) {
      return;
    }

    const favoriteSearch: FavoriteSearch = {
      label: this.f.searchLabel.value,
      showPrompt: this.f.showPrompt.value,
      searchPrompts: this.f.useCurrentPromptData.value
        ? (this.searchesQuery.currentSearchPrompts ?? [])
        : [],
      targetArchiveId: this.archivesQuery.activeId,
    };

    this.dialog.close(favoriteSearch);
  }
}
