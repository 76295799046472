<ng-container *ngIf="document$ | async as document; else noDocument">
  <!-- Document was successfully loaded. -->
  <ng-container *ngTemplateOutlet="documentToolbar"></ng-container>
  <div>
    <ng-container *ngTemplateOutlet="unsupportedDocument"></ng-container>
    <ng-container [ngSwitch]="displayMode">
      <ng-container *ngSwitchCase="'inbox'">
        <ng-container *ngTemplateOutlet="inboxView"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'inboxIndexing'">
        <ng-container *ngTemplateOutlet="inboxView"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'archiveImport'">
        <app-document-view-sidebar
          #rightSidebar
          [archiveId]="archiveId"
          [documentId]="0"
          [documentSecureId]="''"
          [uniqueId]="importUniqueId"
          [viewerPageNumber]="pageNumber"
          [permissions]="documentPermissions"
          (indexerFieldFocused)="onIndexerFieldFocused($event)"
        >
          <div class="view-container" [ngStyle]="viewerContainerStyle">
            <ng-container
              *ngIf="showArchiveImportPreview; else importDocumentCannotPreview"
            >
              <app-pdf-viewer
                *ngIf="pdfOptions"
                [pdf-options]="pdfOptions"
                [go-to-first-page-on-load]="
                  (viewerGoToFirstPageOnLoad$ | async) ?? false
                "
                (loadFailure)="onLoadFailure()"
                (pageChange)="onPageChange($event)"
                (documentLoadingChange)="onDocumentLoadingChange($event)"
              ></app-pdf-viewer>
            </ng-container>
            <ng-template #importDocumentCannotPreview>
              <app-import-viewer-unavailable></app-import-viewer-unavailable>
            </ng-template>
          </div>
          <div>
            <app-table-field-grid
              #tableFieldGrid
              *ngIf="showTableFieldGrid"
              [archiveId]="archiveId"
              [editModeEnabled]="!this.isGuest"
            ></app-table-field-grid>
          </div>
        </app-document-view-sidebar>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <app-document-view-sidebar
          #rightSidebar
          [archiveId]="archiveId"
          [documentId]="documentAsSearchResult.id"
          [documentSecureId]="documentAsSearchResult.secureId"
          [fieldValues]="documentAsSearchResult.fields"
          [viewerPageNumber]="pageNumber"
          [workflowProcessId]="documentAsSearchResult.workflowProcessId"
          [permissions]="documentPermissions"
          (indexerFieldFocused)="onIndexerFieldFocused($event)"
          (userActionExecuted)="onUserActionExecuted($event)"
        >
          <div class="thumbnailer-viewer-container">
            <app-pdf-thumbnail-viewer
              #thumbnailViewer
              *ngIf="
                showModifyDocumentThumbnailer && pdfviewer && !isDocumentLoading
              "
              [appPdfThumbnailerEventHandler]="thumbnailViewer"
              [appPdfThumbnailerEventHandlerViewer]="pdfviewer"
              [appPdfThumbnailerHandlerFields]="documentAsSearchResult.fields"
              [hasModifyPermissions]="
                documentPermissions.modifyDocumentPages &&
                documentPermissions.modifyDocuments
              "
              [pdfBytes]="pdfviewer.pdfBytes"
              [thumbnails]="pdfviewer.thumbnails"
              (pdfModified)="onPdfModified($event)"
            >
            </app-pdf-thumbnail-viewer>
            <div class="viewer-container" [ngStyle]="viewerContainerStyle">
              <app-pdf-viewer
                *ngIf="pdfOptions"
                [pdf-options]="pdfOptions"
                [go-to-first-page-on-load]="
                  (viewerGoToFirstPageOnLoad$ | async) ?? false
                "
                (loadFailure)="onLoadFailure()"
                (pageChange)="onPageChange($event)"
                (documentLoadingChange)="onDocumentLoadingChange($event)"
              ></app-pdf-viewer>
            </div>
          </div>

          <div>
            <app-table-field-grid
              #tableFieldGrid
              *ngIf="showTableFieldGrid"
              [archiveId]="archiveId"
              [editModeEnabled]="!this.isGuest"
              [viewingRevision]="displayMode === 'archiveRevision'"
            ></app-table-field-grid>
          </div>
        </app-document-view-sidebar>
      </ng-container>
    </ng-container>

    <ng-template #inboxView>
      <ng-container *ngIf="inboxTargetArchiveId === 0; else indexingInboxView">
        <div class="viewer-container" [ngStyle]="viewerContainerStyle">
          <app-pdf-viewer
            *ngIf="pdfOptions"
            [pdf-options]="pdfOptions"
            [go-to-first-page-on-load]="
              (viewerGoToFirstPageOnLoad$ | async) ?? false
            "
            (loadFailure)="onLoadFailure()"
            (pageChange)="onPageChange($event)"
            (documentLoadingChange)="onDocumentLoadingChange($event)"
          ></app-pdf-viewer>
        </div>
      </ng-container>
      <ng-template #indexingInboxView>
        <app-document-view-sidebar
          #rightSidebar
          [archiveId]="inboxTargetArchiveId"
          [documentId]="0"
          [documentSecureId]="''"
          [viewerPageNumber]="pageNumber"
          [permissions]="documentPermissions"
          [uniqueId]="importUniqueId"
        >
          <div class="viewer-container" [ngStyle]="viewerContainerStyle">
            <app-pdf-viewer
              *ngIf="pdfOptions"
              [pdf-options]="pdfOptions"
              [go-to-first-page-on-load]="
                (viewerGoToFirstPageOnLoad$ | async) ?? false
              "
              (loadFailure)="onLoadFailure()"
              (pageChange)="onPageChange($event)"
              (documentLoadingChange)="onDocumentLoadingChange($event)"
            ></app-pdf-viewer>
          </div>
          <div>
            <app-table-field-grid
              #tableFieldGrid
              *ngIf="showTableFieldGrid"
              [archiveId]="inboxTargetArchiveId"
              [editModeEnabled]="!this.isGuest"
            ></app-table-field-grid>
          </div>
        </app-document-view-sidebar>
      </ng-template>
    </ng-template>
  </div>

  <!-- Action menu. -->
  <ng-container [ngSwitch]="displayMode">
    <ng-container *ngSwitchCase="'archive'">
      <ng-container *ngTemplateOutlet="archiveActions"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'inbox'">
      <ng-container *ngTemplateOutlet="inboxActions"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'inboxIndexing'">
      <ng-container *ngTemplateOutlet="inboxActions"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'task'">
      <ng-container *ngTemplateOutlet="taskSearchActions"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #archiveActions>
    <app-archive-actions-menu
      #actionMenuComponent
      [searchResults]="[documentAsSearchResult]"
      [hideOpen]="true"
      [hideMultiple]="true"
      [showModify]="true"
      (refreshSearch)="onRefreshSearch($event)"
      (openSelectedDocuments)="onOpenSearchResults($event)"
      (modifyDocumentEnabledChange)="showModifyDocumentThumbnailer = $event"
    ></app-archive-actions-menu>
  </ng-template>
  <ng-template #inboxActions>
    <app-inbox-actions-menu
      [inboxFiles]="[documentAsInboxFile]"
      #actionMenuComponent
      [hideOpen]="true"
      [hideMultiple]="true"
      (refreshInbox)="onRefreshSearch($event)"
      (openSelectedDocuments)="onOpenInboxDocuments($event)"
    ></app-inbox-actions-menu>
  </ng-template>
  <ng-template #taskSearchActions>
    <app-task-action-menu
      #actionMenuComponent
      [hideOpen]="true"
      [hideMultiple]="true"
      [hideUserActions]="true"
      (refreshSearch)="onRefreshSearch($event)"
      (openSelectedDocuments)="onOpenTaskViewDocuments($event)"
    ></app-task-action-menu>
  </ng-template>

  <!-- Document toolbar. -->
  <ng-template #documentToolbar>
    <mat-toolbar>
      <!-- Toggle menu. -->
      <app-left-sidebar-toggle-button
        *ngIf="shouldShowNavigationToggle"
      ></app-left-sidebar-toggle-button>

      <!-- Return to search results. -->
      <button
        mat-icon-button
        (click)="onClickReturnToSearch()"
        [matTooltip]="'RESULTS' | transloco"
        matTooltipShowDelay="1000"
        matTooltipPosition="left"
        *ngIf="!isRestrictedUser"
      >
        <mat-icon>manage_search</mat-icon>
      </button>

      <!-- If the menu toggle won't be shown, offset for it. -->
      <div *ngIf="!shouldShowNavigationToggle; then singleIconOffset"></div>

      <span class="spacer"></span>

      <!-- Document pager. -->
      <ng-container *ngIf="isMultipleDocuments; then documentPager">
      </ng-container>

      <!-- Right side controls. -->
      <span class="spacer"></span>
      <ng-container
        *ngIf="
          isGuest || isRestrictedUser;
          then guestControlsRight;
          else userControlsRight
        "
      >
      </ng-container>
    </mat-toolbar>
  </ng-template>

  <!-- Toolbar controls shown to guest users. -->
  <ng-template #guestControlsRight>
    <app-copy-link-button
      [includeUser]="true"
      matTooltipPosition="left"
    ></app-copy-link-button>
    <button
      mat-icon-button
      [disabled]="isDownloadDisabled"
      [matTooltip]="'DOWNLOAD' | transloco"
      matTooltipShowDelay="1000"
      matTooltipPosition="left"
      (click)="onClickDownloadDocument()"
    >
      <mat-icon>file_download</mat-icon>
    </button>
  </ng-template>

  <!-- Controls shown to an authenticated user. -->
  <ng-template #userControlsRight>
    <ng-container *ngIf="newPdfDocumentsExist$ | async as newPdfDocumentsExist">
      <button
        mat-icon-button
        (click)="onClickOpenNewDocumentsDialog()"
        [matTooltip]="'OPEN_NEW_DOCUMENTS_DIALOG' | transloco"
      >
        <mat-icon
          [matBadge]="newPdfDocumentCount$ | async"
          [matBadgeHidden]="!newPdfDocumentsExist"
          >tab</mat-icon
        >
      </button>
    </ng-container>
    <button
      mat-icon-button
      *ngIf="displayMode === 'inboxIndexing'"
      [matTooltip]="'CANCEL_INDEXING' | transloco"
      matTooltipPosition="left"
      matTooltipShowDelay="1000"
      (click)="onClickCancelIndexing()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="showSave"
      [disabled]="disableSave"
      [matTooltip]="'SAVE' | transloco"
      matTooltipPosition="left"
      matTooltipShowDelay="1000"
      (click)="onClickSave()"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="showSaveAll"
      [disabled]="disableSave"
      [matTooltip]="'SAVE_ALL' | transloco"
      matTooltipPosition="left"
      matTooltipShowDelay="1000"
      (click)="onClickSaveAll()"
    >
      <mat-icon svgIcon="save_all"></mat-icon>
    </button>
    <button
      *ngIf="isMultipleDocuments"
      mat-icon-button
      [matTooltip]="'CLOSE_DOCUMENT' | transloco"
      matTooltipShowDelay="1000"
      matTooltipPosition="right"
      (click)="onCloseDocument()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <ng-container *ngIf="displayMode !== 'inbox'">
      <app-revisions-menu
        *ngIf="
          displayMode !== 'inboxIndexing' && displayMode !== 'archiveImport'
        "
        [multipleDocumentsOpen]="multipleDocumentsOpen"
        [archiveId]="archiveId"
        [databaseId]="databaseId"
        [documentId]="revisionParentDocumentId"
        [documentSecureId]="revisionParentSecureId"
        [activeVersion]="activeRevisionNumber"
      ></app-revisions-menu>
      <button
        mat-icon-button
        [disabled]="isKfiDisabled"
        [matTooltip]="'KEYFREE' | transloco"
        matTooltipPosition="left"
        matTooltipShowDelay="1000"
        (click)="onKfiToggle(false)"
      >
        <mat-icon *ngIf="!kfiActive">flash_on</mat-icon>
        <!-- Blue -->
        <mat-icon
          class="active-keyfree-text-layer"
          *ngIf="kfiActive && !useLocalOcr && !forceOcr"
          >flash_on</mat-icon
        >
        <!-- Yellow -->
        <mat-icon
          class="active-keyfree-ocr"
          *ngIf="(kfiActive && forceOcr) || (kfiActive && useLocalOcr)"
          >flash_on</mat-icon
        >
      </button>
      <button
        *ngIf="kfiActive && !useLocalOcr && !forceOcr"
        mat-icon-button
        [disabled]="isKfiDisabled"
        [matTooltip]="'KEYFREE' | transloco"
        matTooltipPosition="left"
        matTooltipShowDelay="1000"
        (click)="onKfiToggle(true)"
      >
        <mat-icon svgIcon="ocr"></mat-icon>
      </button>
    </ng-container>
    <app-state-toolbar></app-state-toolbar>
    <app-locked-document-menu
      *ngIf="shouldShowLockedDocumentMenu"
      [search-result]="documentAsSearchResult"
    ></app-locked-document-menu>
    <app-copy-link-button matTooltipPosition="left"></app-copy-link-button>
    <app-related-searches-menu
      *ngIf="
        displayMode !== 'inbox' &&
        displayMode !== 'inboxIndexing' &&
        displayMode !== 'archiveRevision'
      "
      (relatedSearchSelected)="onRelatedSearchSelected($event)"
    ></app-related-searches-menu>
    <app-dxc-menu
      *ngIf="showDXCMenu"
      [databaseId]="databaseId"
      [archiveId]="
        displayMode === 'inboxIndexing' ? inboxTargetArchiveId : archiveId
      "
      (dxcSourceSelected)="onDXCMatchSelected($event)"
    ></app-dxc-menu>
    <button
      mat-icon-button
      *ngIf="
        displayMode !== 'archiveImport' && displayMode !== 'archiveRevision'
      "
      [disabled]="disableActionMenuButton"
      [matTooltip]="'ACTIONS' | transloco"
      matTooltipShowDelay="1000"
      matTooltipPosition="left"
      (click)="onClickActionMenu($event)"
    >
      <mat-icon>assignment_turned_in</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="showToggleIndexer"
      [matTooltip]="'TOGGLE_INDEXER' | transloco"
      [disabled]="false"
      matTooltipShowDelay="1000"
      matTooltipPosition="left"
      (click)="onClickToggleIndexer()"
    >
      <mat-icon>vertical_split</mat-icon>
    </button>
  </ng-template>

  <!-- Document pager. Allows moving between multiple open documents. -->
  <ng-template #documentPager>
    <button
      mat-icon-button
      [matTooltip]="'PREVIOUS' | transloco"
      matTooltipShowDelay="1000"
      matTooltipPosition="left"
      (click)="onClickBack()"
      [disabled]="!isPreviousAvailable"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>

    <mat-chip-listbox *ngIf="documentOfDocuments" class="toolbar-label">
      <mat-chip-option>
        {{ documentOfDocuments.current }} {{ 'OF' | transloco | lowercase }}
        {{ documentOfDocuments.total }}
      </mat-chip-option>
    </mat-chip-listbox>

    <button
      mat-icon-button
      [matTooltip]="'NEXT' | transloco"
      matTooltipShowDelay="1000"
      matTooltipPosition="right"
      (click)="onClickForward()"
      [disabled]="!isNextAvailable"
    >
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </ng-template>
</ng-container>

<!-- Show a progress bar while loading, and a message when no match was found. -->
<ng-template #noDocument>
  <ng-container *ngIf="isSearchLoading; else noMatch">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <ng-template #noMatch>No document matched the query.</ng-template>
</ng-template>

<!-- Unsupported document output.  -->
<ng-template #unsupportedDocument>
  <ng-container *ngIf="isUnsupportedDocument">
    <mat-card class="unsupported-doc-card">
      <mat-card-title
        ><span transloco="UNSUPPORTED_DOCUMENT_FORMAT"></span
      ></mat-card-title>
      <mat-card-content
        ><span transloco="UNSUPPORTED_DOCUMENT_CARD_MSG"></span
      ></mat-card-content>
      <mat-card-actions align="end" *ngIf="!isDownloadDisabled">
        <button mat-button (click)="onClickDownloadDocument()">
          <span transloco="DOWNLOAD"></span>
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</ng-template>

<!-- Used to balance centering by placeholding for an icon. -->
<ng-template #singleIconOffset>
  <span class="single-icon-offset"></span>
</ng-template>
