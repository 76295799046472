/** User UI Settings. */
export interface UserUiSettings {
  /** Always open in new tab. */
  alwaysOpenNewTab: boolean;
  /** Archive history, results per page. */
  archiveHistoryResultsPerPage: number;
  /** Archive results per page. */
  archiveResultsPerPage: number;
  /** Current language. */
  currentLanguage: string;
  /** Insert from file dialog delete document inbox option. */
  deleteInboxDocumentOnInsert: boolean;
  /** Insert from search dialog delete documents option. */
  deleteSearchDocumentsOnInsert: boolean;
  /** Double-click on result opens document. */
  doubleClickOpen: boolean;
  /** Whether DXC should append matched values to existing multivalues. */
  dxcAppendToMultivalue: boolean;
  /** Whether DXC should include system fields in lookups. */
  dxcSearchSystemFields: boolean;
  /** List of GlobalAction tasks currently enable for display. */
  enabledTasksList: string[];
  /** Hide archives with no results. */
  hideArchivesWithNoResults: boolean;
  /** Reload fields after save. */
  indexerReloadAfterSave: boolean;
  /** Insert from search dialog keep base document option. */
  keepBaseSearchDocumentOnInsert: boolean;
  /** Use local ocr for keyfree. */
  keyfreeUseOcr: boolean;
  /** Whether to persist archive import data from previous imports. */
  persistArchiveImportData: boolean;
  /** Whether to refresh results when closing document tabs. Only relevent if alwaysOpenNewTab is true. */
  refreshResultsWhenClosingDocumentTabs: boolean;
  /** Whether to load search counts in archive menu. */
  searchCountEnabled: boolean;
  /** Archive history, show domain in results. */
  showDomainInHistoryResults: boolean;
  /** Show favorite searches in the UI sidebar. */
  showFavoriteSearches: boolean;
  /** Show GlobalAction tasks in the UI sidebar. */
  showGlobalActionTasks: boolean;
  /** Whether to show the versions archive. */
  showVersionsArchive: boolean;
  /** Sort archives in multi-archive search by the number of results returned. */
  sortResultsArchivesByResultsCount: boolean;
  /** Theme mode in use. */
  themeMode: ThemeMode;
  /** Whether to use previous search criteria in related search. */
  usePreviousSearchCriteriaInRelatedSearch: boolean;
  /** Whether to autosave changes in the internal viewer. */
  viewerAutoSave: boolean;
  /** Always go to the first page when viewer loads. */
  viewerGoToFirstPageOnLoad: boolean;
  /** Use the internal document viewer. */
  viewerUseInternal: boolean;
  /** Use the internal document viewer for imports. */
  viewerUseInternalForImport: boolean;
}

/** Color theme mode. */
export enum ThemeMode {
  /** Always use dark mode. */
  dark,
  /** Always use light mode. */
  light,
  /** Follow the system to determine light or dark mode. */
  system,
}
