<ng-container *ngIf="favoriteSearch">
  <div
    matRipple
    class="highlight-hover sidebar-group-item"
    [ngClass]="{ active: isActiveSearch }"
    matTooltip="{{ search.name }}"
    matTooltipShowDelay="1000"
    matTooltipPosition="right"
    [matTooltipTouchGestures]="(isHandset$ | async) ? 'off' : 'auto'"
    (click)="onClickSearch()"
  >
    <span class="ellipsis">{{ favoriteSearch.label }}</span>
    @if (isHandset$ | async) {
      <span class="spacer"></span>
      <button mat-icon-button [matMenuTriggerFor]="mobileMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    }
  </div>

  <!-- This only exists to show the task search name on mobile. -->
  <!-- If additionally menu items are needed, this should be refactored to work more like the nav archive actions menu. -->
  <mat-menu #mobileMenu>
    <!-- This ng template lazy loads the menu. -->
    <ng-template matMenuContent>
      <button mat-menu-item disabled>{{ search.name }}</button>
    </ng-template>
  </mat-menu>
</ng-container>
