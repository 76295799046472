import { Pipe, PipeTransform } from '@angular/core';

import { assertExists } from 'common';

/** OrderBy pipe. */
@Pipe({
  name: 'orderBy',
  standalone: false,
})
export class OrderByPipe implements PipeTransform {
  /**
   * Orders an array.
   *
   * @param array Array.
   * @param expression Property or properties of an object to use to sort.
   * @param order Descending (desc) and ascending (asc).
   *
   * @returns An ordered array.
   */
  transform(
    array: any[],
    expression: string | string[],
    order?: string | string[]
  ): any[] {
    let orderArray: string[] = [];
    let expressionArray: string[];

    // Convert expression and order to arrays if they are not
    if (!Array.isArray(expression)) {
      expressionArray = [expression];
    }

    if (!order) {
      orderArray = ['desc'];
    }

    assertExists(order);

    if (!Array.isArray(order)) {
      orderArray = [order];
    }
    // Create a compare function that compares two items by the given expression and order
    const compare = (a: any, b: any): number => {
      for (const [index, element] of expressionArray.entries()) {
        // Get the property values of the items by the expression
        const propertyA = this.getProperty(a, element);
        const propertyB = this.getProperty(b, element);
        // Compare the property values
        if (propertyA < propertyB) {
          return orderArray[index] === 'desc' ? 1 : -1;
        }
        if (propertyA > propertyB) {
          return orderArray[index] === 'desc' ? -1 : 1;
        }
      }
      // If all expressions are equal, return 0
      return 0;
    };
    // Sort the array using the compare function
    return array.sort(compare);
  }

  // Helper function to get a nested property value from an object
  private getProperty(obj: any, path: string): any {
    // TODO I don't really understand this rule or see the issue with the code here.
    return path.split('.').reduce((o, p) => o && o[p], obj);
  }
}
