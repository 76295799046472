import { Component } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';

import { NotificationService } from 'src/app/services/notification.service';
import { ListsService } from 'src/app/state/lists/lists.service';

import { FieldCellEditorBaseComponent } from '../field-cell-editor-base.component';
import { FieldCellEditorParameters } from '../field-cell-editor-parameters';

/** Typeahead cell editor. */
@Component({
  selector: 'app-typeahead-cell-editor',
  templateUrl: './typeahead-cell-editor.component.html',
  styleUrls: ['./typeahead-cell-editor.component.scss'],
  standalone: false,
})
export class TypeaheadCellEditorComponent extends FieldCellEditorBaseComponent {
  constructor(
    private logger: NGXLogger,
    private listsService: ListsService,
    notifications: NotificationService,
    translate: TranslocoService
  ) {
    super(translate, notifications);
  }

  /** @inheritdoc */
  agInit(params: FieldCellEditorParameters): void {
    super.agInit(params);
    // this.cellParams = params;
    let initialValue = params.value;
    if (params.eventKey) {
      initialValue = params.eventKey;
    }
    const validators: ValidatorFn[] = [
      Validators.maxLength(params.field.length),
    ];

    this.formControl.addValidators(validators);
    this.formControl.setValue(initialValue);
  }
}
