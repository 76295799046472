<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="menu"
></div>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <button mat-menu-item (click)="onClickCut()">
    <mat-icon>content_cut</mat-icon>
    <span transloco="CUT_PAGES"></span>
  </button>
  <button mat-menu-item (click)="onClickCopy()">
    <mat-icon>content_copy</mat-icon>
    <span transloco="COPY_PAGES"></span>
  </button>
  <button
    [disabled]="!pasteEnabled()"
    mat-menu-item
    (click)="onClickPaste('above_selection')"
  >
    <mat-icon>content_paste</mat-icon>
    <span transloco="PASTE_PAGES_ABOVE"></span>
  </button>
  <button
    [disabled]="!pasteEnabled()"
    mat-menu-item
    (click)="onClickPaste('below_selection')"
  >
    <mat-icon>content_paste</mat-icon>
    <span transloco="PASTE_PAGES_BELOW"></span>
  </button>
</mat-menu>
