import * as pdfjs from '@kariudo/pdfjs/legacy/build/pdf.mjs';
import { assertExists } from 'common';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { deriveLoading } from 'ngxtension/derive-loading';
import { Observable, defer, map, shareReplay, switchMap } from 'rxjs';

/** Pdf thumbnail. */
export class PdfThumbnail {
  /** Url that leads to the new document view where the document can be viewed. */
  burstedDocumentUrl?: string;
  /** Does the page have annotations. */
  hasAnnotations: boolean;
  /** Observable loading state. */
  isLoading$: Observable<boolean>;
  /** Is the thumbnail selected. */
  isSelected = false;
  /** Is the thumbnail on the clipboard. */
  isOnClipboard = false;
  /** Zero based index of the page. */
  pageIndex: number;
  /** Page number. */
  pageNumber: number;
  /** Observable url to the thumbnail image. */
  url$: Observable<string>;

  constructor(
    pageNumber: number,
    hasAnnotations: boolean,
    pdfDocumentProxy: pdfjs.PDFDocumentProxy
  ) {
    this.pageNumber = pageNumber;
    this.pageIndex = pageNumber - 1;
    this.hasAnnotations = hasAnnotations;
    this.url$ = this.createThumbnailUrl$(pdfDocumentProxy);
    this.isLoading$ = this.url$.pipe(
      deriveLoading({ threshold: 100, loadingTime: 10 })
    );
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
  }

  private createThumbnailUrl$(
    pdfDocumentProxy: pdfjs.PDFDocumentProxy
  ): Observable<string> {
    return defer(() => pdfDocumentProxy.getPage(this.pageNumber)).pipe(
      switchMap((pdfPageProxy) => {
        // Get the original view port for the page.
        const viewport = pdfPageProxy.getViewport({ scale: 1 });
        const isTall = viewport.width / viewport.height < 0.75;
        // Calculate scale.
        const scale = isTall ? 194 / viewport.height : 150 / viewport.width;
        // Get a scaled version of the viewport.
        const scaledViewport = pdfPageProxy.getViewport({ scale: scale });
        // Create a canvas where the thumbnail can be rendered.
        const canvas = document.createElement('canvas');
        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;
        const context = canvas.getContext('2d');
        assertExists(context);
        return defer(
          () =>
            pdfPageProxy.render({
              canvasContext: context,
              viewport: scaledViewport,
            }).promise
        ).pipe(map(() => canvas.toDataURL()));
      }),
      shareReplay(1)
    );
  }
}
