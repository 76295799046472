import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellEditingStoppedEvent,
  GridApi,
  GridOptions,
  RowDoubleClickedEvent,
} from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { Subscription, interval } from 'rxjs';

import { assertExists } from 'common';
import {
  InboxFiles,
  InboxSession,
  InboxSessionDocument,
  UserFriendlyError,
  createInboxSessionDocumentFromInboxFile,
} from 'models';
import { InboxDocumentOpenRequest, InboxGridData } from 'src/app/models';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';
import { InboxesService } from 'src/app/state/inboxes/inboxes.service';

import { InboxActionsMenuComponent } from '../inbox-actions-menu/inbox-actions-menu.component';

/**
 * Inbox View.
 */
@UntilDestroy()
@Component({
  selector: 'app-inbox-view',
  templateUrl: './inbox-view.component.html',
  styleUrls: ['./inbox-view.component.scss'],
  standalone: false,
})
export class InboxViewComponent implements OnInit {
  /** INbox actions menu instance for context menu. */
  @ViewChild(InboxActionsMenuComponent)
  inboxActionsMenu: InboxActionsMenuComponent;
  /** AG Grid instance. */
  @ViewChild('inboxGrid') inboxGrid: AgGridAngular;

  /** Determines if the grid columns are currently editable. */
  editModeEnabled = false;
  /** Inbox results grid configuration. */
  gridOptions: GridOptions;
  /** Active inbox observable. */
  inbox$ = this.inboxesQuery.activeInbox$;
  /** Observable of right sidebar visibility. */
  rightSidebarOpen$ = this.applicationQuery.rightSidebarOpen$;

  private activeGridData: InboxGridData;
  private refreshGridSubscription: Subscription;

  constructor(
    private logger: NGXLogger,
    private databasesQuery: DatabasesQuery,
    private applicationQuery: ApplicationQuery,
    private inboxesQuery: InboxesQuery,
    private inboxesService: InboxesService,
    private viewerService: ViewerService,
    private notify: NotificationService,
    private gridHelper: GridHelperService,
    private gridStatesService: GridSettingsService,
    private appQuery: ApplicationQuery,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  /**
   * Gets the grid api for the current grid.
   *
   * @returns An instance of GridApi or undefined if the grid does not exist.
   */
  get gridApi(): GridApi<any> | undefined {
    return this.inboxGrid?.api;
  }

  /**
   * Grid style attached to the AG grid element.
   *
   * Height will adjust for the presense of view tabs and table field controls.
   *
   * @returns CSS rule for the size of the grid control.
   */
  get gridStyle() {
    // Establish the height of all other controls (toolbars above and below).
    let totalControlHeight = 128;
    if (this.breakpointObserver.isMatched('(max-width: 599px)'))
      totalControlHeight -= 16;

    return {
      width: '100%',
      height: `calc(100vh - ${totalControlHeight}px)`,
    };
  }

  ngOnInit(): void {
    this.configureGrid();
  }

  /**
   * Right click event for the grid right click.
   *
   * @param event Mouse event.
   */
  onContextMenuClick(event: MouseEvent): void {
    this.gridHelper.openContextMenu(
      event,
      this.activeGridData.selectedRowNodes,
      (rowId) =>
        this.inboxGrid.api?.getRowNode(
          new DOMParser().parseFromString(rowId, 'text/html')?.body
            ?.textContent ?? ''
        ),
      this.inboxActionsMenu
    );
  }

  /**
   * Handler for open documents event.
   *
   * @param inboxOpenRequest Request to open documents.
   */
  onOpenDocumentSelectedDocuments(
    inboxOpenRequest?: InboxDocumentOpenRequest
  ): void {
    this.logger.debug('Opening selected documents.', inboxOpenRequest);
    const filesToOpen = inboxOpenRequest?.inboxFiles
      ? inboxOpenRequest.inboxFiles
      : this.activeGridData.selectedRowNodes.map((row) => row.data);
    this.openDocuments(
      filesToOpen,
      false,
      !!inboxOpenRequest?.forceExternalViewer
    );
  }

  /** Event handler for the refresh inbox event. */
  onRefreshInbox(): void {
    this.logger.debug('Refreshing the inbox data.');
    this.refreshActiveInbox();
  }

  /** Handler for toggle edit event. */
  onToggleEdit(): void {
    this.logger.debug('Toggling edit mode.');
    this.editModeEnabled = !this.editModeEnabled;
  }

  private configureGrid(): void {
    this.gridOptions = this.gridHelper.createInboxGridOptions(
      this.inboxesQuery.activeId,
      {
        onGridReady: () => {
          this.listenToInboxesLoading();
          this.listenToResultsForRows();
        },
        onRowSelected: (params) => this.activeGridData.onRowSelected(params),
        onCellEditingStopped: (params) => this.onCellEditingStopped(params),
      },
      false,
      (params) => this.gridHelper.editableInboxRow(params, this.editModeEnabled)
    );
    this.appQuery.doubleClickToOpenDocuments$
      .pipe(untilDestroyed(this))
      .subscribe((doubleClickToOpen) => {
        this.gridOptions.onRowDoubleClicked = doubleClickToOpen
          ? (dblClickEvent) => this.onRowDoubleClicked(dblClickEvent)
          : undefined;
      });
  }

  private listenToInboxesLoading(): void {
    this.inboxesQuery
      .selectLoading()
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => {
        if (isLoading) {
          this.inboxGrid.api?.showLoadingOverlay();
        } else {
          this.inboxGrid.api?.hideOverlay();
        }
      });
  }

  private listenToResultsForRows(): void {
    // Subscribe but take values only if there are files since that is all we care about here.
    this.inbox$.subscribe((inbox) => {
      this.logger.debug('Active inbox updated.', inbox);
      this.activeGridData = this.gridStatesService.getOrCreateInboxgridData(
        inbox.id
      );

      // Ensure we unsubscribe from previous refresh event first.
      this.refreshGridSubscription?.unsubscribe();

      this.refreshGridSubscription = this.activeGridData.gridRefresh.subscribe(
        () => this.onRefreshInbox()
      );
      // Ensure there are no previously selected rows.
      this.activeGridData.selectedRowNodes.length = 0;
      const rowData = this.gridHelper.getInboxRowData(inbox.files);
      this.inboxGrid.api.updateGridOptions({ rowData: rowData });
    });
  }

  private onCellEditingStopped(params: CellEditingStoppedEvent): void {
    this.logger.debug('Row editting stopped.', params);
    if (params.newValue === undefined || params.oldValue === params.newValue) {
      this.logger.debug('The cell value was not changed.');
      return;
    }

    this.inboxesService
      .renameDocument(this.inboxesQuery.getActiveId() as number, {
        newName: params.newValue,
        filename: params.oldValue,
        fileType: params.data.fileType,
        dateCreated: params.data.dateCreated,
        dateModified: params.data.dateModified,
        permissions: params.data.permissions,
      })
      .subscribe({
        next: () => {
          this.notify.success('FILES_RENAMED_SUCCESSFULLY');
          this.refreshActiveInbox();
        },
        error: (error: UserFriendlyError) => {
          error.i18n = 'this.notify.error(error)';
          this.notify.error(error);
        },
      });
  }

  private onRowDoubleClicked(rowDblClickEvent: RowDoubleClickedEvent): void {
    this.logger.debug('Row double clicked.', rowDblClickEvent);
    if (this.editModeEnabled) {
      this.logger.debug(
        'Edit mode is enabled. Double click to open is disabled.'
      );
      return;
    }

    this.logger.debug('Opening double clicked documents.');
    const mouseEvent = rowDblClickEvent.event as MouseEvent;
    const rowsToOpen =
      rowDblClickEvent.node.isSelected() &&
      (mouseEvent.ctrlKey || mouseEvent.shiftKey)
        ? this.activeGridData.selectedRowNodes
        : [rowDblClickEvent.node];
    this.openDocuments(rowsToOpen.map((row) => row.data));
  }

  private openDocuments(
    inboxFiles: InboxFiles,
    forceInternalDocumentViewer = false,
    forceExternalDocumentViewer = false
  ): void {
    if (!forceExternalDocumentViewer && !forceInternalDocumentViewer) {
      this.logger.warn(
        'Both internal and external viewer can not be forced for use here, external viewer will be selected.'
      );
    }
    const useInternalDocumentViewer =
      (this.appQuery.viewerUseInternal || forceInternalDocumentViewer) &&
      !forceExternalDocumentViewer;
    if (useInternalDocumentViewer) {
      this.openInDocumentView(
        inboxFiles,
        this.inboxesQuery.activeId,
        this.databasesQuery.activeId
      );
    } else {
      // Use an external viewer session.
      const session: InboxSession = {
        database: this.databasesQuery.activeId,
        documents: inboxFiles.map((inboxFile) =>
          createInboxSessionDocumentFromInboxFile(
            inboxFile,
            this.inboxesQuery.activeId
          )
        ),
      };

      this.viewerService.createInboxSession(session).subscribe({
        next: (sessionId) => {
          this.viewerService
            .openViewerSession(sessionId, this.appQuery.alwaysOpenNewTab)
            .subscribe(() => {
              this.logger.debug('Viewer closed.');
              this.refreshActiveInbox();
            });
        },
        error: (error: UserFriendlyError) => {
          error.i18n = 'ERROR_CREATE_SESSION_MSG';
          this.notify.error(error);
        },
      });
    }
  }

  private openInDocumentView(
    inboxFiles: InboxFiles,
    inboxId: number | undefined,
    databaseId: number | undefined
  ) {
    // Map the grid data row nodes to inbox session document objects.
    assertExists(inboxId);
    const selectedDocuments = inboxFiles.map((inboxFile) =>
      createInboxSessionDocumentFromInboxFile(inboxFile, inboxId)
    );

    // Inbox documents are key by name.
    const documentKey = (document: InboxSessionDocument) =>
      encodeURIComponent(document.filename);

    if (this.appQuery.alwaysOpenNewTab) {
      const urlTree = this.router.createUrlTree([
        'db',
        databaseId,
        'inbox',
        inboxId,
        'document',
        // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
        selectedDocuments
          .map(documentKey)
          .join(),
      ]);
      const viewerWindow = window.open(urlTree.toString(), '_blank');
      assertExists(
        viewerWindow,
        'Internal viewer window must exist to listen for close event.'
      );

      if (this.appQuery.refreshResultsWhenClosingDocumentTabs) {
        const intervalSubscription = interval(1000)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            if (viewerWindow.closed) {
              intervalSubscription.unsubscribe();
              this.refreshActiveInbox();
            }
          });
      }
    } else {
      // Open documents.
      this.logger.debug('Navigate to document.');
      this.router.navigate(
        [
          'db',
          databaseId,
          'inbox',
          inboxId,
          'document',
          // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
          selectedDocuments
            .map(documentKey)
            .join(),
        ],
        { queryParamsHandling: 'merge' }
      );
    }
  }

  private refreshActiveInbox() {
    // Deselect the documents.
    this.inboxGrid.api?.deselectAll();

    const activeInboxId = this.inboxesQuery.getActiveId() as number;
    this.inboxGrid.api.updateGridOptions({
      rowData: [],
    });
    this.inboxesService.getById(activeInboxId).subscribe();
  }
}
