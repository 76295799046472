import { Component } from '@angular/core';

/** Displays a card intended for use when the internal viewer cannot render a document during import. */
@Component({
  selector: 'app-import-viewer-unavailable',
  templateUrl: './import-viewer-unavailable.component.html',
  styleUrls: ['./import-viewer-unavailable.component.scss'],
  standalone: false,
})
export class ImportViewerUnavailableComponent {}
