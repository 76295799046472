import { Component } from '@angular/core';
import { map } from 'rxjs';

import { SearchesQuery } from 'src/app/state/searches/searches.query';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: false,
})
export class DashboardComponent {
  /** Observable of presense of dashboard searches. */
  hasDashboardSearches$ = this.searchesQuery.dashboardSearches$.pipe(
    map((searches) => searches.length > 0)
  );

  constructor(private searchesQuery: SearchesQuery) {}
}
