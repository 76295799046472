import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import {
  AgGridEvent,
  CellKeyDownEvent,
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  Column,
  FullWidthCellKeyDownEvent,
  GridApi,
  GridOptions,
  TabToNextCellParams,
} from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { Observable, tap } from 'rxjs';

import { assert, assertExists } from 'common';
import {
  Archive,
  DocumentUpdateProvider,
  Field,
  SearchProvider,
  TableField,
  TableFieldItem,
  UserFriendlyError,
} from 'models';
import {
  DOCUMENT_UPDATE_PROVIDER,
  SEARCH_PROVIDER,
} from 'src/app/common/tokens';
import { DirtyComponent, DocumentUpdateSession } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ActiveTableField,
  TableFieldUIService,
} from 'src/app/services/table-field-ui.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';

import { UiService } from 'src/app/services/ui.service';
import { ListsService } from 'src/app/state/lists/lists.service';
import { TableFieldGridStatesQuery } from 'src/app/state/table-field-grid-states/table-field-grid-states.query';
import { TableFieldGridStatesService } from 'src/app/state/table-field-grid-states/table-field-grid-states.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../confirmation-dialog/confirmation-dialog.component';
import {
  getCellEditor,
  getCellRenderer,
  getFieldCellEditorProperties,
} from '../grid-cell-components/cell-selectors';
import { FieldCellEditorBaseComponent } from '../grid-cell-components/field-cell-editor-base.component';

/** Table field grid. */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-table-field-grid',
  templateUrl: './table-field-grid.component.html',
  styleUrls: ['./table-field-grid.component.scss'],
  standalone: false,
})
export class TableFieldGridComponent
  implements OnInit, OnChanges, OnDestroy, DirtyComponent
{
  /** Archive id. Defaults to the active id in the route. */
  @Input({ required: true })
  archiveId: number;
  /** Determines if the grid columns are currently editable. */
  @Input()
  editModeEnabled: boolean;
  @Input()
  viewingRevision = false;
  /** AG Grid instance. */
  @ViewChild('tableFieldGrid')
  grid: AgGridAngular;
  /** Last/current table field event being displayed. */
  activeTableField: ActiveTableField;
  /** Table field grid options. */
  gridOptions: GridOptions;
  /** Whether the current user is guest. */
  isGuest = this.auth.isGuest;

  private activeEditor: FieldCellEditorBaseComponent;
  /** Form control of the currently active cell editor.
   * This is **ONLY** used to track the dirty state of a cell that is currently being editted.
   */
  private activeEditorControl = new UntypedFormControl();
  private archive: Archive;
  /** Whether the grid is dirty. This tracks cell updates, and adding/removing rows.
   * The 'activeEditorControl' reference is used to track the currently active editor if there is one.
   */
  private gridIsDirty = false;
  private tableFieldItem: TableFieldItem;

  /**
   * Whether edit is enabled.
   *
   * @returns True if edit mode controls should be disabled.
   */
  get disableEdit(): boolean {
    return !this.archive.permissions.modifyData || !this.editModeEnabled;
  }

  /**
   *  Current GridApi.
   *
   * @returns GridApi.
   */
  private get gridApi(): GridApi {
    return this.grid.api;
  }

  /** @inheritdoc */
  get isDirty(): boolean {
    return this.gridIsDirty || this.activeEditorControl.dirty;
  }

  /**
   * Should the save button be displayed.
   *
   * @returns True if the save button should be displayed.
   */
  get showSave(): boolean {
    return (
      this.activeTableField?.documentId !== 0 &&
      this.activeTableField?.documentSecureId !== ''
    );
  }

  /**
   * Gets the table field from the table field event.
   *
   * @returns The current table field.
   */
  get tableField(): TableField | undefined {
    return this.activeTableField?.tableField;
  }

  constructor(
    private logger: NGXLogger,
    private auth: AuthenticationService,
    private notify: NotificationService,
    private archivesQuery: ArchivesQuery,
    private listService: ListsService,
    private tableFieldUIService: TableFieldUIService,
    private ui: UiService,
    private databasesQuery: DatabasesQuery,
    private tableFieldGridStatesQuery: TableFieldGridStatesQuery,
    private tableFieldGridStatesService: TableFieldGridStatesService,
    private dialog: MatDialog,
    @Inject(SEARCH_PROVIDER)
    private searchProvider: SearchProvider,
    @Inject(DOCUMENT_UPDATE_PROVIDER)
    private documentUpdateProvider: DocumentUpdateProvider
  ) {}

  /**
   * Adds the provided value to the focused column.
   *
   * @param values Array of values to be added to a column.
   */
  addColumnData(values: string[]): void {
    // start with currently focused cell
    let focusedCell = this.grid.api.getFocusedCell();
    let lastRowIndex = this.grid.api.getLastDisplayedRowIndex();
    const allColumns = this.grid.api.getAllDisplayedColumns();
    if (!focusedCell) {
      // if there is no focused then create a new row and focus first column
      this.logger.warn(
        'No cell is focused. Adding a new row and inserting into the first column.'
      );
      this.addRow(lastRowIndex);
      lastRowIndex = this.grid.api.getLastDisplayedRowIndex();
      this.grid.api.setFocusedCell(lastRowIndex, allColumns[0]);
      focusedCell = this.grid.api.getFocusedCell();
    }
    // It shouldn't be possible for this to be null so should just tell typescript everything is fine
    // but if it doesn't at least there will be an error.
    assertExists(focusedCell, 'There must be a focused cell.');
    const currentColumnIndex = allColumns.indexOf(focusedCell.column);
    const startingRowIndex = focusedCell.rowIndex;
    for (const value of values) {
      this.addDataToFocusedCell(value, false);
      // Do not add another row if this is the last column to be added.
      if (values.indexOf(value) + 1 === values.length) {
        continue;
      }
      const nextRowIndex = focusedCell.rowIndex + 1;
      const nextRowNode = this.grid.api.getDisplayedRowAtIndex(nextRowIndex);
      if (!nextRowNode) {
        // Insert new row after a cell update ONLY if there isn't one otherwise just update column for the next row
        this.addRow(lastRowIndex);
        lastRowIndex++;
      }

      this.focusCellForEditing(nextRowIndex, focusedCell.column);
      focusedCell = this.grid.api.getFocusedCell();
      assertExists(
        focusedCell,
        'There must be a focused cell after focus change.'
      );
    }
    // After complete we go to the row we started in at the next column.
    // If at the last column, create a new row and focus the first cell
    if (currentColumnIndex >= allColumns.length - 1) {
      lastRowIndex = this.grid.api.getLastDisplayedRowIndex();
      this.addRow(lastRowIndex);
      this.focusCellForEditing(lastRowIndex, allColumns[0]);
    } else {
      // Go to the row we started in at the next column.
      this.focusCellForEditing(
        startingRowIndex,
        allColumns[currentColumnIndex + 1]
      );
    }
  }

  /**
   * Inserts the provided value into the currently focused cell.
   *
   * @param value Value to insert into the focused cell.
   * @param focusNextCell Whether to focus the next cell after inserting the value.
   * @throws {Error} If there is no focused cell.
   */
  addDataToFocusedCell(value: string, focusNextCell: boolean): void {
    let focusedCell = this.grid.api.getFocusedCell();
    let lastRowIndex = this.grid.api.getLastDisplayedRowIndex();
    const allColumns = this.grid.api.getAllDisplayedColumns();
    if (!focusedCell) {
      this.logger.warn(
        'No cell is focused. Adding a new row and inserting into the first column.'
      );
      this.addRow(lastRowIndex);
      lastRowIndex = this.grid.api.getLastDisplayedRowIndex();
      this.grid.api.setFocusedCell(lastRowIndex, allColumns[0]);
      focusedCell = this.grid.api.getFocusedCell();
    }
    // It shouldn't be possible for this to be null so should just tell typescript everything is fine
    // but if it doesn't at least there will be an error.
    assertExists(focusedCell, 'There must be a focused cell.');
    const rowNode = this.grid.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
    assertExists(rowNode, 'Row node must exist.');
    const colId = focusedCell.column.getColId();
    const cellEditors = this.grid.api.getCellEditorInstances({
      rowNodes: [rowNode],
      columns: [colId],
    });
    assert(cellEditors.length > 0, 'There must be a cell editor.');
    const cellEditor = cellEditors[0] as FieldCellEditorBaseComponent;

    const handleFocus = () => {
      const currentColumnIndex = allColumns.indexOf(focusedCell.column);
      if (focusNextCell) {
        const nextColumn =
          currentColumnIndex < allColumns.length - 1
            ? allColumns[currentColumnIndex + 1]
            : allColumns[0];
        const nextRowIndex =
          currentColumnIndex < allColumns.length - 1
            ? focusedCell.rowIndex
            : focusedCell.rowIndex + 1;

        // Add a row if we are at the last column and there is no next row.
        if (
          currentColumnIndex >= allColumns.length - 1 &&
          nextRowIndex > lastRowIndex
        ) {
          this.addRow(lastRowIndex);
        }

        this.focusCellForEditing(nextRowIndex, nextColumn);
      } else {
        this.focusCellForEditing(focusedCell.rowIndex, focusedCell.column);
      }
    };

    const isDynamicList =
      cellEditor.cellParams.field.list.primary > 0 ||
      cellEditor.cellParams.field.list.secondary > 0;
    if (
      (isDynamicList || cellEditor.cellParams.field.list.listId > 0) &&
      !cellEditor.formControl.disabled
    ) {
      const list$ = isDynamicList
        ? this.listService.getDynamicList(
            cellEditor.cellParams.field.id,
            cellEditor.cellParams.data[
              'field_' + cellEditor.cellParams.field.list.primary
            ],
            cellEditor.cellParams.data[
              'field_' + cellEditor.cellParams.field.list.secondary
            ] || ''
          )
        : this.listService.get(cellEditor.cellParams.field.list.listId);
      list$.subscribe((list) => {
        if (list.values.find((v) => v === value)) {
          // Change value if value exists in list.
          assertExists(focusedCell?.column, 'There must be a focused column.');
          cellEditor.formControl.setValue(value);
          rowNode.setDataValue(focusedCell.column, value);
          handleFocus();
        } else {
          // Throw error if value does not exist in list.
          this.notify.error(
            new UserFriendlyError(
              'Invalid list value.',
              'List value does not exist in the list.',
              'INVALID_LIST_VALUE',
              { value: value }
            )
          );
        }
      });
    } else if (!cellEditor.formControl.disabled) {
      cellEditor.formControl.setValue(value);
      rowNode.setDataValue(focusedCell.column, value);
      handleFocus();
    }
  }

  /**
   * Adds the provided data as a new row at the bottom of the table field.
   *
   * @param columnValues Map where key is the column field id and value is the column value.
   * @throws {Error} Because it is not yet implemented.
   */
  addRowData(columnValues: Map<number, string>): void {
    throw new Error('Not yet implemented.');
    // const rowItem: any = {};
    // for (const [fieldId, value] of columnValues) {
    //   rowItem[`field_${fieldId}`] = value;
    // }

    // // Add a row to existing rows or initialize rows with the provided data.
    // this.grid.rowData?.push(rowItem) ?? [rowItem];
  }

  /**
   * Clears all data from the table field.
   *
   * @param shouldBeDirty Whether the grid should be considered dirty after this operation.
   */
  clearData(shouldBeDirty: boolean): void {
    const rowItem: any = {};
    rowItem.id = 1;
    for (const fieldId of this.activeTableField.tableField.fieldIds) {
      rowItem[`field_${fieldId}`] = '';
    }

    this.gridApi.updateGridOptions({ rowData: [rowItem] });

    this.gridIsDirty = shouldBeDirty;
  }
  /**
   * Focus table field.
   *
   * @param rowIndex - Row index.
   * @param columnOrKey - Column or key.
   */
  focusCellForEditing(
    rowIndex: number,
    columnOrKey: string | Column<any>
  ): void {
    this.grid.api.setFocusedCell(rowIndex, columnOrKey);
    this.grid.api.startEditingCell({ rowIndex, colKey: columnOrKey });
  }

  /**
   * Forces the dirty state to whatever is provided.
   *
   * This does **not** reset the grid in any way. It only seeks to make the grid dirty state appear as whatever is provided.
   * Do not use this unless you know what you are doing.
   *
   * @param isDirty Whether the grid should be considered dirty.
   */
  forceSetGridDirty(isDirty: boolean) {
    this.gridIsDirty = isDirty;
  }

  /**
   * Gets the field for which functions like addDataToFocusedCell will use to insert data next.
   *
   * @returns The field that will be the next field addDataToFocusedCell uses to insert data.
   */
  getTargetField(): Field {
    const allColumns = this.grid.api.getAllDisplayedColumns();
    const focusedCell = this.grid.api.getFocusedCell();
    const column = focusedCell ? focusedCell.column : allColumns[0];
    const fields = this.archivesQuery
      .getFields(this.archiveId)
      .filter((field) =>
        this.activeTableField.tableField.fieldIds.includes(field.id)
      );

    const fieldId = Number(column.getColId().split('_')[1]); //field_{id}
    const field = fields.find((field) => field.id === fieldId);
    assertExists(field, 'The field must exist.');
    return field;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const editModeEnabledChange = changes.editModeEnabled;
    if (
      // Only check these changes if edit mode is the change.
      editModeEnabledChange &&
      !editModeEnabledChange.isFirstChange() && // do not check on first change since grid should just be initing
      editModeEnabledChange.currentValue !==
        editModeEnabledChange.previousValue &&
      !editModeEnabledChange.currentValue
    ) {
      // Turns off current cell editing when edit mode is turned off.
      this.grid.api?.stopEditing();
    }
  }

  ngOnDestroy(): void {
    // Unregister component from the UI.
    this.ui.unregister(this);
  }

  ngOnInit(): void {
    this.archive = this.archivesQuery.getArchive(this.archiveId);
    this.configureGrid();

    // Register as active UI component.
    this.ui.register(this);
  }

  /**
   * Event handler for the add row button.
   *
   * @throws {Error} If the table field insert index is null.
   * */
  onClickAddRow(): void {
    this.logger.debug('Add new row clicked.');
    const selectedRows = this.gridApi.getSelectedNodes();
    const insertIndex =
      selectedRows.length === 1
        ? selectedRows[0].rowIndex
        : this.gridApi.getDisplayedRowCount();
    if (insertIndex === null) {
      throw new Error('Table field insert index can not be null.');
      // TODO: This may require explicitly supporting 0 instead in this scenario if this error occurs to account
      // for inserting into a completely empty set. Set here as a throw to bring that to light if it is the case.
    }
    this.addRow(insertIndex);
  }

  /**
   * Event handler for the close button.
   */
  onClickClose(): void {
    if (this.isDirty) {
      this.confirmDiscardChanges().subscribe((confirmed) => {
        if (confirmed) {
          this.tableFieldUIService.clear();
        }
      });
    } else {
      this.tableFieldUIService.clear();
    }
  }

  /** Event handler for the delete rows button. */
  onClickDelete(): void {
    this.logger.debug('Delete rows button clicked.');
    const selectedRowNodes = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedRowNodes });
    this.gridIsDirty = true;
  }

  /**
   * Event handler for the save button.
   */
  onClickSave(): void {
    this.logger.debug('Save button clicked.');
    this.save().subscribe({
      next: () => {
        this.notify.success('Table data save successful.');
      },
      error: (error: UserFriendlyError) => this.notify.error(error),
    });
  }

  /**
   * Saves the current table data to the requested document.
   *
   * @param documentId Document Id.
   * @param secureId Document Secure Id.
   * @param updateSession Document update session.
   * @returns An observable which completes once the save completes.
   */
  saveTableData(
    documentId: number,
    secureId: string,
    updateSession: DocumentUpdateSession
  ): Observable<void> {
    return this.save(documentId, secureId, updateSession);
  }

  private addRow(index: number): void {
    // const columnsArray: string[] = [];
    const rowItem: any = {};
    for (const fieldId of this.tableFieldItem.fields) {
      // columnsArray.push('');
      rowItem[`field_${fieldId}`] = '';
    }
    this.gridApi.applyTransaction({
      addIndex: index + 1,
      add: [rowItem],
    });
    this.gridIsDirty = true;
  }

  private configureGrid(): void {
    // todo a lot of the config of ag grid mirrors what is happening in the archive view component
    // there might be some room to DRY things out here and there.
    this.gridOptions = {
      columnDefs: [],
      rowSelection: {
        mode: 'multiRow',
        checkboxes: true,
      },
      singleClickEdit: true,
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      onGridReady: () => this.listenToTableField(),
      onFirstDataRendered: (params) => this.resetStateIfNoneSaved(params),
      onRowDataUpdated: (params) => this.resetStateIfNoneSaved(params),
      onCellEditingStarted: (event) => {
        this.logger.debug('Cell editting started.', event);
        const cellEditors = event.api.getCellEditorInstances();
        if (cellEditors.length > 1) {
          this.logger.warn(
            'More than one cell editor existed. This is unexpected and only the first one will be used to track the dirty state.'
          );
        }
        this.activeEditor = cellEditors[0] as FieldCellEditorBaseComponent;
        this.activeEditorControl = this.activeEditor.formControl;
      },
      onCellEditingStopped: (event) => {
        this.logger.debug('Cell editting stopped.', event);
        this.activeEditorControl = new UntypedFormControl();
      },
      onCellValueChanged: (params) => this.onCellValueChanged(params),
      tabToNextCell: (params) => this.onTabToNextCell(params),
      onCellKeyDown: (params) => this.onCellKeyPress(params),
      onColumnResized: (params) => {
        if (params.finished) {
          this.saveColumnState(params);
        }
      },
      onSortChanged: (params) => this.saveColumnState(params),
      onColumnVisible: (params) => this.saveColumnState(params),
      onDragStopped: (params) => this.saveColumnState(params),
    };
  }

  private confirmDiscardChanges(): Observable<boolean> {
    const data: ConfirmationDialogData = {
      cancelActionText: 'NO',
      confirmActionText: 'YES',
      contents: 'CONFIRM_GENERIC_UNSAVED_CHANGES',
      title: 'USAVED_CHANGES_WILL_BE_LOST',
    };
    return this.dialog
      .open(ConfirmationDialogComponent, { data })
      .afterClosed();
  }

  /**
   * Converts table data from the grid into the object required for save.
   *
   * @returns A two dimensional array of table field values.
   */
  private getTableFieldItemData(): string[][] {
    const tableData: string[][] = [];
    this.gridApi.forEachNode((rowNode) => {
      this.logger.debug('Grid row', rowNode);
      tableData[rowNode.rowIndex as number] = [];
      const rowDataArray: string[] = [];
      for (const fieldId of this.tableFieldItem.fields) {
        const columnData = rowNode.data[`field_${fieldId}`] ?? '';
        rowDataArray.push(columnData);
      }
      tableData[rowNode.rowIndex as number] = rowDataArray;
    });
    return tableData;
  }

  private listenToTableField(): void {
    this.tableFieldUIService.activeTableField$
      .pipe(untilDestroyed(this))
      .subscribe((tfChangeEvent) => {
        if (tfChangeEvent) {
          const loadNewTableField = () => {
            this.activeTableField = tfChangeEvent;
            this.loadGrid(tfChangeEvent);
          };

          if (this.isDirty) {
            this.confirmDiscardChanges().subscribe((confirmed) => {
              if (confirmed) {
                loadNewTableField();
              }
            });
          } else {
            loadNewTableField();
          }
        }
      });
  }

  private loadGrid(tfChangeEvent: ActiveTableField): void {
    const tableField = tfChangeEvent.tableField;
    const fields = this.archivesQuery
      .getFields(this.archiveId)
      .filter((field) => tableField.fieldIds.includes(field.id));
    this.tableFieldItem = {
      id: tableField.id,
      fields: fields.map((field) => field.id),
      data: [],
    };
    const isImport = tfChangeEvent.documentId === 0;
    const canEdit =
      this.archive.permissions.modifyData ||
      (isImport && this.archive.permissions.addNewDocuments);
    const newCols: ColDef[] = fields.map((field, index) => ({
      field: `field_${field.id}`,
      colId: `field_${field.id}`,
      headerName: field.name,
      sortable: true,
      resizable: true,
      editable: () => this.editModeEnabled && canEdit,
      cellDataType: false, // Ag grid cannot property check type in table fields
      cellEditor: getCellEditor(field),
      cellEditorPopup: true,
      cellEditorPopupPosition: 'over',
      cellEditorParams: getFieldCellEditorProperties(field),
      cellRenderer: getCellRenderer(field),
      cellRendererParams: {
        format: field.format,
        type: field.type,
      },
    }));

    // Clear any existing column definitions because having them can cause issues with
    // colid being set to `field_${field.id}_1` if the column already existed.
    this.gridApi.updateGridOptions({ columnDefs: [] });
    // Update the column definitions.
    this.gridApi.updateGridOptions({ columnDefs: newCols });

    const columnStates =
      this.tableFieldGridStatesQuery.getTableFieldGridColumnStates(
        this.databasesQuery.activeId.toString(),
        this.archiveId.toString(),
        tableField.id.toString()
      );

    this.gridApi.applyColumnState({
      state: columnStates,
      applyOrder: true,
    });

    if (isImport) {
      // Do not try to load data for a document that doesn't exist.
      const rowItem: any = {};
      rowItem.id = 1;
      for (const fieldId of tableField.fieldIds) {
        rowItem[`field_${fieldId}`] = '';
      }

      this.gridApi.updateGridOptions({ rowData: [rowItem] });

      // Emit when tableFieldLoaded.
      this.tableFieldUIService.onTableFieldLoaded.emit(tfChangeEvent);
      return;
    }
    const archiveId = this.viewingRevision
      ? this.archivesQuery.getVersionsArchive().id
      : this.archiveId;
    this.gridApi.showLoadingOverlay();
    this.searchProvider
      .getTableData(
        this.databasesQuery.activeId,
        archiveId,
        tfChangeEvent.documentId,
        tfChangeEvent.documentSecureId
      )
      .subscribe((results) => {
        this.logger.debug('Table data retrieved from search.', results);
        const rowData = results.map((result, index) => {
          const rowItem: any = {};
          rowItem.id = index + 1;
          for (const field of result.fields.filter((f) =>
            tableField.fieldIds.includes(f.id)
          )) {
            rowItem[`field_${field.id}`] = field.value;
          }
          return rowItem;
        });

        this.gridApi.updateGridOptions({ rowData: rowData });
        this.gridApi.hideOverlay();

        // Emit when tableFieldLoaded.
        this.tableFieldUIService.onTableFieldLoaded.emit(tfChangeEvent);
      });
  }

  private onCellKeyPress(
    event: CellKeyDownEvent<any, any> | FullWidthCellKeyDownEvent<any, any>
  ): void {
    if (event.event) {
      const keyboardEvent = event.event as KeyboardEvent;
      if (
        !this.disableEdit &&
        keyboardEvent.ctrlKey &&
        keyboardEvent.key.toLowerCase() === 'delete'
      ) {
        this.logger.debug('Ctrl + Delete was pressed in grid row.', event);
        this.gridApi.applyTransaction({ remove: [event.node.data] });
        this.gridIsDirty = true;
      }
    }
  }

  private onCellValueChanged(event: CellValueChangedEvent): void {
    this.logger.debug('Cell value changed event fired.', event);
    this.gridIsDirty = true;
  }

  private onTabToNextCell(params: TabToNextCellParams): CellPosition | boolean {
    // Check if we need to add a new row to the end and go to it.
    if (params.editing && !params.backwards && !params.nextCellPosition) {
      this.logger.debug(
        'The last row/column of the table has been reached in edit mode. A new row will be created.'
      );
      this.addRow(this.gridApi.getLastDisplayedRowIndex());
      // target the newly created row
      const lastRowNode = this.gridApi.getLastDisplayedRowIndex();
      const newCellPosition: CellPosition = {
        column: this.gridApi.getAllGridColumns()[0],
        rowIndex: lastRowNode,
        rowPinned: undefined,
      };
      return newCellPosition;
    }

    return params.nextCellPosition || false;
  }

  private resetStateIfNoneSaved(params: AgGridEvent) {
    if (!this.tableField) return;
    if (
      !this.tableFieldGridStatesQuery.tableFieldGridHasState(
        this.databasesQuery.activeId.toString(),
        this.archiveId.toString(),
        this.tableField.id.toString()
      )
    ) {
      params.api.sizeColumnsToFit();
    }
  }

  private save(
    documentId = this.activeTableField.documentId,
    secureId = this.activeTableField.documentSecureId,
    updateSession = new DocumentUpdateSession(false, false, false, true)
  ): Observable<void> {
    // Ensure there are no open cell editors.
    this.grid.api.stopEditing();
    this.tableFieldItem.data = this.getTableFieldItemData();

    this.logger.debug(
      'Field data will be updated.',
      documentId,
      secureId,
      this.tableFieldItem
    );

    return this.documentUpdateProvider
      .updateTableFieldData(
        this.databasesQuery.activeId,
        this.archiveId,
        documentId,
        secureId,
        [this.tableFieldItem],
        updateSession
      )
      .pipe(
        tap(() => {
          this.gridIsDirty = false;
        })
      );
  }

  private saveColumnState(params: AgGridEvent): void {
    const columnStates = params.api.getColumnState();
    this.logger.debug('Storing column state.', columnStates);
    this.tableFieldGridStatesService.storeTableFieldGridColumnState(
      this.databasesQuery.activeId.toString(),
      this.archiveId.toString(),
      this.tableFieldItem.id.toString(),
      columnStates
    );
  }
}
