import { ColDef, SelectionColumnDef } from 'ag-grid-community';

import { Field, FieldDataType, ListType, TableField } from 'models';

import { CharacterCellEditorComponent } from './character-cell-editor/character-cell-editor.component';
import { DateTimeCellEditorComponent } from './date-time-cell-editor/date-time-cell-editor.component';
import { DateTimeCellRendererComponent } from './date-time-cell-renderer/date-time-cell-renderer.component';
import { DecimalCellEditorComponent } from './decimal-cell-editor/decimal-cell-editor.component';
import { DecimalCellRendererComponent } from './decimal-cell-renderer/decimal-cell-renderer.component';
import { DropdownCellEditorComponent } from './dropdown-cell-editor/dropdown-cell-editor.component';
import { FieldCellEditorProperties } from './field-cell-editor-parameters';
import { FieldCellRendererProperties } from './field-cell-renderer-parameters';
import { MultiValueCellRendererComponent } from './multi-value-cell-renderer/multi-value-cell-renderer.component';
import { NumericCellEditorComponent } from './numeric-cell-editor/numeric-cell-editor.component';
import { TableFieldCellRendererProperties } from './table-field-cell-renderer-parameters';
import { ThumbnailPreviewRendererComponent } from './thumbnail-preview-renderer/thumbnail-preview-renderer.component';
import { ThumbnailSource } from './thumbnail-source';
import { TypeaheadCellEditorComponent } from './typeahead-cell-editor/typeahead-cell-editor.component';

/** Selection Order Column ID. */
export const SELECTION_ORDER_COLUMN_ID = 'selectionOrder';

/**
 * Get the cell editor component name.
 *
 * @param field Field.
 * @returns The cell editor component to be used or undefined if the default should be used.
 */
export const getCellEditor = (field: Field): any | undefined => {
  let cellEditor;
  if (
    (field.list.listId > 0 && field.list.type === ListType.dropdown) ||
    field.list.primary > 0 ||
    field.list.secondary > 0
  ) {
    return DropdownCellEditorComponent; //'dropdownCellEditorComponent';
  }
  if (field.list.listId > 0 && field.list.type === ListType.typeahead) {
    return TypeaheadCellEditorComponent;
  }
  switch (field.type) {
    case FieldDataType.integer:
      cellEditor = NumericCellEditorComponent;
      break;
    case FieldDataType.decimal:
      cellEditor = DecimalCellEditorComponent;
      break;
    case FieldDataType.date:
      cellEditor = DateTimeCellEditorComponent;
      break;
    case FieldDataType.character:
      cellEditor = CharacterCellEditorComponent;
      break;
  }

  return cellEditor;
};

/**
 * Get field cell editor properties.
 *
 * @param field Field object.
 * @returns A FieldCellEditorProperties object.
 */
export const getFieldCellEditorProperties = (
  field: Field
): FieldCellEditorProperties => ({
  field,
});

/**
 * Get field cell editor properties.
 *
 * @param field Field object.
 * @returns A e FieldCellRendererProperties object.
 */
export const getFieldCellRendererProperties = (
  field: Field
): FieldCellRendererProperties => ({
  format: field.format,
  multiValue: field.multiValue,
  type: field.type,
});

/**
 * Get the cell renderer component name.
 *
 * @param field Field.
 * @returns The cell renderer component or undefined if the default should be used.
 */
export const getCellRenderer = (field: Field): any | undefined => {
  let cellEditor;
  if (field.multiValue) {
    return MultiValueCellRendererComponent;
  }
  switch (field.type) {
    // case 'Numeric':
    // case FieldDataType.integer:
    //   cellEditor = 'numericCellRendererComponent';
    //   break;

    case FieldDataType.decimal:
      cellEditor = DecimalCellRendererComponent;
      break;
    case FieldDataType.date:
      cellEditor = DateTimeCellRendererComponent;
      break;
  }

  return cellEditor;
};

/**
 * Get the table field cell renderer properties.
 *
 * @param tableField Table field.
 * @returns A table field cell renderer properties instance.
 */
export const getTableFieldCellRendererProperties = (
  tableField: TableField
): TableFieldCellRendererProperties => ({
  tableField,
});

/** Thumbnail column Id. */
export const THUMBNAIL_COL_ID = 'thumnbail';

/**
 * Custom thumbnail column definition.
 *
 * @param thumbnailSource Thumbnail source.
 * @returns A ColDef.
 */
export const createThumbnailColumnDefinition = (
  thumbnailSource: ThumbnailSource
): ColDef => {
  const columnDefinition: ColDef = {
    field: THUMBNAIL_COL_ID,
    headerName: '',
    sortable: false,
    filter: false,
    editable: false,
    lockPosition: true,
    width: 120,
    initialWidth: 120,
    minWidth: 120,
    maxWidth: 120,
    resizable: false,
    cellClass: 'thumbnail-preview-cell',
    cellRenderer: ThumbnailPreviewRendererComponent,
    cellRendererParams: thumbnailSource,
  };

  return columnDefinition;
};

/**
 * Custom thumbnail column definition.
 *
 * @param thumbnailSource Thumbnail source.
 * @returns A ColDef.
 */
export const createThumbnailSelectionColumnDefinition = (
  thumbnailSource: ThumbnailSource
): SelectionColumnDef => {
  const width = 140;
  const columnDefinition: SelectionColumnDef = {
    sortable: false,
    lockPosition: true,
    width: width,
    initialWidth: width,
    minWidth: width,
    maxWidth: width,
    resizable: false,
    cellClass: 'thumbnail-preview-cell',
    cellRenderer: ThumbnailPreviewRendererComponent,
    cellRendererParams: thumbnailSource,
  };

  return columnDefinition;
};
