import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import {
  Archive,
  ArchiveImportSession,
  ScanResult,
  Search,
  Searches,
  UserFriendlyError,
} from 'models';
import { removeFileExtension } from 'src/app/common/utility';
import { CSVImportDialogService } from 'src/app/services/csvimport-dialog.service';
import { GseService } from 'src/app/services/gse.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchUIService } from 'src/app/services/search-ui.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

import { assertTypeByKey } from 'common';
import { LayoutService } from 'src/app/services/layout.service';
import { ScanService } from 'src/app/services/scan.service';
import { MenuBaseComponent } from '../menu/menu-base.component';
import { ScanTargetEnum } from '../scan-dialog/scan-dialog.component';

/** Archive navigation menu. */
@Component({
  selector: 'app-navigation-archive-menu',
  templateUrl: './navigation-archive-menu.component.html',
  styleUrls: ['./navigation-archive-menu.component.scss'],
  standalone: false,
})
export class NavigationArchiveMenuComponent extends MenuBaseComponent {
  /** An event raised when the import button is clicked in the menu. */
  @Output()
  importFile = new EventEmitter();
  /** Archive. */
  archive: Archive;
  /** Observable containing the GSE connection status. */
  isGseConnected$ = this.gseService.isConnected$;
  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layoutService.isHandset$;
  /** Observable list of searches defined on the archive. */
  searches: Searches;

  private searchesListener: Subscription;

  constructor(
    private logger: NGXLogger,
    private layoutService: LayoutService,
    private appQuery: ApplicationQuery,
    private databasesQuery: DatabasesQuery,
    private searchesQuery: SearchesQuery,
    private notify: NotificationService,
    private viewerService: ViewerService,
    private gseService: GseService,
    private csvImportDialogService: CSVImportDialogService,
    private searchUIService: SearchUIService,
    private scanService: ScanService,
    private router: Router
  ) {
    super();
  }

  /**
   * Gets whether the user has add new document permissions on the archive.
   *
   * @returns Whether the user has add new document permissions or false if there is no archive.
   */
  get hasAddNewDocumentPermissions(): boolean {
    // if true there is no way the menu will even show.
    if (!this.archive) return false;
    return this.archive.permissions.addNewDocuments;
  }

  /**
   * Gets whether the search is the currently active one.
   *
   * @param searchId Search ID.
   * @returns Whether the search is the active search.
   */
  getIsActiveSearch(searchId: number): boolean {
    try {
      return Number(this.searchesQuery.activeId) === searchId;
    } catch {
      return false;
    }
  }

  /**
   * The click event for the import button.
   */
  onClickImport(): void {
    this.importFile.emit();
  }

  /**
   * The click event for the import CSV button.
   */
  onClickImportCSV(): void {
    this.logger.debug('Import CSV button clicked.');
    this.csvImportDialogService
      .createAndStartCSVImportJob(this.databasesQuery.activeId, this.archive.id)
      .subscribe({
        next: (jobId) => {
          this.logger.debug(
            'CSV dialog service returned a started job.',
            jobId
          );
        },
        error: (error) =>
          this.logger.error('Error in import csv dialog', error),
      });
  }

  /**
   * The click event for the import scan button.
   */
  onClickScan(): void {
    this.logger.debug('Scan clicked.', this.archive);
    const scanDialogResult$ = this.scanService.openScanDialog({
      target: ScanTargetEnum.archive,
    });
    scanDialogResult$.subscribe((result) => {
      assertTypeByKey<ScanResult>(result, 'uploadId', 'string');
      this.onScanDialogClose(result);
    });
  }

  /**
   * The click event for selecting a search.
   *
   * @param search Clicked search.
   */
  onClickSearch(search: Search): void {
    this.logger.debug('Search clicked.', search);
    this.searchUIService.runWithPrompt(
      search,
      this.databasesQuery.activeId,
      search.archives[0]
    );
  }

  /** Handler for the menu close event. */
  onMenuClosed(): void {
    // Stop listening for searches.
    this.searchesListener.unsubscribe();
    super.onMenuClosed();
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   * @param archive Targetted archive.
   */
  openMenu(mouseEvent: MouseEvent, archive: Archive): void {
    /** Set the current archive. */
    this.archive = archive;

    // Add the searches listener to load searches defined on the archive.
    this.searchesListener = this.searchesQuery
      .selectForParent(this.archive.id)
      .subscribe((searches) => (this.searches = searches));
    this.open(mouseEvent);
  }

  private onScanDialogClose(result: ScanResult): void {
    this.logger.debug('Scan dialog closed.', result);
    if (result) {
      this.logger.debug('Creating a new viewer session.');

      if (this.appQuery.viewerUseInternalForImport) {
        this.startInternalViewerImportSession(result.uploadId);
      } else {
        this.startViewerImportSession(result.uploadId);
      }
    }
  }

  private startInternalViewerImportSession(filename: string): void {
    this.logger.debug(
      'File uploads will be attached to an internal viewer session.'
    );

    this.router.navigate(
      [
        'db',
        this.databasesQuery.activeId,
        'archive',
        this.archive.id,
        'import',
        filename,
      ],
      { queryParamsHandling: 'merge' }
    );
  }

  private startViewerImportSession(filename: string) {
    const session: ArchiveImportSession = {
      database: this.databasesQuery.activeId,
      documents: [
        {
          archiveId: this.archive.id,
          filename: removeFileExtension(filename),
        },
      ],
    };
    this.viewerService.createSession(session).subscribe({
      next: (sessionId) =>
        this.viewerService
          .openViewerSession(sessionId, this.appQuery.alwaysOpenNewTab)
          .subscribe({
            next: () => {
              // todo refresh grid
              this.logger.debug('Viewer closed.');
            },
          }),
      error: (error: UserFriendlyError) =>
        this.notify.error({
          ...error,
          i18n: 'ERROR_CREATE_SESSION_MSG',
        }),
    });
  }
}
