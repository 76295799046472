import { Component, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';

import { assert } from 'common';
import { DXCMatch } from 'models';
import { FieldsQuery } from 'src/app/state/fields/fields.query';

/** DXC Multiple Match Selection Dialog. */
@Component({
  selector: 'app-dxc-multiple-match-selection-dialog',
  templateUrl: './dxc-multiple-match-selection-dialog.component.html',
  styleUrls: ['./dxc-multiple-match-selection-dialog.component.scss'],
  standalone: false,
})
export class DXCMultipleMatchSelectionDialogComponent {
  /** Grid component reference. */
  @ViewChild('dxcMultiMatchGrid')
  grid: AgGridAngular;
  /** Grid configuration. */
  gridOptions: GridOptions;

  /**
   * Whether the select button should be disabled.
   *
   * @returns A boolean indicating if the select button should be disabled.
   */
  get disableSelect(): boolean {
    return this.grid && this.grid.api.getSelectedNodes().length === 0;
  }

  constructor(
    private logger: NGXLogger,
    private fieldsService: FieldsQuery,
    private dialog: MatDialogReference<DXCMultipleMatchSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private matches: DXCMatch[]
  ) {
    this.configureGrid();
  }

  /** Handler for the select button click event. */
  onClickSelect(): void {
    const selectedRows = this.grid.api.getSelectedNodes();
    assert(selectedRows.length == 1, 'One and only one row must be selected.');
    const match = this.matches[selectedRows[0].data.id];
    this.dialog.close(match);
  }

  private configureGrid(): void {
    this.gridOptions = {
      rowSelection: {
        mode: 'singleRow',
        checkboxes: true,
      },
      tooltipShowDelay: 1000,
      pagination: false,
      getRowId: (params) => params.data.id,
      columnDefs: this.getColumnDefinitations(),
      rowData: this.createRowData(),
    };
  }

  /**
   * Create row data from the provided matches.
   *
   * @returns Row data.
   */
  private createRowData(): any[] {
    const rowData = [];
    let index = 0;
    for (const match of this.matches) {
      const rowItem: any = {};
      rowItem.id = index;
      for (const fieldValue of match.values) {
        rowItem[`field_${fieldValue.id}`] = fieldValue.value;
      }
      rowData.push(rowItem);
      index++;
    }

    return rowData;
  }

  private getColumnDefinitations(): ColDef[] {
    const firstMatch = this.matches[0];
    return firstMatch.values.map((fieldValue, index) => {
      const field = this.fieldsService.getField(fieldValue.id);
      const columnDefinition: ColDef = {
        field: `field_${fieldValue.id}`,
        headerName: field.name,
        sortable: true,
        filter: true,
        resizable: true,
        editable: false,
        suppressMovable: true,
      };

      return columnDefinition;
    });
  }
}
