import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { NotificationService } from 'src/app/services/notification.service';

import { FieldCellEditorBaseComponent } from '../field-cell-editor-base.component';
import { FieldCellEditorParameters } from '../field-cell-editor-parameters';

/** Character Cell Editor. */
@Component({
  selector: 'app-character-cell-editor',
  templateUrl: './character-cell-editor.component.html',
  styleUrls: ['./character-cell-editor.component.scss'],
  standalone: false,
})
export class CharacterCellEditorComponent extends FieldCellEditorBaseComponent {
  constructor(translate: TranslocoService, notifications: NotificationService) {
    super(translate, notifications);
  }

  /** @inheritdoc */
  agInit(params: FieldCellEditorParameters): void {
    super.agInit(params);
    this.formControl.valueChanges.subscribe(() => {
      this.formControl.markAllAsTouched();
    });
  }
}
