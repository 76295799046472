import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { Database } from 'models';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

@Component({
  selector: 'app-favorite-searches-panel',
  templateUrl: './favorite-searches-panel.component.html',
  styleUrls: ['./favorite-searches-panel.component.scss'],
  standalone: false,
})
@UntilDestroy({ checkProperties: true })
export class FavoriteSearchesPanelComponent {
  /** Database. */
  @Input()
  database: Database;
  /** Favorited Searches. */
  favoriteSearches$ = this.searchesQuery.favoritedSearches$;
  /** Observable of whether the favorite search navigation section is expanded. */
  isExpanded$ = this.applicationQuery.favoriteSearchNavSectionExpanded$;
  /** Observable of search loading state. */
  isSearchesLoading$ = this.searchesQuery.isLoading$.pipe(delay(0));
  /** Observable of if the panel should show. */
  showPanel$ = combineLatest([
    this.appQuery.showFavoriteSearches$,
    this.searchesQuery.favoritedSearches$.pipe(
      map((favoriteSearches) => favoriteSearches?.length > 0)
    ),
  ]).pipe(
    map(
      ([showFavoriteSearches, hasFavoriteSearches]) =>
        showFavoriteSearches && hasFavoriteSearches
    )
  );

  constructor(
    private applicationQuery: ApplicationQuery,
    private applicationService: ApplicationService,
    private searchesQuery: SearchesQuery,
    private appQuery: ApplicationQuery
  ) {}

  /**
   * Handler for the on expand change event.
   *
   */
  onExpandChanged(): void {
    this.applicationService.toggleFavoriteSearchNavSectionExpanded();
  }
}
