import { inject } from '@angular/core';
import { assertTypeByKey } from 'common';
import { ScanBytesResult } from 'models';
import { map, of, throwError } from 'rxjs';
import { ScanTargetEnum } from '../components/scan-dialog/scan-dialog.component';
import { PdfScanProvider } from '../modules/pdf-viewer';
import { GseService } from '../services/gse.service';
import { ScanService } from '../services/scan.service';

export const pdfScanProviderFactory = (): PdfScanProvider => {
  const scanService = inject(ScanService);
  const gseService = inject(GseService);
  return {
    isAvailable$: () => {
      return gseService.isConnected$;
    },
    scan: () => {
      const scanResult$ = scanService.openScanDialog({
        target: ScanTargetEnum.browser,
      });
      return scanResult$.pipe(
        map((result) => {
          if (!result) {
            throwError(() => new Error('Scan cancelled.'));
          }
          assertTypeByKey<ScanBytesResult>(result, 'fileType', 'string');
          return result;
        })
      );
    },
  };
};
