import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ApplicationQuery } from 'src/app/state/application/application.query';

@UntilDestroy()
@Component({
  selector: 'app-name-svg',
  templateUrl: './name-svg.component.svg',
  styleUrls: ['./name-svg.component.scss'],
  standalone: false,
})
export class NameSvgComponent implements OnInit {
  /** Logo text dark mode color. */
  @Input('logo-text-color-dark') private logoTextColorDark = '#fff';
  /** Logo text light mode color. */
  @Input('logo-text-color-light') private logoTextColorLight = '#fff';

  /** Logo text color. */
  logoTextColor: string;

  /** Dark mode state. */
  private darkMode = true;

  constructor(private app: ApplicationQuery) {}

  ngOnInit(): void {
    this.app.darkMode$.pipe(untilDestroyed(this)).subscribe((result) => {
      this.darkMode = result;
      this.logoTextColor = this.darkMode
        ? this.logoTextColorDark
        : this.logoTextColorLight;
    });
  }
}
