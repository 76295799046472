import { computeValue } from "../../../core";
import { isDate, isNil, isNumber, MingoError } from "../../../util";
const LEAP_YEAR_REF_POINT = -1e9;
const DAYS_PER_WEEK = 7;
const isLeapYear = y => (y & 3) == 0 && (y % 100 != 0 || y % 400 == 0);
const DAYS_IN_YEAR = [365, 366
/*leap*/];
const daysInYear = year => DAYS_IN_YEAR[+isLeapYear(year)];
const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const daysInMonth = d => DAYS_IN_MONTH[d.getUTCMonth()] + Number(d.getUTCMonth() === 1 && isLeapYear(d.getUTCFullYear()));
const YEAR_DAYS_OFFSET = [[0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334], [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335]
/*leap*/];
const dayOfYear = d => YEAR_DAYS_OFFSET[+isLeapYear(d.getUTCFullYear())][d.getUTCMonth()] + d.getUTCDate();
const isoWeekday = (date, startOfWeek = "sun") => {
  const dow = date.getUTCDay() || 7;
  return (dow - ISO_WEEKDAY_MAP[startOfWeek] + DAYS_PER_WEEK) % DAYS_PER_WEEK;
};
const p = y => (y + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400)) % 7;
const weeks = y => 52 + Number(p(y) == 4 || p(y - 1) == 3);
function isoWeek(d) {
  const dow = d.getUTCDay() || 7;
  const w = Math.floor((10 + dayOfYear(d) - dow) / 7);
  if (w < 1) return weeks(d.getUTCFullYear() - 1);
  if (w > weeks(d.getUTCFullYear())) return 1;
  return w;
}
function isoWeekYear(d) {
  return d.getUTCFullYear() - Number(d.getUTCMonth() === 0 && d.getUTCDate() == 1 && d.getUTCDay() < 1);
}
const MINUTES_PER_HOUR = 60;
const MILLIS_PER_DAY = 1e3 * 60 * 60 * 24;
const TIMEUNIT_IN_MILLIS = {
  week: MILLIS_PER_DAY * DAYS_PER_WEEK,
  day: MILLIS_PER_DAY,
  hour: 1e3 * 60 * 60,
  minute: 1e3 * 60,
  second: 1e3,
  millisecond: 1
};
const DAYS_OF_WEEK = ["monday", "mon", "tuesday", "tue", "wednesday", "wed", "thursday", "thu", "friday", "fri", "saturday", "sat", "sunday", "sun"];
const DAYS_OF_WEEK_SET = new Set(DAYS_OF_WEEK);
const ISO_WEEKDAY_MAP = Object.freeze({
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7
});
const DATE_FORMAT = "%Y-%m-%dT%H:%M:%S.%LZ";
const DATE_PART_INTERVAL = [["year", 0, 9999], ["month", 1, 12], ["day", 1, 31], ["hour", 0, 23], ["minute", 0, 59], ["second", 0, 59], ["millisecond", 0, 999]];
const DATE_SYM_TABLE = Object.freeze({
  "%Y": {
    name: "year",
    padding: 4,
    re: /([0-9]{4})/
  },
  "%G": {
    name: "year",
    padding: 4,
    re: /([0-9]{4})/
  },
  "%m": {
    name: "month",
    padding: 2,
    re: /(0[1-9]|1[012])/
  },
  "%d": {
    name: "day",
    padding: 2,
    re: /(0[1-9]|[12][0-9]|3[01])/
  },
  "%H": {
    name: "hour",
    padding: 2,
    re: /([01][0-9]|2[0-3])/
  },
  "%M": {
    name: "minute",
    padding: 2,
    re: /([0-5][0-9])/
  },
  "%S": {
    name: "second",
    padding: 2,
    re: /([0-5][0-9]|60)/
  },
  "%L": {
    name: "millisecond",
    padding: 3,
    re: /([0-9]{3})/
  },
  "%u": {
    name: "weekday",
    padding: 1,
    re: /([1-7])/
  },
  "%U": {
    name: "week",
    padding: 2,
    re: /([1-4][0-9]?|5[0-3]?)/
  },
  "%V": {
    name: "isoWeek",
    padding: 2,
    re: /([1-4][0-9]?|5[0-3]?)/
  },
  "%z": {
    name: "timezone",
    padding: 2,
    re: /(([+-][01][0-9]|2[0-3]):?([0-5][0-9])?)/
  },
  "%Z": {
    name: "minuteOffset",
    padding: 3,
    re: /([+-][0-9]{3})/
  }
  // "%%": "%",
});
const TIMEZONE_RE = /^[a-zA-Z_]+\/[a-zA-Z_]+$/;
function parseTimezone(tzstr) {
  if (isNil(tzstr)) return 0;
  if (TIMEZONE_RE.test(tzstr)) {
    const date = /* @__PURE__ */new Date();
    const utcDate = new Date(date.toLocaleString("en-US", {
      timeZone: "UTC"
    }));
    const tzDate = new Date(date.toLocaleString("en-US", {
      timeZone: tzstr
    }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }
  const m = DATE_SYM_TABLE["%z"].re.exec(tzstr);
  if (!m) {
    throw new MingoError(`Timezone '${tzstr}' is invalid or not supported`);
  }
  const hr = parseInt(m[2]) || 0;
  const min = parseInt(m[3]) || 0;
  return (Math.abs(hr * MINUTES_PER_HOUR) + min) * (hr < 0 ? -1 : 1);
}
function formatTimezone(minuteOffset) {
  return (minuteOffset < 0 ? "-" : "+") + padDigits(Math.abs(Math.floor(minuteOffset / MINUTES_PER_HOUR)), 2) + padDigits(Math.abs(minuteOffset) % MINUTES_PER_HOUR, 2);
}
function adjustDate(d, minuteOffset) {
  d.setUTCMinutes(d.getUTCMinutes() + minuteOffset);
}
function computeDate(obj, expr, options) {
  if (isDate(obj)) return obj;
  const d = computeValue(obj, expr, null, options);
  if (isDate(d)) return new Date(d);
  if (isNumber(d)) return new Date(d * 1e3);
  if (d.date) {
    const date = isDate(d.date) ? new Date(d.date) : new Date(d.date * 1e3);
    if (d.timezone) {
      adjustDate(date, parseTimezone(d.timezone));
    }
    return date;
  }
  throw Error(`cannot convert ${JSON.stringify(expr)} to date`);
}
function padDigits(n, digits) {
  return new Array(Math.max(digits - String(n).length + 1, 0)).join("0") + n.toString();
}
const leapYearsSinceReferencePoint = year => {
  const yearsSinceReferencePoint = year - LEAP_YEAR_REF_POINT;
  return Math.trunc(yearsSinceReferencePoint / 4) - Math.trunc(yearsSinceReferencePoint / 100) + Math.trunc(yearsSinceReferencePoint / 400);
};
function daysBetweenYears(startYear, endYear) {
  return Math.trunc(leapYearsSinceReferencePoint(endYear - 1) - leapYearsSinceReferencePoint(startYear - 1) + (endYear - startYear) * DAYS_IN_YEAR[0]);
}
const dateDiffYear = (start, end) => end.getUTCFullYear() - start.getUTCFullYear();
const dateDiffMonth = (start, end) => end.getUTCMonth() - start.getUTCMonth() + dateDiffYear(start, end) * 12;
const dateDiffQuarter = (start, end) => {
  const a = Math.trunc(start.getUTCMonth() / 3);
  const b = Math.trunc(end.getUTCMonth() / 3);
  return b - a + dateDiffYear(start, end) * 4;
};
const dateDiffDay = (start, end) => dayOfYear(end) - dayOfYear(start) + daysBetweenYears(start.getUTCFullYear(), end.getUTCFullYear());
const dateDiffWeek = (start, end, startOfWeek) => {
  const wk = (startOfWeek || "sun").substring(0, 3);
  return Math.trunc((dateDiffDay(start, end) + isoWeekday(start, wk) - isoWeekday(end, wk)) / DAYS_PER_WEEK);
};
const dateDiffHour = (start, end) => end.getUTCHours() - start.getUTCHours() + dateDiffDay(start, end) * 24;
const addMonth = (d, amount) => {
  const m = d.getUTCMonth() + amount;
  const yearOffset = Math.floor(m / 12);
  if (m < 0) {
    const month = m % 12 + 12;
    d.setUTCFullYear(d.getUTCFullYear() + yearOffset, month, d.getUTCDate());
  } else {
    d.setUTCFullYear(d.getUTCFullYear() + yearOffset, m % 12, d.getUTCDate());
  }
};
const dateAdd = (date, unit, amount, _timezone) => {
  const d = new Date(date);
  switch (unit) {
    case "year":
      d.setUTCFullYear(d.getUTCFullYear() + amount);
      break;
    case "quarter":
      addMonth(d, 3 * amount);
      break;
    case "month":
      addMonth(d, amount);
      break;
    default:
      d.setTime(d.getTime() + TIMEUNIT_IN_MILLIS[unit] * amount);
  }
  return d;
};
export { DATE_FORMAT, DATE_PART_INTERVAL, DATE_SYM_TABLE, DAYS_OF_WEEK, DAYS_OF_WEEK_SET, DAYS_PER_WEEK, LEAP_YEAR_REF_POINT, MILLIS_PER_DAY, MINUTES_PER_HOUR, TIMEUNIT_IN_MILLIS, adjustDate, computeDate, dateAdd, dateDiffDay, dateDiffHour, dateDiffMonth, dateDiffQuarter, dateDiffWeek, dateDiffYear, dayOfYear, daysBetweenYears, daysInMonth, daysInYear, formatTimezone, isLeapYear, isoWeek, isoWeekYear, isoWeekday, padDigits, parseTimezone };