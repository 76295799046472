import { Component, Input } from '@angular/core';
import { Column, ColumnPinnedType, GridApi } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';

import { THUMBNAIL_COL_ID } from '../grid-cell-components/cell-selectors';

/** Column sort value when no sort should be applied. */
const NO_SORT = null;

/** Grid settings. */
@Component({
  selector: 'app-grid-settings',
  templateUrl: './grid-settings.component.html',
  styleUrls: ['./grid-settings.component.scss'],
  standalone: false,
})
export class GridSettingsComponent {
  /**
   * Grid API Instance.
   *
   * Note that is can be undefined to stop template errors but this component doesn't function without it.
   */
  @Input()
  gridApi: GridApi<any> | undefined;

  /**
   * Get the array of columns. Excluding any thumbnail column.
   *
   * @returns Columns.
   */
  get gridColumns(): Column[] {
    const columns = this.gridApi?.getColumns() ?? [];
    // Check for a thumbnail column.
    const index = columns.findIndex(
      (col) => col.getColId() === THUMBNAIL_COL_ID
    );
    // If found, remove the thumbnail column.
    if (index > -1) {
      columns.splice(index, 1);
    }
    return columns;
  }

  constructor(private logger: NGXLogger) {}

  /**
   * Get the display name of the column.
   *
   * @param column Column.
   * @returns The name of the column.
   * @throws {TypeError} If the column name was undefined.
   */
  getColumnName(column: Column): string {
    const name = column.getColDef().headerName;
    if (typeof name === 'undefined') {
      throw new TypeError('Column name was undefined.');
    }
    return name;
  }

  /**
   * Retrieve the pin icon for a column.
   *
   * @param column Column.
   * @returns The name of the icon to be displayed.
   */
  getPinIconName(column: Column): string {
    if (column.isPinnedLeft()) {
      return 'chevron_left';
    } else if (column.isPinnedRight()) {
      return 'chevron_right';
    }

    return 'pin_off';
  }

  /**
   * Click even handler to fit column to width.
   */
  onClickFitToColumnWidth(): void {
    this.logger.debug('Auto sizing all columns.');
    this.gridApi?.autoSizeAllColumns();
  }

  /**
   * Click event handler to hide all columns.
   */
  onClickHideAllColumns(): void {
    this.logger.debug('Hiding all columns.');
    const columns = this.gridApi?.getColumns() ?? [];
    const thumbnailColumnIndex = columns.findIndex(
      (c) => c.getColId() === THUMBNAIL_COL_ID
    );
    if (thumbnailColumnIndex > -1) {
      columns.splice(thumbnailColumnIndex, 1);
    }
    this.gridApi?.setColumnsVisible(columns, false);
  }

  /**
   * Click event handler to cycle the pin state of a column through unpinned, pinned left, and pinned right.
   *
   * @param column Column.
   */
  onClickPinToggle(column: Column): void {
    let pinState: ColumnPinnedType;
    if (!column.isPinned()) {
      pinState = 'left';
    } else if (column.isPinnedLeft()) {
      pinState = 'right';
    }
    // If previously pinned to the right, empty string will pass to 'disable' pin.
    this.logger.debug('Changing pin state.', pinState);
    this.gridApi?.setColumnsPinned([column], pinState);
  }

  /**
   * Click event handler to remove all sorts.
   */
  onClickRemoveAllSorts(): void {
    this.logger.debug('Resetting all column sorts.');
    const columnStates = this.gridApi?.getColumnState() ?? [];
    for (const columnState of columnStates) {
      columnState.sort = NO_SORT;
    }
    this.gridApi?.applyColumnState({
      state: columnStates,
      applyOrder: true,
    });
  }

  /**
   * Click event handler to reset column layout.
   */
  onClickResetLayout(): void {
    this.logger.debug('Resetting column layout.');
    this.gridApi?.resetColumnState();
    this.gridApi?.sizeColumnsToFit();
  }

  /**
   * Click event handler to show all columns.
   */
  onClickShowAllColumns(): void {
    this.logger.debug('Showing all columns.');
    this.gridApi?.setColumnsVisible(this.gridApi?.getColumns() ?? [], true);
  }

  /**
   * Click event handler to toggle the visibility of a column.
   *
   * @param column Column def.
   */
  onClickToggleVisibility(column: Column): void {
    this.logger.debug('Toggling column visibility.');
    this.gridApi?.setColumnsVisible([column], !column.isVisible());
  }

  /**
   * Click event handler to unpin all columns.
   */
  onClickUnPinAllColumns(): void {
    this.logger.debug('Unpinning all columns.');
    this.gridApi?.setColumnsPinned(
      this.gridApi?.getColumns() ?? [],
      undefined as ColumnPinnedType
    );
  }
}
