import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RowNode } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';

import { assertExists } from 'common';
import { SearchResults, UserActions } from 'models';
import {
  ArchiveGridData,
  SearchResultDocumentOpenRequest,
} from 'src/app/models';
import { LayoutService } from 'src/app/services/layout.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';
import { TaskSearchesService } from 'src/app/state/task-searches/task-searches.service';

import { TaskActionMenuComponent } from '../task-action-menu/task-action-menu.component';
import { TaskSearchArchiveSelectorComponent } from '../task-search-archive-selector/task-search-archive-selector.component';

/** Task Actions Toolbar. */
@UntilDestroy()
@Component({
  selector: 'app-task-actions-toolbar',
  templateUrl: './task-actions-toolbar.component.html',
  styleUrls: ['./task-actions-toolbar.component.scss'],
  standalone: false,
})
export class TaskActionsToolbarComponent implements OnInit {
  /** Actions menu. */
  @Input()
  actionMenuComponent: TaskActionMenuComponent;

  /** Task view archive selector reference. */
  @ViewChild(TaskSearchArchiveSelectorComponent)
  archiveSelector: TaskSearchArchiveSelectorComponent;

  /** Determines if edit mode is enabled. */
  @Input()
  editModeEnabled: boolean;
  /** Event raised when edit mode changes. */
  @Output()
  editModeEnabledChange = new EventEmitter<boolean>();
  /** First row. */
  @Input()
  /** Event raised when selected rows should be opened. */
  @Output()
  openSelectedDocuments = new EventEmitter<SearchResultDocumentOpenRequest>();
  /**
   * Event triggered when the search should be refreshed.
   */
  @Output()
  refreshSearch = new EventEmitter();

  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layout.isHandset$;
  /** Array of user actions. */
  userActions: UserActions;

  private activeGridData: ArchiveGridData;

  constructor(
    private logger: NGXLogger,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private taskSeachesQuery: TaskSearchesQuery,
    private gridStatesService: GridSettingsService,
    private taskSearchesService: TaskSearchesService,
    private layout: LayoutService
  ) {}

  ngOnInit(): void {
    this.taskSeachesQuery.taskSearchRouteParams$
      .pipe(untilDestroyed(this))
      .subscribe(([databaseId, archiveId, taskId]) => {
        assertExists(databaseId);
        assertExists(archiveId);
        assertExists(taskId);
        const taskSearch = this.taskSeachesQuery.getEntity(taskId);
        assertExists(taskSearch);
        this.activeGridData = this.gridStatesService.getOrCreateArchiveGridData(
          databaseId,
          archiveId
        );
      });
  }

  /**
   * No SearchResult is selected.
   *
   * @returns True if none are selected. */
  get isNoneSelected(): boolean {
    return this.selectedSearchResults.length === 0;
  }

  /**
   * Exactly one SearchResult is selected.
   *
   * @returns True iff one is selected. */
  get isSingleSelection(): boolean {
    return this.selectedSearchResults.length === 1;
  }

  /**
   * Number of selected SearchResults.
   *
   * @returns Count.
   */
  get selectedCount(): number {
    return this.selectedSearchResults.length;
  }

  /**
   * Selected SearchResults.
   *
   * @returns An array of selected SearchResults.
   */
  get selectedSearchResults(): SearchResults {
    return (this.activeGridData?.selectedRowNodes ?? []).map((row) => row.data);
  }

  /**
   * Handler for click of action menu.
   *
   * @param $event Event.
   */
  onClickActionMenu($event: any) {
    this.actionMenuComponent.openMenu($event, this.selectedSearchResults);
  }

  /** Handler for the refresh button click event. */
  onClickRefreshSearch(): void {
    this.logger.debug('Refresh search button clicked.');
    this.archiveSelector.reloadSearchCounts();
    this.refreshSearch.emit();
  }

  /** Handler for the edit button click event. */
  onClickToggleEdit(): void {
    this.logger.debug('Toggle edit button clicked.');
    this.editModeEnabled = !this.editModeEnabled;
    this.editModeEnabledChange.emit(this.editModeEnabled);
  }

  /**
   * Handler for the first node change event.
   *
   * @param newFirstRowNode New first row node.
   */
  onFirstRowNodeChange(newFirstRowNode: RowNode): void {
    this.taskSearchesService.userActionsApi
      .getActions(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        newFirstRowNode.data.id,
        newFirstRowNode.data.secureId
      )
      .subscribe({
        next: (userActions) =>
          (this.userActions = this.sortUserActions(userActions)),
      });
  }

  /**
   * Click event for the open documents button.
   *
   * @param openRequest Open request.
   */
  onOpenDocuments(openRequest?: SearchResultDocumentOpenRequest): void {
    this.logger.debug('Open documents button clicked.');
    this.openSelectedDocuments.emit(
      openRequest ??
        ({
          searchResults: this.selectedSearchResults,
          forceExternalViewer: false,
        } as SearchResultDocumentOpenRequest)
    );
  }

  /** Reloads the search counts in the archive selector. */
  reloadArchiveSearchSelectorCounts(): void {
    this.archiveSelector.reloadSearchCounts();
  }

  private sortUserActions(userActions: UserActions): UserActions {
    return userActions.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}
