import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-divider-with-text',
  // templateUrl: './divider-with-text.component.html',
  template: '<hr class="hr-text" [attr.data-content]="text" />',
  styleUrls: ['./divider-with-text.component.scss'],
  standalone: false,
})
export class DividerWithTextComponent {
  /** Text displayed in the divider. */
  @Input()
  text: string;

  constructor() {}
}
