import { Injectable } from '@angular/core';
import { ColumnState } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { TableFieldGridStateStore } from './table-field-grid-states.store';

/** Table field grid state service. */
@Injectable({ providedIn: 'root' })
export class TableFieldGridStatesService {
  constructor(
    private logger: NGXLogger,
    private store: TableFieldGridStateStore
  ) {}

  /**
   * Inserts or updates the column states for a table field grid.
   * @param databaseId Database id.
   * @param archiveId Archive id.
   * @param tableFieldId Table field id.
   * @param columnStates Column states to store.
   */
  storeTableFieldGridColumnState(
    databaseId: string,
    archiveId: string,
    tableFieldId: string,
    columnStates: ColumnState[]
  ): void {
    const id = `${databaseId}_${archiveId}_${tableFieldId}`;
    this.store.upsert(
      id,
      { columnStates: columnStates },
      (newId, newState) => ({ id: newId, ...newState })
    );
  }
}
