import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, switchMap } from 'rxjs';

import { assertExists } from 'common';
import { Search } from 'models';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

@UntilDestroy()
@Component({
  selector: 'app-related-searches-menu',
  templateUrl: './related-searches-menu.component.html',
  styleUrls: ['./related-searches-menu.component.scss'],
  standalone: false,
})
export class RelatedSearchesMenuComponent implements OnInit {
  /** Event emitted when a search is selected. */
  @Output()
  relatedSearchSelected = new EventEmitter<Search>();
  /** Observable array of searches. */
  searches$: Observable<Search[]>;

  private archiveId: number;
  private databaseId: number;

  constructor(
    private logger: NGXLogger,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery
  ) {}

  ngOnInit(): void {
    this.searches$ = this.archivesQuery.archiveRouteParams$.pipe(
      untilDestroyed(this),
      map(([databaseId, archiveId]) => {
        assertExists(databaseId, 'There must be an active database id.');
        assertExists(archiveId, 'There must be an active archive id.');
        this.databaseId = databaseId;
        this.archiveId = archiveId;
        return [databaseId, archiveId];
      }),
      switchMap(([databaseId, archiveId]) => {
        return this.searchesQuery.selectForParent(archiveId);
      })
    );
  }

  /**
   * Handler for the search on click event.
   *
   * @param search Selected search.
   */
  onClickSearch(search: Search): void {
    this.relatedSearchSelected.emit(search);
  }
}
