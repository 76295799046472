import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, startWith } from 'rxjs';

import { Field, List } from 'models';
import { ListsService } from 'src/app/state/lists/lists.service';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filterList } from 'src/app/common/utility';
import { FieldBaseComponent } from '../field-component.base.component';
import { ListFieldBaseComponent } from '../list-field-base.component';
import { MultiValueFieldMenuComponent } from '../multi-value-field-menu/multi-value-field-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-typeahead-field',
  templateUrl: './typeahead-field.component.html',
  styleUrls: ['./typeahead-field.component.scss'],
  standalone: false,
})
export class TypeaheadFieldComponent
  extends FieldBaseComponent
  implements OnInit, ListFieldBaseComponent
{
  /**
   * Alternate label for the mat-form-field.
   *
   * The field.name will be used if this is not provided.
   */
  @Input()
  alternateFormLabel: string;
  /** Field form control. */
  @Input('form-control')
  control: UntypedFormControl;
  /** Field. */
  @Input()
  field: Field;
  /** MV Field Menu Component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;
  /** Emits when this field is blurred. The event contains the field that was blurred. */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Autocomplete trigger. */
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  /** Input element reference. */
  @ViewChild('input')
  inputElement: ElementRef;

  /** Filtered list values. */
  filteredListValues$: Observable<string[]>;
  /** Field list. */
  list: List;

  constructor(
    translate: TranslocoService,
    private lists: ListsService
  ) {
    super(translate);
  }

  /**
   * Get the label for the mat-form-field.
   *
   * @returns The label string for the mat-form-field
   */
  get fieldLabel(): string {
    return this.alternateFormLabel || this.field.name;
  }

  /** @inheritdoc */
  closeListPanel(): void {
    this.autocompleteTrigger.closePanel();
  }

  /** @inheritdoc */
  focus(): void {
    this.inputElement.nativeElement.focus();
    this.inputElement.nativeElement.select();
  }

  ngOnInit(): void {
    this.addValidators();
    this.listenForList();
    this.filteredListValues$ = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => filterList(value, this.list?.values ?? [], 100))
    );
  }

  /** @inheritdoc */
  onBlur(): void {
    this.fieldBlurred.emit(this.field);
  }

  /** @inheritdoc */
  onFocus(): void {
    this.fieldFocused.emit(this.field);
  }

  /**
   * Handler for the select change event.
   */
  onSelectionChange(): void {
    if (this.field.multiValue) {
      this.mvFieldMenu.addMultiValue.emit({ append: true });
    }
  }

  private listenForList(): void {
    this.lists
      .get(this.field.list.listId)
      .pipe(untilDestroyed(this))
      .subscribe((list) => {
        this.list = list;
      });
  }
}
