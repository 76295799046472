import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filterNilValue, setLoading } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NGXLogger } from 'ngx-logger';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Database, DatabaseProvider } from 'models';
import { NO_ACTIVE_ID } from 'src/app/common/constants';
import { CommandService } from 'src/app/modules/command-palette';

import { DATABASE_PROVIDER } from '../../common/tokens';
import { toNumberOrUndefined } from '../../common/utility';
import { AdvancedLinksService } from '../advanced-links/advanced-links.service';
import { ApplicationQuery } from '../application/application.query';
import { ArchivesService } from '../archives/archives.service';
import { FieldsService } from '../fields/fields.service';
import { SearchesService } from '../searches/searches.service';
import { TableFieldsService } from '../table-fields/table-fields.service';
import { TaskSearchesService } from '../task-searches/task-searches.service';

import { AppConfigQuery } from 'src/app/modules/app-config';
import { ListsService } from '../lists/lists.service';
import { DatabasesQuery } from './databases.query';
import { DatabasesStore } from './databases.store';

/**
 * Databases service.
 */
@Injectable({ providedIn: 'root' })
export class DatabasesService {
  constructor(
    private logger: NGXLogger,
    private databasesStore: DatabasesStore,
    private databasesQuery: DatabasesQuery,
    private routerQuery: RouterQuery,
    private archivesService: ArchivesService,
    private applicationQuery: ApplicationQuery,
    private applicationConfigQuery: AppConfigQuery,
    private searchesService: SearchesService,
    private taskSearchesService: TaskSearchesService,
    private fieldsService: FieldsService,
    private tableFieldsService: TableFieldsService,
    private advancedLinksService: AdvancedLinksService,
    private listService: ListsService,
    private commandService: CommandService,
    private router: Router,
    @Inject(DATABASE_PROVIDER) private databaseProivder: DatabaseProvider
  ) {
    // Watch route to determine active.
    this.routerQuery
      .selectParams('dbId')
      .pipe(map(toNumberOrUndefined), distinctUntilChanged())
      .subscribe((databaseId) => this.onDatabaseChange(databaseId));
    // Refresh the list of databases on user change.
    this.applicationQuery.user$.pipe(filterNilValue()).subscribe(() => {
      this.get();
    });
  }

  /**
   * Add a database to the store.
   *
   * @param database Database object to add.
   */
  add(database: Database) {
    this.databasesStore.add(database);
  }

  /**
   * Refresh the list of databases.
   */
  get() {
    this.logger.debug('Refreshing databases.');
    this.databaseProivder
      .getAll()
      .pipe(setLoading(this.databasesStore))
      .subscribe((databases) => {
        this.databasesStore.set(databases);
      });
  }

  /**
   * Remove a database from the store.
   *
   * @param id Database ID.
   */
  remove(id: number) {
    this.databasesStore.remove(id);
  }

  /**
   * Update a database in the store.
   *
   * @param id Database ID.
   * @param database Database object properties to update.
   */
  update(id: number, database: Partial<Database>) {
    this.databasesStore.update(id, database);
  }

  /**
   * Add an array of `Database` to the the command Pallete.
   *
   * @param databases Databases.
   */
  private addToCommandPalette(databases: Database[]): void {
    // Clear any existing "Database" items.
    this.commandService.unregister('*', 'Database');
    // Register databases as navigation commands.
    for (const database of databases) {
      this.commandService.register(
        database.name,
        'Switch Database',
        () => this.router.navigate(['db', database.id]),
        'Database'
      );
    }
  }

  /**
   * Handles activities to be completed after a database change is completed.
   *
   * @param databaseId Database Id.
   */
  private afterDatabaseChange(databaseId?: number) {
    this.addToCommandPalette(
      this.databasesQuery
        .getAll()
        .filter((database) => database.id !== databaseId)
    );
    this.registerDashboardNavigationCommand(databaseId);
  }

  /**
   * Handles change of database Id.
   *
   * @param databaseId Database Id.
   */
  private onDatabaseChange(databaseId?: number) {
    // get the previously active database
    const previousDatabaseId = this.databasesStore.getValue().active;
    if (previousDatabaseId) {
      this.logger.debug(
        `Database changed. Clearing favorite searches from command palette for database ${previousDatabaseId}.`
      );
      this.searchesService.clearFavoriteSearchesFromCommandPalette(
        previousDatabaseId
      );

      this.logger.debug('Database changed. Clearing cached lists.');
      this.listService.reset();
    }
    // Set the new active database.
    this.databasesStore.setActive(databaseId ?? NO_ACTIVE_ID);

    if (typeof databaseId === 'number') {
      // Refresh data for releated stores based on the database.
      this.archivesService.get(databaseId as number);
      this.searchesService.get(databaseId as number);
      if (!this.applicationConfigQuery.disableGlobalActionTasks) {
        // Load task searches only if the instance's app config is not set to disable Global Action tasks.
        this.taskSearchesService.get(databaseId as number);
      }
      this.fieldsService.get(databaseId as number);
      this.tableFieldsService.get(databaseId as number);
      this.advancedLinksService.get(databaseId as number);
    }

    this.logger.debug(
      typeof databaseId === 'undefined'
        ? 'No database active.'
        : `Database ${databaseId} set as active.`
    );

    // Handle any work that should be done after a database change completes.
    this.afterDatabaseChange(databaseId);
  }

  /**
   * Update the registered database dashboard navigation command.
   *
   * @param databaseId Database Id.
   */
  private registerDashboardNavigationCommand(databaseId: number | undefined) {
    this.commandService.unregister('Dashboard');

    if (typeof databaseId === 'number') {
      this.commandService.register(
        'Dashboard',
        'Navigate to the dashboard.',
        () => this.router.navigate(['/db', databaseId])
      );
    }
  }
}
