import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, map } from 'rxjs';

import { DocumentProvider, Permissions } from 'models';
import { DOCUMENT_PROVIDER } from 'src/app/common/tokens';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';

import {
  ArchiveThumbnailSource,
  InboxThumbnailSource,
  ThumbnailSource,
} from '../thumbnail-source';

const pdfPreviewTitle = 'PDF Preview';

/** Thumbnail Cell Renderer Parameters.
 *
 * @augments ICellRendererParams
 */
export interface ThumbnailCellRendererParameters
  extends ThumbnailSource,
    ICellRendererParams {}

/** Thumbnail Preview Cell Renderer. */
@Component({
  selector: 'app-thumbnail-preview-renderer',
  templateUrl: './thumbnail-preview-renderer.component.html',
  styleUrls: ['./thumbnail-preview-renderer.component.scss'],
  standalone: false,
})
export class ThumbnailPreviewRendererComponent
  implements ICellRendererAngularComp
{
  /** Observable of whether the pdf preview is open. */
  isPdfPreviewOpen$ = combineLatest([
    this.appQuery.pdfPreviewUrl$,
    this.appQuery.rightSidebarOpen$,
  ]).pipe(
    map(([pdfPreviewUrl, rightSidebarOpen]) => {
      return (
        rightSidebarOpen &&
        !!this.pdfPreviewUrl &&
        !!pdfPreviewUrl &&
        this.pdfPreviewUrl === pdfPreviewUrl
      );
    })
  );
  /** Permissions for the row. */
  permissions: Permissions;

  private cellParameters: ThumbnailCellRendererParameters;
  private pdfPreviewUrl: string;

  constructor(
    private applicationService: ApplicationService,
    private appQuery: ApplicationQuery,
    private logger: NGXLogger,
    private authService: AuthenticationService,
    @Inject(DOCUMENT_PROVIDER) private documentProvider: DocumentProvider
  ) {}

  /**
   * Get thumbnail icon.
   *
   * @returns A string of the name of the icon to be used.
   */
  get icon(): string {
    switch (this.cellParameters.data.fileType.toLowerCase()) {
      case '.pdf':
        return 'picture_as_pdf';
      case '.tif':
      case '.tiff':
      case '.bmp':
      case '.jpeg':
      case '.jpg':
      case '.gif':
      case '.png':
      case '.dwg':
        return 'image';
      case '.dot':
      case '.doc':
      case '.docx':
        return 'file_word';
      case '.ppt':
      case '.pptx':
      case '.ppsm':
        return 'file_powerpoint';
      case '.csv':
      case '.xls':
      case '.xlsx':
        return 'file_excel';
      case '.txt':
      case '.rtf':
      case '.xml':
        return 'article';
      case '.zip':
      case '.rar':
        return 'folder_zip';
      case '.eml':
      case '.msg':
        return 'mail';
      default:
        return 'insert_drive_file';
    }
  }

  /**
   * Is the user a guest?
   *
   * @returns True if the user is a guest.
   * */
  get isGuest() {
    return this.authService.isGuest;
  }

  /**
   * Gets whether the file type is PDF.
   *
   * @returns True if the file type is PDF.
   */
  get isPdf(): boolean {
    return this.cellParameters.data.fileType.toLowerCase() === '.pdf';
  }

  /**
   * Gets whether the file type is convertable to PDF.
   *
   * @returns True if the file type can be converted to PDF.
   */
  get isPdfConvertable(): boolean {
    const pdfConvertableTypes = ['.jpg', '.jpeg', '.png', '.tif', '.tiff'];
    return pdfConvertableTypes.includes(
      this.cellParameters.data.fileType.toLowerCase()
    );
  }

  /**
   * Gets whether the file type uses an SVG icon.
   *
   * Extensions that use SVG icons from the assets folder **MUST** be included in this function.
   *
   * @returns A boolean indicating if the file type uses an SVG icon.
   */
  get isSvgIcon(): boolean {
    switch (this.cellParameters.data.fileType.toLowerCase()) {
      case '.dot':
      case '.doc':
      case '.docx':
      case '.ppt':
      case '.pptx':
      case '.ppsm':
      case '.csv':
      case '.xls':
      case '.xlsx':
        return true;
      default:
        return false;
    }
  }

  /** @inheritdoc */
  agInit(params: ThumbnailCellRendererParameters): void {
    this.cellParameters = params;
    this.permissions = params.data.permissions;
    if (
      this.cellParameters.sourceType === 'archive' ||
      this.cellParameters.sourceType === 'taskSearch' // Task search preview url is identical to regular archive url.
    ) {
      const archiveSource = this.cellParameters
        .source as ArchiveThumbnailSource;
      // TODO: Should things like this just be part of a Document class?
      this.pdfPreviewUrl = this.documentProvider.getArchivePreviewUrl(
        archiveSource.databaseId,
        archiveSource.archiveId,
        this.cellParameters.data.id,
        this.authService.user.token,
        this.cellParameters.data.secureId,
        false
      );
    } else {
      // Assume inbox since there are only two source types
      const filename =
        this.cellParameters.data.filename + this.cellParameters.data.fileType;
      const inboxSource = this.cellParameters.source as InboxThumbnailSource;
      this.pdfPreviewUrl =
        this.isPdfConvertable || this.cellParameters.data.fileType === '.pdf'
          ? this.documentProvider.getInboxDownloadUrl(
              inboxSource.inboxId,
              filename
            )
          : this.documentProvider.getInboxPreviewUrl(
              inboxSource.inboxId,
              filename
            );
    }
    this.pdfPreviewUrl = this.updateUrlIfClientConvertable(this.pdfPreviewUrl);
  }

  /**
   * Handler for click of download icon.
   *
   * @param event Mouse event.
   */
  onClickDownload(event: MouseEvent): void {
    this.logger.debug('Download icon clicked.', event);
    let downloadFileUrl = '';
    if (this.cellParameters.sourceType === 'archive') {
      const archiveSource = this.cellParameters
        .source as ArchiveThumbnailSource;
      downloadFileUrl = this.documentProvider.getArchiveDownloadUrl(
        archiveSource.databaseId,
        archiveSource.archiveId,
        this.cellParameters.data.id,
        this.authService.user.token,
        this.cellParameters.data.secureId
      );
    } else {
      const inboxSource = this.cellParameters.source as InboxThumbnailSource;
      downloadFileUrl = this.documentProvider.getInboxDownloadUrl(
        inboxSource.inboxId,
        this.cellParameters.data.filename + this.cellParameters.data.fileType
      );
    }
    window.open(downloadFileUrl, '_blank');
  }

  /**
   * Handler for the thumbnail icon button click event.
   *
   * @param event Mouse event.
   */
  onClickThumbnailIcon(event: MouseEvent): void {
    switch (this.cellParameters.sourceType) {
      case 'archive':
        this.openArchivePdfPreview();
        break;
      case 'inbox':
        this.openInboxPdfPreview();
        break;
      case 'taskSearch':
        this.openTaskSearchPdfPreview();
        break;
    }
    // prevent AG grid from seeing the click.
    event.stopPropagation();
  }

  /** @inheritdoc */
  refresh(params: ThumbnailCellRendererParameters): boolean {
    this.cellParameters = params;
    return true;
  }

  /**
   * Get an archive URL and open a preview of a document.
   *
   */
  private openArchivePdfPreview(): void {
    this.applicationService.setOpenDocumentToArchiveDocument(
      this.cellParameters.data.id,
      this.cellParameters.data.secureId
    );

    this.logger.debug('Preview document URL:', this.pdfPreviewUrl);
    this.applicationService.setPdfPreviewUrl(this.pdfPreviewUrl);
  }

  /**
   * Get an inbox URL and open a preview of a document.
   *
   */
  private openInboxPdfPreview(): void {
    this.applicationService.setPdfPreviewUrl(this.pdfPreviewUrl);
  }

  private openTaskSearchPdfPreview(): void {
    this.applicationService.setOpenDocumentToTaskSearchDocument(
      this.cellParameters.data.id,
      this.cellParameters.data.secureId
    );

    this.logger.debug('Preview document URL:', this.pdfPreviewUrl);
    this.applicationService.setPdfPreviewUrl(this.pdfPreviewUrl);
  }

  private updateUrlIfClientConvertable(url: string) {
    if (this.isPdfConvertable) {
      url +=
        '&clientConvert=' +
        this.cellParameters.data.fileType.replace('.', '').toLowerCase();
    }
    return url;
  }
}
