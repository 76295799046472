import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApplicationQuery } from 'src/app/state/application/application.query';

/** Describes the metadata about the application. */
interface AboutApplication {
  /** Application Version. */
  buildIdentifier: string;
  /** Changelog from previous version. */
  changelog: string[];
  /** Copyright. */
  copyright: string;
  /** Licenses. */
  licenses: string[];
}

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  standalone: false,
})
export class AboutDialogComponent implements OnInit {
  /** Application data. */
  aboutApplication: AboutApplication;
  apiVersion = this.appQuery.apiVersion;
  /** Current year. */
  year = new Date().getFullYear();

  constructor(
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // This can be tested on a dev server by adding a build.json to src/assets/build.json.
    // Change /build.json below to /assets/build.json
    this.http.get<AboutApplication>('build.json').subscribe((aboutData) => {
      this.logger.debug('About data loaded', aboutData);
      this.aboutApplication = aboutData;
    });
  }

  /**
   * Handler for EULA on click event.
   */
  onClickEula(): void {
    window.open(
      'https://www.square-9.com/end-user-license-agreement',
      '_blank'
    );
  }

  /**
   * Handler for Licenses on click event.
   */
  onClickLicenses(): void {
    window.open('/3rdpartylicenses.txt', '_blank');
  }
}
