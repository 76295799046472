import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

/** Whether the window supports passive events. */
const supportsPassiveEvents = (): boolean => {
  let supportsPassive = false;
  try {
    const options = {
      get passive() {
        supportsPassive = true;
        return false;
      },
    };
    // biome-ignore lint/suspicious/noExplicitAny: Window expects a listener but testing for passive works with null.
    window.addEventListener('test', null as any, options);
    // biome-ignore lint/suspicious/noExplicitAny: Window expects a listener but testing for passive works with null.
    window.removeEventListener('test', null as any, options as any);
  } catch (err) {
    supportsPassive = false;
  }
  return supportsPassive;
};

/** Keycodes for scroll keys such as arrow keys. */
const scrollKeys = [37, 38, 39, 40];

/** Prevent default behavior on event. */
const preventDefault = (e: Event) => {
  e.preventDefault();
  e.stopImmediatePropagation();
  e.stopPropagation();
};

/** Prevent default behavior on scroll keys. */
const preventDefaultForScrollKeys = (e: KeyboardEvent) => {
  if (scrollKeys.find((k) => k === e.keyCode)) {
    preventDefault(e);
    return false;
  }
};

/** Service containing general functions that use the window. */
@Injectable({
  providedIn: 'root',
})
export class WindowService {
  private supportsPassive = supportsPassiveEvents();
  private wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
  private wheelOptions:
    | boolean
    | {
        passive: boolean;
      } = this.supportsPassive ? { passive: false } : false;
  constructor(private http: HttpClient) {}

  /** Disable scrolling on the window. */
  disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(this.wheelEvent, preventDefault, this.wheelOptions); // modern desktop
    window.addEventListener('touchmove', preventDefault, this.wheelOptions); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  }

  /** Enable scrolling on the window. */
  enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(
      this.wheelEvent,
      preventDefault,
      // biome-ignore lint/suspicious/noExplicitAny: Remove event listener expects a specific type that is satisified with wheel options.
      this.wheelOptions as any
    );

    window.removeEventListener(
      'touchmove',
      preventDefault,
      // biome-ignore lint/suspicious/noExplicitAny: Remove event listener expects a specific type that is satisified with wheel options.
      this.wheelOptions as any
    );
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  }

  /**
   * Fetch and download a file.
   *
   * Creates an invisible link to the provided file, using a blob, to be
   * downloaded as the provided name.
   *
   * @param downloadFileUrl Download URL.
   * @param filename Filename used to save the file locally.
   * @returns An observable that completes once the file is downloaded.
   */
  fetchAndDownloadUrl(
    downloadFileUrl: string,
    filename: string
  ): Observable<void> {
    return this.http
      .get<any>(downloadFileUrl, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          link.hidden = true;
          link.download = filename;
          document.body.append(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
        })
      );
  }
}
