import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, map, skip, tap } from 'rxjs/operators';

import { DocumentHistoryProvider } from 'models';
import { DOCUMENT_HISTORY_PROVIDER } from 'src/app/common/tokens';
import {
  DisplayHistoryPage,
  HISTORY_FILTERS,
  createDisplayHistoryPage,
} from 'src/app/models';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';

/** Indicates that no history is available. */
const NO_DISPLAY_HISTORY = undefined;
/** Document History Component. */
@UntilDestroy()
@Component({
  selector: 'app-document-history',
  templateUrl: './document-history.component.html',
  styleUrls: ['./document-history.component.scss'],
  standalone: false,
})
export class DocumentHistoryComponent implements OnInit, OnChanges {
  /** Filter buttons list. */
  @ViewChild('filterActionsList', { read: ElementRef })
  filterActionList: ElementRef;
  // This input is ONLY used for getting values in from the user. The private 'selectedDocumentIds` is what is actually used internally.
  /** Array of selected document ids. */
  @Input('selected-document-ids')
  selectedDocumentIdsInput: number[] = [];

  /** Current page index for the paginator. */
  currentPageIndex = 0;
  /** Available history filter values. */
  historyFilterValues: string[] = HISTORY_FILTERS.map((filter) => filter.name);
  /** Available history filters. */
  historyFilters = HISTORY_FILTERS;
  /** Determines if history entries are being loaded. */
  historyLoading = false;
  /** Observable of the current history page. */
  historyPage$: Observable<DisplayHistoryPage | typeof NO_DISPLAY_HISTORY>;
  /** Archive history results per page. */
  pageSize: number;
  /** Search text. */
  searchFilter: string;
  /** Determines if domain should be shown in entries. */
  showUserDomain$ = this.applicationQuery.showDomainInHistoryResults$;

  private archiveId: number;
  private databaseId: number;
  private historyPageSubject = new BehaviorSubject<
    DisplayHistoryPage | typeof NO_DISPLAY_HISTORY
  >(NO_DISPLAY_HISTORY);
  private selectedDocumentIds: number[] = [];
  // This is used in combination with ngOnChanges in order to set the document ids source on change but also debounce this change in its subscription.
  private selectedDocumentIdsSource = new BehaviorSubject<number[]>(
    this.selectedDocumentIdsInput
  );

  constructor(
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private applicationQuery: ApplicationQuery,
    private logger: NGXLogger,
    @Inject(DOCUMENT_HISTORY_PROVIDER) private history: DocumentHistoryProvider
  ) {}

  /**
   * Click event to disable all filters and show all entries.
   */
  clickDisableAllFilters(): void {
    this.historyFilterValues = this.historyFilters.map((f) => f.name);
  }

  /**
   * Click event to download current history page.
   *
   */
  clickDownloadHistory(): void {
    const page = this.historyPageSubject.getValue();
    if (!page) {
      this.logger.warn(
        'Uanble to export history. No page is currently loaded.'
      );
      return;
    }
    let csvString =
      'Date,Action,UserName,Document_ID,Archive_ID,formattedDate\r\n';
    for (const entry of [...page.entries].reverse()) {
      csvString += `"${entry.date}","${entry.action}","${entry.username}",${entry.documentId},${entry.archiveId},"${entry.displayDate}"\r\n`;
    }

    const uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
    const link = document.createElement('a');
    link.href = uri;
    link.hidden = true;
    link.download = `archive_${this.archiveId}_history.csv`;
    document.body.append(link);
    link.click();
    link.remove();
  }

  /**
   * Click event to enable all filters and show no entries.
   */
  clickEnableAllFilters(): void {
    this.historyFilterValues = [];
  }

  /**
   * Click event for scrolling the history filters.
   *
   * @param direction Scrolls up if "up" and "down" otherwise.
   */
  clickFilterScroll(direction: 'up' | 'down'): void {
    if (direction === 'up') {
      this.filterActionList.nativeElement.scrollBy({
        top: -100,
        behavior: 'smooth',
      });
    } else {
      this.filterActionList.nativeElement.scrollBy({
        top: 100,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Click event for refreshing history.
   */
  clickRefresh(): void {
    this.logger.debug('Refresh history clicked.');
    this.currentPageIndex = 0;
    this.loadHistory(1);
  }

  /**
   * Click event for toggling all filters. If any filters are not enabled then all are enabled. Otherwise all are disabled.
   */
  clickToggleAllFilters(): void {
    this.logger.debug('Toggle all filters clicked', this.historyFilterValues);
    if (this.historyFilterValues.length === this.historyFilters.length) {
      this.historyFilterValues = [];
    } else {
      this.historyFilterValues = this.historyFilters.map((f) => f.name);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedDocumentChanges = changes.selectedDocumentIdsInput;
    if (
      selectedDocumentChanges &&
      !selectedDocumentChanges.isFirstChange() &&
      selectedDocumentChanges.currentValue !==
        selectedDocumentChanges.previousValue
    ) {
      this.selectedDocumentIdsSource.next(selectedDocumentChanges.currentValue);
    }
  }

  ngOnInit(): void {
    this.logger.debug('Initializing document history component...');
    this.historyPage$ = this.historyPageSubject.asObservable();
    this.listenForHistoryReload();
  }

  /**
   * Handler for the paginator on change event.
   *
   * @param event A page event.
   */
  onPageChange(event: PageEvent): void {
    this.logger.debug('Page change event triggered.', event);
    this.currentPageIndex = event.pageIndex;
    this.loadHistory(event.pageIndex + 1);
  }

  private listenForHistoryReload(): void {
    combineLatest([
      this.databasesQuery.selectActiveId(),
      this.archivesQuery.selectActiveId(),
      this.applicationQuery.archiveHistoryResultsPerPage$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([databaseId, archiveId, pageSize]) => {
        this.logger.debug('History component data is being reloaded.');
        if (
          typeof databaseId === 'undefined' ||
          databaseId === null ||
          typeof archiveId === 'undefined' ||
          archiveId === null
        ) {
          throw new TypeError(
            'Values are required for both database and archive Id.'
          );
        }
        this.databaseId = databaseId;
        this.archiveId = archiveId;
        this.pageSize = pageSize;

        this.selectedDocumentIds = this.selectedDocumentIdsInput;
        this.loadHistory(1);

        this.selectedDocumentIdsSource
          .asObservable()
          .pipe(
            skip(1), // prevent initial value emission since the above 'loadHistory' handled it.
            tap(() => {
              /*
              Setting the loading state here is necessary even though it is done in `loadHistory`.
              This is in order to show the progress bar while the debounce is preventing `loadHistory` from running.
              */
              this.historyLoading = true;
              this.historyPageSubject.next(NO_DISPLAY_HISTORY);
            }),
            debounceTime(2000)
          )
          .subscribe((selectedDocumentIds) => {
            this.selectedDocumentIds = selectedDocumentIds;
            this.currentPageIndex = 0;
            this.loadHistory(1);
          });
      });
  }

  private loadHistory(pageNumber: number) {
    this.historyLoading = true;
    this.historyPageSubject.next(NO_DISPLAY_HISTORY);
    this.history
      .getArchiveHistory(
        this.databaseId,
        this.archiveId,
        pageNumber,
        this.pageSize,
        this.selectedDocumentIds
      )
      .pipe(untilDestroyed(this), map(createDisplayHistoryPage))
      .subscribe({
        next: (displayPage) => {
          this.historyLoading = false;
          this.historyPageSubject.next(displayPage);
        },
        error: (error) => {
          this.historyLoading = false;
          this.logger.error(error);
        },
      });
  }
}
