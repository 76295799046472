import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Archive, Archives, Database, Search } from 'models';
import { ROOT_ARCHIVE_ID } from 'src/app/common/constants';
import { CommandService } from 'src/app/modules/command-palette';
import { SearchUIService } from 'src/app/services/search-ui.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { ArchivesService } from 'src/app/state/archives/archives.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

/**
 * Navigation Panel Archive Section.
 */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-archive-panel',
  templateUrl: './archive-panel.component.html',
  styleUrls: ['./archive-panel.component.scss'],
  standalone: false,
})
export class ArchivePanelComponent implements OnInit {
  /** Database. */
  @Input() database: Database;

  /** Archive breadcrumbs. */
  archiveBreadcrumbs: Archives;
  /** Archives observable. */
  archives$ = this.archivesService.archives$;
  /** Displayed archive breadcrumb string. */
  breadcrumbString: string;
  /** Currently visible archives. */
  displayedArchives: Archives;
  /** Currently visible archives observable. */
  displayedArchives$: Observable<Archives>;
  /** Archive panel is displaying child archives. */
  inChildArchive: boolean;
  /** Archive loading state observable. */
  isArchivesLoading$ = this.archivesService.isLoading$;
  /** Observable of whether the archive navigation section is expanded. */
  isExpanded$ = this.applicationQuery.archiveNavSectionExpanded$;
  /** Observable array of root searches. */
  rootSearches$ = this.searchesQuery.selectForParent(ROOT_ARCHIVE_ID);
  /** Observable of whether to show the versions archive. */
  showVersionsArchive$ = this.applicationQuery.showVersionsArchive$;

  private archiveSubscription: Subscription;
  private displayedArchivesSource = new BehaviorSubject<Archives>([]);

  constructor(
    private logger: NGXLogger,
    private applicationService: ApplicationService,
    private applicationQuery: ApplicationQuery,
    private archivesQuery: ArchivesQuery,
    private commandService: CommandService,
    private searchesQuery: SearchesQuery,
    private searchUIService: SearchUIService,
    private archivesService: ArchivesService
  ) {}

  /**
   * Click event for a search button.
   *
   * @param search Search.
   */
  clickSearch(search: Search): void {
    this.logger.debug('Search button clicked.', search);
    this.searchUIService.runWithPrompt(
      search,
      this.database.id,
      search.archives[0] // the root archive id cannot be used so run the search on the first archive the search runs on.
    );
  }

  /**
   * Loads the parent archive of the current child archive.
   */
  loadParentArchives(): void {
    this.archiveBreadcrumbs.pop();
    if (this.archiveBreadcrumbs.length === 0) {
      this.logger.debug('Loading root archives.');
      this.loadRootArchives();
    } else {
      this.logger.debug(
        'Loading parent archives for: ',
        this.getCurrentParent()
      );
      this.displayedArchivesSource.next(this.getCurrentParent().children);
      this.updateBreadcrumbString();
    }
  }

  /**
   * Loads root level archives.
   */
  loadRootArchives(): void {
    this.archiveBreadcrumbs = [];
    // TODO: See if the explicit subscription cancel and create pattern im using can or should be replaced with 'until' operator pattern
    this.archiveSubscription?.unsubscribe();
    this.archiveSubscription = this.archives$.subscribe((archives) => {
      const nextValue = archives.filter(
        (a: Archive) => a.parentId === ROOT_ARCHIVE_ID
      );
      this.logger.debug(
        `Updating displayed archive tree: ${JSON.stringify(
          nextValue.map(({ name }) => name)
        )}`
      );
      this.displayedArchivesSource.next(nextValue);
    });
    this.inChildArchive = false;
    this.updateBreadcrumbString();
  }

  ngOnInit(): void {
    this.displayedArchives$ = this.displayedArchivesSource.asObservable();
    this.loadRootArchives();
    this.registerRootSearchCommands();
    this.listenForActiveArchive();
  }

  /**
   * Handler for the on expand change event.
   *
   */
  onExpandChanged(): void {
    this.applicationService.toggleArchiveNavSectionExpanded();
  }

  /**
   * Event handler for loadChildArchives event.
   *
   * @listens this.loadChildArchives
   * @param archive $event data.
   */
  onLoadChildArchives(archive: Archive): void {
    this.logger.debug('Loading child archives for: ', archive);
    // This adds the archive breadcrumbs only if the last value in the array isn't the same as the archive
    // you are trying to push to it.
    if (
      this.archiveBreadcrumbs.length === 0 ||
      this.archiveBreadcrumbs[this.archiveBreadcrumbs.length - 1].id !==
        archive.id
    ) {
      this.archiveBreadcrumbs.push(archive);
    }
    this.displayedArchivesSource.next(archive.children);
    this.inChildArchive = true;
    this.updateBreadcrumbString();
  }

  private addRootSearchesToCommands(searches: Search[]): void {
    for (const search of searches) {
      this.searchUIService.addToCommandPalette(
        search,
        this.database.id,
        ROOT_ARCHIVE_ID,
        true,
        false
      );
    }
  }

  private getCurrentParent(): Archive {
    return this.archiveBreadcrumbs[this.archiveBreadcrumbs.length - 1];
  }

  private listenForActiveArchive() {
    this.archivesQuery.selectActive().subscribe((archive) => {
      if (!archive) {
        // There is nothing for this handler to do if there is no active archive.
        return;
      }
      // Check if the archive is a child archive.
      if (archive.parentId > 0) {
        // If it is get the parent archive from the store.
        const parentArchive = this.archivesQuery.getEntity(archive.parentId);
        if (parentArchive) {
          // If we find a parent archive load it the same way we would if you clicked the button.
          this.onLoadChildArchives(parentArchive);
        }
      } else if (archive.parentId === 0 && this.inChildArchive) {
        // Load the root archives if the active archive changed to a root archive and this panel is currently displaying children.
        this.loadRootArchives();
      }
    });
  }

  private registerRootSearchCommands(): void {
    this.rootSearches$
      .pipe(
        untilDestroyed(this),
        map((searches) =>
          searches.filter(
            (s) =>
              !this.commandService.commands.some(
                (c) => c.group === 'Search' && c.name === s.name
              )
          )
        )
      )
      .subscribe((searches) => this.addRootSearchesToCommands(searches));
  }

  private updateBreadcrumbString(): void {
    if (!this.inChildArchive) {
      this.breadcrumbString = '';
      return;
    }
    let str = '';
    for (const archive of this.archiveBreadcrumbs) {
      str += `${str.length > 0 ? ' > ' : ''}${archive.name}`;
    }
    this.breadcrumbString = str;
  }
}
