import {
  NgUploaderService,
  UploadFile,
  UploadInput,
  UploadOutput,
  UploadStatus,
  UploaderOptions,
} from '@angular-ex/uploader';
import { CdkStep, StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions, IRowNode } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { assertExists } from 'common';
import {
  CSVImportMapping,
  CSVImportMappings,
  CSVImportProvider,
  Fields,
  UserFriendlyError,
} from 'models';
import { CSV_IMPORT_PROVIDER } from 'src/app/common/tokens';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { CSVImportJobService } from 'src/app/state/csv-import-jobs/csvimport-job.service';

import {
  SelectionCellRendererComponent,
  SelectionCellRendererParameters,
} from '../selection-cell-renderer/selection-cell-renderer.component';

/** CSV Import Dialog Data. */
export interface CSVImportDialogData {
  /** Target Archive ID. */
  archiveId: number;
  /** Target Database ID. */
  databaseId: number;
}

/** CSV import dialog result. */
export interface CSVImportDialogResult {
  /** Job ID for the started import job. */
  jobId: string;
}

/**
 * CSV Upload response.
 */
declare type CSVUploadResult = { [csvFilename: string]: string };

/** CSV Import Dialog. */
@Component({
  selector: 'app-csvimport-dialog',
  templateUrl: './csvimport-dialog.component.html',
  styleUrls: ['./csvimport-dialog.component.scss'],
  standalone: false,
})
export class CSVImportDialogComponent implements OnInit {
  /** AG Grid Instance. */
  @ViewChild('grid')
  grid: AgGridAngular;
  /** Reference to the csv file input element. */
  @ViewChild('jobFileUploader')
  jobFileUploader: ElementRef;
  /** Mat stepper. */
  @ViewChild('stepper')
  stepper: MatStepper;
  /** CSV file upload form group. */
  csvFileUploadFormGroup: UntypedFormGroup;
  /** Upload emitter for sending commands to file uploader. */
  csvUploadInput = new EventEmitter<UploadInput>();
  /** CSV upload progress. */
  csvUploadProgress = 0;
  /** Archive fields. */
  fields: Fields;
  /** Grid options. */
  gridOptions: GridOptions;
  /** CSV job column mappings. */
  jobColumnMappings: Observable<CSVImportMappings>;
  /** Array of job files being uploaded. */
  jobFiles: UploadFile[] = [];
  /** Job file upload input. */
  jobUploadInput = new EventEmitter<UploadInput>();
  /** Whether to show the next step button. */
  showGoToNextStep = true;
  /** Whether to show the previous step button. */
  showGoToPreviousStep = false;
  /** Uploader options bound to the @angular-ex/uploader. */
  uploaderOptions$: Observable<UploaderOptions> =
    this.appConfigQuery.concurrentUploads$.pipe(
      map((concurrentUploads) => ({
        concurrency: concurrentUploads,
      }))
    );

  private currentStep: CdkStep;
  private importJobId: string;
  private uploadService: NgUploaderService;

  /**
   * Whether the field mapper has at least one included field.
   *
   * @returns True if at least one field is included.
   */
  get atLeastOneFieldIncluded(): boolean {
    let atLeastOneFieldIncluded = false;
    this.grid.api.forEachNode((rowNode) => {
      if (rowNode.data.includeField) {
        atLeastOneFieldIncluded = true;
        return;
      }
    });

    return atLeastOneFieldIncluded;
  }

  /**
   * Whether the current step is invalid.
   *
   * @returns True if the current step is invalid. False otherwise.
   */
  get currentStepInvalid(): boolean {
    if (!this.currentStep)
      return this.csvFileUploadFormGroup.invalid || !this.importJobId;

    return this.currentStep.hasError || !this.importJobId;
  }

  /**
   * Determines if the import button should be disabled.
   *
   * @returns True if the import button should be disabled. Otherwise false.
   */
  get disableImport(): boolean {
    return !this.gridDataValid();
  }

  /**
   * Whether the file uploads are completed.
   *
   * @returns True when some files are uploaded, and all are complete.
   */
  get fileUploadComplete(): boolean {
    return (
      this.jobFiles.length > 0 &&
      this.jobFiles.every((x) => x.progress.status === UploadStatus.Done)
    );
  }

  /**
   * Whether the file uploader is the current step.
   *
   * @returns True when current step.
   */
  get fileUploadCurrentStep(): boolean {
    return this.stepper.steps.get(1) === this.currentStep;
  }

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private notify: NotificationService,
    private authenticationService: AuthenticationService,
    private archivesQuery: ArchivesQuery,
    private dialogReference: MatDialogReference<CSVImportDialogComponent>,
    private csvImportJobService: CSVImportJobService,
    private appConfigQuery: AppConfigQuery,
    @Inject(MAT_DIALOG_DATA) public dialogData: CSVImportDialogData,
    @Inject(CSV_IMPORT_PROVIDER) private csvImportProvider: CSVImportProvider
  ) {
    const tableFields = this.archivesQuery.getTableFields(
      this.dialogData.archiveId
    );
    this.fields = this.archivesQuery
      .getFields(this.dialogData.archiveId)
      .filter(
        (field) =>
          !field.liveField &&
          !tableFields.some((tf) => tf.fieldIds.includes(field.id)) &&
          field.name !== 'Document Parent ID' &&
          field.name !== 'Document Version' &&
          // Only indexedBy and dateEntered are populated during CSV imports
          (!field.systemField ||
            field.systemField === 'indexedBy' ||
            field.systemField === 'dateEntered')
      );
    this.uploadService = new NgUploaderService();
    this.uploadService.serviceEvents.subscribe((event: UploadOutput) =>
      this.onCSVUploadOutput(event)
    );
  }

  /**
   * Checks if the column mapping grid is currently valid.
   *
   * @returns Whether the grid data is currently valid.
   */
  gridDataValid(): boolean {
    let filenameColumnSelected = false;
    let fieldIncludedWithoutMapping = false;
    this.grid.api?.forEachNode((rowNode) => {
      if (rowNode.data.includeField) {
        if (rowNode.data.isFilepath) {
          filenameColumnSelected = true;
        } else if (rowNode.data.fieldId === 0) {
          // The column is marked as included but is not mapped to a field or the file path.
          fieldIncludedWithoutMapping = true;
          return;
        }
      }
    });

    // Ensure there is a filename mapped and that there are no fields marked as included without a mapping.
    return filenameColumnSelected && !fieldIncludedWithoutMapping;
  }

  ngOnInit(): void {
    this.configureMappingGrid();
    this.csvFileUploadFormGroup = this.formBuilder.group({
      uploaded: [false, Validators.requiredTrue],
      csvFile: ['', Validators.required],
      columnDelimiter: [',', Validators.required],
      stringDelimiter: ['"', Validators.required],
      containsColumnHeaders: [false],
    });
    this.uploadService.initInputEvents(this.csvUploadInput);
  }

  /**
   * Handler for the include all button click handler.
   *
   * @param includeAll Whether to include all.
   */
  onClickToggleIncludeAll(includeAll: boolean): void {
    // Loops through all
    this.grid.api.forEachNode((rowNode) => {
      rowNode.setDataValue('includeField', includeAll);
    });

    this.grid.api.refreshCells({ force: true });
  }

  /**
   * Handler for the upload output event.
   *
   * @param outputEvent @angular-ex/uploader upload output event.
   */
  onCSVUploadOutput(outputEvent: UploadOutput): void {
    this.logger.debug('On upload event triggered', outputEvent);
    switch (outputEvent.type) {
      case 'allAddedToQueue':
        this.csvUploadProgress = 0;
        break;
      case 'rejected': {
        this.notify.error(
          'File upload was rejected. Ensure the file was a CSV.'
        );

        break;
      }
      case 'done': {
        this.logger.debug('Upload is done.', outputEvent);
        if (outputEvent.file?.responseStatus === 200) {
          // this.csvFileUploadFormGroup.controls.csvFile.setValue(output.file.name);
          this.onCsvUploadComplete(outputEvent.file.response);
        } else {
          this.notify.error({
            error: outputEvent.file?.response,
            description: `The ${outputEvent.file?.name} file could not be uploaded.`,
            i18n: 'ERROR_FILE_UPLOAD_FAILED',
          });
        }

        break;
      }
      case 'uploading': {
        this.csvUploadProgress =
          outputEvent.file?.progress.data?.percentage ?? 0;

        break;
      }
      // No default
    }
  }

  /** Handler for the import documents event. */
  onImportDocuments(): void {
    const columnMappings: CSVImportMappings = [];
    this.grid.api?.forEachNode((rowNode) =>
      columnMappings.push(rowNode.data as CSVImportMapping)
    );
    this.logger.debug('Import documents clicked.', columnMappings);

    this.csvImportJobService
      .startJob(this.importJobId, columnMappings)
      .subscribe({
        next: () => {
          this.logger.debug('CSV import job started.', this.importJobId);
          const result: CSVImportDialogResult = {
            jobId: this.importJobId,
          };
          this.dialogReference.close(result);
        },
        error: (error: UserFriendlyError) => this.notify.error(error),
      });
  }

  /**
   * Handler for the reset event.
   */
  onReset(): void {
    if (this.importJobId) {
      this.logger.debug('Delete job.', this.importJobId);
      this.csvImportJobService.deleteJob(this.importJobId).subscribe(() => {
        this.importJobId = '';
      });
    }
    this.stepper.reset();
    this.csvFileUploadFormGroup.enable();
    this.csvFileUploadFormGroup.controls.columnDelimiter.setValue(',');
    this.csvFileUploadFormGroup.controls.stringDelimiter.setValue('"');
    this.csvFileUploadFormGroup.controls.containsColumnHeaders.setValue(false);
  }

  /**
   * Handler for the start upload job event.
   */
  onStartJobUpload(): void {
    const startUploadRequest = this.csvImportProvider.getJobFileUploadRequest(
      this.importJobId,
      this.authenticationService.user.token
    );
    const event: UploadInput = {
      type: 'uploadAll',
      url: startUploadRequest.url,
      method: startUploadRequest.method,
      headers: startUploadRequest.headers,
    };

    this.jobUploadInput.emit(event);
  }

  /**
   * Event handler for the select CSV button.
   */
  onStartUpload(): void {
    this.jobFiles.length = 0; // Truncate the array of files.
    const file = this.csvFileUploadFormGroup.controls.csvFile.value as File;
    if (!file) return;
    const extension = file.name.slice(Math.max(0, file.name.lastIndexOf('.')));
    if (extension.toLowerCase() !== '.csv' || file.size === 0) {
      this.notify.error(
        new UserFriendlyError(
          undefined,
          'File must be a CSV',
          'UPLOADED_FILE_MUST_BE_A_CSV'
        )
      );
      return;
    }
    const columnDelimiter =
      this.csvFileUploadFormGroup.controls.columnDelimiter.value;
    const stringDelimiter =
      this.csvFileUploadFormGroup.controls.stringDelimiter.value;
    const containsColumnHeaders = this.csvFileUploadFormGroup.controls
      .containsColumnHeaders.value as boolean;
    const csvJobRequest = this.csvImportProvider.getCreateJobRequest(
      this.dialogData.databaseId,
      this.dialogData.archiveId,
      columnDelimiter,
      stringDelimiter,
      containsColumnHeaders,
      this.authenticationService.user.token
    );
    const fileList = this.createrFileList([file]);
    this.uploadService.handleFiles(fileList);
    this.csvUploadInput.emit({
      type: 'uploadAll',
      url: csvJobRequest.url,
      method: csvJobRequest.method,
      headers: csvJobRequest.headers,
    });
  }

  /**
   * Event handler for the step selection change event.
   *
   * @param event Stepper selection event.
   */
  onStepSelectionChange(event: StepperSelectionEvent): void {
    this.logger.debug('Stepper step changed', event);
    this.currentStep = event.selectedStep;
    this.showGoToNextStep = event.selectedIndex < this.stepper.steps.length - 1;
    this.showGoToPreviousStep =
      event.selectedIndex === this.stepper.steps.length - 1;
  }

  /**
   * Event handler for the file uploader output event.
   *
   * @param uploadEvent Upload output event.
   * @throws {TypeError} If upload event is missing a required file object.
   */
  onUploadOutput(uploadEvent: UploadOutput): void {
    this.logger.debug('Upload output triggered', uploadEvent);
    const file = uploadEvent.file;
    switch (uploadEvent.type) {
      case 'start':
        break;
      case 'addedToQueue':
        assertExists(uploadEvent.file, 'Upload event requires a file object.');
        // Adding files must be to a new array, as cdkVirtualFor expects immutable arrays.
        this.jobFiles = [...this.jobFiles, uploadEvent.file];
        break;
      case 'allAddedToQueue':
        this.onStartJobUpload();
        break;
      case 'removed':
      case 'cancelled':
      case 'done':
        break;
      case 'uploading': {
        assertExists(file, 'Upload event requires a file object.');
        const index = this.jobFiles.findIndex((f) => f.name === file.name);
        this.jobFiles[index] = file;
        break;
      }
    }
  }

  /**
   * Checks if another column is already a filepath.
   *
   * @param api Grid API instance.
   * @param currentRowNode Current row node to ensure we aren't checking a row node that is the current row. Skipped if not provided.
   * @returns True if another column is a file path.
   */
  private checkIfAnotherColumnIsFilePath(
    api: GridApi,
    currentRowNode?: IRowNode | undefined | null
  ): boolean {
    let anotherColumnIsFilepath = false;
    api.forEachNode((rowNode) => {
      if (
        !anotherColumnIsFilepath && // if we already found one don't bother with the rest of the condition
        (!currentRowNode ||
          rowNode.data.displayName !== currentRowNode.data.displayName) && // Make sure we aren't looking at the current row node.
        rowNode.data.isFilepath
      ) {
        anotherColumnIsFilepath = true;
      }
    });

    return anotherColumnIsFilepath;
  }

  private configureMappingGrid(): void {
    this.gridOptions = {
      pagination: false,
      columnDefs: [
        {
          field: 'displayName',
          headerName: 'Display Name',
          sortable: false,
          filter: false,
          editable: false,
        },
        {
          field: 'includeField',
          headerName: 'Include Field',
          sortable: false,
          filter: false,
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          valueSetter: (params) => {
            // Sets the backing row data.
            params.data.includeField = params.newValue;

            // Try to either map the column to a field or mark it as a filepath.
            if (params.data.includeField) {
              if (params.data.displayName.toLowerCase().includes('filepath')) {
                // ensure no other column mapping already has a filepath
                let anotherColumnIsFilepath =
                  this.checkIfAnotherColumnIsFilePath(params.api, params.node);
                if (!anotherColumnIsFilepath) {
                  // Only automap filepath is there isn't a column already marked as the filepath.
                  params.data.isFilepath = true;
                }
              } else {
                // Check if any archive fields have the same name as the display name.
                const fieldForColumn = this.fields.find(
                  (f) =>
                    f.name.toLowerCase() ===
                    params.data.displayName.toLowerCase()
                );
                if (fieldForColumn) {
                  this.logger.debug(
                    'Field with a name that matches the column was found. Automapping it.'
                  );
                  params.data.fieldId = fieldForColumn.id;
                }
              }
            } else {
              // Ensure filepath is false if the field will no longer be included.
              params.data.isFilepath = false;
            }

            assertExists(params.node);
            // Ensures that all cells in the row refresh to update their editablility.
            params.api.refreshCells({
              rowNodes: [params.node],
              force: true,
            });
            return true;
          },
        },
        {
          field: 'isFilepath',
          headerName: 'Is Filepath',
          sortable: false,
          filter: false,
          editable: (params) => {
            if (!params.data.includeField) {
              return false;
            }

            // check if any other row already has filepath checked.
            let anotherColumnIsFilepath = this.checkIfAnotherColumnIsFilePath(
              params.api,
              params.node
            );
            return !anotherColumnIsFilepath;
          },
          cellRenderer: 'agCheckboxCellRenderer',
          valueSetter: (params) => {
            // Sets the backing row data.
            params.data.isFilepath = params.newValue;
            assertExists(params.node);
            // Ensures that all cells refresh to update their editablility.
            params.api.refreshCells({
              force: true,
            });
            return true;
          },
        },
        {
          field: 'fieldId',
          headerName: 'Mapped Field',
          sortable: false,
          filter: false,
          editable: false,
          cellRenderer: SelectionCellRendererComponent,
          cellRendererParams: {
            disabled: (params: SelectionCellRendererParameters) => {
              return (
                params.node.data.isFilepath || !params.node.data.includeField
              );
            },
            selectionOptions: this.fields.map((field) => ({
              value: field.id,
              display: field.name,
            })),
          },
        },
      ],
    };
  }

  /**
   * Helper method for creating a file list object that @angular-ex/uploader can use.
   *
   * @param files Files.
   * @returns A file list object.
   */
  private createrFileList(files: File[]): FileList {
    // Switched this to any to fix an error with DOM.iterable
    const fileList: any = {
      length: files.length,
      item: (index: number): File => fileList[index],
    };

    for (const [index, file] of files.entries()) fileList[index] = file;
    return fileList;
  }

  private onCsvUploadComplete(csvResponse: CSVUploadResult): void {
    this.logger.debug('CSV Upload completed successfully', csvResponse);
    this.importJobId = csvResponse[Object.keys(csvResponse)[0]];
    // Mark the upload as completed for form validity.
    this.csvFileUploadFormGroup.controls.uploaded.setValue(true);
    this.csvImportProvider
      .getJobColumnMappings(this.importJobId)
      .subscribe((mappings) => {
        const rowData = mappings.map((columnMapping) => ({
          displayName: columnMapping.displayName,
          includeField: columnMapping.includeField,
          isFilepath: columnMapping.isFilepath,
          fieldId: columnMapping.fieldId,
          columnIndex: columnMapping.columnIndex,
        }));
        this.grid.api?.updateGridOptions({ rowData: rowData });
        this.grid.api?.hideOverlay();
        this.csvFileUploadFormGroup.disable();
        this.stepper.next();
      });
  }
}
