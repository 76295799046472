import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { Field } from 'models';

import { FieldBaseComponent } from '../field-component.base.component';
import { MultiValueFieldMenuComponent } from '../multi-value-field-menu/multi-value-field-menu.component';

/** Number Field Component. */
@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
  standalone: false,
})
export class NumberFieldComponent extends FieldBaseComponent implements OnInit {
  /** Field form control. */
  @Input('form-control')
  control: UntypedFormControl;
  /** Field. */
  @Input()
  field: Field;
  /** Emits when this field is blurred. The event contains the field that was blurred. */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Input element reference. */
  @ViewChild('input')
  inputElement: ElementRef;
  /** MV Field Menu Component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;

  /** @inheritdoc */
  get errorMessage(): string {
    // super.errorMessage has pattern but it is for regex pattern
    // so this allows us to override that default error message.
    if (this.control.hasError('pattern')) {
      return this.translate.translate('INVALID_FIELD_NUMBER');
    }

    return super.errorMessage;
  }

  /** @inheritdoc */
  focus(): void {
    this.inputElement.nativeElement.focus();
    this.inputElement.nativeElement.select();
  }

  ngOnInit(): void {
    this.addValidators();
    this.control.addValidators(Validators.pattern('^-?[0-9]*$'));
    this.control.valueChanges.subscribe((newValue) => {
      if (!newValue) return; // Don't try to format if there is no value.
      // Returns only the integer.
      const truncatedValue = Math.trunc(newValue);
      this.control.setValue(truncatedValue, { emitEvent: false });
    });
  }

  /** @inheritdoc */
  onBlur(): void {
    this.fieldBlurred.emit(this.field);
  }

  /** @inheritdoc */
  onFocus(): void {
    this.fieldFocused.emit(this.field);
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === '+' || event.key.toLowerCase() === 'e') {
      event.preventDefault();
    }
  }
}
