import {
  Directive,
  computed,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { assertExists } from 'common';
import { FieldValues, UserFriendlyError } from 'models';
import { NGXLogger } from 'ngx-logger';
import { derivedAsync } from 'ngxtension/derived-async';
import {
  PdfThumbnailViewerComponent,
  PdfViewerComponent,
} from 'src/app/modules/pdf-viewer';
import { NewPdfDocumentService } from 'src/app/services/new-documents.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';

@Directive({
  selector: '[appPdfThumbnailerEventHandler]',
  standalone: false,
})
export class PdfThumbnailerEventHandlerDirective {
  // Injections
  private archivesQuery = inject(ArchivesQuery);
  private databasesQuery = inject(DatabasesQuery);
  private logger = inject(NGXLogger);
  private newDocumentService = inject(NewPdfDocumentService);
  private notify = inject(NotificationService);
  private router = inject(Router);

  /** Target archive id. Defaults to the active archive id. */
  archiveId = input<number | undefined>(undefined, {
    alias: 'appPdfThumbnailerEventHandlerArchiveId',
  });
  /** Pdf thumbnailer component reference. */
  pdfThumbnailer = input.required<PdfThumbnailViewerComponent>({
    alias: 'appPdfThumbnailerEventHandler',
  });
  /** Pdf viewer component reference. */
  pdfViewer = input.required<PdfViewerComponent>({
    alias: 'appPdfThumbnailerEventHandlerViewer',
  });
  /** Optional array of field values to associate with documents. */
  fieldValues = input<FieldValues | undefined>(undefined, {
    alias: 'appPdfThumbnailerHandlerFields',
  });
  /**
   * The target archive id used when bursting to a new document.
   * This will either be the archive id provided as an input or the active archive id.
   */
  targetArchiveId = derivedAsync(() => {
    const archiveId = this.archiveId();
    if (archiveId) return archiveId;

    return this.archivesQuery.activeId$;
  });

  private addPagesErrorEvent = computed(
    () => this.pdfThumbnailer().addPagesError
  );
  private burstCompletedEvent = computed(
    () => this.pdfThumbnailer().burstCompleted
  );
  private burstErrorEvent = computed(() => this.pdfThumbnailer().burstError);
  private goToPageEvent = computed(() => this.pdfThumbnailer().goToPage);
  private selectedThumbnails = computed(() =>
    this.pdfThumbnailer().selectedThumbnails()
  );

  constructor() {
    /** Handles grabbing the burst completed event and listening for emissions. */
    effect(() => {
      const burstCompletedEvent = this.burstCompletedEvent();
      // Use untracked to grab the target archive id signal without
      // letting that signal run this effect.
      const targetArchiveId = untracked(this.targetArchiveId);
      assertExists(targetArchiveId);
      burstCompletedEvent.subscribe((event) => {
        this.logger.debug('Burst completed event received.', event);
        this.newDocumentService
          .addNewDocument(
            event.newDocumentBytes,
            event.pageCount,
            targetArchiveId,
            this.databasesQuery.activeId,
            this.fieldValues()
          )
          .subscribe({
            next: (newDocumentId) => {
              this.logger.debug(
                'New document successfully added to the local database.',
                newDocumentId
              );
              if (!event.pagesDeletedFromSource) {
                this.logger.debug(
                  'Pages were not deleted from the source document.'
                );
                this.addBurstedDocumentViewUrlToThumbnails(newDocumentId);
              }
            },
          });
      });
    });

    /** Handles grabbing the burst error event and listening for emissions. */
    effect(() => {
      const burstError = this.burstErrorEvent();
      burstError.subscribe((event) => {
        this.logger.error('Burst error event received.', event);
        const userFriendlyError: UserFriendlyError = {
          i18n: 'PAGES_FAILED_TO_BURST',
          error: event.error,
          description: 'The pages could not be added to a new document.',
        };
        this.notify.error(userFriendlyError);
      });
    });

    /** Handles grabbing the add pages error event and listening for emissions. */
    effect(() => {
      const addPagesError = this.addPagesErrorEvent();
      addPagesError.subscribe((event) => {
        this.logger.error('Add pages error event received.', event);
        this.notify.error(event.error);
      });
    });

    /** Handles the go to page event. */
    effect(() => {
      const goToPageEvent = this.goToPageEvent();
      goToPageEvent.subscribe((event) => {
        this.logger.debug('Go to page event received.', event);
        this.pdfViewer().pageNumber = event.pageNumber;
      });
    });
  }

  private addBurstedDocumentViewUrlToThumbnails(newDocumentId: string): void {
    this.logger.debug('Adding bursted url to the thumbnail.');
    const urlTree = this.router.createUrlTree([
      'db',
      this.databasesQuery.activeId,
      'new',
      newDocumentId,
    ]);
    const urlString = urlTree.toString();
    for (const thumbnail of this.selectedThumbnails()) {
      thumbnail.burstedDocumentUrl = urlString;
    }

    this.pdfThumbnailer().refresh();
  }
}
