import { v4 as uuid } from 'uuid';

import { IDocumentUpdateSession } from 'models';

/** Handles the creation of a document update session. */
export class DocumentUpdateSession implements IDocumentUpdateSession {
  /** @inheritdoc */
  get actions(): string {
    let actionString = '';
    if (this.updateAnnotationData) {
      actionString += 'annotationdata,';
    }
    if (this.updateDocument) {
      actionString += 'document,';
    }
    if (this.updateFieldData) {
      actionString += 'fielddata,';
    }
    if (this.updateTableData) {
      actionString += 'tabledata,';
    }

    if (actionString.endsWith(',')) {
      actionString = actionString.slice(0, -1);
    }

    return actionString;
  }

  /** @inheritdoc */
  id: string;
  /** Will annotation data be updated? */
  updateAnnotationData: boolean;
  /** Will document data be updated? */
  updateDocument: boolean;
  /** Will field data be updated? */
  updateFieldData: boolean;
  /** Will table data be updated? */
  updateTableData: boolean;

  constructor(
    updateAnnotationData: boolean,
    updateDocument: boolean,
    updateFieldData: boolean,
    updateTableData: boolean
  ) {
    this.id = uuid();
    this.updateAnnotationData = updateAnnotationData;
    this.updateDocument = updateDocument;
    this.updateFieldData = updateFieldData;
    this.updateTableData = updateTableData;
  }
}
