import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NGXLogger } from 'ngx-logger';

import { GridHelperService } from 'src/app/services/grid-helper.service';
import { DateFormatsService } from 'src/app/state/date-formats/date-formats.service';

import { FieldCellRendererParameters } from '../field-cell-renderer-parameters';

/** Date Cell Renderer. */
@Component({
  selector: 'app-date-time-cell-renderer',
  templateUrl: './date-time-cell-renderer.component.html',
  styleUrls: ['./date-time-cell-renderer.component.scss'],
  standalone: false,
})
export class DateTimeCellRendererComponent implements ICellRendererAngularComp {
  /** Displayed cell value. */
  displayValue: string;

  private momentFormat: string;

  constructor(
    private logger: NGXLogger,
    private gridHelper: GridHelperService,
    private dateFormatService: DateFormatsService
  ) {}

  /** @inheritdoc */
  agInit(params: FieldCellRendererParameters): void {
    this.displayValue = this.getValue(params);
    if (params.format) {
      this.dateFormatService.get(params.format).subscribe((momentFormat) => {
        this.momentFormat = momentFormat;
        this.displayValue = this.getValue(params);
      });
    }
  }

  /** @inheritdoc */
  refresh(params: FieldCellRendererParameters): boolean {
    this.displayValue = this.getValue(params);
    return true;
  }

  private getValue(params: FieldCellRendererParameters): string {
    const parameterValue = params.valueFormatted
      ? params.valueFormatted
      : params.value;
    return this.gridHelper.getDateTimeCellValue(
      parameterValue,
      this.momentFormat
    );
  }
}
