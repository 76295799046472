import { Pipe, PipeTransform } from '@angular/core';

import { assert } from 'common';

const NO_MATCH = undefined;

/**
 * Filter an object by its name propety with a regex as string.
 */
@Pipe({
  name: 'filterName',
  standalone: false,
})
export class FilterNamePipe implements PipeTransform {
  /**
   * Filter an object by its name propety with a regex as string.
   *
   * @param value Value to test.
   * @param filterExpression Expression for test.
   * @returns Value when filter matches.
   */
  transform(value: any[], filterExpression: string): any | undefined {
    // Validate.
    if (!value) return NO_MATCH;
    if (!filterExpression) return value;
    // Filter matches.
    return value.filter((obj) => {
      assert(typeof obj === 'object', "The 'value' must be an object.");
      assert(Object.hasOwn(obj, 'name'), 'Object must have a name property.');
      assert(typeof filterExpression === 'string');
      const re = new RegExp(filterExpression, 'i');
      return re.test(obj.name);
    });
  }
}
