import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef as MatDialogReference } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, RowNode } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';

import { AppendType, Inbox, InboxProvider, UserFriendlyError } from 'models';
import { INBOX_PROVIDER } from 'src/app/common/tokens';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';

import { SELECTION_ORDER_COLUMN_ID } from '../grid-cell-components/cell-selectors';

const INBOX_SELECT_INDEX = 0;
const DOCUMENT_SELECT_INDEX = 1;

/** Insert from inbox dialog result. */
export interface InsertFromInboxDialogResult {
  /** Append type. */
  appendType: AppendType;
  /** Whether the selected files should be deleted after the merge. */
  deleteFiles: boolean;
  /** Files to be inserted. */
  files: string[];
  /** Inbox ID containing the files. */
  inboxId: number;
}

/** Append Inbox Document Dialog Component. */
@Component({
  selector: 'app-append-inbox-documents-dialog',
  templateUrl: './insert-from-inbox-dialog.component.html',
  styleUrls: ['./insert-from-inbox-dialog.component.scss'],
  standalone: false,
})
export class InsertFromInboxDialogComponent implements OnInit {
  /** AG Grid instance. */
  @ViewChild('inboxGrid') inboxGrid: AgGridAngular;
  /** Stepper instance. */
  @ViewChild('stepper') stepper: MatStepper;
  /** Current step index. */
  currentStepIndex: number;
  /** Grid configuration. */
  gridOptions: GridOptions;
  /** Inbox selection form. */
  inboxSelectForm: UntypedFormGroup;
  /** Observable array of inboxes. */
  inboxes$ = this.inboxesQuery.inboxes$;
  /** Insert options form. */
  insertOptionsForm: UntypedFormGroup;
  /** Show the remember delete inbox document option. */
  showRememberDeleteSelection = false;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  private selectedRownodes: RowNode[] = [];

  /**
   * Determines if append buttons should be disabled.
   *
   * @returns A boolean indicating if the append buttons should be disabled.
   */
  get appendButtonsDisabled(): boolean {
    return !this.documentsSelected;
  }

  /**
   * Determines if user can advance to the next step.
   *
   * @returns A boolean indicating if the user can advance to the next step.
   */
  get canGoToNext(): boolean {
    switch (this.currentStepIndex) {
      case INBOX_SELECT_INDEX:
        return this.inboxSelectForm.valid;
      case DOCUMENT_SELECT_INDEX:
        return this.documentsSelected;
      default:
        this.logger.error('Unhandled step');
        return true;
    }
  }

  /**
   * Documents are selected in the inbox grid.
   *
   * @returns A boolean indicating if documents are selected.
   */
  get documentsSelected(): boolean {
    return this.selectedRownodes.length > 0;
  }

  /**
   * Determines if the stepper is on the last step.
   *
   * @returns A boolean indicating if the stepper is on the last step.
   */
  get isLastStep(): boolean {
    return this.currentStepIndex === DOCUMENT_SELECT_INDEX;
  }

  /**
   * The selected inbox.
   *
   * @returns An inbox.
   */
  get selectedInbox(): Inbox {
    return this.inboxSelectForm.controls.selectedInbox.value as Inbox;
  }

  constructor(
    private logger: NGXLogger,
    private notifications: NotificationService,
    private appQuery: ApplicationQuery,
    private appService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private inboxesQuery: InboxesQuery,
    private dialog: MatDialogReference<InsertFromInboxDialogComponent>,
    private gridHelper: GridHelperService,
    private layout: LayoutService,
    @Inject(INBOX_PROVIDER) private inboxProvider: InboxProvider
  ) {}

  ngOnInit(): void {
    this.currentStepIndex = 0;
    this.inboxSelectForm = this.formBuilder.group({
      selectedInbox: new UntypedFormControl(undefined, Validators.required),
    });
    this.insertOptionsForm = this.formBuilder.group({
      deleteInboxDocumentOnInsert: new UntypedFormControl(
        this.appQuery.deleteInboxDocumentOnInsert
      ),
      rememberDeleteOnInsertSelection: new UntypedFormControl(false),
    });
    this.configureGrid();
  }

  /**
   * Event handler for the append button click events.
   *
   * @param prepend Determines if append should be to the beginning.
   */
  onClickAppend(prepend: boolean): void {
    this.logger.debug('Append clicked.', prepend);
    const deleteInboxDocumentOnInsert =
      this.insertOptionsForm.controls.deleteInboxDocumentOnInsert.value;
    if (this.insertOptionsForm.controls.rememberDeleteOnInsertSelection.value) {
      this.logger.debug(
        'Saving "deleteInboxDocumentOnInsert" option.',
        deleteInboxDocumentOnInsert
      );
      this.appService.applyUserUiSettings({ deleteInboxDocumentOnInsert });
    }

    const result: InsertFromInboxDialogResult = {
      files: this.selectedRownodes.map(
        (row) => `${row.data.filename}${row.data.fileType}`
      ), //row.data.filename as string
      inboxId: this.selectedInbox.id,
      appendType: prepend ? AppendType.toBeginning : AppendType.toEnd,
      deleteFiles: deleteInboxDocumentOnInsert,
    };

    this.dialog.close(result);
  }

  /**
   * Event handler for inbox selection changes.
   */
  onInboxSelectionChange(): void {
    this.logger.debug('Inbox selection changed.', this.selectedInbox);
    this.inboxGrid.api?.showLoadingOverlay();
    this.stepper.next();
    this.inboxProvider.get(this.selectedInbox.id).subscribe({
      next: (inbox) => {
        this.logger.debug('Loaded inbox with files', inbox);
        const rowData = this.gridHelper.getInboxRowData(inbox.files);
        this.inboxGrid.api.setGridOption('rowData', rowData);
      },
      error: (error: UserFriendlyError) => this.notifications.error(error),
    });
  }

  /**
   * Event handler for the step selection change event.
   *
   * @param event Stepper Selection Event.
   */
  onStepSelectionChange(event: StepperSelectionEvent): void {
    this.logger.debug('Append dialog step changed', event);
    this.currentStepIndex = event.selectedIndex;
  }

  private configureGrid(): void {
    this.gridOptions = this.gridHelper.createInboxGridOptions(
      0, // set this initial state which will be updated when onInboxSelectionChange fires.
      {
        onRowSelected: (params) => {
          this.gridHelper.onRowSelected(params, this.selectedRownodes);
          this.resetSelections();
        },
        onRowDataUpdated: undefined,
        suppressMovableColumns: true,
      },
      true,
      false
    );
  }

  private resetSelections(): void {
    for (let index = 0; index < this.selectedRownodes.length; ++index) {
      this.selectedRownodes[index].setDataValue(
        SELECTION_ORDER_COLUMN_ID,
        index + 1
      );
    }
  }
}
