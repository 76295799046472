import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { AppConfig } from 'models';

import { AppConfigStore } from './app-config.store';

/** The default password regex. */
const DEFAULT_PASSWORD_REGEX =
  '^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20})$';

/** Application Config Query. */
@Injectable({ providedIn: 'root' })
export class AppConfigQuery extends Query<AppConfig> {
  /** Observable administration URL. */
  adminUrl$ = this.select('adminUrl');
  /** Observable of whether to allow guests access to the viewer. */
  allowGuestSquare9Viewer$ = this.select('allowGuestSquare9Viewer');
  /** Observable base API URL. */
  apiUrl$ = this.select('apiUrl');
  /** Observable of authentication providers. */
  authenticationProviders$ = this.select('authenticationProviders');
  /** Observable of maximum concurrent uploads. */
  concurrentUploads$ = this.select('concurrentUploads');
  /** Observable of custom links. */
  customLinks$ = this.select('customLinks');
  /** Observable of the dashboards. */
  dashboards$ = this.select('dashboards');
  /** Observable of if Global Action tasks are disabled. */
  disableGlobalActionTasks$ = this.select('disableGlobalActionTasks');
  /** Observable of GSE download URL. */
  gseDownloadUrl$ = this.select('gseDownloadUrl');
  /** Observable of if branding should be hidden. */
  hideBranding$ = this.select('hideBranding');
  /** Observable of whether to hide internal user login inputs. */
  hideLoginInternalUsers$ = this.select('hideLoginInternalUsers');
  /** Observable of whether to hide the login sidebar. */
  hideLoginSidePanel$ = this.select('hideLoginSidePanel');
  /** Observable of language CDN URL. */
  languageCdnUrl$ = this.select('languageCdnUrl');
  /** Observable license manager URL. */
  licenseManagerUrl$ = this.select('licenseManagerUrl');
  /** Observable loading state. */
  loading$ = this.selectLoading();
  /** Observable login side panel URL. */
  loginSidePanelUrl$ = this.select('loginSidePanelUrl');
  /** Observable whether NTLM authentication is enabled. */
  ntlmAuthentication$ = this.select('ntlmAuthentication');
  /** Observable S9 help URL. */
  s9HelpUrl$ = this.select('s9HelpUrl');
  /** Observable of whether to sort archives, tasks, and inboxes alphabetically. */
  sortArchivesTasksAndInboxesAlphabetically$ = this.select(
    'sortArchivesTasksAndInboxesAlphabetically'
  );
  /** Observable terms of service URL. */
  tosUrl$ = this.select('tosUrl');
  /** Observable user management URL. */
  userManagementUrl$ = this.select('userManagementUrl');

  constructor(protected store: AppConfigStore) {
    super(store);
  }

  /**
   * Get the admin URL.
   *
   * @returns A string representing the admin URL.
   */
  get adminUrl(): string {
    return this.appConfig.adminUrl;
  }

  /**
   * Get the API URL.
   *
   * @returns A string representing the API URL.
   */
  get apiUrl(): string {
    return this.appConfig.apiUrl;
  }

  /**
   * Get the app config.
   *
   * Getting config properties using their query observables should be preferred over this in most cases.
   *
   * @returns An app config.
   */
  get appConfig(): AppConfig {
    return this.getValue();
  }

  /**
   * Default number of days to add to the current time to create a link's default expiration date/time.
   *
   * @returns The number of days to add to the current date to create the expiration date.
   */
  get defaultShareDocumentExpirationDays(): number {
    return this.appConfig.defaultShareDocumentExpirationDays;
  }

  /**
   * Gets whether Global Action tasks are disabled.
   *
   * @returns True if Global Action tasks are disabled.
   */
  get disableGlobalActionTasks(): boolean {
    return this.appConfig.disableGlobalActionTasks;
  }

  /**
   * Get the GlobalCapture URL.
   *
   * @returns A string representing the GSE download URL.
   */
  get globalCaptureUrl(): string {
    return this.appConfig.globalCaptureUrl;
  }

  /**
   * Get the GSE download URL.
   *
   * @returns A string representing the GSE download URL.
   */
  get gseDownloadUrl(): string {
    return this.appConfig.gseDownloadUrl;
  }

  /**
   * Get whether the instance URL is a cloud API instance.
   *
   * @returns True if the instance URL is a cloud API instance.
   */
  get isCloudInstanceApi(): boolean {
    return this.apiUrl.toLowerCase().includes('.mysquare9.com');
  }

  /**
   * Get the language CDN URL.
   *
   * @returns A string representing the language CDN URL.
   */
  get languageCdnUrl(): string {
    return this.appConfig.languageCdnUrl;
  }

  /**
   * Get the license manager URL.
   *
   * @returns A string representing the license manager URL.
   */
  get licenseManagerUrl(): string {
    return this.appConfig.licenseManagerUrl;
  }

  /**
   * Determines if there is a custom password regex.
   *
   * @returns True if there is a password regex that is not the default.
   */
  get customPasswordRegexProvided(): boolean {
    return (
      !!this.appConfig.passwordRegex &&
      this.appConfig.passwordRegex !== DEFAULT_PASSWORD_REGEX
    );
  }

  /**
   * Get the S9 help URL.
   *
   * @returns A string representing the S9 help URL.
   */
  get s9HelpUrl(): string {
    return this.appConfig.s9HelpUrl;
  }

  /**
   * Gets Whether archives, tasks, and inboxes should be sorted alphabetically.
   *
   * @returns True if archives and inboxes should be sorted alphabetically.
   */
  get sortArchivesTasksAndInboxesAlphabetically(): boolean {
    return this.appConfig.sortArchivesTasksAndInboxesAlphabetically;
  }

  /**
   * Get the user management URL.
   *
   * @returns A string representing the user management URL.
   */
  get userManagementUrl(): string {
    return this.appConfig.userManagementUrl;
  }

  /**
   * Get the PDF.js default use setting.
   *
   * @returns True if PDF.js wrapper lib should be used by default.
   */
  get usePdfJs(): boolean {
    return this.appConfig.usePdfJs;
  }
}
