import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

import { assertExists } from 'common';
import { AdvancedLink, Fields, SearchResults } from 'models';
import {
  ArchiveGridData,
  SearchResultDocumentOpenRequest,
} from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { FavoriteSearchesService } from 'src/app/state/application/favorite-searches.service';
import { ShareDocumentService } from 'src/app/state/application/share-document.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

import { TranslocoService } from '@jsverse/transloco';
import { map, of, switchMap, take, tap } from 'rxjs';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { NewPdfDocumentService } from 'src/app/services/new-documents.service';
import { SupportedFeaturesService } from 'src/app/services/supported-features.service';
import { ArchiveActionsMenuComponent } from '../archive-actions-menu/archive-actions-menu.component';
import { SearchResultArchiveSelectorComponent } from '../search-result-archive-selector/search-result-archive-selector.component';

/** Archive Actions Toolbar. */
@UntilDestroy()
/** Archive Actions Toolbar. */
@Component({
  selector: 'app-archive-actions-toolbar',
  templateUrl: './archive-actions-toolbar.component.html',
  styleUrls: ['./archive-actions-toolbar.component.scss'],
  standalone: false,
})
export class ArchiveActionsToolbarComponent implements OnInit {
  /** Archive Actions Menu reference. */
  @Input()
  actionMenu: ArchiveActionsMenuComponent;
  /** Reference to the archive results selector component. */
  @ViewChild(SearchResultArchiveSelectorComponent)
  archiveSelector: SearchResultArchiveSelectorComponent;
  /** Event raised when selected rows should be downloaded as a CSV. */
  @Output()
  downloadSelectedRowsAsCsv = new EventEmitter<boolean>();
  /** Determines if edit mode is enabled. */
  @Input()
  editModeEnabled: boolean;
  /** Event raised when edit mode changes. */
  @Output()
  editModeEnabledChange = new EventEmitter<boolean>();
  /** Event triggered when the left sidebar toggle button is used. */
  @Output()
  leftSidebarHidden: EventEmitter<any> = new EventEmitter();
  /** Event raised when selected rows should be opened. */
  @Output()
  openSelectedDocuments = new EventEmitter<SearchResultDocumentOpenRequest>();
  /** Event triggered when the search should be refined. */
  @Output()
  refineSearch: EventEmitter<any> = new EventEmitter();
  /** Event triggered when the search should be refreshed. */
  @Output()
  refreshSearch: EventEmitter<any> = new EventEmitter();
  /** Current search results. */
  @Input()
  searchResults: SearchResults;

  /** Observable value of the active archive object. */
  archive$ = this.archivesQuery.selectActive();
  /** If the user is guest. */
  isGuest = this.auth.isGuest;
  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layout.isHandset$;
  /* Observable of new PDF document count. */
  newPdfDocumentCount$ = this.archivesQuery.archiveRouteParams$.pipe(
    switchMap(([databaseId, archiveId]) => {
      if (!databaseId) return of(0);
      return this.newPdfDocumentService.observeCountInDatabase$(databaseId);
    })
  );
  /** Observable of whether new PDF documents exist. */
  newPdfDocumentsExist$ = this.newPdfDocumentCount$.pipe(
    tap((count) => {
      this.logger.debug(count);
    }),
    map((count) => count > 0)
  );
  /** Observable of right sidebar visibility. */
  rightSidebarOpen$ = this.applicationQuery.rightSidebarOpen$;
  /** Observable value of the active search object. */
  search$ = this.searchesQuery.selectActive();
  /** Whether share links are supported. */
  shareLinksSupported = this.supportedFeatures.versionSupports(
    this.supportedFeatures.shareLinks
  );
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  /** Active grid data. */
  private activeGridData: ArchiveGridData;
  private archiveId: number;
  private databaseId: number;

  constructor(
    private auth: AuthenticationService,
    private applicationQuery: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private logger: NGXLogger,
    private translate: TranslocoService,
    private gridStatesService: GridSettingsService,
    private layout: LayoutService,
    private supportedFeatures: SupportedFeaturesService,
    private favoriteService: FavoriteSearchesService,
    private newPdfDocumentService: NewPdfDocumentService,
    private shareService: ShareDocumentService
  ) {}

  /**
   * Array of archive fields.
   *
   * @returns An array of fields.
   */
  get archiveFields(): Fields {
    return this.archivesQuery.getFields();
  }

  /** Whether the edit feature should be disabled and disallowed. */
  get isEditDisabled() {
    const archive = this.archivesQuery.getActive();
    // User does not have permission to modify data or the archive contains a liveField.
    return (
      !archive ||
      !archive.permissions.modifyData ||
      this.archivesQuery
        .getFields(archive.id)
        .some((field) => !!field.liveField)
    );
  }

  /**
   * No SearchResult is selected.
   *
   * @returns True if none are selected. */
  get isNoneSelected(): boolean {
    return this.selectedSearchResults.length === 0;
  }

  /**
   * Exactly one SearchResult is selected.
   *
   * @returns True iff one is selected. */
  get isSingleSelection(): boolean {
    return this.selectedSearchResults.length === 1;
  }

  /**
   * Number of selected SearchResults.
   *
   * @returns Count.
   */
  get selectedCount(): number {
    return this.selectedSearchResults.length;
  }

  /**
   * Selected SearchResults.
   *
   * @returns An array of selected SearchResults.
   */
  get selectedSearchResults(): SearchResults {
    return (this.activeGridData?.selectedRowNodes ?? []).map((row) => row.data);
  }

  /**
   * Gets the translation key displayed if share links are not supported.
   *
   * @returns Translation string.
   */
  get shareLinksUnsupportedMessage(): string {
    const translationKey = this.appConfigQuery.isCloudInstanceApi
      ? 'FEATURE_REQUIRES_NEW_CLOUD_VERSION'
      : 'FEATURE_REQUIRES_NEW_VERSION';

    return this.translate.translate(translationKey);
  }

  /** Click event for the favorite button. */
  clickFavorite(): void {
    this.logger.debug('Favorite button clicked.');
    const activeSearch = this.searchesQuery.getActive();
    if (typeof activeSearch === 'undefined') {
      this.logger.warn(
        'Favorite button was clicked, but not search is active.'
      );
      return;
    }
    if (
      this.favoriteService.getIsFavoriteSearch(
        `${this.databaseId}`,
        `${activeSearch.id}`
      )
    ) {
      this.favoriteService.removeFavoriteSearch(
        this.databaseId,
        activeSearch.id
      );
    } else {
      this.favoriteService.promptToAddFavoriteSearch(
        activeSearch,
        this.databaseId,
        this.archivesQuery.activeId
      );
    }
  }

  /**
   * Handler for the new document dialog click event.
   */
  clickOpenNewDocumentsDialog(): void {
    this.newPdfDocumentService.openNewDocumentDialog();
  }

  /** Click event for the refine search button. */
  clickRefineSearch(): void {
    this.logger.debug('Refine search button clicked.');
    this.refineSearch.emit();
  }

  /** Click event for the share button. */
  clickShare(): void {
    this.logger.debug('Share button clicked.');
    this.search$.pipe(take(1)).subscribe((search) => {
      assertExists(search, 'Search must exist.');
      this.shareService.promptToShareDocument(
        this.databaseId,
        this.archiveId,
        this.selectedSearchResults[0].id,
        search?.id
      );
    });
  }

  /** Click event for the edit button. */
  clickToggleEdit(): void {
    this.logger.debug('Toggle edit button clicked.');
    this.editModeEnabled = !this.editModeEnabled;
    this.editModeEnabledChange.emit(this.editModeEnabled);
  }

  ngOnInit(): void {
    this.listenForRouteParams();
  }

  /**
   * Click event for the download CSV button.
   *
   * @param advancedConfig Determines the user should be shown advanced configuration options.
   */
  onDownloadCsv(advancedConfig: boolean): void {
    this.logger.debug('Download CSV clicked.', advancedConfig);
    this.downloadSelectedRowsAsCsv.emit(advancedConfig);
  }

  /**
   * Click event for the open documents button.
   *
   * @param openRequest Open request.
   */
  onOpenDocuments(openRequest?: SearchResultDocumentOpenRequest): void {
    this.logger.debug('Open documents button clicked.');
    this.openSelectedDocuments.emit(
      openRequest ??
        ({
          searchResults: this.selectedSearchResults,
          forceExternalViewer: false,
        } as SearchResultDocumentOpenRequest)
    );
  }

  /** Handler for the refresh search event. */
  onRefreshSearch(): void {
    this.logger.debug('Refresh search button clicked.');
    this.archiveSelector.reloadSearchCounts();
    this.refreshSearch.emit();
  }

  /** Reloads the search counts in the archive selector. */
  reloadArchiveSearchSelectorCounts(): void {
    this.archiveSelector.reloadSearchCounts();
  }

  /**
   * Determines if the specified Advanced Link be displayed in this archive context.
   *
   * @param advancedLink AdvancedLink.
   * @returns Boolean.
   */
  shouldShowAdvancedLink(advancedLink: AdvancedLink): boolean {
    return (
      (advancedLink.archiveId === this.archiveId ||
        advancedLink.archiveId === 0) &&
      advancedLink.fieldId === 0
    );
  }

  private listenForRouteParams(): void {
    this.searchesQuery.searchRouteParams$
      .pipe(untilDestroyed(this))
      .subscribe(([databaseId, archiveId]) => {
        assertExists(databaseId);
        assertExists(archiveId);
        this.databaseId = databaseId;
        this.archiveId = archiveId;
        this.activeGridData = this.gridStatesService.getOrCreateArchiveGridData(
          databaseId,
          archiveId
        );
      });
  }
}
