import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { coerce } from 'semver';

import { assertExists } from 'common';
import { User } from 'models';
import { ThemeMode, UserUiSettings } from 'src/app/models';

import { ArchivesQuery } from '../archives/archives.query';
import { DatabasesQuery } from '../databases/databases.query';
import { SearchesQuery } from '../searches/searches.query';

import {
  ApplicationState,
  ApplicationStore,
  createDefaultUserUiSettings,
} from './application.store';

/**
 * Application service.
 */
@Injectable({ providedIn: 'root' })
export class ApplicationService {
  constructor(
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private applicationStore: ApplicationStore,
    private logger: NGXLogger
  ) {}

  /**
   * Apply User UI settings.
   *
   * @param settings New desired settings.
   */
  applyUserUiSettings(settings: Partial<UserUiSettings>) {
    this.applicationStore.update(settings);
  }

  /**
   * Clear the open document.
   */
  clearOpenDocument(): void {
    this.applicationStore.update({ openDocument: undefined });
  }

  /**
   * Clear PDF Preview URL.
   */
  clearPdfPreviewUrl() {
    this.applicationStore.update({ pdfPreviewUrl: '' });
  }

  /**
   * Clear the current user.
   */
  clearUser() {
    this.applicationStore.update({ user: undefined });
  }

  /**
   * Reset user settings to default.
   */
  resetUserSettingsToDefault(): void {
    const defaultSettings = createDefaultUserUiSettings();
    this.applicationStore.update(defaultSettings);
  }

  /**
   * Sets the current API version.
   *
   * @param apiVersion API version.
   */
  setApiVersion(apiVersion: string) {
    const semanticVersion = coerce(apiVersion);
    assertExists(
      semanticVersion,
      'API version could not be coerced to semver.'
    );
    this.applicationStore.update({ apiVersion: semanticVersion.toString() });
  }

  /**
   * Set if Dark Mode is enabled.
   *
   * @param on New desired state.
   */
  setDarkMode(on: boolean) {
    this.logger.debug(`Setting dark mode to: ${on}`);
    const themeMode = on ? ThemeMode.dark : ThemeMode.light;
    this.applicationStore.update({ themeMode });
  }

  /**
   * Set the GSE API Url.
   *
   * @param url New GSE Api Url.
   */
  setGSEUrl(url: string): void {
    this.logger.debug(`Setting GSE URL to: ${url}`);
    this.applicationStore.update({ gseApiConfig: { apiUrl: url } });
  }

  /**
   * Sets the indexerSidebarOpen state.
   *
   * @param open Whether the sidebar should be open.
   */
  setIndexerSidebarOpen(open: boolean): void {
    this.applicationStore.update({ indexerSidebarOpen: open });
  }

  /**
   * Set the instance URL.
   *
   * @param instanceUrl New instance URL.
   */
  setInstanceUrl(instanceUrl: string) {
    this.applicationStore.update({ instanceUrl });
  }

  /**
   * Set if system notifcations are enabled.
   *
   * @param on New desired state.
   */
  setNotifications(on: boolean) {
    this.applicationStore.update({ notifications: on });
  }

  /**
   * Sets the open document to an archive document.
   *
   * @param documentId Document Id.
   * @param documentSecureId Document Secure Id.
   * @param databaseId Database Id.
   * @param archiveId Archive Id.
   * @param searchId Search Id.
   * @param searchSecureId OptionSearch Secure Id.
   */
  setOpenDocumentToArchiveDocument(
    documentId: number,
    documentSecureId: string,
    databaseId: number = this.databasesQuery.activeId,
    archiveId: number = this.archivesQuery.activeId,
    searchId: number = this.searchesQuery.activeId,
    searchSecureId: string = ''
  ): void {
    const changes: Partial<ApplicationState> = {
      openDocument: {
        type: 'archive',
        source: {
          databaseId,
          archiveId,
          searchId,
          searchSecureId,
          documentId,
          documentSecureId,
        },
      },
    };
    this.applicationStore.update(changes);
  }

  /**
   * Sets the open document to an inbox document.
   *
   * @param id Inbox Id.
   * @param filename Inbox filename.
   */
  setOpenDocumentToInboxDocument(id: number, filename: string): void {
    const changes: Partial<ApplicationState> = {
      openDocument: {
        type: 'inbox',
        source: {
          id,
          filename,
        },
      },
    };
    this.applicationStore.update(changes);
  }

  /**
   * Sets the open document to a task search document.
   *
   * @param documentId Document Id.
   * @param documentSecureId Document Secure Id.
   * @param databaseId Database Id. Defaults to the active database.
   * @param archiveId Archive Id. Defaults to the active archive.
   */
  setOpenDocumentToTaskSearchDocument(
    documentId: number,
    documentSecureId: string,
    databaseId: number = this.databasesQuery.activeId,
    archiveId: number = this.archivesQuery.activeId
  ): void {
    const changes: Partial<ApplicationState> = {
      openDocument: {
        type: 'taskSearch',
        source: {
          archiveId,
          databaseId,
          documentId,
          documentSecureId,
        },
      },
    };
    this.applicationStore.update(changes);
  }

  /**
   * Set the current PDF preview URL.
   *
   * @param url PDF document URL to display.
   */
  setPdfPreviewUrl(url: string) {
    const changes: Partial<ApplicationState> = { pdfPreviewUrl: url };
    if (url !== '') changes.rightSidebarOpen = true;
    this.applicationStore.update(changes);
  }

  /**
   * Set the refresh results when closing document tabs setting.
   *
   * @param state New state.
   */
  setRefreshResultsWhenClosingDocumentTabs(state: boolean) {
    this.applicationStore.update({
      refreshResultsWhenClosingDocumentTabs: state,
    });
  }

  /**
   * Set the current visibility of the right sidebar.
   *
   * @param state New state.
   */
  setRightSidebarOpen(state: boolean) {
    this.applicationStore.update({ rightSidebarOpen: state });
  }

  /**
   * Sets the database ID for which the search store is currently loaded.
   * @param databaseId Database ID.
   */
  setSearchStoreDatabaseId(databaseId: string) {
    this.applicationStore.update({ searchStoreDatabaseId: databaseId });
  }

  /**
   * Sets the current visibility of the versions archive.
   *
   * @param state New state.
   */
  setShowVersionsArchive(state: boolean) {
    this.applicationStore.update({ showVersionsArchive: state });
  }

  /**
   * Set the theme mode.
   *
   * @param themeMode New theme mode.
   */
  setThemeMode(themeMode: ThemeMode): void {
    this.applicationStore.update({ themeMode: themeMode });
  }

  /**
   * Sets the usePreviousSearchCriteriaInRelatedSearch setting.
   *
   * @param state Whether or not the setting should be enabled.
   */
  setUsePreviousSearchCriteriaInRelatedSearch(state: boolean) {
    this.applicationStore.update({
      usePreviousSearchCriteriaInRelatedSearch: state,
    });
  }

  /**
   * Set current user.
   *
   * @param user New user value.
   */
  setUser(user: User) {
    this.applicationStore.update({ user });
  }

  /**
   * Set the auto save setting.
   *
   * @param on Whether or not auto save should be enabled.
   */
  setViewerAutoSave(on: boolean) {
    this.applicationStore.update({ viewerAutoSave: on });
  }

  /**
   * Invert the current stored value of archiveNavSectionExpanded.
   */
  toggleArchiveNavSectionExpanded() {
    this.logger.debug('Toggling archive navigation section expanded.');
    this.applicationStore.update((state) => ({
      archiveNavSectionExpanded: !state.archiveNavSectionExpanded,
    }));
  }

  /**
   * Invert the current stored value of favoriteSearchesExpanded.
   */
  toggleFavoriteSearchNavSectionExpanded(): void {
    this.logger.debug('Toggling favorite search navigation section expanded.');
    this.applicationStore.update((state) => ({
      favoriteSearchesExpanded: !state.favoriteSearchesExpanded,
    }));
  }

  /**
   * INvert the current stored value of indexerSidebarOpen.
   */
  toggleIndexerSidebarOpen(): void {
    this.applicationStore.update((state) => ({
      indexerSidebarOpen: !state.indexerSidebarOpen,
    }));
  }

  /**
   * Invert the current stored value of leftSidebarNavHidden.
   */
  toggleLeftSidebarNav() {
    this.logger.debug('Toggling left sidebar navigation expanded.');
    this.applicationStore.update((state) => ({
      leftSidebarNavHidden: !state.leftSidebarNavHidden,
    }));
  }

  /**
   * Invert the current stored value of rightSidebarOpen.
   */
  toggleRightSidebarOpen() {
    this.logger.debug('Toggling right sidebar open state.');
    this.applicationStore.update((state) => ({
      rightSidebarOpen: !state.rightSidebarOpen,
    }));
  }

  /**
   * Invert the current stored value of taskSearchSectionExpanded.
   */
  toggleTaskSearchSectionExpanded() {
    this.logger.debug('Toggling task search section expanded.');
    this.applicationStore.update((state) => ({
      taskSearchSectionExpanded: !state.taskSearchSectionExpanded,
    }));
  }

  /**
   * Invert the current stored value of archiveNavSectionExpanded.
   */
  toggleinboxNavSectionExpanded() {
    this.logger.debug('Toggling inbox navigation section expanded.');
    this.applicationStore.update((state) => ({
      inboxNavSectionExpanded: !state.inboxNavSectionExpanded,
    }));
  }
}
