import { Component } from '@angular/core';

import { DATABASE_NAME_SPACING_PATTERN } from '../../common/constants';
import { DatabasesQuery } from '../../state/databases/databases.query';

@Component({
  selector: 'app-database-menu',
  templateUrl: './database-menu.component.html',
  styleUrls: ['./database-menu.component.scss'],
  standalone: false,
})
export class DatabaseMenuComponent {
  /** Pattern to use for matching desired spaces in database names. */
  databaseNameReplacement = DATABASE_NAME_SPACING_PATTERN;
  /** Observable of all available databases. */
  databases$ = this.databasesQuery.databases$;
  /** Observable of the current state of if there is currently a pending load of database data. */
  isDatabasesLoading$ = this.databasesQuery.isLoading$;

  constructor(private databasesQuery: DatabasesQuery) {}
}
