import { Component, ViewChild, computed, input, output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription, fromEvent } from 'rxjs';
import { PdfThumbnail } from '../../models';

export type ThumbnailPastePosition = 'above_selection' | 'below_selection';

export interface ThumbnailPasteEvent {
  position: ThumbnailPastePosition;
}

@Component({
  selector: 'app-pdf-thumbnailer-context-menu',
  templateUrl: './pdf-thumbnailer-context-menu.component.html',
  styleUrl: './pdf-thumbnailer-context-menu.component.scss',
  standalone: false,
})
export class PdfThumbnailerContextMenuComponent {
  /** Mat Menu Trigger Reference. */
  @ViewChild(MatMenuTrigger, { static: true })
  matMenuTrigger: MatMenuTrigger;

  /** Event emitted when copy is clicked. */
  copy = output();
  /** Event emitted when cut is clicked. */
  cut = output();
  /** Menu Position styling. */
  menuRootStyle: {
    /** Left position in px. */
    'left.px': number;
    /** Top position in px. */
    'top.px': number;
  };
  /** Event emitted when paste is clicked. */
  paste = output<ThumbnailPasteEvent>();
  /** Whether paste is enabled. */
  pasteEnabled = computed(() => this.selectedThumbnails().length === 1);
  /** Selected thumbnails. */
  selectedThumbnails = input.required<PdfThumbnail[]>();

  private listenerSubscription: Subscription;
  private menuOpen = false;

  /** Handler for the copy click event. */
  onClickCopy(): void {
    this.copy.emit();
  }

  /** Handler for the cut click event. */
  onClickCut(): void {
    this.cut.emit();
  }

  /** Handler for the copy click event. */
  onClickPaste(position: ThumbnailPastePosition): void {
    this.paste.emit({ position });
  }

  /** Handler for the menu close event. */
  onMenuClosed(): void {
    this.listenerSubscription.unsubscribe();
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   */
  open(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    // Record the mouse position in our object.
    this.menuRootStyle = {
      'left.px': mouseEvent.clientX,
      'top.px': mouseEvent.clientY,
    };

    // Open the menu
    this.menuOpen = true;
    this.matMenuTrigger.openMenu();
    this.listenerSubscription = fromEvent(window, 'contextmenu').subscribe(
      (event) => {
        if (this.menuOpen) {
          event.preventDefault();
          this.matMenuTrigger.closeMenu();
          this.menuOpen = false;
          event.target?.dispatchEvent(new MouseEvent('click', event));
        }
      }
    );
  }
}
