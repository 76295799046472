import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ColumnState } from 'ag-grid-community';

export interface TableFieldGridState {
  columnStates: ColumnState[];
}

export type TableFieldGridColumnState = EntityState<
  TableFieldGridState,
  string
>;

/**
 * Table field grid store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'table-field-grid' })
export class TableFieldGridStateStore extends EntityStore<TableFieldGridColumnState> {
  constructor() {
    super([]);
  }
}
