<div class="thumbnail-container" *ngIf="thumbnails().length > 0">
  <mat-toolbar>
    <mat-toolbar-row>
      <span transloco="MODIFY_DOCUMENT"></span>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <button
        [disabled]="selectionActionsDisabled()"
        mat-icon-button
        [matTooltip]="'BURST_SELECTED_PAGES' | transloco"
        [matMenuTriggerFor]="burstMenu"
        [matMenuTriggerData]="{ deleteOnBurst: true }"
      >
        <mat-icon>burst_mode</mat-icon>
      </button>
      <button
        [disabled]="selectionActionsDisabled()"
        mat-icon-button
        [matTooltip]="'CLONE_SELECTED_PAGES' | transloco"
        [matMenuTriggerFor]="burstMenu"
        [matMenuTriggerData]="{ deleteOnBurst: false }"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'MORE_DOCUMENT_ACTIONS' | transloco"
        [mat-menu-trigger-for]="moreActionsMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #burstMenu="matMenu">
        <ng-template matMenuContent let-deleteOnBurst="deleteOnBurst">
          <button mat-menu-item (click)="onClickBurst(deleteOnBurst)">
            {{ 'CREATE_NEW_DOCUMENT' | transloco }}
          </button>
        </ng-template>
      </mat-menu>

      <mat-menu #moreActionsMenu="matMenu">
        <button
          [disabled]="selectionActionsDisabled()"
          mat-menu-item
          (click)="onClickDeletePages()"
        >
          {{ 'DELETE_PAGES' | transloco }}
        </button>
        <button
          [disabled]="
            !hasModifyPermissions() ||
            modifyInProgress() ||
            containsAnnotations()
          "
          mat-menu-item
          (click)="onClickAddPages()"
        >
          {{ 'ADD_PAGES' | transloco }}
        </button>
        <button
          [disabled]="
            !hasModifyPermissions() ||
            modifyInProgress() ||
            !this.scanIsAvailable() ||
            containsAnnotations()
          "
          mat-menu-item
          (click)="onClickScanPages()"
        >
          {{ 'ADD_PAGES_FROM_SCAN' | transloco }}
        </button>
        <button
          [disabled]="selectionActionsDisabled()"
          mat-menu-item
          [matMenuTriggerFor]="rotationMenu"
        >
          {{ 'ROTATE' | transloco }}
        </button>
        <button
          [disabled]="!hasModifyPermissions() || modifyInProgress()"
          mat-menu-item
          (click)="onClickSelectAllThumbnails()"
        >
          {{ 'SELECT_ALL_THUMBNAILS' | transloco }}
        </button>
        <button
          [disabled]="!hasModifyPermissions() || modifyInProgress()"
          mat-menu-item
          (click)="onClickDeselectAllThumbnails()"
        >
          {{ 'DESELECT_ALL_THUMBNAILS' | transloco }}
        </button>
      </mat-menu>
      <mat-menu #rotationMenu="matMenu">
        <button mat-menu-item (click)="onClickRotatePages(-90)">
          {{ 'ROTATE_LEFT' | transloco }}
        </button>
        <button mat-menu-item (click)="onClickRotatePages(90)">
          {{ 'ROTATE_RIGHT' | transloco }}
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
  @if (modifyInProgress()) {
    <mat-progress-bar
      class="modify-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  }

  <input
    #fileInput
    type="file"
    accept=".pdf"
    (cancel)="onFileInputCancel()"
    id="fileInput"
    multiple
    hidden
  />
  <!--
  Virtual scroll can't be used with drag and drop so we've removed it. If it needs to be re-added add the following to the virtual scroll viewport:
  cdkDropList
  (cdkDropListSorted)="onThumbnailDragSort($event)"
  [cdkDropListAutoScrollDisabled]="true"
   -->
  <div
    class="thumbnail-virtual-scroll-container"
    (contextmenu)="onContextMenu($event)"
  >
    <cdk-virtual-scroll-viewport
      #thumbnailVirtalScrollViewPort
      class="thumbnail-virtual-scroll-viewport"
      itemSize="240"
      minBufferPx="264"
      maxBufferPx="528"
    >
      <!--
    Virtual scroll can't be used with drang and drop so we've removed it. If it needs to be re-added add the following to the below div.thumbnail:
    cdkDrag
    (cdkDragStarted)="thumbnailBeingDragged.set(thumbnail)"
    (cdkDragEnded)="thumbnailBeingDragged.set(undefined)"
    (cdkDragDropped)="onDropThumbnail($event)"
    [cdkDragData]="thumbnail"
    [cdkDragDisabled]="dragDisabled()"
    -->
      <div
        class="thumbnail"
        *cdkVirtualFor="let thumbnail of sortedThumbnails(); trackBy: trackByFn"
        [id]="'thumbnail-page-' + thumbnail.pageNumber"
        [class.selected-thumbnail-container]="
          thumbnail.isSelected || thumbnail.isOnClipboard
        "
        (click)="onClickThumbnail(thumbnail, $event)"
      >
        @if (thumbnail.isLoading$ | async) {
          <mat-progress-spinner
            class="thumbnail-progress-spinner"
            mode="indeterminate"
            diameter="50"
          ></mat-progress-spinner>
        } @else {
          @if (thumbnail.burstedDocumentUrl) {
            <div class="thumbnail-top-container">
              <button
                class="burst-button"
                mat-mini-fab
                mat-small-icon-button
                (click)="onClickBurstUrl($event, thumbnail.burstedDocumentUrl)"
              >
                <mat-icon>open_in_new</mat-icon>
              </button>
            </div>
          }
          <div class="thumbnail-bottom-container">
            <div class="thumbnail-bottom-centered">
              <div class="page-number custom-badge">
                {{ thumbnail.pageNumber }}
              </div>
            </div>
          </div>
          <div
            class="thumbnail-image-container"
            [class.selected-thumbnail-image-container]="thumbnail.isSelected"
            [class.clipboard-thumbnail-image-container]="
              thumbnail.isOnClipboard && !thumbnail.isSelected
            "
            [class.selected-thumbnail-on-clipboard-image-container]="
              thumbnail.isOnClipboard && thumbnail.isSelected
            "
          >
            <img class="mat-elevation-z2" [src]="thumbnail.url$ | async" />
          </div>
        }

        <!-- Drag and drop specific containers. These have been commented out because virtual scroll currently can't work with drag and drop -->
        <!-- <div *cdkDragPreview class="thumbnail-drag-preview">
          <div class="thumbnail-bottom-container">
            <div class="thumbnail-bottom-centered">
              <div class="page-number custom-badge">
                {{ thumbnail.pageNumber }}
              </div>
            </div>
          </div>
          <img [src]="thumbnail.url$ | async" />
        </div>

        <div *cdkDragPlaceholder class="thumbnail-drag-placeholder"></div> -->
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <!-- Context menu for the thumbnail window. -->
  <app-pdf-thumbnailer-context-menu
    #contextMenu
    [selectedThumbnails]="selectedThumbnails()"
    (copy)="onCopyPages()"
    (cut)="onCutPages()"
    (paste)="onPastePages($event)"
  ></app-pdf-thumbnailer-context-menu>
</div>
