import { Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { DisplayHistoryEntry } from 'src/app/models';

/** History Filter. */
@Pipe({
  name: 'HistoryFilter',
  pure: false,
  standalone: false,
})
export class HistoryFilterPipe implements PipeTransform {
  constructor(private logger: NGXLogger) {}
  /** @inheritdoc */
  transform(
    items: DisplayHistoryEntry[],
    filters: string[],
    searchFilter: string
  ): DisplayHistoryEntry[] {
    if (!items) {
      return items;
    }
    return items.filter(
      (item) =>
        filters.includes(item.displayFilterName) &&
        (!searchFilter || item.action.toLowerCase().includes(searchFilter))
    );
  }
}
