@if (loading() && !error) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (error) {
  <mat-card class="error-card">
    <mat-card-title>{{
      'SEARCH_BY_NAME_REDIRECT_ERROR' | transloco
    }}</mat-card-title>
    <mat-card-content>{{ error | transloco }}</mat-card-content>
  </mat-card>
}
