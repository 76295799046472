import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef as MatDialogReference } from '@angular/material/dialog';
import { MatTab } from '@angular/material/tabs';
import { NGXLogger } from 'ngx-logger';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { assertExists } from 'common';
import { UserFriendlyError } from 'models';
import { tap } from 'rxjs';
import { ThemeMode, UserUiSettings } from 'src/app/models';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LanguagesService } from 'src/app/services/languages.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SupportedFeaturesService } from 'src/app/services/supported-features.service';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ApplicationStore } from 'src/app/state/application/application.store';
import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';

/** Describes a password form. */
interface UserPasswordForm {
  /** Current Password. */
  currentPassword: FormControl<string>;
  /** New Password. */
  newPassword: FormControl<string>;
}

/** User Settings Dialog. */
@UntilDestroy()
@Component({
  selector: 'app-user-settings-dialog',
  templateUrl: './user-settings-dialog.component.html',
  styleUrls: ['./user-settings-dialog.component.scss'],
  standalone: false,
})
export class UserSettingsDialogComponent implements OnInit, AfterViewInit {
  /** Password strength component reference. */
  @ViewChild('passwordComponent')
  passwordStrength: MatPasswordStrengthComponent;
  /** Password strength info component reference. */
  @ViewChild('passwordStrengthInfo')
  passwordStrengthInfo: MatPasswordStrengthInfoComponent;
  /** User tab. */
  @ViewChild('userTab')
  userTab: MatTab;
  /** Observable of whether to allow quests access to the viewer. */
  allowGuestSquare9Viewer$ = this.appConfigQuery.allowGuestSquare9Viewer$;
  /** Custom password regex validation message. */
  customPasswordRegexMessage =
    this.appConfigQuery.appConfig.passwordRegexValidationMessage;
  /** Determines if there is a custom password regex. */
  customPasswordRegexProvided = this.appConfigQuery.customPasswordRegexProvided;
  /** Determines if we should show the internal import settings. */
  internalImportsSupported = this.supportedFeatures.versionSupports(
    this.supportedFeatures.internalViewerImports
  );
  /** If the user is guest. */
  isGuest = this.auth.isGuest;
  /** Available languages. */
  languages$ = this.languagesService.getAvailableLanguages();
  /** Observable of the available task searches. */
  taskSearches$ = this.taskSearchesQuery.taskSearches$;
  /** Observable of whether task searches are disabled. */
  taskSearchesDisabled$ = this.appConfigQuery.disableGlobalActionTasks$;
  /** Available theme modes. */
  themeModes = ThemeMode;
  /** User password form. */
  userPasswordFormGroup: FormGroup<UserPasswordForm>;
  /** User settings form group. */
  userSettingsGroup: UntypedFormGroup;

  /**
   * Whether to disable refresh results when closing document tabs setting.
   *
   * @returns True if setting should be disabled or if the alwaysOpenNewTab setting is somehow missing from the form.
   */
  get disableRefreshResultsWhenClosingDocumentTabs(): boolean {
    return !this.userSettingsGroup?.get('alwaysOpenNewTab')?.value;
  }

  /**
   * Checks if the user profile update button should be disabled.
   *
   * @returns A boolean indicating if the button should be disabled.
   */
  get disableUserProfileUpdate(): boolean {
    return (
      this.userPasswordFormGroup.invalid ||
      this.passwordStrength.passwordFormControl.invalid ||
      // indicates that not all rules were met.
      (this.passwordStrength.passwordFormControl.value &&
        this.passwordStrength.passwordFormControl.value < 100) ||
      this.passwordStrength.passwordConfirmationFormControl.invalid ||
      this.userPasswordFormGroup.pristine
    );
  }

  /**
   * Whether to show the user tab.
   *
   * TODO the tab will need to show while hiding the password section if we add other user profile options such as settings sync to this tab.
   *
   * @returns A boolean.
   */
  get showUserTab(): boolean {
    if (!this.user || this.isGuest) {
      return false;
    }

    return this.user.provider === 'ui' && this.user.isInternalUser;
  }

  /**
   * Determines if the user tab is active.
   *
   * @returns A boolean.
   */
  get userTabIsActive(): boolean {
    return !!this.userTab && this.userTab.isActive;
  }

  private appState = this.applicationStore.getValue();
  private user = this.applicationStore.getValue().user;

  constructor(
    private logger: NGXLogger,
    private languagesService: LanguagesService,
    private dialogReference: MatDialogReference<UserSettingsDialogComponent>,
    private supportedFeatures: SupportedFeaturesService,
    private taskSearchesQuery: TaskSearchesQuery,
    private auth: AuthenticationService,
    private notify: NotificationService,
    private appConfigQuery: AppConfigQuery,
    private application: ApplicationService,
    private applicationStore: ApplicationStore
  ) {}

  ngAfterViewInit(): void {
    this.checkAndSetCustomPasswordRegex();
  }

  ngOnInit() {
    // Setup form controls
    this.userSettingsGroup = new UntypedFormGroup({
      // Results
      searchCountEnabled: new FormControl<boolean>(
        this.appState.searchCountEnabled,
        { nonNullable: true }
      ),
      sortResultsArchivesByResultsCount: new FormControl<boolean>(
        this.appState.sortResultsArchivesByResultsCount
      ),
      hideArchivesWithNoResults: new UntypedFormControl(
        this.appState.hideArchivesWithNoResults
      ),
      doubleClickOpen: new UntypedFormControl(this.appState.doubleClickOpen),
      usePreviousSearchCriteriaInRelatedSearch: new FormControl<boolean>(
        this.appState.usePreviousSearchCriteriaInRelatedSearch,
        { nonNullable: true }
      ),
      alwaysOpenNewTab: new UntypedFormControl(this.appState.alwaysOpenNewTab),
      refreshResultsWhenClosingDocumentTabs: new FormControl<boolean>(
        this.appState.refreshResultsWhenClosingDocumentTabs,
        { nonNullable: true }
      ),
      archiveHistoryResultsPerPage: new UntypedFormControl(
        this.appState.archiveHistoryResultsPerPage,
        [Validators.required, Validators.min(25), Validators.max(200)]
      ),
      archiveResultsPerPage: new FormControl<number>(
        this.appState.archiveResultsPerPage,
        [Validators.required, Validators.min(25), Validators.max(200)]
      ),
      showDomainInHistoryResults: new UntypedFormControl(
        this.appState.showDomainInHistoryResults
      ),
      // Appearance
      themeMode: new UntypedFormControl(this.appState.themeMode),
      showGlobalActionTasks: new UntypedFormControl(
        this.appState.showGlobalActionTasks
      ),
      showFavoriteSearches: new UntypedFormControl(
        this.appState.showFavoriteSearches
      ),
      showVersionsArchive: new FormControl<boolean>(
        this.appState.showVersionsArchive,
        { nonNullable: true }
      ),
      enabledTasksList: new FormControl<string[]>(
        this.appState.enabledTasksList,
        { nonNullable: true }
      ),
      //Keyfree
      keyfreeUseOcr: new FormControl<boolean>(this.appState.keyfreeUseOcr, {
        nonNullable: true,
      }),
      // Language, has to go into an array due to fancy select control
      currentLanguage: new UntypedFormControl([this.appState.currentLanguage]),
      // Viewer
      viewerAutoSave: new FormControl<boolean>(this.appState.viewerAutoSave, {
        nonNullable: true,
      }),
      indexerReloadAfterSave: new FormControl<boolean>(
        this.appState.indexerReloadAfterSave,
        {
          nonNullable: true,
        }
      ),
      persistArchiveImportData: new FormControl<boolean>(
        this.appState.persistArchiveImportData,
        { nonNullable: true }
      ),
      viewerGoToFirstPageOnLoad: new UntypedFormControl(
        this.appState.viewerGoToFirstPageOnLoad
      ),
      viewerUseInternal: new UntypedFormControl(
        this.appState.viewerUseInternal
      ),
      viewerUseInternalForImport: new FormControl<boolean>(
        this.appState.viewerUseInternalForImport,
        { nonNullable: true }
      ),
      dxcAppendToMultivalue: new FormControl<boolean>(
        this.appState.dxcAppendToMultivalue,
        { nonNullable: true }
      ),
      dxcSearchSystemFields: new FormControl<boolean>(
        this.appState.dxcSearchSystemFields,
        { nonNullable: true }
      ),
    });

    this.userPasswordFormGroup = new FormGroup<UserPasswordForm>({
      currentPassword: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      newPassword: new FormControl<string>('', { nonNullable: true }),
    });

    // Disable/enable values on changes:
    const alwaysOpenNewTabControl =
      this.userSettingsGroup.get('alwaysOpenNewTab');
    assertExists(alwaysOpenNewTabControl);
    alwaysOpenNewTabControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          if (value) {
            this.userSettingsGroup
              .get('refreshResultsWhenClosingDocumentTabs')
              ?.enable();
          } else {
            this.userSettingsGroup
              .get('refreshResultsWhenClosingDocumentTabs')
              ?.disable();
          }
        })
      )
      .subscribe();
    alwaysOpenNewTabControl.updateValueAndValidity();
    const searchCountEnabledControl =
      this.userSettingsGroup.get('searchCountEnabled');
    assertExists(searchCountEnabledControl);
    searchCountEnabledControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          if (value) {
            this.userSettingsGroup
              .get('sortResultsArchivesByResultsCount')
              ?.enable();
            this.userSettingsGroup.get('hideArchivesWithNoResults')?.enable();
          } else {
            this.userSettingsGroup
              .get('sortResultsArchivesByResultsCount')
              ?.disable();
            this.userSettingsGroup.get('hideArchivesWithNoResults')?.disable();
          }
        })
      )
      .subscribe();
    searchCountEnabledControl.updateValueAndValidity();
  }

  /**
   * Reset event handler.
   */
  onReset(): void {
    this.logger.debug(
      'Reset button clicked. Resetting user settings to default.'
    );
    this.application.resetUserSettingsToDefault();
    this.dialogReference.close();
  }

  /**
   * Save event handler.
   */
  onSave() {
    const settingsValues = this.userSettingsGroup.value;
    // Collect settings
    const savedSettings: UserUiSettings = {
      ...settingsValues,
      // flatten the current language selection since the control returns an array.
      currentLanguage: settingsValues.currentLanguage[0],
    };
    this.application.applyUserUiSettings(savedSettings);
    this.logger.debug('Settings saved.', savedSettings);
    if (settingsValues.newPassword) {
      this.logger.debug(
        'New password was provided. Checking if it matches the confirm password field.'
      );
      if (settingsValues.newPassword === settingsValues.confirmPassword) {
        this.logger.debug(
          'New password matched confirmation password. Attempting to save the change.'
        );
      } else {
        this.logger.warn(
          'New password does not match confirmation password and will not be saved.'
        );
      }
    }
    this.dialogReference.close();
  }

  /**
   * Handler for the show global action tasks checkbox change event.
   *
   * @param event Checkbox change event.
   */
  onShowGlobalActionTasksChange(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.logger.debug(
        'Global action tasks are disabled. Disabling all enabled queues.'
      );
      this.userSettingsGroup.controls.enabledTasksList.setValue([]);
    }
  }

  /** Password update event handler. */
  onUpdatePassword(): void {
    this.logger.debug('Update password clicked.');
    // TODO this will log the user out. Should we prompt to confirm?
    const formValues = this.userPasswordFormGroup.value;
    if (!formValues.newPassword) {
      this.logger.warn('No new password was provided.');
      return;
    }
    if (!formValues.currentPassword) {
      this.logger.warn('Current password was not provided.');
      return;
    }
    this.auth
      .changePassword(formValues.currentPassword, formValues.newPassword)
      .subscribe({
        next: () => {
          this.notify.success('PASSWORD_CHANGE_SUCCESS');
          this.dialogReference.close();
        },
        error: (error: UserFriendlyError) => this.notify.error(error),
      });
  }

  private checkAndSetCustomPasswordRegex(): void {
    if (this.appConfigQuery.customPasswordRegexProvided) {
      this.passwordStrength.customValidator = new RegExp(
        this.appConfigQuery.appConfig.passwordRegex
      );

      this.passwordStrength.setRulesAndValidators();
      this.passwordStrengthInfo.customCharsCriteriaMsg =
        this.appConfigQuery.appConfig.passwordRegexValidationMessage;
    }
  }
}
