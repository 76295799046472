import { Pipe, PipeTransform } from '@angular/core';

import { Command } from '../models/command';

/**
 * Highlight a string within a command.
 */
@Pipe({
  name: 'highlightCommand',
  standalone: false,
})
export class HighlightCommandPipe implements PipeTransform {
  /** @inheritdoc */
  transform(command: Command, highlight: string): string {
    const highlightText = command.filterPrefix ? highlight.slice(1) : highlight;
    return command.name.replace(
      new RegExp(`(${highlightText})`, 'gi'),
      '<span class="command-highlight">$1</span>'
    );
  }
}
