import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { MatTabGroup } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GridApi } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  tap,
  timestamp,
} from 'rxjs';

import { assert, assertExists } from 'common';
import { DocumentAnnotations, DocumentProvider, PdfOptions } from 'models';
import { DOCUMENT_PROVIDER } from 'src/app/common/tokens';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

/** Index of the Settings tab. */
export const SETTINGS_TAB_INDEX = 0;
/** Index of the PDF View tab. */
export const PDF_VIEW_TAB_INDEX = 1;
/** Index of the History tab. */
export const HISTORY_TAB_INDEX = 2;
/** Minimum left side width for resizable panel. */
const MIN_LEFT_RESIZE_PX = 64;
/** Minimum left side width for resizable panel before invoking overlay mode. */
const MIN_LEFT_RESIZE_OVERLAY_PX = 330;
/** Minimum right side width for resizable panel. */
const MIN_RIGHT_RESIZE_PX = 120;

/** Right Sidebar. */
@UntilDestroy()
@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  standalone: false,
})
export class RightSidebarComponent implements OnInit, AfterViewInit {
  /** Draw container reference. */
  @ViewChild('drawer') drawer: MatDrawer;
  /** Grid Api instance. */
  @Input()
  gridApi: GridApi<any> | undefined;
  /** Sidebar mode. */
  @Input() mode: 'archive' | 'inbox';
  /** Tab controls. */
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;

  /** If the user is a guest. */
  isGuest = this.auth.isGuest;
  /** Observable state of if we are on a mobile handset sized output. */
  isHandset$ = this.layout.isHandset$.pipe(
    // Disable resize when handset, it just gets complicated.
    tap((isHandset) => (this.isResizeDisabled = isHandset))
  );
  /** If a PDF URL is set. */
  isPdfViewAvailable: boolean;
  /** If the ability to resize should be disabled. */
  isResizeDisabled = false;
  /**
   * Observable of annotation data basedon back parsing the URL.
   *
   * @todo This is not really ideal, and perhaps we should be handling this more directly and track the annotations or "preview document" as an object in state. Remove once added to viewer.
   */
  pdfAnnotations$: Observable<DocumentAnnotations> =
    this.applicationQuery.pdfPreviewUrl$.pipe(
      switchMap((previewUrl) => {
        const regex =
          /.*\/dbs\/(?<db>\d+)\/archives\/(?<archive>\d+)\/documents\/(?<document>\d+)\/previewpdf\?token=(?<token>[\w-]+).*&secureid=(?<secureId>\w+)/;
        let match;
        if ((match = regex.exec(previewUrl)) !== null) {
          this.logger.debug(
            'Parsed document identifiers:',
            'db',
            match.groups?.db,
            'arch',
            match.groups?.archive,
            'doc',
            match.groups?.document,
            'token',
            match.groups?.token,
            'secureId',
            match.groups?.secureId
          );
          // Fetch annotations.
          return this.documentProvider.getDocumentAnnotations(
            Number(match.groups?.db),
            Number(match.groups?.archive),
            Number(match.groups?.document),
            match.groups?.token as string,
            match.groups?.secureId as string
          );
        }
        return of([] as DocumentAnnotations);
      })
    );
  /** Observable of debounced PDF display options. */
  pdfOptions$: Observable<PdfOptions>;
  /** Observable URL of the PDF displayed in the preview component. */
  pdfUrl$ = this.applicationQuery.pdfPreviewUrl$;
  /** A resize event is in progress. */
  resizeInProgress: boolean;
  /** A CSS object container style adjustments for resize. */
  resizeStyle: { /** Width. */ width?: string } = {};
  /** An optional array of currently selected documents. */
  selectedDocumentIds$ = new BehaviorSubject<number[]>([]);
  /** Value of the currently selected tab index. */
  selectedTabIndex$: Observable<number>;
  /** Observable of whether the sidebar is open. */
  sidebarOpen$ = this.applicationQuery.rightSidebarOpen$;
  /** Observable of whether to always load the first page on viewer load. */
  viewerGoToFirstPageOnLoad$ = this.applicationQuery.viewerGoToFirstPageOnLoad$;

  /** Should the drawer overlay mode be forced on. */
  private isDrawerOverlayForced = false;
  /** Last known width of the window. */
  private lastWindowWidth: number;
  /** Value of the currently selected tab index. */
  private selectedTabIndexSource = new BehaviorSubject<number>(
    SETTINGS_TAB_INDEX
  );

  constructor(
    private searches: SearchesQuery,
    private logger: NGXLogger,
    private layout: LayoutService,
    private applicationService: ApplicationService,
    private applicationQuery: ApplicationQuery,
    private archivesQuery: ArchivesQuery,
    @Inject(DOCUMENT_PROVIDER) private documentProvider: DocumentProvider,
    private auth: AuthenticationService,
    private gridStatesService: GridSettingsService,
    private changeDector: ChangeDetectorRef
  ) {}

  /**
   *  Get drawer mode.
   *
   * @returns Observable mat-drawer mode.
   */
  get drawerMode$(): Observable<MatDrawerMode> {
    if (this.isDrawerOverlayForced) {
      return of('over');
    }
    return this.isHandset$.pipe(
      map((isHandset) => (isHandset ? 'over' : 'side'))
    );
  }

  /**
   *  Get if the current selected tab is for the PDF view.
   *
   * @returns True when the PDF view is the selected sidebar tab.
   */
  get isPdfViewSelected(): boolean {
    return this.selectedTabIndexSource.value === PDF_VIEW_TAB_INDEX;
  }

  /**
   * Determines if the document history tab should be shown.
   *
   * @returns True if document history should be displayed.
   */
  get shouldShowDocumentHistory(): boolean {
    return (
      this.mode === 'archive' &&
      !this.isGuest &&
      this.archivesQuery.active.permissions.viewDocumentHistory
    );
  }

  ngAfterViewInit(): void {
    this.storeDrawerWidth();
  }

  ngOnInit(): void {
    // Configure selected tab observable.
    this.selectedTabIndex$ = this.selectedTabIndexSource.asObservable();

    // Define the pdf preview options listener.
    this.listenForPdfViewOptions();

    // When a pdf view url is set, open the preview panel.
    this.openPreviewWhenUrlSet();

    // Handle search changes.
    this.onSearchChange();

    if (this.mode === 'archive') {
      this.archivesQuery.archiveRouteParams$
        .pipe(untilDestroyed(this))
        .subscribe(([databaseId, archiveId]) => {
          assertExists(databaseId);
          assertExists(archiveId);
          const archiveGridData =
            this.gridStatesService.getOrCreateArchiveGridData(
              databaseId,
              archiveId
            );
          archiveGridData.selectedRowNodes$
            .pipe(map((rows) => rows.map((row) => row.data.id as number)))
            .subscribe((selectedDocumentIds) => {
              this.selectedDocumentIds$.next(selectedDocumentIds);
            });
        });
    }
  }

  /** Handler for the sidebar closed event. */
  onClosed(): void {
    this.logger.debug('Right sidebar was closed.');
    this.setSidebarOpen(false);
  }

  /**
   * Handler for resize events.
   *
   * @param event Event.
   */
  onResize(event: any): void {
    // Set the style to render the pending size.
    this.resizeStyle = {
      width: `${event.rectangle.width}px`,
    };

    // Force the drawer to overlay mode if it would block controls.
    this.isDrawerOverlayForced =
      event.rectangle.width >= window.innerWidth - MIN_LEFT_RESIZE_OVERLAY_PX;

    // Update the stored width for layout service.
    this.storeDrawerWidth();
  }

  /**
   * Handler for end of resize event.
   *
   * @param event Event.
   */
  onResizeEnd(event: any): void {
    this.resizeInProgress = false;
    // If the resize event ends at minimum, they get a close, because thats an
    // unusable size.
    if (event.rectangle.width <= MIN_RIGHT_RESIZE_PX) {
      this.setSidebarOpen(false);
    }

    // Update the known window width.
    this.lastWindowWidth = window.innerWidth;

    // Fix the tabs ink highlight position.
    this.tabs.realignInkBar();
  }

  /**
   * Handler for double click of resize handle.
   *
   * Resets size and state.
   */
  onResizeHandleDoubleClick(): void {
    this.resetPanelSize();
  }

  /**
   * Handler for resize event start.
   */
  onResizeStart(): void {
    // We set this so we can track the state of the resize event.
    // Specically this is required to change our "handle" to cover the
    // entire right panel while we are resizing. This prevents events from
    // falling through to the iFrame of a viewer if active, which breaks events.
    this.resizeInProgress = true;
  }

  /**
   * Handler for resize event validation.
   *
   * @param event Event.
   * @returns If the resize event is valid.
   */
  onResizeValidate(event: any): boolean {
    // Do nothing if resizing is disabled.
    if (this.isResizeDisabled) return false;
    // Test the horizontal size of the event rectangle to see if it fits within
    // our resizing bounds.
    const outOfBounds =
      // More than the left side minimum allows.
      event.rectangle.width > window.innerWidth - MIN_LEFT_RESIZE_PX ||
      // Less than the right side minimum.
      event.rectangle.width < MIN_RIGHT_RESIZE_PX;
    // If it is out of bounds, ignore it.
    return !outOfBounds;
  }

  /**
   * Handler for change of selected tab.
   *
   * @param value Selected tab value, from event.
   */
  onTabChange(value: number): void {
    assert(typeof value == 'number');
    if (value !== this.selectedTabIndexSource.value) {
      this.selectedTabIndexSource.next(value);
    }
    this.logger.debug('Tab changed to index', value);
    this.storeDrawerWidth();
  }

  /**
   * Handler for resize of the window.
   *
   * Resets size and state, if the width is reduced.
   *
   * @param event Event.
   */
  onWindowResize(event: any): void {
    if (event.target.innerWidth < this.lastWindowWidth) this.resetPanelSize();
    this.lastWindowWidth = event.target.innerWidth;
  }

  /**
   * Check if a URL is available, and react accordingly.
   *
   * @param url URL to test.
   * @returns True if a URL is available.
   */
  private checkUrlAvailabilty(url: string): boolean {
    this.isPdfViewAvailable = url !== '';
    // If the pdf view is disabled, do not allow it to remain selected.
    if (
      !this.isPdfViewAvailable &&
      this.selectedTabIndexSource.value === PDF_VIEW_TAB_INDEX
    ) {
      this.selectSettingsTab();
      this.logger.debug('PDF View not available, deselected tab.');
    }
    return this.isPdfViewAvailable;
  }

  /**
   * Listen for PDF View Options changes.
   *
   * This is what fires when the user envokes a new PDF preview.
   */
  private listenForPdfViewOptions() {
    let lastCombinedTimestamp = 0;
    // PDF Options should be passed to the viewer together, syncronized.
    this.pdfOptions$ = combineLatest({
      url: this.pdfUrl$.pipe(timestamp()),
      annotations: this.pdfAnnotations$.pipe(timestamp()),
    }).pipe(
      // Ignore emitted options until both are "new" ones.
      filter((latest) => {
        this.logger.debug('Comparing timestamps of preview options');
        return (
          latest.annotations.timestamp > lastCombinedTimestamp &&
          latest.url.timestamp > lastCombinedTimestamp
        );
      }),
      // Update the timestamp comparitor.
      tap((optionsWithTimestamps) => {
        this.logger.debug(
          'Received time syncronized options for preview, emitting for load.'
        );
        // Mark last combined timestamp as the greater of the two values, so it
        // must complete each before the next emit.
        lastCombinedTimestamp = Math.max(
          optionsWithTimestamps.annotations.timestamp,
          optionsWithTimestamps.url.timestamp
        );
      }),
      // Remove the stampstamps from the options.
      map((optionsWithTimestamps) => ({
        url: optionsWithTimestamps.url.value,
        annotations: optionsWithTimestamps.annotations.value,
      }))
    );
  }

  /**
   * Handle changes to the search route.
   *
   * This will close the siderbar if it was open, and clear any current preview.
   */
  private onSearchChange() {
    this.searches.searchRouteParams$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.logger.debug(
          'Route changed, clear the PDF view.',
          this.applicationQuery.pdfPreviewUrl
        );
        // Close the sidebar if it was open to the pdf view.
        if (this.isPdfViewSelected) {
          this.logger.debug('Sidebar open when route changed, closing.');
          this.setSidebarOpen(false);
        }
        // Clear the preview.
        this.applicationService.clearPdfPreviewUrl();
      },
    });
  }

  /**
   * Initiate the opening of the preview panel when a URL is set.
   */
  private openPreviewWhenUrlSet() {
    this.pdfUrl$
      .pipe(
        // Determine if any is availble for view. Handle loss of preview URL.
        tap((url) => this.checkUrlAvailabilty(url)),
        // Only react to available URLs.
        filter((url) => url !== '')
      )
      .subscribe({
        next: (url) => {
          // Right sidebar state is opened by ApplicationService on update of URL.
          // Tab must be selected here when a value is available.
          this.logger.debug(
            'PDF View URL update detected. Selecting PDF View tab.',
            url
          );
          this.selectPdfViewTab();
        },
      });
  }

  /**
   * Restore size of panel.
   */
  private resetPanelSize() {
    this.resizeStyle = { width: undefined };
    this.isDrawerOverlayForced = false;
    this.storeDrawerWidth();
  }

  /**
   * Select the PDF View tab.
   */
  private selectPdfViewTab() {
    this.selectedTabIndexSource.next(PDF_VIEW_TAB_INDEX);
  }

  /**
   * Select the Settings tab.
   */
  private selectSettingsTab() {
    this.selectedTabIndexSource.next(SETTINGS_TAB_INDEX);
  }

  /**
   * Set the open state of the sidebar.
   *
   * @param state State.
   */
  private setSidebarOpen(state: boolean): void {
    this.logger.debug('Setting sidebar open state:', state);
    this.applicationService.setRightSidebarOpen(state);
  }

  /**
   * Update the stored drawer with use by layout.
   */
  private storeDrawerWidth(): void {
    const width = this.drawer._getWidth();
    if (this.layout.rightSidebarWidth === width) return;
    this.logger.debug('Store drawer width called', width);
    this.layout.rightSidebarWidth = width;
    // Prevent expression change errors when we know we are doing this.
    this.changeDector.detectChanges();
  }
}
