import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { ApplicationService } from 'src/app/state/application/application.service';

@Component({
  selector: 'app-left-sidebar-toggle-button',
  templateUrl: './left-sidebar-toggle-button.component.html',
  styleUrls: ['./left-sidebar-toggle-button.component.scss'],
  standalone: false,
})
export class LeftSidebarToggleButtonComponent {
  constructor(
    private logger: NGXLogger,
    private appService: ApplicationService
  ) {}

  /** Click event for the toggle left sidebar button. */
  onClickToggleLeftSidebar(): void {
    this.logger.debug('Toggle left sidebar button clicked.');
    this.appService.toggleLeftSidebarNav();
  }
}
