import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { Search } from 'models';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import {
  FavoriteSearch,
  FavoriteSearchesService,
} from 'src/app/state/application/favorite-searches.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

@Component({
  selector: 'app-navigation-favorite-search',
  templateUrl: './navigation-favorite-search.component.html',
  styleUrls: ['./navigation-favorite-search.component.scss'],
  standalone: false,
})
export class NavigationFavoriteSearchComponent implements OnInit {
  /** Database ID. */
  @Input()
  databaseId: number;
  /** Search. */
  @Input()
  search: Search;
  /** Favorite search. */
  favoriteSearch: FavoriteSearch;
  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layoutService.isHandset$;

  constructor(
    private favoriteSearchService: FavoriteSearchesService,
    private appQuery: ApplicationQuery,
    private databasesQuery: DatabasesQuery,
    private searchesQuery: SearchesQuery,
    private layoutService: LayoutService,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    this.favoriteSearch = this.appQuery.getFavoritedSearchById(
      `${this.databasesQuery.activeId}`,
      `${this.search.id}`
    );
    if (!this.favoriteSearch) {
      // If we could not find the favorite search, log an error. The view's ngIf will prevent further exceptions.
      this.logger.error(
        `Favorite search was not matched by Id:${this.search.id} on Db:${this.databasesQuery.activeId}. Favorited searches:`,
        this.appQuery.getFavoritedSearches(`${this.databasesQuery.activeId}`)
      );
    }
  }

  /**
   * Get whether the search is currently active.
   *
   * @returns A boolean.
   */
  get isActiveSearch(): boolean {
    return this.search.id === this.searchesQuery.getActiveId();
  }

  /**
   * Handler for the search on click event.
   */
  onClickSearch(): void {
    this.favoriteSearchService.runFavoritedSearch(this.databaseId, this.search);
  }
}
