import {
  formatCurrency,
  formatPercent,
  getLocaleCurrencyCode,
  getLocaleCurrencySymbol,
} from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ColDef,
  EditableCallbackParams,
  GridOptions,
  IDateFilterParams,
  IRowNode,
  RowSelectedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';

import { AssertionError, assertExists } from 'common';
import {
  Field,
  FieldDataType,
  InboxFile,
  Permissions,
  SearchResult,
} from 'models';

import { isSameMoment } from '../common/utility';
import { BadgeCellRendererComponent } from '../components/grid-cell-components/badge-cell-renderer/badge-cell-renderer.component';
import {
  SELECTION_ORDER_COLUMN_ID,
  createThumbnailSelectionColumnDefinition,
  getCellEditor,
  getCellRenderer,
  getFieldCellEditorProperties,
  getTableFieldCellRendererProperties,
} from '../components/grid-cell-components/cell-selectors';
import { DateTimeCellRendererComponent } from '../components/grid-cell-components/date-time-cell-renderer/date-time-cell-renderer.component';
import { TableFieldCellRendererComponent } from '../components/grid-cell-components/table-field-cell-renderer/table-field-cell-renderer.component';
import { ThumbnailSource } from '../components/grid-cell-components/thumbnail-source';
import { ActionsMenu } from '../models/actions-menu';
import { ApplicationQuery } from '../state/application/application.query';
import { ArchivesQuery } from '../state/archives/archives.query';
import { DatabasesQuery } from '../state/databases/databases.query';
import { FieldsQuery } from '../state/fields/fields.query';

import { ThumbnailPreviewRendererComponent } from '../components/grid-cell-components/thumbnail-preview-renderer/thumbnail-preview-renderer.component';
import { AuthenticationService } from './authentication.service';

/** Grid helper service. */
@Injectable({
  providedIn: 'root',
})
export class GridHelperService {
  constructor(
    private logger: NGXLogger,
    private appQuery: ApplicationQuery,
    private databasesQuery: DatabasesQuery,
    private fieldsQuery: FieldsQuery,
    private archivesQuery: ArchivesQuery,
    private auth: AuthenticationService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * Creates a new archive grid options object.
   *
   * @param gridOptions Grid options which will overload the defaults.
   * @returns A grid options object for use with an AG-Grid.
   */
  createArchiveGridOptions(gridOptions: Partial<GridOptions>): GridOptions {
    const options: GridOptions<SearchResult> = {
      rowSelection: {
        mode: 'multiRow',
        checkboxes: true,
        hideDisabledCheckboxes: true,
      },
      singleClickEdit: true,
      tooltipShowDelay: 1000,
      pagination: true,
      paginationPageSize: this.appQuery.archiveResultsPerPage,
      suppressPaginationPanel: true,
      columnDefs: [], // should be populated by search results.
      editType: 'fullRow',
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      getRowId: (params) => params.data.id.toString(),
      onFilterChanged: (event) => {
        event.api.deselectAll();
      },
    };

    Object.assign(options, gridOptions);
    return options;
  }

  /**
   * Creates a new inbox grid options object.
   *
   * @param inboxId Inbox Id.
   * @param gridOptions Grid options which will overload the defaults.
   * @param addSelectionOrderColumn Whether to add the selection order column as the first column.
   * @param editable Boolean or function returning a boolean indicating if the filename column is editable.
   * @returns A grid options object for use with an AG-Grid.
   */
  createInboxGridOptions(
    inboxId: number,
    gridOptions: Partial<GridOptions>,
    addSelectionOrderColumn: boolean,
    editable: boolean | ((params: EditableCallbackParams) => boolean)
  ): GridOptions {
    const columnDefs: ColDef[] = [
      {
        field: 'filename',
        headerName: 'Filename',
        resizable: true,
        editable,
        sortable: true,
        headerCheckboxSelection: false,
        checkboxSelection: false,
      },
      {
        field: 'fileType',
        headerName: 'File Type',
        resizable: true,
        sortable: true,
      },
      {
        field: 'dateModified',
        headerName: 'Date Modified',
        resizable: true,
        sortable: true,
        cellRenderer: DateTimeCellRendererComponent,
      },
      {
        field: 'dateCreated',
        headerName: 'Date Created',
        resizable: true,
        sortable: true,
        cellRenderer: DateTimeCellRendererComponent,
      },
    ];

    const thumbnailSource: ThumbnailSource = {
      sourceType: 'inbox',
      source: {
        inboxId,
      },
    };

    if (addSelectionOrderColumn) {
      columnDefs.unshift(this.getSelectionColumn());
    }

    const options: GridOptions = {
      rowSelection: {
        mode: 'multiRow',
        checkboxes: true,
      },
      singleClickEdit: true,
      pagination: false,
      stopEditingWhenCellsLoseFocus: true,
      selectionColumnDef:
        createThumbnailSelectionColumnDefinition(thumbnailSource),
      columnDefs,
      getRowId: (params) => params.data.id,
      onFirstDataRendered: (params) => params.api.autoSizeAllColumns(),
      onRowDataUpdated: (params) => params.api.autoSizeAllColumns(),
      onFilterChanged: (event) => {
        event.api.deselectAll();
      },
    };

    Object.assign(options, gridOptions);
    return options;
  }

  /**
   * Determines if the archive row should be editable.
   *
   * @param params Editable callback parameters.
   * @param field Field.
   * @param editEnabled Whether edit mode is enabled.
   * @returns Whether editting should be enabled.
   */
  editableArchiveRow(
    params: EditableCallbackParams,
    field: Field,
    editEnabled: boolean | (() => boolean)
  ): boolean {
    const permissions = params.data.permissions as Permissions;
    return (
      permissions.modifyData &&
      field.systemField === '' &&
      !field.multiValue &&
      (typeof editEnabled === 'function' ? editEnabled() : editEnabled)
    );
  }

  /**
   * Determines if the inbox row should be editable.
   *
   * @param params Editable callback parameters.
   * @param editEnabled Whether edit mode is enabled.
   * @returns Whether editting should be enabled.
   */
  editableInboxRow(
    params: EditableCallbackParams,
    editEnabled: boolean
  ): boolean {
    return (
      editEnabled && (params.data.permissions as Permissions).modifyDocuments
    );
  }

  /**
   * Gets an array of AG-Grid column definitions for an archive view.
   *
   * @param archiveId Archive Id.
   * @param editEnabled Function that returns a boolean indicating if edit mode is enabled.
   * @returns An array of column definitions for AG-Grid.
   */
  getArchiveColumnDefinitions(
    archiveId: number,
    editEnabled: () => boolean
  ): ColDef[] {
    const archiveFields = this.archivesQuery.getFields(archiveId);
    const archiveTableFields = this.archivesQuery.getTableFields(archiveId);

    // Adds support for filtering our date strings by casting them to moments without the time to compare them.
    const dateFilterParameters: IDateFilterParams = {
      comparator: (fitlerDate: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        const cellDate = moment(moment(dateAsString));
        const filterMoment = moment(fitlerDate);

        if (cellDate.isSame(filterMoment, 'day')) {
          return 0;
        }

        if (cellDate.isBefore(filterMoment, 'day')) {
          return -1;
        }

        return 1;
      },
    };

    const newCols: ColDef[] = archiveFields
      .filter(
        (field) =>
          // Field is not a LiveField.
          !field.liveField &&
          // Field is not in a table field.
          !archiveTableFields.some((tableField) =>
            tableField.fieldIds.includes(field.id)
          )
      )
      .map((field) => ({
        field: `field_${field.id}`,
        headerName: field.name,
        sortable: true,
        filter: field.type === FieldDataType.date ? 'agDateColumnFilter' : true,
        filterParams:
          field.type === FieldDataType.date ? dateFilterParameters : undefined,
        resizable: true,
        valueGetter: (params: ValueGetterParams<SearchResult>) => {
          const fieldValue = params.data?.fields.find((f) => f.id === field.id);
          if (!fieldValue) {
            this.logger.warn(
              'The search result for the document does not contain a value for the field.',
              field
            );
            return;
          }
          this.logger.debug(`${field.name} value: `, fieldValue);
          return field.multiValue
            ? fieldValue.multiValue.join(', ')
            : fieldValue.value;
        },
        valueSetter: (params: ValueSetterParams<SearchResult>) => {
          const fieldValue = params.data.fields.find((f) => f.id === field.id);
          if (!fieldValue) return false;
          if (field.multiValue) {
            fieldValue.multiValue = params.newValue.split(', ');
          } else {
            fieldValue.value = params.newValue;
          }
          return true;
        },
        editable: (params) =>
          this.editableArchiveRow(params, field, editEnabled),
        cellEditor: getCellEditor(field),
        cellEditorParams: getFieldCellEditorProperties(field),
        cellRenderer: getCellRenderer(field),
        cellRendererParams: {
          format: field.format,
          type: field.type,
        },
        comparator: (valueA, valueB) => {
          if (field.type === 2 || field.type === 4) {
            return valueA - valueB;
          }
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        equals:
          field.type === FieldDataType.date
            ? (oldValue, newValue) => isSameMoment(oldValue, newValue)
            : undefined,
      }));

    newCols.push(
      ...archiveTableFields.map((tableField) => ({
        field: `tablefield_${tableField.id}`,
        headerName: tableField.name,
        sortable: false,
        filter: false,
        editable: false,
        resizable: false,
        cellRenderer: TableFieldCellRendererComponent,
        cellRendererParams: getTableFieldCellRendererProperties(tableField),
      }))
    );

    return newCols;
  }

  /**
   * Formats the raw value into the appropriate date string for a grid cell.
   *
   * @param value Raw value.
   * @param momentFormat Moment format.
   * @returns The value formatted as it should be displayed to the user.
   */
  getDateTimeCellValue(value: string, momentFormat: string): string {
    if (value) {
      const date = moment(value);
      if (momentFormat) {
        return date.format(momentFormat);
      }

      return date.toDate().toLocaleString();
    }
    return '';
  }

  /**
   * Formats the raw value into the appropriate decimal string for a grid cell.
   *
   * @param value Raw value.
   * @param format Field format.
   * @returns The value formatted as it should be displayed to the user.
   */
  getDecimalCellValue(value: any, format: string): string {
    let parsedDisplayValue: string = value;
    try {
      switch (format.toLowerCase()) {
        case 'c':
        case '$#,###.00':
        case '€#,###.00':
          parsedDisplayValue = this.parseAsCurrency(value);
          break;
        case 'p':
          parsedDisplayValue = formatPercent(value / 100, this.locale);
          break;
        case 'x':
          parsedDisplayValue = Number(value).toString(16);
          break;
        case 'n':
          parsedDisplayValue = Number(value).toFixed(2);
          break;
        case 'e':
          parsedDisplayValue = Number(value).toExponential(6); // fraction digit 6 is default in knowledge base;
          break;
      }
    } catch (error) {
      this.logger.error('Unable to format decimal value.', error);
    }
    return parsedDisplayValue;
  }

  /**
   * Converts inbox files into row data for AG-Grid.
   *
   * @param files Inbox Files.
   * @returns A row data object for the grid to use.
   */
  getInboxRowData(files: InboxFile[]): any[] {
    const rowData = files.map((inboxFile) => {
      const rowItem: any = {};
      rowItem.id = `${inboxFile.filename}${inboxFile.fileType}`;
      rowItem.dateCreated = inboxFile.dateCreated;
      rowItem.dateModified = inboxFile.dateModified;
      rowItem.filename = inboxFile.filename;
      rowItem.fileType = inboxFile.fileType;
      rowItem.permissions = inboxFile.permissions;
      return rowItem;
    });

    return rowData;
  }

  /**
   * Gets the selection column definition.
   *
   * @returns A column definition.
   */
  getSelectionColumn(): ColDef {
    return {
      field: SELECTION_ORDER_COLUMN_ID,
      headerName: '#',
      headerCheckboxSelection: false,
      checkboxSelection: false,
      suppressSizeToFit: true,
      width: 110,
      lockPosition: true,
      resizable: true,
      cellClass: 'centered-grid-cell',
      cellRenderer: BadgeCellRendererComponent,
    };
  }

  /**
   * Handles row selection events.
   *
   * @param event Row selected event.
   * @param selectedRows Rows which are already selected.
   * @returns All selected rows including the one that was just selected.
   */
  onRowSelected = (
    event: RowSelectedEvent,
    selectedRows: IRowNode[]
  ): IRowNode[] => {
    this.logger.debug('On row selected event raised.', event);
    // Skip rows that are there but not displayed unless the row is being deselected in which case we always deselect it.
    if (!event.node.displayed && event.node.isSelected()) return selectedRows;
    const hasSelectionOrderColumn = !!event.api.getColumnDef(
      SELECTION_ORDER_COLUMN_ID
    );
    if (event.node.isSelected()) {
      selectedRows.push(event.node);
      if (hasSelectionOrderColumn) {
        event.node.setDataValue(SELECTION_ORDER_COLUMN_ID, selectedRows.length);
      }
    } else {
      const index = selectedRows.indexOf(event.node);
      if (index > -1) {
        selectedRows.splice(index, 1);
        if (hasSelectionOrderColumn) {
          event.node.setDataValue(SELECTION_ORDER_COLUMN_ID, '');
        }
      }
    }

    this.logger.debug('Selected rows have been ordered', selectedRows);
    return selectedRows;
  };

  /**
   * Opens the provided context menu.
   *
   * If there are selected row nodes then they are passed to the actions menu.
   * Otherwise they are pulled from the mouse event on the grid.
   *
   * @param event Mouse event.
   * @param selectedRowNodes Any selected row nodes.
   * @param getRowNode Function that can retrieve the row node.
   * @param actionsMenu Actions menu to open.
   */
  openContextMenu(
    event: MouseEvent,
    selectedRowNodes: IRowNode[],
    getRowNode: (rowId: string) => IRowNode<any> | undefined,
    actionsMenu: ActionsMenu
  ): void {
    this.logger.debug('Right clicked in grid.', event);
    event.preventDefault();
    // If a selection exists, use those rows.
    if (selectedRowNodes.length > 0) {
      this.logger.debug(
        'Trigger context menu with selected rows.',
        selectedRowNodes
      );
      actionsMenu.openMenu(
        event,
        selectedRowNodes.map((row) => row.data)
      );
    } else {
      // Otherwise use the event and function parameter to get the target row.
      const rowElement = (event.target as HTMLElement)
        ?.parentElement as HTMLElement;
      // Must have a row element.
      assertExists(rowElement, 'Event target did not contain a row element.');
      const rowId = rowElement.getAttribute('row-id');
      // Row must have a row id attribute. If it does not this isn't a row.
      if (!rowId) {
        this.logger.warn('Unable to get row id from target row.');
        return;
      }
      const rowNode = getRowNode(rowId);
      // The row must exist.
      assertExists(
        rowNode,
        'Target row element did not match a grid row node.'
      );
      this.logger.debug('Trigger context menu with target row.', rowNode);
      actionsMenu.openMenu(event, [rowNode.data]);
    }
  }

  /**
   * Format a number as locale currency.
   *
   * @todo Reference any required supported locales. Unsure if production builds include other than english.
   * @example
   * ```js
   * // Add additional required locales.
   * import localeFrCa from '@angular/common/locales/fr-CA'
   * registerLocaleData(localeFrCa);`
   * ```
   *
   * @param parameterValue Value to parse.
   * @returns Formatted currency string.
   * @throws {Error} If unable to determine currency from locale.
   */
  private parseAsCurrency(parameterValue: number): string {
    if (!parameterValue) return '';
    const currencyCode = getLocaleCurrencyCode(this.locale);
    const currencySymbol = getLocaleCurrencySymbol(this.locale);
    try {
      assertExists(currencyCode);
      assertExists(currencySymbol);
    } catch (error) {
      throw new Error('Unable to get currency from locale.', {
        cause: error as AssertionError,
      });
    }
    return formatCurrency(
      parameterValue,
      this.locale,
      currencySymbol,
      currencyCode
    );
  }
}
