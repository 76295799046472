import { Component, Input } from '@angular/core';

import { TaskSearch } from 'models';
import { LayoutService } from 'src/app/services/layout.service';
import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';

@Component({
  selector: 'app-navigation-task-search',
  templateUrl: './navigation-task-search.component.html',
  styleUrls: ['./navigation-task-search.component.scss'],
  standalone: false,
})
export class NavigationTaskSearchComponent {
  /** Database ID. */
  @Input() dbId: number;
  /** Task Search object. */
  @Input() taskSearch: TaskSearch;
  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layoutService.isHandset$;

  /**
   * Is this task search the active one.
   *
   * @returns True if the task search is currently active.
   */
  get isActive(): boolean {
    try {
      return this.taskSearch.id === this.taskQuery.active.id;
    } catch {
      // If there is an error it is with getting the active task. This indicates that there is no active task.
      return false;
    }
  }

  constructor(
    private taskQuery: TaskSearchesQuery,
    private layoutService: LayoutService
  ) {}
}
