import { Injectable } from '@angular/core';
import { Query, filterNilValue } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { GseApiConfig } from 'gse-api';
import { User } from 'models';
import { ThemeMode } from 'src/app/models';

import { ApplicationState, ApplicationStore } from './application.store';
import {
  FavoriteDatabaseSearches,
  FavoriteSearch,
} from './favorite-searches.service';
import { OpenDocumentSource } from './open-document-source';

/**
 * Application query.
 */
@Injectable({ providedIn: 'root' })
export class ApplicationQuery extends Query<ApplicationState> {
  /** Observable of archive history results per page state. */
  archiveHistoryResultsPerPage$ = this.select('archiveHistoryResultsPerPage');
  /** Observable of whether the archive navigation section is expanded. */
  archiveNavSectionExpanded$ = this.select('archiveNavSectionExpanded');
  /** Observable of archive results per page state. */
  archiveResultsPerPage$ = this.select('archiveResultsPerPage');
  /** Observable of the current Language. */
  currentLanguage$ = this.select('currentLanguage');
  /** Observable of dark mode enabled state. */
  darkMode$: Observable<boolean>;
  /** Observable of double click to open docs enabled state. */
  doubleClickToOpenDocuments$ = this.select('doubleClickOpen');
  /** Observable of enabled task searches state. */
  enabledTaskSearches$ = this.select('enabledTasksList');
  /** Observable of whether the favorite search navigation section is expanded. */
  favoriteSearchNavSectionExpanded$ = this.select('favoriteSearchesExpanded');
  /** Observable of GlobalSerach Extensions configuration. */
  gseConfig$ = this.select('gseApiConfig');
  /** Observable of whether to hide archives with no results in a multi-archive search. */
  hideArchivesWithNoResults$ = this.select('hideArchivesWithNoResults');
  /** Observable of whether the inbox navigation section is expanded. */
  inboxNavSectionExpanded$ = this.select('inboxNavSectionExpanded');
  /** Observable of whether the indexer sidebar should be open. */
  indexerSidebarOpen$ = this.select('indexerSidebarOpen');
  /** Observable of whether to reload fields on save. */
  indexerReloadAfterSave$ = this.select('indexerReloadAfterSave');
  /** Observable instance URL. */
  instanceUrl$ = this.select('instanceUrl');
  /** Observable of whether to use the local ocr keyfree. */
  keyfreeUseOcr$ = this.select('keyfreeUseOcr');
  /** Observable of whether the left sidebar navigation section is hidden. */
  leftSidebarNavHidden$ = this.select('leftSidebarNavHidden');
  /** Obserable of system notifications enabled state. */
  notifications$ = this.select('notifications');
  /**
   * Observable of the open document.
   * @todo this is currently only set via `ApplicationService.setOpenDocument()` when "preview" is used.
   */
  openDocument$ = this.select('openDocument');
  /** Observable of the PDF Preview URL. */
  pdfPreviewUrl$ = this.select('pdfPreviewUrl');
  /** Observable of the visibility of the right sidebar state. */
  rightSidebarOpen$ = this.select('rightSidebarOpen');
  /** Observable of search count enabled. */
  searchCountEnabled$ = this.select('searchCountEnabled');
  /** Observable of the database ID for which the search store is currently loaded. */
  searchStoreDatabaseId$ = this.select('searchStoreDatabaseId');
  /** Observable of show domain in history results state. */
  showDomainInHistoryResults$ = this.select('showDomainInHistoryResults');
  /** Observable of the show favorite searches state. */
  showFavoriteSearches$ = this.select('showFavoriteSearches');
  /** Observable of whether to show versions archive. */
  showVersionsArchive$ = this.select('showVersionsArchive');
  /** Observable of whether to sort results archives by results count. */
  sortResultsArchivesByResultsCount$ = this.select(
    'sortResultsArchivesByResultsCount'
  );
  /** Observable of whether the task search section is expanded. */
  taskSearchSectionExpanded$ = this.select('taskSearchSectionExpanded');
  /** Observable theme mode. */
  themeMode$ = this.select('themeMode');
  /** Observable of authenticated users token. */
  token$ = this.select((state) => state.user?.token);
  /** Obserable of authenticated user. */
  user$ = this.select('user');
  /** Observable of if a user is currently logged in. */
  userLoggedIn$ = this.user$.pipe(distinctUntilChanged(), filterNilValue());
  /** Observable of whether the internal viewer should automatically save changes. */
  viewerAutoSave$ = this.select('viewerAutoSave');
  /** Observable of whether to always load the first page on viewer load. */
  viewerGoToFirstPageOnLoad$ = this.select('viewerGoToFirstPageOnLoad');
  /** Observable of whether to use the internal viewer. */
  viewerUseInternal$ = this.select('viewerUseInternal');

  constructor(
    private logger: NGXLogger,
    protected store: ApplicationStore
  ) {
    super(store);

    this.createDarkModeListener();
  }

  /**
   * Get setting for if a documents should always be opened in a new tab.
   *
   * @returns True if documents should always open in a new tab.
   */
  get alwaysOpenNewTab(): boolean {
    return this.getValue().alwaysOpenNewTab;
  }

  /**
   * Get the Square9 API URL.
   *
   * @returns A string representing the Square 9 API URL.
   */
  get apiUrl(): string {
    return `${this.instanceUrl}/square9api/api`;
  }

  /**
   * Gets the current API version.
   *
   * @returns An API version string.
   */
  get apiVersion(): string {
    return this.getValue().apiVersion;
  }

  /**
   * Get the archive results per page setting.
   *
   * @returns A number.
   */
  get archiveResultsPerPage(): number {
    return this.getValue().archiveResultsPerPage;
  }

  /**
   * Get the GSE API config.
   *
   * @returns A GSE API Config.
   */
  get gseConfig(): GseApiConfig {
    return this.getValue().gseApiConfig;
  }

  /**
   * Get the base URL for the instance being used.
   *
   * @returns A string represeting the base URL.
   */
  get instanceUrl(): string {
    return this.getValue().instanceUrl;
  }

  /**
   * Is search count enabled.
   */
  get isSearchCountEnabled(): boolean {
    return this.getValue().searchCountEnabled;
  }

  /**
   * Is the user a readonly user.
   *
   * @returns True if the user is readonly. False if the user is not readonly or if no user is logged in.
   */
  get isReadOnlyLicense(): boolean {
    return this.user?.isReadOnly ?? false;
  }

  /**
   * Get setting for if double clicking a row should open a document.
   *
   * @returns True if documents should be opened by double clicking a row.
   */
  get doubleClickToOpenDocuments(): boolean {
    return this.getValue().doubleClickOpen;
  }

  /**
   * Get the delete search document on insert option.
   *
   * @returns True if the search document should be deleted during the insert.
   */
  get deleteSearchDocumentOnInsert(): boolean {
    return this.getValue().deleteSearchDocumentsOnInsert;
  }

  /**
   * Get the delete inbox document on insert option.
   *
   * @returns True if the inbox document should be deleted during the insert.
   */
  get deleteInboxDocumentOnInsert(): boolean {
    return this.getValue().deleteInboxDocumentOnInsert;
  }

  /**
   * Get the state of dxc append to multivalue.
   *
   * @returns True if dxc append to multivalue is enabled.
   */
  get dxcAppendToMultivalue(): boolean {
    return this.getValue().dxcAppendToMultivalue;
  }

  /**
   * Get the state of fxc search system, fields.
   *
   * @returns True if dxc search system fields is enabled.
   */
  get dxcSearchSystemFields(): boolean {
    return this.getValue().dxcSearchSystemFields;
  }

  /**
   * Is the indexer sidebar open.
   *
   * @returns True if the indexer sidebar is open.
   */
  get indexerSidebarOpen(): boolean {
    return this.getValue().indexerSidebarOpen;
  }

  /**
   * Get the keep base search document on insert option.
   *
   * @returns True if the search document should be kept during the insert.
   */
  get keepBaseSearchDocumentOnInsert(): boolean {
    return this.getValue().keepBaseSearchDocumentOnInsert;
  }

  /**
   * Get the state of system notifications.
   *
   * @returns True if enabled.
   */
  get notifications(): boolean {
    return this.getValue().notifications;
  }

  get openDocument(): OpenDocumentSource | undefined {
    return this.getValue().openDocument;
  }

  /**
   * Gets the state of persisting archive import data.
   *
   * @returns True if enabled.
   */
  get persistArchiveImportData(): boolean {
    return this.getValue().persistArchiveImportData;
  }

  /**
   * Get the PDF Preview URL.
   *
   * @returns Current value of the PDF Preview URL.
   */
  get pdfPreviewUrl(): string {
    return this.getValue().pdfPreviewUrl;
  }

  /**
   * Get the refreshResultsWhenClosingDocumentTabs setting.
   *
   * @returns True if enabled.
   */
  get refreshResultsWhenClosingDocumentTabs(): boolean {
    return this.getValue().refreshResultsWhenClosingDocumentTabs;
  }

  /**
   * Get the state of whether to show versions archive.
   *
   * @returns True if the versions archive should be shown.
   */
  get showVersionsArchive(): boolean {
    return this.getValue().showVersionsArchive;
  }

  /**
   * Get the theme mode in use.
   *
   * @returns Current value of the theme mode.
   */
  get themeMode(): ThemeMode {
    return this.getValue().themeMode;
  }

  /**
   * Whether to use previous search criteria in related search.
   *
   * @returns True if the setting is enabled.
   */
  get usePreviousSearchCriteriaInRelatedSearch(): boolean {
    return this.getValue().usePreviousSearchCriteriaInRelatedSearch;
  }

  /**
   * Get the currently logged in user.
   *
   * @returns A user or undefined if there is no user.
   */
  get user(): User | undefined {
    return this.getValue().user;
  }

  /**
   * Gets whether the internal viewer should automatically save changes.
   *
   * @returns True if the internal viewer should automatically save changes.
   */
  get viewerAutoSave(): boolean {
    return this.getValue().viewerAutoSave;
  }

  /**
   * Get the current value of if the internal viewer should be used.
   *
   * @returns True if it should be used.
   */
  get viewerUseInternal(): boolean {
    return this.getValue().viewerUseInternal;
  }

  /**
   * Gets the current value of if the internal viewer should be used for imports.
   *
   * @returns True if the internal viewer should be used for import.
   */
  get viewerUseInternalForImport(): boolean {
    return this.getValue().viewerUseInternalForImport;
  }

  /**
   * Get favorited search.
   *
   * @param databaseId Database ID.
   * @param searchId Search ID.
   * @returns The user provided search label for the favorite.
   */
  getFavoritedSearchById(databaseId: string, searchId: string): FavoriteSearch {
    return this.getFavoritedSearches(databaseId)[searchId];
  }

  /**
   * Get favorited searches.
   *
   * @param databaseId Database ID.
   * @returns A sparse array of favorited searches.
   */
  getFavoritedSearches(databaseId: string): FavoriteDatabaseSearches {
    return this.getValue().favoritedSearches[databaseId] ?? {};
  }

  /**
   * Check if a search is favorited.
   *
   * @param databaseId Database ID.
   * @param searchId Search ID.
   * @returns If the search is favorited.
   */
  isFavoritedSearch(databaseId: string, searchId: string): boolean {
    return !!this.getFavoritedSearchById(databaseId, searchId);
  }

  /**
   * Adds the dark mode observable.
   */
  private createDarkModeListener() {
    const darkModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    const systemDarkModeEnabled$ = fromEvent<MediaQueryList>(
      darkModeMediaQuery,
      'change'
    ).pipe(
      startWith(darkModeMediaQuery), // Ensure we get the initial value.
      map((mediaQueryList) => mediaQueryList.matches)
    );

    this.darkMode$ = combineLatest([
      this.themeMode$,
      systemDarkModeEnabled$,
    ]).pipe(
      map(([themeMode, systemDarkModeEnabled]) => {
        this.logger.debug(
          'Dark mode query map hit',
          themeMode,
          systemDarkModeEnabled
        );
        if (themeMode === ThemeMode.system) {
          return systemDarkModeEnabled;
        }

        return themeMode === ThemeMode.dark ? true : false;
      })
    );
  }
}
