import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NGXLogger } from 'ngx-logger';

import { TableFieldUIService } from 'src/app/services/table-field-ui.service';

import { TableFieldFieldCellRendererParameters } from '../table-field-cell-renderer-parameters';

/** Table field cell renderer. */
@Component({
  selector: 'app-table-field-cell-renderer',
  templateUrl: './table-field-cell-renderer.component.html',
  styleUrls: ['./table-field-cell-renderer.component.scss'],
  standalone: false,
})
export class TableFieldCellRendererComponent
  implements ICellRendererAngularComp
{
  /** Table field cell parameters. */
  cellParams: TableFieldFieldCellRendererParameters;

  constructor(
    private logger: NGXLogger,
    private tableFieldUIService: TableFieldUIService
  ) {}

  /** @inheritdoc */
  agInit(params: TableFieldFieldCellRendererParameters): void {
    this.cellParams = params;
  }

  /** @inheritdoc */
  refresh(params: TableFieldFieldCellRendererParameters): boolean {
    this.cellParams = params;
    return true;
  }
}
