import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Signal,
  ViewChild,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  GridOptions,
  ITooltipParams,
  RowDoubleClickedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { assert, assertExists, assertTypeByKey } from 'common';
import { Archive, FieldDataType } from 'models';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { derivedAsync } from 'ngxtension/derived-async';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { map, of, tap } from 'rxjs';
import { DateAgoPipe } from 'src/app/common/date-ago.pipe';
import { isSameMoment } from 'src/app/common/utility';
import {
  NewPdfDocumentService,
  NewPdfDocumentType,
} from 'src/app/services/new-documents.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import {
  getCellEditor,
  getCellRenderer,
  getFieldCellEditorProperties,
} from '../grid-cell-components/cell-selectors';
import { NewDocumentPreviewCellRendererComponent } from '../new-document-preview-cell-renderer/new-document-preview-cell-renderer.component';

@Component({
  selector: 'app-new-documents-dialog',
  templateUrl: './new-documents-dialog.component.html',
  styleUrls: ['./new-documents-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NewDocumentsDialogComponent {
  @ViewChild('grid')
  grid: AgGridAngular<NewPdfDocumentType>;
  /** Archives with new documents. */
  archivesWithNewDocuments: Signal<Archive[]>;
  /** Pdf bytes. */
  pdfBytes = signal<Uint8Array | undefined>(undefined);
  /** Grid configuration. */
  gridOptions: GridOptions<NewPdfDocumentType>;
  /** Array of documents. */
  newDocuments: Signal<NewPdfDocumentType[]>;
  /** The archive for which new documents are currently being viewed. */
  selectedArchive = signal<Archive | undefined>(undefined);
  /** Selected rows. */
  selectedRows = signal<NewPdfDocumentType[]>([]);

  private archivesQuery = inject(ArchivesQuery);
  private databasesQuery = inject(DatabasesQuery);
  private injector = inject(Injector);
  private logger = inject(NGXLogger);
  private newDocumentsService = inject(NewPdfDocumentService);
  private router = inject(Router);
  private translateService = inject(TranslocoService);

  private documentBeingPreviewed: NewPdfDocumentType | undefined;

  constructor() {
    this.newDocuments = derivedAsync(
      () => {
        const selectedArchive = this.selectedArchive();
        if (!selectedArchive) {
          return of([]);
        }

        return this.newDocumentsService
          .observeAllForArchive$(
            this.databasesQuery.activeId,
            selectedArchive.id
          )
          .pipe(
            tap((documents) => {
              this.logger.debug('New documents loaded.', documents);
            })
          );
      },
      { initialValue: [], injector: this.injector }
    );

    this.archivesWithNewDocuments = toSignal(
      this.newDocumentsService
        .observeAllArchiveIds$(this.databasesQuery.activeId)
        .pipe(
          map(
            (archiveIds) =>
              archiveIds.map((archiveId) =>
                this.archivesQuery.getArchive(archiveId)
              ),
            tap((archiveIds) => {
              this.logger.debug('Archive ids reloaded.', archiveIds);
            })
          )
        ),
      {
        initialValue: [],
        injector: this.injector,
      }
    );

    explicitEffect([this.archivesWithNewDocuments], ([archives]) => {
      if (archives.length === 0) {
        this.selectedArchive.set(undefined);
        return;
      }

      const selectedArchive = this.selectedArchive();
      if (
        !selectedArchive ||
        !archives.some((a) => a.id === selectedArchive.id)
      ) {
        // Either there is no selected archive or the previously selected archive no longer has new documents.
        this.selectedArchive.set(archives[0]);
      }
    });
    this.configureGrid();
  }

  /**
   * Handler for the mat-select change event.
   *
   * @param event Selection change event.
   */
  onArchiveSelectionChange(event: MatSelectChange): void {
    assertTypeByKey<Archive>(event.value, 'id', 'number');
    this.selectedArchive.set(event.value);
  }

  /** Handler for the close preview button click event. */
  onClickClosePreview(): void {
    this.clearActivePreview();
  }

  /** Handler for the delete button click event. */
  onClickDelete(): void {
    const selectedDocuments = this.selectedRows();
    this.logger.debug('Deleting selected documents', selectedDocuments);
    if (
      selectedDocuments.some(
        (document) => document.id === this.documentBeingPreviewed?.id
      )
    ) {
      this.logger.debug(
        'Document being previewed is being deleted. Closing the preview.'
      );
      this.clearActivePreview();
    }
    this.newDocumentsService
      .delete(selectedDocuments.map((document) => document.id))
      .subscribe();
  }

  /** Handler for the open button click event. */
  onClickOpen(): void {
    const selectedDocuments = this.selectedRows();
    this.logger.debug(
      'Open button clicked with selected documents.',
      selectedDocuments
    );
    assert(
      selectedDocuments.length > 0,
      'There must be at least one document selected.'
    );
    const firstDocument = selectedDocuments[0];
    this.logger.debug(
      'Opening the new document view with at first document.',
      firstDocument
    );
    this.openDocument(firstDocument);
  }

  private clearActivePreview(): void {
    this.documentBeingPreviewed = undefined;
    this.pdfBytes.set(undefined);
  }

  private configureGrid(): void {
    this.gridOptions = {
      rowSelection: {
        mode: 'multiRow',
      },
      singleClickEdit: true,
      tooltipShowDelay: 1000,
      pagination: true,
      columnDefs: [],
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      getRowId: (params) => params.data.id.toString(),
      onSelectionChanged: (event) => {
        const selectedRows = event.api.getSelectedRows();
        this.selectedRows.set(selectedRows);
      },
      onRowDoubleClicked: (event) => this.onRowDoubleClicked(event),
      onCellValueChanged: (
        event: CellValueChangedEvent<NewPdfDocumentType>
      ) => {
        this.logger.debug('Cell value change event fired.', event);
        this.newDocumentsService
          .updateDocumentRecord(
            event.data.id,
            event.data.pageCount,
            event.data.targetArchiveId,
            event.data.targetDatabaseId,
            event.data.fields
          )
          .subscribe(() => {
            this.logger.debug('Values saved to database.');
          });
      },
      onGridReady: () => {
        this.loadArchiveColumns(1);
        effect(
          () => {
            const selectedArchive = this.selectedArchive();
            if (!selectedArchive) return;
            this.loadArchiveColumns(selectedArchive.id);
          },
          { injector: this.injector }
        );
        effect(
          () => {
            const newDocuments = this.newDocuments();
            this.grid.api.setGridOption('rowData', newDocuments);
          },
          { injector: this.injector }
        );
      },
    };
  }

  private loadArchiveColumns(archiveId: number): void {
    const archiveFields = this.archivesQuery.getFields(archiveId);
    const archiveTableFields = this.archivesQuery.getTableFields(archiveId);

    const columns: ColDef[] = archiveFields
      .filter(
        (field) =>
          // Field is not a LiveField.
          !field.liveField &&
          // Field is not in a table field.
          !archiveTableFields.some((tableField) =>
            tableField.fieldIds.includes(field.id)
          )
      )
      .map((field) => ({
        field: `field_${field.id}`,
        headerName: field.name,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params: ValueGetterParams<NewPdfDocumentType>) => {
          const fieldValue = params.data?.fields.find((f) => f.id === field.id);
          if (!fieldValue) {
            this.logger.warn(
              'The search result for the document does not contain a value for the field.',
              field
            );
            return;
          }
          this.logger.debug(`${field.name} value: `, fieldValue);
          return field.multiValue
            ? fieldValue.multiValue.join(', ')
            : fieldValue.value;
        },
        valueSetter: (params: ValueSetterParams<NewPdfDocumentType>) => {
          const fieldValue = params.data.fields.find((f) => f.id === field.id);
          if (!fieldValue) return false;
          if (field.multiValue) {
            fieldValue.multiValue = params.newValue.split(', ');
          } else {
            fieldValue.value = params.newValue;
          }
          return true;
        },
        editable: (params: EditableCallbackParams<NewPdfDocumentType>) => {
          const archive = this.selectedArchive();
          assertExists(archive);
          return (
            archive.permissions.modifyData &&
            field.systemField === '' &&
            !field.multiValue
          );
        },
        cellEditor: getCellEditor(field),
        cellEditorParams: getFieldCellEditorProperties(field),
        // mv cell renderer and dependent dialog can't work with a document not already imported
        cellRenderer: field.multiValue ? undefined : getCellRenderer(field),
        cellRendererParams: {
          format: field.format,
          type: field.type,
        },
        comparator: (valueA, valueB) => {
          if (field.type === 2 || field.type === 4) {
            return valueA - valueB;
          }
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        equals:
          field.type === FieldDataType.date
            ? (oldValue, newValue) => isSameMoment(oldValue, newValue)
            : undefined,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        showDisabledCheckboxes: false,
      }));

    columns.unshift(
      {
        field: `thumbnail-button`,
        headerName: 'Preview',
        sortable: false,
        filter: false,
        resizable: true,
        cellRenderer: NewDocumentPreviewCellRendererComponent,
        cellRendererParams: {
          previewOpen: (document: NewPdfDocumentType) =>
            document.id === this.documentBeingPreviewed?.id,
          previewClickedCallback: async (document: NewPdfDocumentType) => {
            this.documentBeingPreviewed = document;
            // Clear the bytes before setting so that the viewer reloads.
            this.pdfBytes.set(undefined);
            this.newDocumentsService
              .getPdfAttachmentBytes(document.id)
              .subscribe((pdfBytes) => {
                this.pdfBytes.set(pdfBytes);
              });
          },
        },
      },
      {
        field: 'last-modified-date',
        headerName: this.translateService.translate('LAST_MODIFIED'), // TODO should this be translated?
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params: ValueGetterParams<NewPdfDocumentType>) => {
          assertExists(params.data, 'Row data must be defined.');
          const dateAgoPipe = new DateAgoPipe();
          return dateAgoPipe.transform(params.data.lastModifiedDate);
        },
        tooltipValueGetter: (params: ITooltipParams<NewPdfDocumentType>) => {
          assertExists(params.data, 'Row data must be defined.');
          const lastModifiedMoment = moment(params.data.lastModifiedDate);
          return lastModifiedMoment.toLocaleString();
        },
      },
      {
        field: 'page-count',
        headerName: this.translateService.translate('PAGE_COUNT'), // TODO should this be translated?
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params: ValueGetterParams<NewPdfDocumentType>) => {
          assertExists(params.data, 'Row data must be defined.');
          return params.data.pageCount;
        },
      }
    );

    this.grid.api.updateGridOptions({ columnDefs: columns });
  }

  private onRowDoubleClicked(event: RowDoubleClickedEvent<NewPdfDocumentType>) {
    this.logger.debug('Row double clicked.', event);
    assertExists(event.data, 'No document data was provided for double click.');
    this.openDocument(event.data);
  }

  private openDocument(document: NewPdfDocumentType) {
    const urlTree = this.router.createUrlTree([
      'db',
      document.targetDatabaseId,
      'new',
      document.id,
    ]);
    window.open(urlTree.toString(), '_blank');
  }
}
