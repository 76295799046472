import { Pipe, PipeTransform } from '@angular/core';

import { Command } from '../models/command';

/**
 * Limit to commands containing the queried command, and a max of 10.
 */
@Pipe({
  name: 'matchesCommand',
  standalone: false,
})
export class MatchesCommandPipe implements PipeTransform {
  /** @inheritdoc */
  transform(commands: Command[], query: string): Array<any> {
    const matches = commands.filter((command: Command) => {
      let searchQuery = query;
      if (command.filterPrefix) {
        if (!query.startsWith(command.filterPrefix)) return false;

        searchQuery = query.slice(1);
      }

      return new RegExp(searchQuery, 'gi').test(command.name);
    });
    matches.length = Math.min(matches.length, 10);
    return matches;
  }
}
