import { getOperator, initOptions } from "./core";
import { Cursor } from "./cursor";
import { assert, cloneDeep, isObject, isOperator, normalize } from "./util";
const TOP_LEVEL_OPS = new Set(Array.from(["$and", "$or", "$nor", "$expr", "$jsonSchema"]));
class Query {
  #compiled;
  #options;
  #condition;
  constructor(condition, options) {
    this.#condition = cloneDeep(condition);
    this.#options = initOptions(options);
    this.#compiled = [];
    this.compile();
  }
  compile() {
    assert(isObject(this.#condition), `query criteria must be an object: ${JSON.stringify(this.#condition)}`);
    const whereOperator = {};
    for (const [field, expr] of Object.entries(this.#condition)) {
      if ("$where" === field) {
        assert(this.#options.scriptEnabled, "$where operator requires 'scriptEnabled' option to be true.");
        Object.assign(whereOperator, {
          field,
          expr
        });
      } else if (TOP_LEVEL_OPS.has(field)) {
        this.processOperator(field, field, expr);
      } else {
        assert(!isOperator(field), `unknown top level operator: ${field}`);
        for (const [operator, val] of Object.entries(normalize(expr))) {
          this.processOperator(field, operator, val);
        }
      }
      if (whereOperator.field) {
        this.processOperator(whereOperator.field, whereOperator.field, whereOperator.expr);
      }
    }
  }
  processOperator(field, operator, value) {
    const call = getOperator("query", operator, this.#options);
    assert(!!call, `unknown query operator ${operator}`);
    this.#compiled.push(call(field, value, this.#options));
  }
  /**
   * Checks if the object passes the query criteria. Returns true if so, false otherwise.
   *
   * @param obj The object to test
   * @returns {boolean}
   */
  test(obj) {
    return this.#compiled.every(p => p(obj));
  }
  /**
   * Returns a cursor to select matching documents from the input source.
   *
   * @param source A source providing a sequence of documents
   * @param projection An optional projection criteria
   * @returns {Cursor} A Cursor for iterating over the results
   */
  find(collection, projection) {
    return new Cursor(collection, o => this.test(o), projection || {}, this.#options);
  }
  /**
   * Remove matched documents from the collection returning the remainder
   *
   * @param collection An array of documents
   * @returns {Array} A new array with matching elements removed
   */
  remove(collection) {
    return collection.reduce((acc, obj) => {
      if (!this.test(obj)) acc.push(obj);
      return acc;
    }, []);
  }
}
export { Query };