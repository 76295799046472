import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { PdfJsViewerModule } from '@kariudo/ng2-pdfjs-viewer';
import { HotkeysModule } from '@ngneat/hotkeys';
import { PdfViewerModule as Ng2PdfViewerModule } from 'ng2-pdf-viewer';
import { LoggerModule } from 'ngx-logger';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconButtonSizesModule } from 'mat-icon-button-sizes';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FindInFileDialogComponent } from './components/find-in-file-dialog/find-in-file-dialog.component';
import { PartialLoadSnackbarComponent } from './components/partial-load-snackbar/partial-load-snackbar.component';
import { PdfThumbnailViewerComponent } from './components/pdf-thumbnail-viewer/pdf-thumbnail-viewer.component';
import { PdfThumbnailerContextMenuComponent } from './components/pdf-thumbnailer-context-menu/pdf-thumbnailer-context-menu.component';
import { PdfViewerComponent } from './components/pdf-viewer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

@NgModule({
  declarations: [
    PdfViewerComponent,
    PdfThumbnailViewerComponent,
    FindInFileDialogComponent,
    ToolbarComponent,
    PartialLoadSnackbarComponent,
    ConfirmationDialogComponent,
    PdfThumbnailerContextMenuComponent,
  ],
  imports: [
    CommonModule,
    Ng2PdfViewerModule,
    HotkeysModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    TranslocoModule,
    FormsModule,
    LoggerModule.forChild(),
    PdfJsViewerModule,
    ScrollingModule,
    MatIconButtonSizesModule,
    MatMenuModule,
    DragDropModule,
  ],
  exports: [
    PdfViewerComponent,
    PdfThumbnailViewerComponent,
    FindInFileDialogComponent,
  ],
})
export class PdfViewerModule {}
