import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { assertExists } from 'common';
import { NewPdfDocumentType } from 'src/app/services/new-documents.service';

export interface NewDocumentPreviewCellRendererParams
  extends ICellRendererParams<NewPdfDocumentType> {
  previewClickedCallback: (document: NewPdfDocumentType) => void;
  previewOpen: (document: NewPdfDocumentType) => boolean;
}

@Component({
  selector: 'new-document-preview-cell-renderer',
  templateUrl: './new-document-preview-cell-renderer.component.html',
  styleUrl: './new-document-preview-cell-renderer.component.scss',
  standalone: false,
})
export class NewDocumentPreviewCellRendererComponent
  implements ICellRendererAngularComp
{
  private cellParams: NewDocumentPreviewCellRendererParams;

  /**
   * Checks if the document is currently being previewed.
   *
   * @returns True if the document is currently being previewed.
   */
  get previewOpen(): boolean {
    if (!this.cellParams.data) {
      return false;
    }
    return this.cellParams.previewOpen(this.cellParams.data);
  }

  /** @inheritdoc */
  agInit(params: NewDocumentPreviewCellRendererParams): void {
    this.cellParams = params;
  }

  /** Handler for the click preview event. */
  onClickPreview(): void {
    assertExists(this.cellParams.data);
    this.cellParams.previewClickedCallback(this.cellParams.data);
    this.cellParams.api.setNodesSelected({
      nodes: [this.cellParams.node],
      newValue: true,
    });
  }

  /** @inheritdoc */
  refresh(params: NewDocumentPreviewCellRendererParams): boolean {
    this.cellParams = params;
    return true;
  }
}
