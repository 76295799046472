import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ColumnState } from 'ag-grid-community';
import {
  TableFieldGridColumnState,
  TableFieldGridStateStore,
} from './table-field-grid-states.store';

/** Table field grid state query. */
@Injectable({
  providedIn: 'root',
})
export class TableFieldGridStatesQuery extends QueryEntity<TableFieldGridColumnState> {
  constructor(protected store: TableFieldGridStateStore) {
    super(store);
  }

  /**
   * Gets the stored column states for a table field grid.
   *
   * @param databaseId Database id.
   * @param archiveId Archive id.
   * @param tableFieldId Table field id.
   * @returns An array of column states.
   */
  getTableFieldGridColumnStates(
    databaseId: string,
    archiveId: string,
    tableFieldId: string
  ): ColumnState[] {
    const storedGridStates = this.getTableFieldGridState(
      databaseId,
      archiveId,
      tableFieldId
    );
    return storedGridStates?.columnStates ?? [];
  }

  /**
   * Gets the stored table field grid state.
   * @param databaseId Database id.
   * @param archiveId Archive id.
   * @param tableFieldId Table field id.
   * @returns A table field grid state.
   */
  getTableFieldGridState(
    databaseId: string,
    archiveId: string,
    tableFieldId: string
  ) {
    const storedGridState = this.getEntity(
      `${databaseId}_${archiveId}_${tableFieldId}`
    );
    return storedGridState;
  }

  /**
   * Checks if the store contains the table field grid for the archive.
   * @param databaseId Database id.
   * @param archiveId Archive id.
   * @param tableFieldId Table field id.
   * @returns True if there is a stored table field grid state for the table field in the archive.
   */
  tableFieldGridHasState(
    databaseId: string,
    archiveId: string,
    tableFieldId: string
  ): boolean {
    return this.hasEntity(`${databaseId}_${archiveId}_${tableFieldId}`);
  }
}
