import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NGXLogger } from 'ngx-logger';

import { assertExists } from 'common';
import { FieldDataType } from 'models';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { DateFormatsService } from 'src/app/state/date-formats/date-formats.service';

import { FieldCellRendererParameters } from '../field-cell-renderer-parameters';
import {
  MultiValueDialogData,
  MultiValueDialogResult,
  MultiValueEditorDialogComponent,
} from '../multi-value-editor-dialog/multi-value-editor-dialog.component';

/** MultiValue Cell Renderer. */
@Component({
  selector: 'app-multi-value-cell-renderer',
  templateUrl: './multi-value-cell-renderer.component.html',
  styleUrls: ['./multi-value-cell-renderer.component.scss'],
  standalone: false,
})
export class MultiValueCellRendererComponent
  implements ICellRendererAngularComp
{
  /** Displayed cell value. */
  displayValue: string;

  private cellParams: FieldCellRendererParameters;
  private momentFormat: string;

  constructor(
    private logger: NGXLogger,
    private gridHelper: GridHelperService,
    private dateFormatService: DateFormatsService,
    private dialog: MatDialog
  ) {}

  /** @inheritdoc */
  agInit(params: FieldCellRendererParameters): void {
    this.cellParams = params;
    this.displayValue = this.getValue(params);
    if (params.type === FieldDataType.date && params.format) {
      this.dateFormatService.get(params.format).subscribe((momentFormat) => {
        this.momentFormat = momentFormat;
        this.displayValue = this.getValue(params);
      });
    }
  }

  /**
   * Event handler for the edit click.
   */
  onClickEdit(): void {
    this.logger.debug('Edit button clicked');
    const fieldIdString = this.cellParams.colDef?.field?.split('_')[1];
    assertExists(fieldIdString);
    const fieldId = Number(fieldIdString);
    const dialogData: MultiValueDialogData = {
      documentId: this.cellParams.data.id,
      documentPermissions: this.cellParams.data.permissions,
      documentSecureId: this.cellParams.data.secureId,
      fieldValue: {
        id: fieldId,
        value: '',
        multiValue: this.displayValue.split(', '),
      },
    };

    const dialog = this.dialog.open(MultiValueEditorDialogComponent, {
      data: dialogData,
    });

    dialog.afterClosed().subscribe((result: MultiValueDialogResult) => {
      this.logger.debug('MV dialog closed', result);
      if (!result) {
        this.logger.debug('MV dialog was cancelled.');
        return;
      }
      assertExists(this.cellParams.column);
      this.cellParams.node.setDataValue(
        this.cellParams.column,
        result.fieldValue.multiValue.join(', ')
      );
    });
  }

  /** @inheritdoc */
  refresh(params: FieldCellRendererParameters): boolean {
    this.cellParams = params;
    this.displayValue = this.getValue(params);
    return true;
  }

  private getValue(params: FieldCellRendererParameters): string {
    let values: string[] =
      (params.valueFormatted ? params.valueFormatted : params.value)?.split(
        ','
      ) ?? [];

    if (params.type === FieldDataType.date) {
      values = values.map((value) =>
        this.gridHelper.getDateTimeCellValue(value, this.momentFormat)
      );
    } else if (params.type === FieldDataType.decimal) {
      values = values.map((value) =>
        this.gridHelper.getDecimalCellValue(value, params.format)
      );
    }

    return values.join(',');
  }
}
