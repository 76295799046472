import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { assertExists } from 'common';
import { FieldValue } from 'models';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  MigrationStrategies,
  RxCollection,
  RxDatabase,
  RxDocument,
  RxJsonSchema,
  addRxPlugin,
  createBlob,
  createRxDatabase,
  toTypedRxJsonSchema,
} from 'rxdb';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBMigrationSchemaPlugin } from 'rxdb/plugins/migration-schema';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import {
  EMPTY,
  Observable,
  defer,
  from,
  map,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { v4 as uuid } from 'uuid';
import { NewDocumentsDialogComponent } from '../components/new-documents-dialog/new-documents-dialog.component';

// This plugin is required for cleanup.
addRxPlugin(RxDBLeaderElectionPlugin);
// Adds cleanup functionality.
addRxPlugin(RxDBCleanupPlugin);
// This plugin is required for linq style queries.
addRxPlugin(RxDBQueryBuilderPlugin);
// Adds support for storing our documents as attachments.
addRxPlugin(RxDBAttachmentsPlugin);
// Adds support for upgrading the database schema.
addRxPlugin(RxDBMigrationSchemaPlugin);

/**
 * WARNING
 * If you change this schema in ANY way you MUST increment the version number and potentially add a migration to the migrationStrategies function below.
 *
 * Failing to do so will result in database errors and/or the potential for data loss in the database.
 */

/**
 * This is the schema object for rxdb. See https://rxdb.info/rx-schema.html.
 */
const NewPdfDocumentSchema = {
  version: 1, // If ANYTHING is changed in this schema you must increment this version number.
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100, // <- the primary key must have set maxLength,
    },
    lastModifiedDate: {
      type: 'string',
      format: 'date-time',
    },
    pageCount: {
      type: 'number',
    },
    targetArchiveId: {
      type: 'number',
    },
    targetDatabaseId: {
      type: 'number',
    },
    fields: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'number',
          },
          value: {
            type: 'string',
          },
          multiValue: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
        required: ['id', 'value', 'multiValue'],
      },
    },
  },
  attachments: {
    encrypted: false, // if true, the attachment-data will be encrypted with the db-password
  },
  required: [
    'id',
    'lastModifiedDate',
    'pageCount',
    'targetArchiveId',
    'targetDatabaseId',
    'fields',
  ],
} as const;

/**
 * Describes the functions available on the rxdb document record itself.
 *
 * Note this cannot be an interface because it causes type errors from rxdb.
 */
type NewPdfDocumentFunctions = {
  /**
   * Gets an array of attachment filenames in the document record.
   *
   * @returns An array of attachment filenames.
   */
  getAttachments: () => string[];
  /**
   * Gets the requested attachment blob.
   *
   * @returns A blob of the requested attachment.
   * @throws If the filename is not found.
   */
  getAttachmentBlob: (filename: string) => Observable<Blob>;
  /**
   * Gets the pdf attachment blob for the record.
   * @returns A blob of the pdf attachment.
   */
  getPdfAttachmentBlob: () => Observable<Blob>;
  /**
   * Gets an observable arrary of attachment filenames for the document.
   * @returns An observable array of attachment filenames for the document.
   */
  selectAttachments: () => Observable<string[]>;
};

/** The typed schema for rxdb. This is not used for anything else. */
const schemaTyped = toTypedRxJsonSchema(NewPdfDocumentSchema);

/**
 * Describes the new pdf document type.
 *
 * It is derived from the underlying typed schema.
 */
export type NewPdfDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>;

/**
 * Describes the fully typed rxdb document record.
 *
 * This type is used to implement the NewPdfDocumentFunctions.
 */
type NewPdfDocumentRecord = RxDocument<
  NewPdfDocumentType,
  NewPdfDocumentFunctions
>;

const newPdfDocumentRecordFunctions: NewPdfDocumentFunctions = {
  getAttachments: function (this: NewPdfDocumentRecord) {
    return this.allAttachments().map((attachment) => attachment.id);
  },
  getAttachmentBlob: function (this: NewPdfDocumentRecord, filename: string) {
    const attachment = this.getAttachment(filename);
    assertExists(attachment, `Attachment "${filename}" not found.`);
    return defer(() => attachment.getData());
  },
  getPdfAttachmentBlob: function (this: NewPdfDocumentRecord) {
    const pdfAttachment = this.getAttachment('globalsearch-new-document.pdf');
    assertExists(pdfAttachment, 'No PDF attachment found.');
    return defer(() => pdfAttachment.getData());
  },
  selectAttachments: function (this: NewPdfDocumentRecord) {
    return this.allAttachments$.pipe(
      map((attachments) => attachments.map((a) => a.id))
    );
  },
};

// create the typed RxJsonSchema from the literal typed object.
const newPdfDocumentSchema: RxJsonSchema<NewPdfDocumentType> =
  NewPdfDocumentSchema;

// and then merge all our types
export type NewDocumentCollection = RxCollection<
  NewPdfDocumentType,
  NewPdfDocumentFunctions
>;

/** Describes the GlobalSearch database collection. */
export type GlobalSearchDatabaseCollections = {
  newDocuments: NewDocumentCollection;
};

// Contains migrations that must run when the schema is changed. See https://rxdb.info/migration-schema.html#providing-strategies
const migrationStrategies: MigrationStrategies = {
  1: (oldDocumentRecord) => {
    oldDocumentRecord.pageCount = 0; // We can't go back and add page count for existing documents.
    oldDocumentRecord.creationDate = new Date().toISOString();
    return oldDocumentRecord;
  },
};

const INDEX_DATABASE_NAME = 'GlobalSearch';

@Injectable({ providedIn: 'root' })
export class NewPdfDocumentService {
  private database: RxDatabase<GlobalSearchDatabaseCollections>;
  private dialog = inject(MatDialog);
  private logger = inject(NGXLogger);
  private translateService = inject(TranslocoService);

  constructor() {}

  /**
   * Adds a new PDF document to the database.
   *
   * @param newDocumentBytes - The byte array of the new PDF document.
   * @param newDocumentPageCount - The number of pages in the new PDF document.
   * @param targetArchiveId - The ID of the target archive where the document should be stored.
   * @param targetDatabaseId - The ID of the target database where the document should be stored.
   * @param fields - Optional array of field values associated with the document.
   * @returns An observable that emits the ID of the newly created document.
   */
  addNewDocument(
    newDocumentBytes: Uint8Array,
    newDocumentPageCount: number,
    targetArchiveId: number,
    targetDatabaseId: number,
    fields?: FieldValue[]
  ): Observable<string> {
    const id = uuid();
    const newDocument: NewPdfDocumentType = {
      id,
      lastModifiedDate: moment().toISOString(),
      pageCount: newDocumentPageCount,
      targetArchiveId,
      targetDatabaseId,
      fields: fields || [],
    };

    return from(
      this.database.collections.newDocuments.insert(newDocument)
    ).pipe(
      switchMap((document) =>
        from(
          document.putAttachment({
            id: 'globalsearch-new-document.pdf',
            data: new Blob([newDocumentBytes], { type: 'application/pdf' }),
            type: 'application/pdf',
          })
        )
      ),
      map(() => id)
    );
  }

  /**
   * Removes documents from the database.
   *
   * @param ids New document ids to be removed.
   * @returns An observable array of docuemnt ids that were removed.
   */
  delete(ids: string[]): Observable<string[] | undefined> {
    return from(this.database.collections.newDocuments.bulkRemove(ids)).pipe(
      switchMap((result) => {
        const deletedDocumentIds: string[] = [];
        for (const document of result.success) {
          deletedDocumentIds.push(document.id);
        }

        // Forcing the cleanup of the database here is fine as is unless we decide to start replicating the local database to a remote
        return from(this.database.collections.newDocuments.cleanup(0)).pipe(
          map(() => deletedDocumentIds)
        );
      })
    );
  }

  /**
   * Gets the requested file from the database record.
   *
   * @param id New document id.
   * @param filename Filename for the attachment to be retrieved.
   * @returns An observable blob of the attachment.
   */
  getAttachmentBlob(id: string, filename: string) {
    return this.selectDocumentRecordbyId(id).pipe(
      switchMap((documentRecord) => documentRecord.getAttachmentBlob(filename))
    );
  }

  /**
   * Gets a blob of the pdf attachment from the database record.
   *
   * @param id New document id.
   * @returns An observable blob of the pdf attachment.
   */
  getPdfAttachmentBlob(id: string) {
    return this.selectDocumentRecordbyId(id).pipe(
      switchMap((documentRecord) => documentRecord.getPdfAttachmentBlob())
    );
  }

  /**
   * Gets a byte array of the pdf attachment from the database record.
   *
   * @param id New document id.
   * @returns An observable byte array of the pdf attachment.
   */
  getPdfAttachmentBytes(id: string) {
    return this.getPdfAttachmentBlob(id).pipe(
      switchMap((blob) => from(blob.arrayBuffer())),
      map((arrayBuffer) => new Uint8Array(arrayBuffer))
    );
  }

  /**
   * Initializes the database for use.
   *
   * This should never be used by anything other than the application initialization.
   *
   * @returns A promise that resolves when the database is initialized.
   */
  async initDatabase() {
    this.database = await createRxDatabase<GlobalSearchDatabaseCollections>({
      name: INDEX_DATABASE_NAME,
      storage: getRxStorageDexie(),
    });

    await this.database.addCollections({
      newDocuments: {
        schema: newPdfDocumentSchema,
        methods: newPdfDocumentRecordFunctions,
        migrationStrategies: migrationStrategies,
      },
    });
  }

  /**
   * Gets an observable that emits all new pdf document records.
   *
   * @returns An observable array of new pdf documents that should emit if any changes are made.
   */
  observeAll$(): Observable<NewPdfDocumentType[]> {
    const query = this.database.collections.newDocuments.find();
    return query.$.pipe(map((result) => result as NewPdfDocumentType[]));
  }

  /**
   * Gets an array of archive ids that are being used as targets for new pdf document records.
   *
   * @param databaseId Database id.
   * @returns An observable array of archive ids that are associated with new pdf documents currently stored.
   */
  observeAllArchiveIds$(databaseId: number): Observable<number[]> {
    const query = this.database.collections.newDocuments
      .find()
      .where('targetDatabaseId')
      .eq(databaseId);
    return query.$.pipe(
      map((documents) =>
        documents
          .map((d) => d.targetArchiveId)
          .filter((value, index, self) => self.indexOf(value) === index)
      )
    );
  }

  /**
   * Gets an observable that emits new pdf document records that target the provided database and archive id.
   *
   * @param databaseId Database id.
   * @param archiveId Archive Id.
   * @returns An observable array of new pdf documents that should emit if any changes are made.
   */
  observeAllForArchive$(
    databaseId: number,
    archiveId: number
  ): Observable<NewPdfDocumentType[]> {
    const query = this.database.collections.newDocuments
      .find()
      .where('targetDatabaseId')
      .eq(databaseId)
      .where('targetArchiveId')
      .eq(archiveId);
    return query.$;
  }

  /**
   * Observe all new documents targetting the provided database.
   *
   * @param databaseId Database id.
   * @returns An observable array of new documents that should update if changes are made to them in the database.
   */
  observeAllInDatabase$(databaseId: number): Observable<NewPdfDocumentType[]> {
    const query = this.database.collections.newDocuments
      .find()
      .where('targetDatabaseId')
      .eq(databaseId);
    return query.$;
  }

  /**
   * Observe the number of new documents targetting the provided database.
   *
   * @param databaseId Database id.
   * @returns An observable number of new documents targetting the provided database.
   */
  observeCountInDatabase$(databaseId: number): Observable<number> {
    /**
     * We get the new documents from the database and just return the count
     * as opposed to running a specific count query in rxdb because targetDb
     * isn't indexed which means the query wouldn't be any more efficient.
     */
    return this.observeAllInDatabase$(databaseId).pipe(
      map((documents) => documents.length)
    );
  }

  /**
   * Gets an observable for the provided document.
   *
   * @param id New document id.
   * @returns An observable new document that should update if changes are made to it in the database.
   */
  observeDocument$(id: string): Observable<NewPdfDocumentType> {
    return this.observeDocumentRecordById(id);
  }

  /**
   * Opens the new document dialog.
   */
  openNewDocumentDialog() {
    this.dialog.open(NewDocumentsDialogComponent, {
      minWidth: '80vw',
      height: '90vh',
    });
  }

  /**
   * Retrieves all PDF documents from the database.
   *
   * @returns An observable array of `NewPdfDocumentType` containing all documents.
   */
  /******  b6b81d73-3c0e-4ba7-9b54-bbf33484ceb7  *******/
  selectAll(): Observable<NewPdfDocumentType[]> {
    return from(this.database.collections.newDocuments.find().exec());
  }

  /**
   * Gets all attachments on a given document record.
   *
   * @param id New document id.
   * @returns An observable array of attachments.
   */
  selectAttachmentsById(id: string) {
    return this.database.collections.newDocuments.findOne(id).$.pipe(
      switchMap((documentRecord) => {
        if (!documentRecord) {
          return throwError(
            () => new Error(`Document with id ${id} not found`)
          );
        }

        return documentRecord.selectAttachments();
      })
    );
  }

  /**
   * Gets the requested document from the database record.
   *
   * @param id New document id.
   * @returns An observable new pdf document record.
   */
  selectById(id: string): Observable<NewPdfDocumentType> {
    return this.selectDocumentRecordbyId(id);
  }

  /**
   * Updates the provided new document record.
   *
   * @param id New document id.
   * @param newDocumentPageCount New document page count.
   * @param targetArchiveId Target archive id.
   * @param targetDatabaseId Target database id.
   * @param fields Optional array of field values.
   * @returns
   */
  updateDocumentRecord(
    id: string,
    newDocumentPageCount: number,
    targetArchiveId: number,
    targetDatabaseId: number,
    fields?: FieldValue[]
  ): Observable<NewPdfDocumentType> {
    return from(
      this.database.collections.newDocuments.findOne(id).modify((record) => {
        record.lastModifiedDate = moment().toISOString();
        record.pageCount = newDocumentPageCount;
        record.targetArchiveId = targetArchiveId;
        record.targetDatabaseId = targetDatabaseId;
        record.fields = fields ?? [];

        return record;
      })
    ).pipe(
      map((record) => {
        if (!record) {
          throw new Error(`Document with id ${id} not found.`);
        }

        return record;
      })
    );
  }

  /**
   * Updates the pdf attachment on the provided new document record.
   *
   * @param id New document id.
   * @param newDocumentBytes Pdf bytes.
   * @returns An observable that completes once the attachment is updated.
   */
  updatePdfDocumentAttachment(id: string, newDocumentBytes: Uint8Array) {
    return this.selectDocumentRecordbyId(id).pipe(
      switchMap((documentRecord) => {
        return from(
          documentRecord.putAttachment({
            id: 'globalsearch-new-document.pdf',
            data: new Blob([newDocumentBytes], { type: 'application/pdf' }),
            type: 'application/pdf',
          })
        );
      }),
      map(() => {})
    );
  }

  private observeDocumentRecordById(
    id: string
  ): Observable<NewPdfDocumentRecord> {
    return this.database.collections.newDocuments.findOne(id).$.pipe(
      map((documentRecord) => {
        if (!documentRecord) {
          throw new Error(`Document with id ${id} not found`);
        }

        return documentRecord;
      })
    );
  }

  private selectDocumentRecordbyId(
    id: string
  ): Observable<NewPdfDocumentRecord> {
    return from(this.database.collections.newDocuments.findOne(id).exec()).pipe(
      map((record) => {
        if (!record) {
          throw new Error(`Document with id ${id} not found`);
        }

        return record;
      })
    );
  }
}
