import { Component } from '@angular/core';

/**
 * Login View Component.
 *
 * Acts as a container for the login module, and provides the projected header
 * and logo components to the module.
 *
 * For the login component itself @see LoginComponent for the gubbins.
 * */
@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
  standalone: false,
})
export class LoginViewComponent {
  constructor() {}
}
