import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Hotkey, HotkeyGroup, HotkeysService } from '@ngneat/hotkeys';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
  standalone: false,
})
export class ShortcutsComponent implements OnInit {
  /** Shortcuts help dismissal event emitter. */
  @Output() dismiss: EventEmitter<any> = new EventEmitter();

  /** Hotkeys Groups. */
  hotkeyGroups: HotkeyGroup[];
  /** Hotkeys. */
  hotkeys: Hotkey[];

  constructor(private hotkeysService: HotkeysService) {}

  ngOnInit(): void {
    this.hotkeys = this.hotkeysService.getHotkeys();
    this.hotkeyGroups = this.hotkeysService.getShortcuts();
  }
}
