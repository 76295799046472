import { InjectionToken } from '@angular/core';
import { ScanBytesResult } from 'models';
import { Observable } from 'rxjs';

export interface PdfScanProvider {
  /**
   * Is PDF scan available.
   *
   * @returns An observable boolean indicating if scan is available.
   */
  isAvailable$(): Observable<boolean>;
  /**
   * Triggers a scan.
   *
   * @returns An observable scan bytes result.
   */
  scan(): Observable<ScanBytesResult>;
}

export const PDF_SCAN_PROVIDER = new InjectionToken<PdfScanProvider>(
  'PDF_SCAN_PROVIDER'
);
