import { NewPdfDocumentService } from '../services/new-documents.service';

/**
 * Initializes any tasks that must complete before the application can fully initialize.
 *
 * @param newDocumentService New document service.
 * @returns A promise that resolves when all async tasks have finished.
 */
export const onAppInitFactory = (
  newDocumentService: NewPdfDocumentService
): (() => Promise<void>) => {
  return async () => {
    await newDocumentService.initDatabase();
  };
};
