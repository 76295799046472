import { Component } from '@angular/core';

import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';

@Component({
  selector: 'app-task-search-panel',
  templateUrl: './task-search-panel.component.html',
  styleUrls: ['./task-search-panel.component.scss'],
  standalone: false,
})
export class TaskSearchPanelComponent {
  /** Observable of if any task searches are enabled. */
  anyEnabledTaskSearches$ = this.taskSearchesQuery.anyEnabledTaskSearches$;
  /** Observable of the active database ID. */
  databaseId$ = this.databasesQuery.activeDbId$;
  /** Observable of whether the section is expanded. */
  isExpanded$ = this.appQuery.taskSearchSectionExpanded$;
  /** Observable of task search loading state. */
  isTaskSearchesLoading$ = this.taskSearchesQuery.isLoading$;
  /** Observable of the available task searches. */
  taskSearches$ = this.taskSearchesQuery.enabledTaskSearches$;

  constructor(
    private taskSearchesQuery: TaskSearchesQuery,
    private appQuery: ApplicationQuery,
    private appService: ApplicationService,
    private databasesQuery: DatabasesQuery
  ) {}

  /**
   * Handler for the on expand change event.
   *
   */
  onExpandChanged(): void {
    this.appService.toggleTaskSearchSectionExpanded();
  }
}
