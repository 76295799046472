import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

import {
  DocumentId,
  DocumentSecureId,
  SearchResults,
  UserAction,
  UserActions,
  UserFriendlyError,
} from 'models';
import { ActionsMenu, SearchResultDocumentOpenRequest } from 'src/app/models';
import { DocumentTransferService } from 'src/app/services/document-transfer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { TaskSearchesService } from 'src/app/state/task-searches/task-searches.service';

import { TaskSearchesQuery } from 'src/app/state/task-searches/task-searches.query';
import { MenuBaseComponent } from '../menu/menu-base.component';

/** Task Search actions menu. */
@UntilDestroy()
@Component({
  selector: 'app-task-action-menu',
  templateUrl: './task-action-menu.component.html',
  styleUrls: ['./task-action-menu.component.scss'],
  standalone: false,
})
export class TaskActionMenuComponent
  extends MenuBaseComponent
  implements OnInit, ActionsMenu
{
  /** If elements related to specifically multiple document contexts should be hidden. */
  @Input()
  hideMultiple: boolean;
  /** If the open option should be hidden. */
  @Input()
  hideOpen: boolean;
  /** Whether user actions should be hidden. */
  @Input()
  hideUserActions: boolean;
  /** Event raised when selected rows should be opened. */
  @Output()
  openSelectedDocuments = new EventEmitter<SearchResultDocumentOpenRequest>();
  /**
   * Event triggered when the search should be refreshed.
   */
  @Output()
  refreshSearch = new EventEmitter();

  /** If the open in the external full viewer option should be hidden. */
  hideOpenFullViewer = true;
  /** Array of user actions. */
  userActions: UserActions;

  private selectedSearchResults: SearchResults;

  constructor(
    private appQuery: ApplicationQuery,
    private logger: NGXLogger,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private taskSearchesQuery: TaskSearchesQuery,
    private taskSearchesService: TaskSearchesService,
    private notifications: NotificationService,
    private transferService: DocumentTransferService
  ) {
    super();
  }

  /**
   * Should user actions be shown.
   *
   * @returns True if user actions should be shown.
   */
  get showUserActions(): boolean {
    return (
      !this.hideUserActions &&
      !this.appQuery.isReadOnlyLicense &&
      (this.userActions?.length ?? 0) > 0
    );
  }

  ngOnInit(): void {
    // Hide the full viewer option when internal has not been enabled for use, or the user is a guest.
    this.appQuery.viewerUseInternal$
      .pipe(untilDestroyed(this))
      .subscribe((viewerUseInternal) => {
        this.hideOpenFullViewer = !viewerUseInternal;
      });
  }

  /** Handler for the copy button click event. */
  onClickCopy(): void {
    this.logger.debug('Copy clicked.');
    this.transferService
      .startDocumentTransferFromArchive(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        this.selectedSearchResults,
        false
      )
      .subscribe({
        complete: () => {
          this.notifications.info('Document transfer job finished.');
          this.refreshSearch.emit();
        },
      });
  }

  /**
   * Click event for the open documents menu button.
   *
   * @param forceExternalViewer If the documents should be opened only with the external viewer.
   */
  onClickOpenDocuments(forceExternalViewer = false): void {
    this.logger.debug('Open documents button was clicked.');
    this.openSelectedDocuments.emit({
      forceExternalViewer,
      searchResults: this.selectedSearchResults,
    });
  }

  /**
   * Handler for the run action click event.
   *
   * @param action Action.
   */
  onClickUserAction(action: UserAction): void {
    this.logger.debug('Action button clicked', action);
    const documents: Record<DocumentId, DocumentSecureId> = {};
    for (const row of this.selectedSearchResults) {
      documents[row.id] = row.secureId;
    }

    this.taskSearchesService.userActionsApi
      .runAction(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        action.key,
        documents
      )
      .subscribe({
        next: () => {
          this.notifications.success('USER_ACTION_SUBMITTED_MESSAGE', {
            userActionName: action.name,
          });
          // We must refresh the task search to update the counts before emitting a refresh.
          this.taskSearchesService
            .getById(
              this.databasesQuery.activeId,
              this.taskSearchesQuery.active.workflowId,
              this.taskSearchesQuery.active.key
            )
            .subscribe(() => {
              this.refreshSearch.emit();
            });
        },
        error: (error: UserFriendlyError) => this.notifications.error(error),
      });
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   * @param searchResults SearchResults to target.
   */
  openMenu(mouseEvent: MouseEvent, searchResults: SearchResults): void {
    // Set the row nodes to act on
    this.selectedSearchResults = searchResults;

    this.taskSearchesService.userActionsApi
      .getActions(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        this.selectedSearchResults[0].id,
        this.selectedSearchResults[0].secureId
      )
      .subscribe({
        next: (userActions) =>
          (this.userActions = this.sortUserActions(userActions)),
      });

    this.open(mouseEvent);
  }

  private sortUserActions(userActions: UserActions): UserActions {
    return userActions.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}
