import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { assert, assertTypeByKey } from 'common';
import { Archive, Inbox } from 'models';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';

/** Destination Selection Result. */
export interface DestinationSelectionResult {
  /** Archive or Inbox ID. */
  id: number;
  /** Archive or Inbox name. */
  name: string;
  /** The type of the selection. */
  type: DestinationSelectionType;
}

/** Destination Selection Type. */
export enum DestinationSelectionType {
  /** Archive. */
  archive,
  /** Inbox. */
  inbox,
}

/** Destination Selection Dialog Data. */
export interface DestinationSelectionDialogData {
  /** Whether to hide archives as available destinations. */
  hideArchives?: boolean;
  /** Whether to hide inboxes as available destinations. */
  hideInboxes?: boolean;
}

/** Destination Selection Dialog. */
@Component({
  selector: 'app-destination-select-dialog',
  templateUrl: './destination-select-dialog.component.html',
  styleUrls: ['./destination-select-dialog.component.scss'],
  standalone: false,
})
export class DestinationSelectDialogComponent {
  /** Archive tree data source. */
  archiveDataSource = new MatTreeNestedDataSource<Archive>();
  /** Archive tree control. */
  archiveTreeControl = new NestedTreeControl<Archive>((node) => node.children);
  /** Observable of available inboxes. */
  inboxes$: Observable<Inbox[]> = this.inboxQuery.inboxes$;

  private archives: Archive[];

  constructor(
    private archiveQuery: ArchivesQuery,
    private inboxQuery: InboxesQuery,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public dialogData: DestinationSelectionDialogData,
    private dialogReference: MatDialogReference<DestinationSelectDialogComponent>
  ) {
    this.archiveQuery.archives$
      .pipe(
        map((archives) =>
          archives.filter((a) => a.name.toLowerCase() !== 'versions')
        )
      )
      .subscribe((archives) => {
        this.archives = archives.filter((archive) => archive.parentId === 0);
        this.archiveDataSource.data = this.archives;
      });
  }

  /**
   * Determines whether the given archive has children.
   *
   * @param _ Unused index.
   * @param archive Archive.
   * @returns True if the arhcive has children.
   */
  archiveHasChildren(_: number, archive: Archive) {
    return !!archive.children && archive.children.length > 0;
  }

  /**
   * Click event handler for selecting a destination.
   *
   * @param destination The archive or inbox that was selected.
   */
  clickDestination(destination: Archive | Inbox) {
    const type =
      'children' in destination
        ? DestinationSelectionType.archive
        : DestinationSelectionType.inbox;
    const selectionResult: DestinationSelectionResult = {
      type,
      id: destination.id,
      name: destination.name,
    };
    this.logger.debug('Destionation selected.', selectionResult);
    this.dialogReference.close(selectionResult);
  }

  /**
   * Gets the archives with the provided parent id.
   *
   * @param parentId Parent Id.
   * @returns An array of archives.
   */
  getArchives(parentId: number): Archive[] {
    return this.archives.filter((archive) => archive.parentId === parentId);
  }

  /**
   * Handler for the inbox selection change event.
   *
   * @param change Selection list change.
   */
  onInboxSelectionChange(change: MatSelectionListChange): void {
    this.logger.debug(change);
    assert(
      change.options.length === 1,
      'Exactly one inbox should be selected.'
    );
    const inbox = change.options[0].value;
    assertTypeByKey<Inbox>(inbox, 'files', 'object');
    this.clickDestination(inbox);
  }
}
