import { Component, Inject, LOCALE_ID } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NGXLogger } from 'ngx-logger';

import { GridHelperService } from 'src/app/services/grid-helper.service';

import { FieldCellRendererParameters } from '../field-cell-renderer-parameters';

/** Decimal Cell Renderer. */
@Component({
  selector: 'app-decimal-cell-renderer',
  templateUrl: './decimal-cell-renderer.component.html',
  styleUrls: ['./decimal-cell-renderer.component.scss'],
  standalone: false,
})
export class DecimalCellRendererComponent implements ICellRendererAngularComp {
  /** Displayed cell value. */
  displayValue: string;

  constructor(
    private logger: NGXLogger,
    private gridHelper: GridHelperService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /** @inheritdoc */
  agInit(params: FieldCellRendererParameters): void {
    this.displayValue = this.getValue(params);
  }

  /** @inheritdoc */
  refresh(params: FieldCellRendererParameters): boolean {
    this.displayValue = this.getValue(params);
    return true;
  }

  private getValue(params: FieldCellRendererParameters): string {
    const parameterValue = params.valueFormatted
      ? params.valueFormatted
      : params.value;
    const parsedDisplayValue = this.gridHelper.getDecimalCellValue(
      parameterValue,
      params.format
    );
    return parsedDisplayValue;
  }

  // formats we've decided to walk away from
  // "R" or "r" => round-trip
  // "D" or "d" => Decimal description says this is only for integers so it just displays 1234 as 1234
}
