import { Component, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { TableField } from 'models';
import { map } from 'rxjs';
import { TableFieldUIService } from 'src/app/services/table-field-ui.service';

/** Table field component. */
@Component({
  selector: 'app-table-field-button',
  templateUrl: './table-field-button.component.html',
  styleUrls: ['./table-field-button.component.scss'],
  standalone: false,
})
export class TableFieldButtonComponent {
  /** Whether the button should be disabled. */
  @Input()
  disabled = false;
  /** Document Id. */
  @Input('document-id')
  id: number;
  /** Document Secure Id. */
  @Input('document-secure-id')
  secureId: string;
  /** If the field name should be displayed. Defaults to true. */
  @Input('show-label')
  showLabel = true;
  /** Table field. */
  @Input('table-field')
  tableField: TableField;
  /** Observable of whether the table field is active. */
  isActive$ = this.tableFieldUI.activeTableField$.pipe(
    map(
      (tableField) =>
        tableField?.tableField.id === this.tableField.id &&
        tableField?.documentId === this.id
    )
  );

  constructor(
    private logger: NGXLogger,
    private tableFieldUI: TableFieldUIService
  ) {}

  /**
   * Event handler for the button click.
   *
   * @param event Event.
   */
  onTableFieldClicked(event: MouseEvent): void {
    const tableField = this.tableField;
    const documentId = this.id;
    const secureId = this.secureId;
    this.logger.debug(`${tableField.name} button clicked.`);
    this.tableFieldUI.update(tableField, documentId, secureId);
    event.stopPropagation();
  }
}
