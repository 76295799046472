import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  ScanBytesResult,
  ScanProvider,
  ScanResult,
  ScanStatus,
  ScanUploadRequest,
} from 'models';

import { MatDialog } from '@angular/material/dialog';
import { assertTypeByKey } from 'common';
import { SCAN_PROVIDER } from '../common/tokens';
import {
  ScanDialogComponent,
  ScanDialogData,
  ScanTargetEnum,
} from '../components/scan-dialog/scan-dialog.component';

/**
 * GSE Scan Service.
 */
@Injectable({
  providedIn: 'root',
})
export class ScanService {
  constructor(
    @Inject(SCAN_PROVIDER) private scanProvider: ScanProvider,
    private dialog: MatDialog
  ) {}

  /**
   * Completes a scan session by uploading the files to the target.
   *
   * @param sessionId ID for the scan session.
   * @param uploadRequest The ScanUploadRequest indicating where the scan will be uploaded.
   * @returns An observable which returns a ScanResult once completed.
   */
  public completeScan(
    sessionId: string,
    uploadRequest: ScanUploadRequest
  ): Observable<ScanResult> {
    return this.scanProvider.complete(sessionId, uploadRequest);
  }

  /**
   * Completes a scan session by converting the scanned tiff pages into a single PDF document.
   * @param sessionId Scan session ID.
   * @param scanIds Scan ids of the individual pages to combine into the scan.
   * @returns An observable scan bytes result.
   */
  completeScanAndGetBytes(sessionId: string, scanIds: string[]) {
    return this.scanProvider.completeAndGetBytes(sessionId, scanIds);
  }

  /**
   * Contune a scan session with a new scan.
   *
   * @param sessionId ID for the existing scan session.
   * @returns An observable which returns a ScanStatus once completed.
   */
  public continueScan(sessionId: string): Observable<ScanStatus> {
    return this.scanProvider.continue(sessionId);
  }

  openScanDialog(scanDialogData: ScanDialogData, dialogMinWidth = 250) {
    return this.dialog
      .open<ScanDialogComponent, ScanDialogData, ScanResult | ScanBytesResult>(
        ScanDialogComponent,
        {
          data: scanDialogData,
          minWidth: dialogMinWidth,
        }
      )
      .afterClosed()
      .pipe(
        map((result) => {
          if (!result) {
            // The dialog was cancelled so let the caller decide how to handle that situation.
            return result;
          }

          // Try to be proactive about throwing if an unexpected type is returned from the dialog.

          if (scanDialogData.target === ScanTargetEnum.browser) {
            assertTypeByKey<ScanBytesResult>(result, 'fileType', 'string');
            return result;
          }

          assertTypeByKey<ScanResult>(result, 'uploadId', 'string');
          return result;
        })
      );
  }

  /**
   * Starts a new scanning session.
   *
   * @returns An observable which returns a ScanStatus once completed.
   */
  public scan(): Observable<ScanStatus> {
    return this.scanProvider.start(true);
  }
}
