import { Component } from '@angular/core';
import { map } from 'rxjs';

import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';

@Component({
  selector: 'app-inboxes-panel',
  templateUrl: './inboxes-panel.component.html',
  styleUrls: ['./inboxes-panel.component.scss'],
  standalone: false,
})
export class InboxesPanelComponent {
  /** Observable of the active database ID. */
  dbId$ = this.databasesQuery.activeDbId$;
  /** Observable of the available inboxes. */
  inboxes$ = this.inboxesQuery.inboxes$;
  /** Observable of whether the inbox navigation section is expanded. */
  isExpanded$ = this.applicationQuery.inboxNavSectionExpanded$;
  /** Observable of the inbox loading state. */
  isInboxesLoading$ = this.inboxesQuery.isLoading$;
  /** Observable of if the panel should show. */
  showPanel$ = this.inboxesQuery.inboxes$.pipe(
    map((inboxes) => inboxes?.length > 0)
  );

  constructor(
    private applicationService: ApplicationService,
    private applicationQuery: ApplicationQuery,
    private inboxesQuery: InboxesQuery,
    private databasesQuery: DatabasesQuery
  ) {}

  /**
   * Handler for the on expand change event.
   *
   */
  onExpandChanged(): void {
    this.applicationService.toggleinboxNavSectionExpanded();
  }
}
