import { COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';
import { debounceTime } from 'rxjs';

import { assertExists } from 'common';
import {
  Field,
  FieldDataType,
  ListType,
  Search,
  SearchOperator,
  SearchParameter,
  SearchPrompt,
} from 'models';
import { FieldsQuery } from 'src/app/state/fields/fields.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

/** Describes a search prompt result. */
export interface SearchPromptResult {
  /** Search prompts. */
  prompts: SearchPrompt[];
  /** Search ID. */
  searchId: number;
}

export interface SearchPromptDialogData {
  /** Search. */
  search: Search;
  /**
   * Optionally array of search prompts with which to pre-populate the dialog.
   *
   * **Warning** this overrides reading the prompt data from the URL.
   */
  searchPrompts?: SearchPrompt[];
}

/** Display Search Parameter. */
interface DisplaySearchParameter extends SearchParameter {
  /** Whether the form control should be displayed in the search prompt. */
  showControl: boolean;
}

/** Search Prompt. */
@Component({
  selector: 'app-search-prompt',
  templateUrl: './search-prompt.component.html',
  styleUrls: ['./search-prompt.component.scss'],
  standalone: false,
})
export class SearchPromptComponent implements OnInit {
  /** Search prompts to display. */
  displaySearchPrompts: DisplaySearchParameter[];
  /** Valid field list types. */
  fieldListType = ListType;
  /** Separator keycodes. */
  readonly multiValueSearchSeparatorCodes = [COMMA] as const;
  /** Search Prompt Form. */
  searchPromptForm: UntypedFormGroup;

  /**
   * Gets the search provided to the dialog.
   *
   * @returns A search.
   */
  get search(): Search {
    return this.searchPromptData.search;
  }

  /**
   * Gets the array of display prompts that the UI should render.
   *
   * @returns An array of display prompts.
   */
  get visibleDisplayPrompts(): DisplaySearchParameter[] {
    return this.displaySearchPrompts?.filter((prompt) => prompt.showControl);
  }

  constructor(
    private logger: NGXLogger,
    private dialogReference: MatDialogReference<SearchPromptComponent>,
    private searchesQuery: SearchesQuery,
    private fieldsQuery: FieldsQuery,
    private translate: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public searchPromptData: SearchPromptDialogData
  ) {}

  /**
   * Add a search value to the multi value search control for the prompt.
   *
   * @param promptId Prompt Id.
   * @param event MatChipInputEvent.
   */
  addMultiValueSearchValue(promptId: number, event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      // TODO add value to control
      const values = this.getMultiValueSearchValues(promptId);
      values.push(value);
      this.searchPromptForm.controls[promptId].setValue(values.join('|'));
    }

    event.chipInput.clear();
  }

  /**
   * Gets if the field has a list.
   *
   * @param field Field id or the field object itself. Field will be retrieved from the store using the id if this is a number.
   * @returns True if the field has a list.
   */
  fieldHasList(field: number | Field): boolean {
    if (typeof field === 'number') {
      field = this.getField(field);
    }
    return (
      field.list.listId > 0 ||
      field.list.primary > 0 ||
      field.list.secondary > 0
    );
  }

  /**
   * Gets the field from the store.
   *
   * @param fieldId Field id.
   * @returns A field.
   */
  getField(fieldId: number): Field {
    const field = this.fieldsQuery.getEntity(fieldId);
    assertExists(field, `Field with id '${fieldId}' does not exist.`);
    return field;
  }

  /**
   * Gets the form control given the field id.
   *
   * @param fieldId Field id.
   * @returns The form control.
   */
  getFormControlFromField(fieldId: number) {
    const searchParameter = this.displaySearchPrompts.find(
      (f) => f.fieldId === fieldId
    );
    return searchParameter
      ? this.getFormControlFromSearchParameter(searchParameter.id)
      : undefined;
  }

  /**
   * Gets the form control given the search parameter id.
   *
   * @param searchParameterId Search parameter id.
   * @returns The form control.
   */
  getFormControlFromSearchParameter(searchParameterId: number) {
    const control = this.searchPromptForm.controls[
      searchParameterId
    ] as UntypedFormControl;
    // Search controls should not enforce required fields.
    control.removeValidators([Validators.required]);
    return control;
  }

  /**
   * Get multi value search values from form field.
   *
   * @param promptId Prompt Id.
   * @returns An array of the multi value search values.
   */
  getMultiValueSearchValues(promptId: number): string[] {
    const control = this.searchPromptForm.controls[promptId];
    const rawValue = control.value ? control.value.toString() : '';
    const values = rawValue ? rawValue.split('|') : [];
    return values;
  }

  /**
   * Gets the prompt input type for a prompt.
   *
   * @param type Field data type.
   * @returns A string of the type for the search prompt.
   */
  getPromptInputType(type: FieldDataType): 'number' | 'text' {
    switch (type) {
      case FieldDataType.integer:
      case FieldDataType.decimal:
        return 'number';
      default:
        return 'text';
    }
  }

  /**
   * Retrieves a search operator tooltip.
   *
   * @param searchParameter Search prompt.
   * @returns A string that should be displayed.
   */
  getSearchOperator(searchParameter: SearchParameter): string {
    let operatorString = '';
    switch (searchParameter.operator) {
      case SearchOperator.contains: {
        operatorString = 'CONTAINS';

        break;
      }
      case SearchOperator.doesNotEqual: {
        operatorString = 'DOES_NOT_EQUAL';

        break;
      }
      case SearchOperator.equals: {
        operatorString = 'EQUALS';

        break;
      }
      case SearchOperator.greaterThanEqual: {
        operatorString = 'GREATER_THAN_OR_EQUAL_TO';

        break;
      }
      case SearchOperator.lessThanEqual: {
        operatorString = 'LESS_THAN_OR_EQUAL_TO';

        break;
      }
      default:
        return '';
    }
    operatorString = this.translate.translate(operatorString).toLowerCase();
    return `... ${operatorString} ${this.removeTrailingColon(
      searchParameter.prompt
    )}`;
  }

  ngOnInit(): void {
    this.displaySearchPrompts = this.getDisplayPrompts();
    this.createPrompForm();
    if (this.displaySearchPrompts.length === 0) {
      // there is nothing to prompt the user with so run submit immediately.
      this.onSubmit();
    }
  }

  /**
   * Handler for the multi search value paste event.
   *
   * @param event Clipboard event.
   * @param promptId Search prompt id.
   */
  onMultiSearchValuePaste(event: ClipboardEvent, promptId: number): void {
    if (!event.clipboardData) {
      this.logger.warn(
        'MV search prompt paste was fired without clipboard data in the event.',
        event
      );
      return;
    }

    /**
     * Adds the provided values to the chip list.
     *
     * @param values Array of values to be added to the chip list.
     */
    const loadValuesToChipList = (values: string[]): void => {
      this.logger.debug('Adding values to chip list', values);
      const multivalueSearchValues = this.getMultiValueSearchValues(promptId);
      for (let value of values) {
        value = value.trim();
        if (value) {
          multivalueSearchValues.push(value);
        }
      }
      this.searchPromptForm.controls[promptId].setValue(
        multivalueSearchValues.join('|')
      );
    };

    const clipboardText = event.clipboardData.getData('text');
    let clipboardValues = clipboardText.split('\n');
    if (clipboardValues.length > 1) {
      this.logger.debug(
        'Values have been split by line breaks',
        clipboardValues
      );
      loadValuesToChipList(clipboardValues);
    } else {
      clipboardValues = clipboardText.split(',');
      if (clipboardValues.length > 1) {
        this.logger.debug('Values have been split by comma', clipboardValues);
        loadValuesToChipList(clipboardValues);
      } else {
        // This is only in an else to log that the attempt to split by \n and by ',' resulted in only one value.
        this.logger.debug(
          'One value was found when split by comma',
          clipboardValues
        );
        loadValuesToChipList(clipboardValues);
      }
    }

    // Prevent the paste from reaching the input.
    event.preventDefault();
  }

  /** Handler for the reset form event. */
  onReset(): void {
    // Get all the hidden prompts
    const hiddenPrompts = this.displaySearchPrompts.filter(
      (prompt) => !prompt.showControl
    );

    // Iterate each form control by its ID which is prompt.id.
    for (const controlId of Object.keys(this.searchPromptForm.controls)) {
      if (hiddenPrompts.some((prompt) => prompt.id.toString() === controlId)) {
        // Do not reset the form control if the prompt is one of the hidden prompts.
        continue;
      }

      this.searchPromptForm.controls[controlId].reset();
    }
  }

  /**
   * Event handler for the prompt form submission.
   */
  onSubmit(): void {
    this.logger.debug(
      'Search prompt form submitted.',
      this.search,
      this.searchPromptForm
    );
    if (this.searchPromptForm.invalid) {
      this.logger.warn('Search prompt form is invalid.');
      return;
    }

    const searchPrompts: SearchPrompt[] = [];
    for (const parameter of this.displaySearchPrompts) {
      const prompt: SearchPrompt = {
        id: parameter.id,
        prompt: parameter.prompt,
        value: this.searchPromptForm.controls[`${parameter.id}`].value ?? '',
      };
      if (this.search.settings.multiValue && prompt.value) {
        prompt.value = `[${prompt.value}]`;
      }
      searchPrompts.push(prompt);
    }

    const searchPromptResult: SearchPromptResult = {
      searchId: this.search.id,
      prompts: searchPrompts,
    };
    this.dialogReference.close(searchPromptResult);
  }

  /**
   * Remove a search value to the multi value search control for the prompt.
   *
   * @param promptId Prompt Id.
   * @param value Value to be removed.
   */
  removeMultiValueSearchValue(promptId: number, value: string) {
    const values = this.getMultiValueSearchValues(promptId);
    const index = values.indexOf(value);
    if (index >= 0) {
      values.splice(index, 1);
      this.searchPromptForm.controls[promptId].setValue(values.join('|'));
    }
  }

  private createPrompForm(): void {
    this.searchPromptForm = new UntypedFormGroup({});
    const searchId = this.searchesQuery.getActiveId();
    const routePromptData =
      searchId === this.search.id
        ? this.searchesQuery.currentSearchPrompts
        : [];
    for (const prompt of this.displaySearchPrompts) {
      this.createPromptFormControl(routePromptData, prompt);
    }
  }

  /**
   * Creates the form control and inserts the correct value into it.
   *
   * @param routePromptData Route prompt data.
   * @param prompt Search prompt.
   */
  private createPromptFormControl(
    routePromptData: SearchPrompt[],
    prompt: DisplaySearchParameter
  ) {
    let routePromptValue = !!this.searchPromptData.searchPrompts
      ? (this.searchPromptData.searchPrompts.find((p) => p.id === prompt.id)
          ?.value ?? '')
      : (routePromptData?.find((p) => p.id === prompt.id)?.value ?? '');
    if (
      this.search.settings.multiValue &&
      routePromptValue.startsWith('[') &&
      routePromptValue.endsWith(']')
    ) {
      routePromptValue = routePromptValue.slice(1, -1);
    }

    // If the prompt is shown pull from route otherwise it is a hidden static search prompt so use the prompt value.
    const promptValue = prompt.showControl ? routePromptValue : prompt.value;

    const control = new UntypedFormControl(promptValue);
    if (
      this.fieldsQuery.getEntity(prompt.fieldId)?.type === FieldDataType.integer
    ) {
      // Debounce to prevent firing on every keypress.
      control.valueChanges.pipe(debounceTime(200)).subscribe((newValue) => {
        if (newValue) {
          if (this.search.settings.multiValue) {
            // MV fields actually store data in a string separated by '|' so get all the values first.
            const values = newValue.split('|');
            const truncatedValues: number[] = [];
            for (const value of values) {
              const truncatedValue = Math.trunc(value);
              truncatedValues.push(truncatedValue);
            }
            // Rejoin the formatted numeric field values to a string separated by '|'.
            const formValue = truncatedValues.join('|');
            // Avoid emitting the event to stop the control.valueChanges from re-emitting again in an infinite loop.
            control.setValue(formValue, { emitEvent: false });
          } else {
            // Returns only the integer as long as a value was provided.
            const truncatedValue = Math.trunc(newValue);
            // Avoid emitting the event to stop the control.valueChanges from re-emitting again in an infinite loop.
            control.setValue(truncatedValue, { emitEvent: false });
          }
        }
      });
    }

    this.searchPromptForm.addControl(`${prompt.id}`, control);
  }

  /**
   * A list of prompts that should be displayed.
   *
   * @returns An array of SearchParameter.
   */
  private getDisplayPrompts(): DisplaySearchParameter[] {
    const filteredSearchParameters = this.search.parameters
      .filter(
        (element, index) =>
          // remove entries with duplicate prompts
          this.search.parameters.findIndex(
            (obj) => obj.prompt === element.prompt
          ) === index &&
          // remove prompts that do not require user input
          element.operator !== SearchOperator.isEmpty &&
          element.operator !== SearchOperator.isNotEmpty
      )
      .map((element) => {
        const displayParamemter = { ...(element as DisplaySearchParameter) };
        displayParamemter.showControl = !!element.prompt;
        return displayParamemter;
      });

    // add content search prompt if applicable
    if (this.search.settings.contentSearch.enabled) {
      filteredSearchParameters.push({
        id: -1,
        prompt: 'Keyword:',
        value: '',
        fieldId: 0,
        operator: SearchOperator.keywords,
        showControl: true,
      });
    }

    return filteredSearchParameters;
  }

  /**
   * Retrieve prompt string without ending ':'.
   *
   * @param promptString Prompt string.
   * @returns Prompt string without ending ':'.
   */
  private removeTrailingColon(promptString: string): string {
    return promptString.replace(/:$/, '');
  }
}
