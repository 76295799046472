import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DocumentUpdateProvider,
  FieldValues,
  IDocumentUpdateSession,
  TableFieldItems,
  UserFriendlyError,
} from 'models';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';

import { UploadResponse, createS9ApiFieldValues } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square 9 API document update service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiDocumentUpdateService implements DocumentUpdateProvider {
  private apiBaseUrl: string;
  private documentUpdatesBaseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => {
      this.documentUpdatesBaseUrl = `${apiUrl}/UpdateDocument`;
      this.apiBaseUrl = apiUrl;
    });
  }

  /** @inheritdoc */
  updateDocument(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    newFileByteArray: Uint8Array,
    newFilename: string,
    mimeType: string,
    session: IDocumentUpdateSession
  ): Observable<void> {
    const blob = new Blob([newFileByteArray], { type: mimeType });
    const formData = new FormData();
    formData.append('file', blob, newFilename);

    const upload$ = this.http
      .post<UploadResponse>(`${this.apiBaseUrl}/files`, formData)
      .pipe(map((response) => response.files[0].name));

    const headers = new HttpHeaders()
      .set('SecureId', secureId)
      .set('session-id', session.id)
      .set('session-actions', session.actions);

    return upload$.pipe(
      switchMap((uploadedFilename) =>
        this.http.put<void>(
          `${this.documentUpdatesBaseUrl}/databases/${databaseId}/archive/${archiveId}/document/${documentId}/Document`,
          {},
          {
            headers,
            params: new HttpParams().set('filename', uploadedFilename),
          }
        )
      )
    );
  }

  /** @inheritdoc */
  updateFieldData(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    fieldValues: FieldValues,
    session: IDocumentUpdateSession
  ): Observable<void> {
    const apiFieldValues = createS9ApiFieldValues(fieldValues);
    return this.http
      .put<void>(
        `${this.documentUpdatesBaseUrl}/databases/${databaseId}/archive/${archiveId}/document/${documentId}/FieldData`,
        apiFieldValues,
        {
          headers: new HttpHeaders()
            .set('SecureId', secureId)
            .set('session-id', session.id)
            .set('session-actions', session.actions),
        }
      )
      .pipe(
        catchError((userFriendlyError: UserFriendlyError) => {
          const errorMessage =
            (userFriendlyError.error?.error?.Message as string) ?? '';
          if (
            errorMessage.toLowerCase().includes('a datatype mismatch occurred')
          ) {
            userFriendlyError.i18n =
              'ERROR_FAILED_SAVE_FIELD_DATA_DATATYPE_MISMATCH';
          }
          return throwError(() => userFriendlyError);
        })
      );
  }

  /** @inheritdoc */
  updateTableFieldData(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    tableFieldItems: TableFieldItems,
    session: IDocumentUpdateSession
  ): Observable<void> {
    return this.http.put<void>(
      `${this.documentUpdatesBaseUrl}/databases/${databaseId}/archive/${archiveId}/document/${documentId}/tabledata`,
      tableFieldItems,
      {
        headers: new HttpHeaders()
          .set('secureid', secureId)
          .set('session-id', session.id)
          .set('session-actions', session.actions),
      }
    );
  }
}
