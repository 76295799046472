import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, first, switchMap } from 'rxjs';
import { SearchUIService } from 'src/app/services/search-ui.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

@UntilDestroy()
@Component({
  selector: 'app-search-by-name-redirect-view',
  templateUrl: './search-by-name-redirect-view.component.html',
  styleUrls: ['./search-by-name-redirect-view.component.scss'],
  standalone: false,
})
export class SearchByNameRedirectViewComponent {
  /** Holds any error returned. */
  error: string | undefined;
  /** Whether the component is loading. */
  loading = signal(true);

  private applicationQuery = inject(ApplicationQuery);
  private archivesQuery = inject(ArchivesQuery);
  private databasesQuery = inject(DatabasesQuery);
  private logger = inject(NGXLogger);
  private route = inject(ActivatedRoute);
  private routerQuery = inject(RouterQuery);
  private searchesQuery = inject(SearchesQuery);
  private searchUIService = inject(SearchUIService);

  constructor() {
    let searchName = this.route.snapshot.params['searchName'];
    let routeDatabaseId = this.route.snapshot.params['dbId'];
    let targetArchiveIdQueryParameter =
      (this.route.snapshot.queryParams['targetArchiveId'] as string) ?? '';

    if (!searchName || typeof searchName !== 'string') {
      this.error = 'SEARCH_BY_NAME_INVALID_SEARCH_NAME';
      return;
    }

    if (!routeDatabaseId || typeof routeDatabaseId !== 'string') {
      this.error = 'SEARCH_BY_NAME_NO_DATABASE';
      return;
    }

    searchName = searchName.toLowerCase();

    // Ensure that the search store is currently caching the route database's searches.
    const storeDatabaseId$ = this.applicationQuery.searchStoreDatabaseId$.pipe(
      first((databaseId) => databaseId === routeDatabaseId)
    );

    storeDatabaseId$
      .pipe(
        switchMap(() =>
          // Ensure that both archives and searches are finished loading before attempting to get the active route parameters.
          combineLatest([
            this.archivesQuery
              .selectLoading()
              .pipe(first((isLoading) => !isLoading)),
            this.searchesQuery
              .selectLoading()
              .pipe(first((isLoading) => !isLoading)),
          ])
            .pipe(
              switchMap(() =>
                combineLatest([
                  this.archivesQuery.archiveRouteParams$,
                  this.searchesQuery.searchPromptParams$,
                ])
              )
            )
            .pipe(untilDestroyed(this))
        )
      )
      .subscribe({
        next: ([[databaseId, archiveId], searchPrompts]) => {
          if (!databaseId) {
            this.error = 'SEARCH_BY_NAME_NO_DATABASE';
            return;
          }

          if (!archiveId) {
            this.error = 'SEARCH_BY_NAME_NO_ARCHIVE';
            return;
          }

          const searches = this.searchesQuery.getAll();
          const search = searches.find(
            (search) =>
              search.parentArchive === archiveId &&
              search.name.toLowerCase() === searchName
          );

          if (!search) {
            this.error = 'SEARCH_BY_NAME_SEARCH_NOT_FOUND';
            return;
          }

          this.loading.set(false);

          // Default to searching the first archive defined in the search.
          let targetArchiveId = search.archives[0];

          if (targetArchiveIdQueryParameter) {
            const targetArchiveIdQueryParameterNumber = Number(
              targetArchiveIdQueryParameter
            );
            // Check if the archive id provided in the query parameter is valid.
            if (isNaN(targetArchiveIdQueryParameterNumber)) {
              this.error = 'SEARCH_BY_NAME_INVALID_TARGET_ARCHIVE_ID';
              return;
            }

            // Check if the target archive id is defined on the search.
            if (
              !search.archives.includes(targetArchiveIdQueryParameterNumber)
            ) {
              this.error = 'SEARCH_BY_NAME_TARGET_ARCHIVE_ID_NOT_IN_SEARCH';
              return;
            }

            targetArchiveId = targetArchiveIdQueryParameterNumber;
          }

          this.searchUIService
            .redirectToSearch$(
              databaseId,
              targetArchiveId,
              search.id,
              searchPrompts
            )
            .subscribe();
        },
        error: (error: unknown) => {
          this.loading.set(false);
          this.logger.error(error);
          this.error = 'SEARCH_BY_NAME_LOOKUP_ERROR';
        },
      });
  }
}
