import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Signal,
  ViewChild,
  computed,
  effect,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslocoService } from '@jsverse/transloco';
import { assertExists } from 'common';
import {
  DocumentUpdateProvider,
  Field,
  FieldValues,
  PdfOptions,
  UserFriendlyError,
} from 'models';
import { NGXLogger } from 'ngx-logger';
import { derivedAsync } from 'ngxtension/derived-async';
import {
  Observable,
  catchError,
  finalize,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { DOCUMENT_UPDATE_PROVIDER } from 'src/app/common/tokens';
import { createDataUrl } from 'src/app/common/utility';
import { DocumentUpdateSession } from 'src/app/models';
import {
  PdfModifiedEvent,
  PdfViewerComponent,
} from 'src/app/modules/pdf-viewer';
import { NewPdfDocumentService } from 'src/app/services/new-documents.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TableFieldUIService } from 'src/app/services/table-field-ui.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { ArchivesService } from 'src/app/state/archives/archives.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import {
  DestinationSelectDialogComponent,
  DestinationSelectionDialogData,
  DestinationSelectionResult,
} from '../destination-select-dialog/destination-select-dialog.component';
import { DocumentViewSidebarComponent } from '../document-view-sidebar/document-view-sidebar.component';
import { NewDocumentsDialogComponent } from '../new-documents-dialog/new-documents-dialog.component';
import { TableFieldGridComponent } from '../table-field-grid/table-field-grid.component';

@Component({
  selector: 'app-new-document-view',
  templateUrl: './new-document-view.component.html',
  styleUrls: ['./new-document-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class NewDocumentViewComponent implements AfterViewInit {
  /** PDF Viewer reference. */
  @ViewChild(PdfViewerComponent)
  pdfViewer: PdfViewerComponent;
  /** Document view sidebar reference. */
  @ViewChild('rightSidebar')
  rightSidebar: DocumentViewSidebarComponent;
  /** Table field grid reference. */
  @ViewChild('tableFieldGrid')
  tableFieldGrid: TableFieldGridComponent;
  /** Active database id. */
  activeDatabaseId = toSignal(this.databasesQuery.activeDbId$, {
    requireSync: true,
  });
  /** Active table field or undefined if there isn't one. */
  activeTableField = derivedAsync(
    () => this.tableFieldUIService.activeTableField$
  );
  /** Array of all new docmuments. */
  allDocuments = derivedAsync(() => {
    const activeDatabaseId = this.activeDatabaseId();
    if (!activeDatabaseId) {
      this.logger.error(
        'Unable to load documents because there is no active database.'
      );
      return [];
    }

    return this.newDocumentService.observeAllInDatabase$(activeDatabaseId);
  });
  /** Are archives still being loaded from the server. */
  archivesLoading = toSignal(this.archivesQuery.isLoading$);
  /** Page number the pdf viewer should load on initialization. */
  pageNumber = signal(1);
  /** PDF viewer options. */
  pdfOptions: Signal<PdfOptions | undefined>;
  /** Is there a next document in the list. */
  canNavigateToNextDocument: Signal<boolean>;
  /** Is there a previous document in the list. */
  canNavigateToPreviousDocument: Signal<boolean>;
  /** Whether the save button should be disabled. */
  disableSave = computed(
    () => this.saveInProgress() || !this.documentPermissions()?.addNewDocuments
  );
  /** Active document. */
  document = toSignal(
    this.routeQuery.selectParams('editId').pipe(
      switchMap((id) => {
        if (!id) {
          this.routeToFirstNewDocument();
          return of(undefined);
        }

        return this.newDocumentService.observeDocument$(id).pipe(
          catchError((error) => {
            const allDocuments = this.allDocuments();
            // We only want to notify if allDocuments is defined and not empty.
            // Otherwise notify that there is something wrong with the currently viewed document.
            if (!!allDocuments && allDocuments.length > 0) {
              this.notify.error({
                i18n: 'ERROR_NEW_DOCUMENT_NOT_FOUND',
                description: '',
                error: error,
              });
            }

            this.routeToFirstNewDocument();
            return of(undefined);
          }),
          tap((record) => {
            this.logger.debug('Observed record changed.', record);
          })
        );
      })
    )
  );
  /** The active document's target archive. */
  documentArchive = derivedAsync(() => {
    const document = this.document();
    if (!document) {
      this.logger.debug(
        'Document archive could not be computed because there is no active document'
      );
      return undefined;
    }

    return this.archivesQuery.archives$.pipe(
      map((archives) =>
        archives.find((archive) => archive.id === document.targetArchiveId)
      )
    );
  });
  /** The active document's field values. */
  documentFields = computed(() => {
    const document = this.document();
    if (!document) {
      this.logger.debug(
        'Document fields could not be computed because there is no active document'
      );
      return [];
    }

    return document.fields;
  });
  /**
   * The index of the active document in the array of all documents.
   *
   * This can be -1 if there is no active document or if there are no documents.
   */
  documentIndex = computed(() => {
    const document = this.document();
    const allDocuments = this.allDocuments();
    if (!allDocuments || !document) return -1;
    return allDocuments.findIndex((d) => d.id === document.id);
  });
  /** Text displayed as `${activeDocument} of ${totalDocuments}`. */
  documentOfDocuments = computed(() => {
    const document = this.document();
    const allDocuments = this.allDocuments();
    if (!document) {
      this.logger.debug(
        'Document of documents could not be computed because there is no active document'
      );
      return '';
    } else if (!allDocuments) {
      this.logger.debug(
        'Document of documents could not be computed because there are no documents'
      );
      return '';
    }

    const currentDocumentIndex = allDocuments.findIndex(
      (d) => d.id === document.id
    );
    if (currentDocumentIndex === -1) {
      this.logger.debug(
        'Document of documents could not be computed because the current document is not in the list of documents'
      );
      return '';
    }

    return `${currentDocumentIndex + 1} ${this.translateService.translate('OF').toLowerCase()} ${allDocuments.length}`;
  });
  /** Permissions for the active document. */
  documentPermissions = computed(() => {
    const documentArchive = this.documentArchive();
    if (!documentArchive) {
      this.logger.warn(
        'Permissions could not be computed because there is no active document archive.'
      );
      return undefined;
    }

    return documentArchive.permissions;
  });
  /** Are there new documents available to be indexed. */
  hasNewDocuments = toSignal(
    this.newDocumentService
      .observeAll$()
      .pipe(map((documents) => documents.length > 0)),
    { initialValue: false }
  );
  /** Is a document loading. */
  isDocumentLoading = signal(false);
  /** Number of new PDF documents in the database. */
  newPdfDocumentCount = toSignal(
    this.databasesQuery.activeDbId$.pipe(
      tap(() => console.log('hi')),
      switchMap((databaseId) => {
        if (!databaseId) return of(0); // No need to try getting count before database is active.
        return this.newDocumentService.observeCountInDatabase$(databaseId);
      })
    ),
    { initialValue: 0 }
  );
  /** Whether a save is in progress. */
  saveInProgress = signal(false);
  /** Has the user toggled the modify document thumbnailer on. */
  showModifyDocumentThumbnailer = signal(false);
  /** Should the table field grid be displayed. */
  showTableFieldGrid = derivedAsync(
    () => {
      const activeTableField = this.activeTableField();
      const documentArchive = this.documentArchive();
      if (!activeTableField || !documentArchive) return false;

      const archiveTableFields = this.archivesQuery.getTableFields(
        documentArchive.id
      );

      const activeTableFieldIsOnArchive = archiveTableFields.some(
        (archiveTableField) =>
          archiveTableField.id === activeTableField.tableField.id
      );

      if (!activeTableFieldIsOnArchive) {
        // Don't show the grid for a table field that doesn't exist on the target archive.
        return false;
      }

      // If we get here it means there is an active table field that is a member of the active document's archive.
      return true;
    },
    { initialValue: false }
  );
  /** Should the modify document thumbnailer be displayed. */
  shouldShowModifyThumbnailer = computed(
    () =>
      this.showModifyDocumentThumbnailer() &&
      this.pdfViewer &&
      !this.isDocumentLoading()
  );

  constructor(
    private logger: NGXLogger,
    private translateService: TranslocoService,
    private router: Router,
    private routeQuery: RouterQuery,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private archivesService: ArchivesService,
    private tableFieldUIService: TableFieldUIService,
    private newDocumentService: NewPdfDocumentService,
    @Inject(DOCUMENT_UPDATE_PROVIDER)
    private documentUpdateProvider: DocumentUpdateProvider,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private notify: NotificationService
  ) {
    this.pdfOptions = derivedAsync(() => {
      const activeDocument = this.document();
      if (!activeDocument) return;
      return this.newDocumentService
        .getPdfAttachmentBytes(activeDocument.id)
        .pipe(
          map((bytes) => ({
            annotations: [],
            url: createDataUrl(bytes),
          }))
        );
    });
    this.canNavigateToNextDocument = computed(() => {
      const document = this.document();
      const allDocuments = this.allDocuments();
      if (!document || !allDocuments || allDocuments.length === 0) {
        return false;
      }

      const currentIndex = this.documentIndex();
      if (currentIndex === -1) {
        // I'm not sure how you would get here but you can technically go to the next document.
        return true;
      }

      return currentIndex + 1 <= allDocuments.length - 1;
    });
    this.canNavigateToPreviousDocument = computed(() => {
      const document = this.document();
      const allDocuments = this.allDocuments();
      if (!document || !allDocuments || allDocuments.length === 0) {
        return false;
      }

      const currentIndex = this.documentIndex();
      if (currentIndex === -1) {
        return false;
      }

      return currentIndex - 1 >= 0;
    });

    effect(() => {
      const documentFields = this.documentFields();
      this.logger.debug(
        'Document fields changed. Reloading the values into the indexer.'
      );
      for (const field of documentFields) {
        this.rightSidebar.indexer.setFieldValue(field.id, field.value);
      }
    });

    effect(() => {
      const documentPermissions = this.documentPermissions();
      const archivesLoading = untracked(this.archivesLoading); // Get the value without making the effect listen for changes to it.
      if (archivesLoading || !documentPermissions) {
        // There is nothing to do here if permissions aren't there or if archives are still loading.
        // We don't want to use cached archive data for this notification.
        return;
      }

      if (!documentPermissions.addNewDocuments) {
        this.notify.warning('WARN_NO_ADD_PERMISSIONS_ON_NEW_DOCUMENT');
      }
    });

    /**
     * WARNING: NOTHING else should be done in this effect.
     *
     * Introducing other behaviors or signals into this effect could cause an infinite loop due to allowing signal writes here.
     * We need signal writes enabled here because the activeTableField$ being touched through the tableFieldUIService.clear() is bound to a signal above.
     */
    effect(
      () => {
        const showTableFieldGrid = this.showTableFieldGrid();
        if (!showTableFieldGrid) {
          this.logger.debug(
            'The table field grid should not be shown so ensure it is cleared from the service.'
          );
          this.tableFieldUIService.clear();
        }
      },
      { allowSignalWrites: true }
    );
  }

  /**
   * Style attached to the viewer container element.
   *
   * Height will adjust for the presence of table field controls.
   *
   * @returns CSS rule for th esize of the viewer container.
   */
  get viewerContainerStyle() {
    // Adjust for (2) toolbars.
    const heightAdjustment = this.breakpointObserver.isMatched(
      '(min-width: 600px)'
    )
      ? '128px'
      : '120px';
    const height = `calc(${
      this.showTableFieldGrid() ? 'calc(50vh + 12px)' : '100vh'
    } - ${heightAdjustment})`;

    return {
      height,
    };
  }

  ngAfterViewInit(): void {}

  /** Handler for the close view event. */
  onClickCloseView(): void {
    const document = this.document();
    if (!document) {
      const routeDatabaseId = this.activeDatabaseId();
      if (!routeDatabaseId) {
        this.router.navigate(['/']);
        return;
      }

      this.router.navigate(['db', routeDatabaseId]);
      return;
    }

    this.router.navigate([
      'db',
      document.targetDatabaseId,
      'archive',
      document.targetArchiveId,
    ]);
  }

  /** Handler for the change archive click event. */
  onClickChangeArchive(): void {
    const dialogData: DestinationSelectionDialogData = {
      hideInboxes: true,
    };
    const dialog = this.dialog.open<
      DestinationSelectDialogComponent,
      DestinationSelectionDialogData,
      DestinationSelectionResult
    >(DestinationSelectDialogComponent, {
      minWidth: 400,
      data: dialogData,
    });
    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        this.logger.debug('Destination selection dialog was cancelled.');
        return;
      }

      const document = this.document();
      assertExists(document);
      const archiveId = result.id;
      const archiveFields = this.archivesQuery.getFields(archiveId);
      // Map the archive fields to field values and populate any field value that can be pulled from the original field values.
      const fieldValues: FieldValues = archiveFields.map((field) => {
        const fieldValue = document.fields.find((f) => f.id === field.id) ?? {
          id: field.id,
          value: '',
          multiValue: [],
        };
        return fieldValue;
      });

      this.newDocumentService
        .updateDocumentRecord(
          document.id,
          document.pageCount,
          archiveId,
          document.targetDatabaseId,
          fieldValues
        )
        .subscribe();
    });
  }

  /** Handler for the open new documents dialog click event. */
  onClickOpenNewDocumentsDialog(): void {
    this.newDocumentService.openNewDocumentDialog();
  }

  /** Handler for the previous button click event. */
  onClickPrevious(): void {
    this.logger.debug('Previous button clicked.');
    const document = this.document();
    const allDocuments = this.allDocuments();
    if (!document || !allDocuments) {
      this.logger.warn(
        'Cannot navigate to previous document. Either there is no active document or there are no documents.'
      );
      return;
    }

    const currentIndex = this.documentIndex();
    if (currentIndex === -1) {
      this.logger.debug('Current document is not in the list of documents.');
      return;
    }

    const previousDocumentIndex = Math.max(currentIndex - 1, 0);
    const previousDocument = allDocuments[previousDocumentIndex];
    this.router.navigate([
      'db',
      previousDocument.targetDatabaseId,
      'new',
      previousDocument.id,
    ]);
  }

  /** Handler for the next button click event. */
  onClickNext(): void {
    this.logger.debug('Next button clicked.');
    const document = this.document();
    const allDocuments = this.allDocuments();
    if (!document || !allDocuments) {
      this.logger.warn(
        'Cannot navigate to next document. Either there is no active document or there are no documents.'
      );
      return;
    }

    const currentIndex = this.documentIndex();
    if (currentIndex === -1) {
      this.logger.debug('Current document is not in the list of documents.');
      return;
    }

    const nextDocumentIndex = Math.min(
      currentIndex + 1,
      allDocuments.length - 1
    );
    const nextDocument = allDocuments[nextDocumentIndex];
    this.router.navigate([
      'db',
      nextDocument.targetDatabaseId,
      'new',
      nextDocument.id,
    ]);
  }

  /**
   * Handler for the save button click event.
   */
  onClickSave(): void {
    this.logger.debug('Save button clicked.');

    if (this.rightSidebar.indexer.indexerForm.invalid) {
      this.logger.warn('Indexer form has errors.');
      return;
    }

    this.logger.debug('Starting save...');
    this.saveInProgress.set(true);

    const document = this.document();
    assertExists(document, 'There must be an active document.');

    // Create session.
    const updateSession = new DocumentUpdateSession(
      false,
      false,
      this.rightSidebar?.indexer?.isDirty ?? false,
      this.tableFieldGrid?.isDirty ?? false
    );

    // Import the document.
    const import$ = this.archivesService.api.importBytes(
      document.targetDatabaseId,
      document.targetArchiveId,
      this.pdfViewer.pdfBytes,
      '.pdf',
      'application/pdf',
      updateSession
    );

    let indexDataSavedSuccessfully = true;
    const tableDataSavedSuccessfully = true;

    const save$ = import$.pipe(
      switchMap((importedDocument) => {
        const updateArray$: Observable<void>[] = [];
        assertExists(this.document, 'There must be an active document.');
        if (updateSession.updateFieldData) {
          const fieldValues = this.rightSidebar.indexer.getFieldValuesForSave();
          const updateFieldData$ = this.documentUpdateProvider
            .updateFieldData(
              document.targetDatabaseId,
              document.targetArchiveId,
              importedDocument.id,
              importedDocument.secureId,
              fieldValues,
              updateSession
            )
            .pipe(
              catchError((error: UserFriendlyError) => {
                this.logger.error(
                  'An error occurred while attempting to save index data.',
                  error
                );
                error.i18n = 'FAILED_TO_SAVE_INDEX_DATA';
                indexDataSavedSuccessfully = false;
                return throwError(() => error);
              }),
              tap(() => {
                this.logger.debug('Index data saved.');
                this.rightSidebar.indexer.indexerForm.markAsPristine();
              })
            );

          updateArray$.push(updateFieldData$);
        }

        if (updateSession.updateTableData) {
          assertExists(
            this.tableFieldGrid,
            'There must be a table field grid.'
          );
          const updateTableData$ = this.tableFieldGrid
            .saveTableData(
              importedDocument.id,
              importedDocument.secureId,
              updateSession
            )
            .pipe(
              catchError((error: UserFriendlyError) => {
                error.i18n = 'FAILED_TO_SAVE_TABLE_DATA';
                return throwError(() => error);
              }),
              tap(() => {
                this.tableFieldGrid.clearData(false);
              })
            );

          updateArray$.push(updateTableData$);
        }

        return forkJoin(updateArray$);
      })
    );

    save$
      .pipe(
        finalize(() => {
          this.saveInProgress.set(false);
        })
      )
      .subscribe(() => {
        if (indexDataSavedSuccessfully && tableDataSavedSuccessfully) {
          this.notify.success('DOCUMENT_CHANGES_SAVED_SUCCESSFULLY_MSG');
          const currentIndex = this.documentIndex();
          const nextDocumentIndex = this.canNavigateToNextDocument()
            ? currentIndex + 1
            : 0;
          const allDocuments = this.allDocuments();
          assertExists(allDocuments, 'There must be at least one document.');

          if (allDocuments.length - 1 === 0) {
            // there are no more documents so close the view.
            this.newDocumentService.delete([document.id]).subscribe(() => {
              this.onClickCloseView();
            });
            return;
          }

          // There are more new documents available if we get here so pick which one to navigate to next.
          const nextDocument = allDocuments[nextDocumentIndex];
          from(
            this.router.navigate([
              'db',
              nextDocument.targetDatabaseId,
              'new',
              nextDocument.id,
            ])
          ).subscribe((navigationSuccessful) => {
            if (!navigationSuccessful) {
              return;
            }

            this.newDocumentService.delete([document.id]).subscribe();
          });
        } else if (indexDataSavedSuccessfully || tableDataSavedSuccessfully) {
          this.notify.warning('DOCUMENT_CHANGES_SAVED_WITH_FAILURES_MSG');
        } else {
          this.notify.error({
            i18n: 'ERROR_DOCUMENT_CHANGES_NOT_SAVED_MSG',
            description: '',
            error: undefined,
          });
        }
      });
  }

  onClickToggleModifyThumbnailer(): void {
    this.showModifyDocumentThumbnailer.set(
      !this.showModifyDocumentThumbnailer()
    );
  }

  /**
   * Handler for the document loading change event.
   *
   * @param documentIsLoading Whether the document is currently loading.
   */
  onDocumentLoadingChange(documentIsLoading: boolean) {
    this.isDocumentLoading.set(documentIsLoading);
  }

  /**
   * Handler for the indexer field focused event.
   *
   * @param field Field that was focused.
   */
  onIndexerFieldFocused(field: Field) {
    // Stop grid editting if there is currently a table field.
    // this.tableFieldGrid?.grid.api.stopEditing();
    // if (this.kfiActive) {
    //   this.currentField = field;
    //   this.highlightByRegularExpression(field);
    // }
    this.logger.debug('Indexer field focused event fired.', field);
  }

  /** Hander for failure to load document. */
  onLoadFailure() {
    this.notify.warning('We have failed.');
  }

  /** Handler for the PDF modified event. */
  onPdfModified(event: PdfModifiedEvent) {
    const document = this.document();
    assertExists(document);
    // Updates the PDF in the database which will trigger a reload of it in the viewer.
    this.newDocumentService
      .updatePdfDocumentAttachment(document.id, event.modifiedPdfBytes)
      .subscribe();
  }

  private routeToFirstNewDocument(): void {
    // Always run a fresh query on the document database here.
    this.newDocumentService.selectAll().subscribe((documents) => {
      if (documents.length === 0) {
        this.logger.error(
          'Unable to navigate to first new document. There are no new documents.'
        );
        return;
      }

      this.router.navigate([
        'db',
        documents[0].targetDatabaseId,
        'new',
        documents[0].id,
      ]);
    });
  }
}
