import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/** Confirmation Dialog Data. */
export interface ConfirmationDialogData {
  /** Cancel action button text. */
  cancelActionText: string;
  /** Confirm action button text. */
  confirmActionText: string;
  /** Dialog Contents. */
  contents: string;
  /** Dialog Title. */
  title: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: false,
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationDialogData
  ) {}
}
