import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProgressDialogData } from 'src/app/services/progress-dialog.service';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss'],
  standalone: false,
})
export class ProgressDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ProgressDialogData) {}
}
