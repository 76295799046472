import moment from 'moment';

/**
 * Covert a string to a `number` or `undefined` explicitly.
 *
 * @param str String to convert.
 * @returns A `number` if possible, otherwise `undefined`.
 */
export const toNumberOrUndefined = (str: string): number | undefined =>
  str ? Number(str) : undefined;

/**
 * Test if a string is a valid email address.
 *
 * @param str String to test.
 * @returns `true` if string is email.
 */
export const isEmail = (str: string): boolean =>
  new RegExp(
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
  ).test(str);

/**
 * Retrieve the filename without its extension.
 *
 * @param filename Filename with extension.
 * @returns Filename without the extension.
 */
export const removeFileExtension = (filename: string): string =>
  filename.slice(0, Math.max(0, filename.lastIndexOf('.')));

/**
 * Add a string between two indexes.
 *
 * @param origin Original string.
 * @param start Start index.
 * @param end End index.
 * @param insertion Value to be inserted.
 * @returns A string.
 */
export const replaceBetweenIndexes = (
  origin: string,
  start: number,
  end: number,
  insertion: string
): string => {
  return (
    origin.slice(0, Math.max(0, start)) +
    insertion +
    origin.slice(Math.max(0, end))
  );
};

/**
 * Checks if one date string is equal to a another.
 *
 * @param oldValue Old value.
 * @param newValue New value.
 * @returns A boolean indicating if the strings are the same moment.
 */
export const isSameMoment = (oldValue: string, newValue: string): boolean => {
  // create a default date to use if one of the values is empty.
  const defaultDate = moment().subtract(1, 'days');
  const oldDate = oldValue ? moment(oldValue) : defaultDate;
  const newDate = newValue ? moment(newValue) : defaultDate;
  return oldDate.isSame(newDate);
};

/**
 * Filters a list by the value provided.
 *
 * @param value Value for which to filter the list.
 * @param list List to search.
 * @param maxMatches Max number of matches to return. Defaults to the length of the list provided.
 * @returns An array of list values where the value is included in the entry.
 */
export const filterList = (
  value: string,
  list: string[],
  maxMatches = list.length
): string[] => {
  if (!value) return [];

  value = value.toLowerCase();
  const matchedListValues: string[] = [];

  for (
    let index = 0, count = list.length;
    index < count && matchedListValues.length <= maxMatches;
    ++index
  ) {
    if (list[index].toLowerCase().includes(value)) {
      matchedListValues.push(list[index]);
    }
  }

  return matchedListValues;
};

/**
 * Converts a byte array to a data URL.
 * @param uint8Array Byte array
 * @param mimeType File mime type.
 * @returns A data URL for the provided byte data and mime type.
 */
export const createDataUrl = (
  uint8Array: Uint8Array,
  mimeType = 'application/pdf'
): string => {
  const base64String = uint8ArrayToBase64(uint8Array);
  return `data:${mimeType};base64,${base64String}`;
};

/**
 * Converts a byte array to a base64 encoded string.
 * @param uint8Array Byte array.
 * @returns A base64 encoded string.
 */
export const uint8ArrayToBase64 = (uint8Array: Uint8Array): string => {
  let binary = '';
  const len = uint8Array.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }
  return window.btoa(binary);
};

/**
 * Move or copies items in an array from the provided index or indices to a target index.
 * This **will** modify the source array. Use @see moveOrCopyImmutable for creating a new array instance.
 * @param arr Source array. Note array will be modified.
 * @param source Either a single index or an array of indices to be copied or moved.
 * @param target Target index for the source indices.
 * @param isAfter Whether to insert after the target index.
 * @param isMove Whether to delete the source indices from their original location.
 */
export const moveOrCopy = <T>(
  arr: T[],
  source: number | number[],
  target: number,
  isAfter: boolean,
  isMove: boolean
): void => {
  // Copy first
  // Adjust for before/after
  const newPos = isAfter ? target + 1 : target;
  // Ensure array
  var srcArr = Array.isArray(source) ? source : [source];
  // Ensure asc sort
  srcArr.sort();
  // Map indexes to values
  var srcVals = srcArr.map((i) => arr[i]);
  // Copy all values to target
  arr.splice(newPos, 0, ...srcVals);
  // Move if required, delete original
  if (isMove) {
    // Reverse src indexes to avoid need to offset when delete
    srcArr.reverse();
    // biome-ignore lint/style/useForOf: This loop doesn't work correctly with a for of loop.
    for (let i = 0; i < srcArr.length; i++) {
      // Adjust delete target index if the copy target was before the original
      const delPos = srcArr[i] < newPos ? srcArr[i] : srcArr[i] + srcArr.length;
      // Delete the source index
      arr.splice(delPos, 1);
    }
  }
};
