import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Field } from 'models';

import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';
import { LiveFieldService } from 'src/app/services/live-field.service';
import { FieldBaseComponent } from '../field-component.base.component';
import { MultiValueFieldMenuComponent } from '../multi-value-field-menu/multi-value-field-menu.component';

@Component({
  selector: 'app-character-field',
  templateUrl: './character-field.component.html',
  styleUrls: ['./character-field.component.scss'],
  standalone: false,
})
export class CharacterFieldComponent
  extends FieldBaseComponent
  implements OnInit
{
  /** Whether to allow character fields above 150 characters to display as textarea. Defaults to false. */
  @Input()
  allowTextArea = false;
  /** Form control for the field. */
  @Input('form-control')
  control: UntypedFormControl;
  /**
   * Field.
   */
  @Input()
  field: Field;
  /**
   * Emits when this field is blurred. The event contains the field that was blurred.
   */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Input element reference. */
  @ViewChild('input')
  inputElement: ElementRef;
  /** MV Field Menu Component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;

  constructor(
    protected translate: TranslocoService,
    private liveFieldService: LiveFieldService,
    private logger: NGXLogger
  ) {
    super(translate);
  }

  /**
   * Whether the field should be displayed as a textarea.
   *
   * @returns True if the field should be displayed as a textarea.
   */
  get showFieldAsTextarea(): boolean {
    return this.allowTextArea && this.field.length > 150;
  }

  /** @inheritdoc */
  focus(): void {
    this.inputElement.nativeElement.focus();
    this.inputElement.nativeElement.select();
  }

  ngOnInit(): void {
    this.addValidators();

    // Invoke LiveField.
    if (this.field.liveField) {
      this.control.disable(); // Make readonly, and exclude from validation.
      this.logger.debug('Loading LiveField:', this.field.liveField);
      this.liveFieldService
        .execute(this.field.liveField)
        .subscribe((result) => {
          this.control.setValue(result);
          this.logger.debug('LiveField result:', result);
        });
    }
  }

  /** @inheritdoc */
  onBlur(event: FocusEvent): void {
    this.fieldBlurred.emit(this.field);
  }

  /** @inheritdoc */
  onFocus(): void {
    this.fieldFocused.emit(this.field);
  }
}
