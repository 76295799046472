import { OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { HotkeysService } from '@ngneat/hotkeys';
import { NGXLogger } from 'ngx-logger';

import { ApiMetadataProvider } from 'models';

import { API_METADATA_PROVIDER } from '../common/tokens';
import { ConsoleLogo } from '../console-logo';
import { AppConfigService } from '../modules/app-config';
import { CommandPaletteComponent } from '../modules/command-palette';
import { AuthenticationService } from '../services/authentication.service';
import { CheckForUpdateService } from '../services/check-for-update.service';
import { ApplicationQuery } from '../state/application/application.query';
import { ApplicationService } from '../state/application/application.service';
import { DatabasesService } from '../state/databases/databases.service';
import { InboxesService } from '../state/inboxes/inboxes.service';

import { LocationStrategy } from '@angular/common';
import { assert } from 'common';
import { Subscription, combineLatest, forkJoin, map, take, tap } from 'rxjs';
import { LanguagesService } from '../services/languages.service';
import { NewPdfDocumentService } from '../services/new-documents.service';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';

const darkClassName = 'dark-mode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  /** Class for the AppComponent. */
  @HostBinding('class') className = '';

  // Logout when window is unloaded, closed, etc...
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: Event) {
    if (this.isRestrictedUser) this.authService.logout().subscribe();
  }

  private languageChangeSubscription: Subscription;
  private isRestrictedUser = this.authService.isRestrictedUser;
  private shortcutDialogRef: MatDialogReference<any>;

  constructor(
    private _databaseService: DatabasesService, // Constructor needs to run.
    private _inboxesService: InboxesService, // Constructor needs to run.
    private _checkForUpdate: CheckForUpdateService, // Constructor needs to run.
    private _newDocumentService: NewPdfDocumentService, // Constructor needs to run.
    @Inject(API_METADATA_PROVIDER)
    private apiMetadataProvider: ApiMetadataProvider,
    private translateService: TranslocoService,
    private authService: AuthenticationService,
    private app: ApplicationQuery,
    private applicationService: ApplicationService,
    private appConfigService: AppConfigService,
    private languageService: LanguagesService,
    private hotkeys: HotkeysService,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private overlayContainer: OverlayContainer,
    private locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    // Print console logo.
    ConsoleLogo.print();

    // Configure dark mode.
    this.app.darkMode$.subscribe(this.darkModeHandler);

    // Configure hotkeys.
    this.hotkeys.registerHelpModal(() => {
      this.shortcutDialogRef?.close(); // make sure we don't have multiple instances.
      this.shortcutDialogRef = this.dialog.open(ShortcutsComponent);
    });

    // Register command palette hotkey.
    this.hotkeys
      .addShortcut({
        group: 'Global',
        keys: 'control.shift.p',
        description: 'Show the command palette',
      })
      .subscribe(() => {
        this.logger.debug('Hotkey for command palette pressed.');
        if (!this.authService.isLoggedIn) {
          this.logger.debug(
            'Cannot open command palette. User is not logged in yet.'
          );
          return;
        }
        this.showCommandPalette();
      });

    // Configure i18n language.
    this.listenForSelectedLanguage();

    // Register SVG icons.
    this.addSvgIcons();

    // Refresh configuration on application load, if available.
    if (this.app.instanceUrl) {
      this.appConfigService.get(this.app.instanceUrl).subscribe(() => {});
    }

    // Ensure that we retrieve the API version after a user is logged in.
    this.app.userLoggedIn$.subscribe((user) => {
      if (user) {
        this.apiMetadataProvider.getVersion().subscribe((apiVersion) => {
          this.applicationService.setApiVersion(apiVersion);
        });
      }
    });
  }

  private addSvgIcons(): void {
    this.iconRegistry.addSvgIcon(
      'pin_off',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() + 'assets/icons/pin-off.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'file_word',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() + 'assets/icons/file-word.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'file_powerpoint',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() + 'assets/icons/file-powerpoint.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'file_excel',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() + 'assets/icons/file-excel.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'ocr',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() + 'assets/icons/ocr.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'save_all',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.locationStrategy.getBaseHref() +
          'assets/icons/content-save-all.svg'
      )
    );
  }

  /**
   * Respond to changes in Dark Mode.
   *
   * @param on New desired value.
   */
  private darkModeHandler = (on: boolean) => {
    // Apply to app container
    this.className = on ? darkClassName : '';

    // Apply to overlay container
    if (on) {
      this.overlayContainer.getContainerElement().classList.add(darkClassName);
    } else {
      this.overlayContainer
        .getContainerElement()
        .classList.remove(darkClassName);
    }
  };

  private listenForSelectedLanguage(): void {
    combineLatest([
      this.languageService.getAvailableLanguages().pipe(
        map((languages) => {
          // Ensure languages are lower case and store them as available.
          languages = languages.map((l) => l.toLowerCase());
          this.translateService.setAvailableLangs(languages);
          return languages;
        })
      ),
      this.app.currentLanguage$,
    ]).subscribe({
      next: ([availableLanguages, language]) => {
        {
          this.logger.debug('Avilable languages:', availableLanguages);
          this.languageChangeSubscription?.unsubscribe();
          this.languageChangeSubscription = this.translateService
            .load(language)
            .pipe(take(1))
            .subscribe(() => {
              assert(
                availableLanguages.includes(language.toLowerCase()),
                'Language must be in the available languages.'
              );
              this.translateService.setActiveLang(language.toLowerCase());
            });
        }
      },
    });
  }

  private showCommandPalette() {
    this.dialog.open(CommandPaletteComponent, { minWidth: 400 });
  }
}
