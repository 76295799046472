import { isSignal, untracked, computed } from '@angular/core';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { assertInjector } from 'ngxtension/assert-injector';
import { combineLatest, identity, startWith, isObservable, distinctUntilChanged, from } from 'rxjs';

/**
 * `derivedFrom` is a function that takes an array/object with `Observable` or `Signal` values and returns a `Signal` that
 * emits the values of the `Observable` or `Signal` values. It is similar to `combineLatest` but it will emit
 * when the value of the `Observable` or `Signal` changes.
 *
 * @param {ObservableSignalInputTuple} sources - array/object of `Observable` or `Signal` values
 * @param {OperatorFunction} [operator] - operator to apply to the `Observable` or `Signal` values
 * @param {DerivedFromOptions} [options] - options to pass initialValue and/or injector to use to inject the `Observable` or `Signal` values
 * @returns {Signal} - `Signal` that emits the values of the `Observable` or `Signal` values
 *
 * @example
 *
 * ```ts
 * export class MyComponent {
 *  private readonly filtersService = inject(FiltersService);
 *  readonly pageNumber = signal(1);
 *
 *  readonly data = derivedFrom(
 *   [this.pageNumber, this.filtersService.filters$],
 *   pipe(
 *     switchMap(([pageNumber, filters]) => this.dataService.getData(pageNumber, filters)),
 *     startWith([])
 *   );
 * }
 * ```
 */
function derivedFrom(...args) {
  const {
    normalizedSources,
    hasInitValue,
    operator,
    options
  } = _normalizeArgs(args);
  const injector = assertInjector(derivedFrom, options?.injector);
  /* try { // Custom error handling for derivedFrom */
  const ret = hasInitValue ? toSignal(combineLatest(normalizedSources).pipe(operator), {
    initialValue: options.initialValue,
    injector
  }) : toSignal(combineLatest(normalizedSources).pipe(operator), {
    injector,
    requireSync: true
  });
  return ret;
}
function _normalizeArgs(args) {
  if (!args || !args.length || typeof args[0] !== 'object')
    //valid even for Array
    throw new TypeError('derivedFrom needs sources');
  const hasOperator = typeof args[1] === 'function';
  if (args.length == 3 && !hasOperator) throw new TypeError('derivedFrom needs pipeable operator as a second argument');
  if (!hasOperator) args.splice(1, 0, identity);
  const [sources, operator, options] = args;
  const hasInitValue = !!options && 'initialValue' in options;
  const normalizedSources = Object.entries(sources).reduce((acc, [keyOrIndex, source]) => {
    if (isSignal(source)) {
      acc[keyOrIndex] = toObservable(source, {
        injector: options?.injector
      }).pipe(startWith(untracked(source)) /* this is done because toObservable doesn't immediatly emit initialValue of the signal */);
    } else if (isObservable(source)) {
      acc[keyOrIndex] = source.pipe(distinctUntilChanged());
    } else if (typeof source === 'function') {
      const computedFn = computed(source);
      acc[keyOrIndex] = toObservable(computedFn, {
        injector: options?.injector
      }).pipe(startWith(source()));
    } else {
      acc[keyOrIndex] = from(source).pipe(distinctUntilChanged());
    }
    return acc;
  }, Array.isArray(sources) ? [] : {});
  return {
    normalizedSources,
    operator,
    hasInitValue,
    options
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { derivedFrom };
