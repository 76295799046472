import {
  ChangeDetectionStrategy,
  Component,
  EnvironmentInjector,
  OnInit,
  Signal,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { assertExists } from 'common';
import { Archive, DocumentRevision } from 'models';
import { NGXLogger } from 'ngx-logger';
import { createNotifier } from 'ngxtension/create-notifier';
import { deriveLoading } from 'ngxtension/derive-loading';
import { derivedFrom } from 'ngxtension/derived-from';
import { catchError, debounceTime, defer, pipe, switchMap } from 'rxjs';
import { CURRENT_DOCUMENT_REVISION } from 'src/app/common/constants';
import { DOCUMENT_PROVIDER } from 'src/app/common/tokens';
import {
  ArchiveRevisionRequestedDocument,
  ArchiveRevisionRequestedDocumentMap,
} from 'src/app/models';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';

// Below type is only to avoid use of `any`.
//`Router.navigate` takes commands as `any[]` but we only use `string` and `number` here.
/** Routing command given to `router.navigate`. */
type RouterCommand = string | number;

@Component({
  selector: 'app-revisions-menu',
  templateUrl: './revisions-menu.component.html',
  styleUrl: './revisions-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RevisionsMenuComponent implements OnInit {
  /**
   * Revision version currently being viewed.
   *
   * Defaults to 0 if not viewing a previous revision.
   */
  activeVersion = input<number>(0);
  /**
   * Archive ID.
   *
   * The archive used in this component is overridden with the versions archive if the activeVersion set is above `0`.
   */
  archiveId = input.required<number>();
  /** Badge label text. */
  badgeLabel = computed(() =>
    this.viewingCurrentRevision() ? '✔' : this.activeVersion()
  );
  /** Badge color. */
  badgeColor = computed(() =>
    this.viewingCurrentRevision() ? undefined : 'accent'
  );
  /** Database ID. */
  databaseId = input.required<number>();
  /** Document ID. */
  documentId = input.required<number>();
  /** Document secure ID. */
  documentSecureId = input.required<string>();
  /** Are multiple documents open in the document view. */
  multipleDocumentsOpen = input.required<boolean>();

  /** Active archive. */
  protected archive: Signal<Archive> = computed(() =>
    this.archivesQuery.getArchive(this.archiveId())
  );
  /** Does the user have the view revisions permission. */
  protected hasPermissionToViewRevisions = computed(
    () =>
      this.archive().permissions.viewDocumentRevisions &&
      this.archivesQuery.getVersionsArchive().permissions.viewDocuments
  );
  /** Array of document revisions. */
  protected documentRevisions: Signal<DocumentRevision[]> = signal([]);
  /** Are revisions being loaded. */
  protected documentRevisionsLoading: Signal<boolean>;
  /** Whether we are showing a previous revision. */
  protected showingPreviousRevision = computed(() => this.activeVersion() > 0);
  /** Whether we are showing the current revision. */
  protected viewingCurrentRevision = computed(() => this.activeVersion() === 0);

  // Injections.
  private applicationQuery = inject(ApplicationQuery);
  private archivesQuery = inject(ArchivesQuery);
  private documentApi = inject(DOCUMENT_PROVIDER);
  private injector = inject(EnvironmentInjector);
  private logger = inject(NGXLogger);
  private router = inject(Router);

  /** Refresh notifier that allows signals to be recomputed. */
  private refreshNotifier = createNotifier();

  constructor() {
    effect(() => {
      this.logger.debug('Active version: ', this.activeVersion());
    });
  }

  ngOnInit(): void {
    const user = this.applicationQuery.user;
    assertExists(user, 'User must exist to load revisions menu.');
    this.documentRevisions = derivedFrom(
      [
        this.databaseId,
        this.archiveId,
        this.documentId,
        this.documentSecureId,
        this.refreshNotifier.listen,
      ],
      pipe(
        debounceTime(500), // debounce to avoid multiple input signals causing multiple invalid requests to getDocumentRevisions.
        switchMap(([databaseId, archiveId, documentId, secureId]) =>
          this.documentApi
            .getDocumentRevisions(
              databaseId,
              archiveId,
              documentId,
              secureId,
              user.token
            )
            .pipe(
              catchError((error) => {
                // Catch errors, log them and return an empty revisions array to prevent execution from stopping.
                this.logger.error(error);
                return [];
              })
            )
        )
      ),
      { initialValue: [], injector: this.injector }
    );

    // This will only be set to true if the observable takes longer than a threshhold.
    // See https://ngxtension.netlify.app/utilities/operators/derive-loading/ for more details.
    this.documentRevisionsLoading = toSignal(
      // We need the observable to attach deriveLoading.
      toObservable(this.documentRevisions, { injector: this.injector }).pipe(
        deriveLoading()
      ),
      { initialValue: false, injector: this.injector }
    );
  }

  onClickGoToCurrentRevision() {
    this.logger.debug('Go to current revision clicked.');

    this.onClickRevision({
      documentId: this.documentId(),
      secureId: this.documentSecureId(),
      version: CURRENT_DOCUMENT_REVISION,
    });
  }

  onClickRevision(revision: DocumentRevision) {
    this.logger.debug(
      'Revision clicked.',
      revision,
      this.multipleDocumentsOpen()
    );

    const requestDocuments = new ArchiveRevisionRequestedDocumentMap([
      new ArchiveRevisionRequestedDocument(
        this.documentId(),
        this.documentSecureId(),
        revision.version
      ),
    ]);

    const routeCommands = [
      'db',
      this.databaseId(),
      'archive',
      this.archiveId(),
      'document',
      requestDocuments.toString(),
    ];

    if (this.multipleDocumentsOpen()) {
      this.logger.debug(
        'Multiple documents are open so revision will be opened in a new tab.'
      );

      const urlTree = this.router.createUrlTree(routeCommands, {
        queryParamsHandling: 'merge',
      });

      window.open(urlTree.toString(), '_blank');
      return;
    }

    const routing$ = this.createRouting$(routeCommands);

    routing$.subscribe();
  }

  /** Handler for the revision menu button click event. */
  onClickRevisionMenu(): void {
    this.refresh();
  }

  /** Triggers a refresh of the component. */
  refresh(): void {
    this.refreshNotifier.notify();
  }

  /** Creates a routing observable with the provided commands. */
  private createRouting$(commands: RouterCommand[]) {
    return defer(() =>
      this.router.navigate(commands, {
        queryParamsHandling: 'merge',
      })
    );
  }
}
