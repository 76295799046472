import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { assert } from 'common';
import { TaskSearch } from 'models';

import { ApplicationQuery } from '../application/application.query';

import { AppConfigQuery } from 'src/app/modules/app-config';
import { TaskSearchesState, TaskSearchesStore } from './task-searches.store';

/**
 * Tuple of Database Id, Archive Id, Search Id, and SearchPrompts.
 *
 * @typedef {number} DatabaseId
 * @typedef {[DatabaseId]} SearchRouteParametersTuple
 */
type TaskSearchRouteParametersTuple = Observable<
  [number | undefined, number | undefined, string | undefined]
>;

/** Global Action Task Searches Query. */
@Injectable({ providedIn: 'root' })
export class TaskSearchesQuery extends QueryEntity<TaskSearchesState> {
  /** Observable of the active task search. */
  active$ = this.selectActive();
  /** Observable of the active task search id. */
  activeId$ = this.selectActiveId();
  /** Observable of if any task searches are enabled. */
  anyEnabledTaskSearches$: Observable<boolean>;
  /** Observable of enabled task searches. */
  enabledTaskSearches$ = combineLatest([
    this.applicationQuery.enabledTaskSearches$,
    this.selectAll(),
    this.applicationConfigQuery.disableGlobalActionTasks$,
  ]).pipe(
    map(([enabledTaskSearches, taskSearches, disableGlobalActionTasks]) => {
      if (disableGlobalActionTasks) {
        // Skip filtering task searches and return an empty array if the instance has tasks disabled.
        return [];
      }

      return taskSearches.filter((taskSearch) =>
        enabledTaskSearches.includes(taskSearch.id)
      );
    })
  );
  /** Observable of task searches loading state. */
  isLoading$ = this.selectLoading();
  /**
   * Task search route parameters.
   *
   * @description Observable of Database, Archive and Task ID tuple.
   */
  taskSearchRouteParams$: TaskSearchRouteParametersTuple = this.routerQuery
    .selectParams(['dbId', 'archiveId', 'taskId'])
    .pipe(
      map(([databaseId, archiveId, taskId]) => {
        // ensure all values are either number or undefined;
        databaseId = databaseId ? Number(databaseId) : undefined;
        archiveId = archiveId ? Number(archiveId) : undefined;
        taskId = taskId ? String(taskId) : undefined;
        assert(
          typeof databaseId === 'number' || typeof databaseId === 'undefined'
        );
        assert(
          typeof archiveId === 'number' || typeof archiveId === 'undefined'
        );
        assert(typeof taskId === 'string' || typeof taskId === 'undefined');
        return [databaseId, archiveId, taskId];
      })
    );
  /** Observable of all task searches. */
  taskSearches$ = this.selectAll();

  /**
   * Currently active task search.
   *
   * @returns Task Search.
   * @throws {TypeError} If active task search is undefined.
   */
  get active(): TaskSearch {
    const taskSearch = this.getActive();
    if (typeof taskSearch === 'undefined') {
      throw new TypeError('No task search is currently active.');
    }
    return taskSearch;
  }

  constructor(
    protected store: TaskSearchesStore,
    protected applicationQuery: ApplicationQuery,
    protected applicationConfigQuery: AppConfigQuery,
    protected routerQuery: RouterQuery
  ) {
    super(store);
    this.anyEnabledTaskSearches$ = this.enabledTaskSearches$.pipe(
      map((taskSearches) => taskSearches.length > 0)
    );
  }

  /**
   * Gets the active task search.
   *
   * @returns A task search.
   */
  getActive() {
    const taskId = this.getActiveId();
    return this.getAll().find(
      (taskSearch: TaskSearch) => taskSearch.id === taskId
    );
  }
}
