import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';

/**
 * Describes the data passed to the dialog.
 */
export interface FindInFileDialogData {
  /** Text to search for. */
  searchText: string;
}

@Component({
  selector: 'app-find-in-file-dialog',
  templateUrl: './find-in-file-dialog.component.html',
  styleUrls: ['./find-in-file-dialog.component.scss'],
  standalone: false,
})
export class FindInFileDialogComponent {
  constructor(
    public dialogReference: MatDialogReference<FindInFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FindInFileDialogData
  ) {}

  /**
   * Event handler for intiating a search.
   */
  onRunSearch(): void {
    this.dialogReference.close(this.data.searchText);
  }
}
