<h2 mat-dialog-title>{{ 'NEW_DOCUMENTS' | transloco | titlecase }}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Selected Archive</mat-label>
    <mat-select
      [value]="selectedArchive()"
      (selectionChange)="onArchiveSelectionChange($event)"
    >
      @for (archive of archivesWithNewDocuments(); track archive) {
        <mat-option [value]="archive">{{ archive.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <ag-grid-angular
    #grid
    class="ag-theme-material"
    [class.grid-with-pdf-viewer]="pdfBytes()"
    [gridOptions]="gridOptions"
  >
  </ag-grid-angular>
  @if (pdfBytes()) {
    <div class="pdf-viewer-container">
      <ng2-pdfjs-viewer
        viewerId="new-document-pdf-preview-viewer"
        downloadFileName="Square9Download.pdf"
        [pdfSrc]="pdfBytes()!"
        [openFile]="false"
        [page]="1"
        #pdfPreviewViewer
      ></ng2-pdfjs-viewer>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'CANCEL' | transloco }}
  </button>
  <button
    mat-button
    [disabled]="selectedRows().length === 0"
    (click)="onClickDelete()"
  >
    {{ 'DELETE' | transloco }}
  </button>
  <button
    mat-button
    [disabled]="selectedRows().length === 0"
    (click)="onClickClosePreview()"
  >
    {{ 'CLOSE_PREVIEW' | transloco }}
  </button>
  <span class="spacer"></span>
  <button
    mat-button
    [disabled]="selectedRows().length === 0"
    (click)="onClickOpen()"
  >
    {{ 'OPEN' | transloco }}
  </button>
</mat-dialog-actions>
