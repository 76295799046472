import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, catchError, finalize, forkJoin } from 'rxjs';

import {
  AppendType,
  EmailInboxRequest,
  Inbox,
  InboxDocumentTransferResult,
  InboxFiles,
  InboxIndexToArchiveSession,
  InboxMergeDocument,
  MergeTarget,
  MergeTargetType,
  UserFriendlyError,
} from 'models';
import { ActionsMenu, InboxDocumentOpenRequest } from 'src/app/models';
import { GseService } from 'src/app/services/gse.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProgressDialogService } from 'src/app/services/progress-dialog.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';
import { InboxesService } from 'src/app/state/inboxes/inboxes.service';

import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../confirmation-dialog/confirmation-dialog.component';
import {
  DestinationSelectDialogComponent,
  DestinationSelectionDialogData,
  DestinationSelectionResult,
  DestinationSelectionType,
} from '../destination-select-dialog/destination-select-dialog.component';
import { MenuBaseComponent } from '../menu/menu-base.component';

/** Inbox actions menu. */
@UntilDestroy()
@Component({
  selector: 'app-inbox-actions-menu',
  templateUrl: './inbox-actions-menu.component.html',
  styleUrls: ['./inbox-actions-menu.component.scss'],
  standalone: false,
})
export class InboxActionsMenuComponent
  extends MenuBaseComponent
  implements OnInit, ActionsMenu
{
  /** If elements related to specifically multiple document contexts should be hidden. */
  @Input()
  hideMultiple: boolean;
  /** If the open option should be hidden. */
  @Input()
  hideOpen: boolean;
  /**
   * Selected InboxFiles.
   *
   * This value is normally provided by the openMenu function; however, it can
   * be set by this Input attribute binding as well.
   */
  @Input()
  inboxFiles: InboxFiles = [];
  /** Emits selected InboxFiles on change. */
  @Output()
  inboxFilesChange = new EventEmitter<InboxFiles>();
  /** Event raised when selected rows should be opened. */
  @Output()
  openSelectedDocuments = new EventEmitter<InboxDocumentOpenRequest>();
  /** Event triggered when the refresh button is used. */
  @Output()
  refreshInbox: EventEmitter<any> = new EventEmitter();

  /** If the open in the external full viewer option should be hidden. */
  hideOpenFullViewer = true;
  /** Active inbox object. */
  inbox: Inbox;
  /** Observable containing the GSE connection status. */
  isGseConnected$ = this.gseService.isConnected$;

  constructor(
    private logger: NGXLogger,
    private gseService: GseService,
    private routerQuery: RouterQuery,
    private applicationQuery: ApplicationQuery,
    private databasesQuery: DatabasesQuery,
    private inboxesQuery: InboxesQuery,
    private inboxesService: InboxesService,
    private viewer: ViewerService,
    private notifications: NotificationService,
    private progressDialogService: ProgressDialogService,
    private translate: TranslocoService,
    private router: Router,
    private dialog: MatDialog
  ) {
    super();
  }

  /**
   * Check if merge should be disabled.
   *
   * @returns True when disabled.
   */
  get mergeDisabled(): boolean {
    return (
      this.inboxFiles.length < 2 || !this.inbox.permissions.addNewDocuments
    );
  }

  ngOnInit(): void {
    this.inboxesQuery.activeInbox$.subscribe((inbox) => (this.inbox = inbox));
    // Hide the full viewer option when internal has not been enabled for use, or the user is a guest.
    this.applicationQuery.viewerUseInternal$
      .pipe(untilDestroyed(this))
      .subscribe((viewerUseInternal) => {
        this.hideOpenFullViewer = !viewerUseInternal;
      });
  }

  /** Click event for the copy documents menu button. */
  onClickCopy(): void {
    this.logger.debug('Copy documents button was clicked.');
    this.transferDocuments(false);
  }

  /** Click event for the copy documents menu button. */
  onClickDelete(): void {
    this.logger.debug('Delete documents button was clicked.');
    const data: ConfirmationDialogData = {
      cancelActionText: 'CANCEL',
      confirmActionText: 'DELETE',
      contents: 'CONFIRM_DELETE',
      title: 'DELETE_DOCUMENTS',
    };

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data,
    });

    dialog.afterClosed().subscribe((confirmed) => {
      this.logger.debug('Confirm dialog closed.', confirmed);
      if (!confirmed) return;

      this.deleteSelectedDocuments();
    });
  }

  /**
   * Click event for the email documents menu button.
   *
   * @param includeAnnotations Whether to include annotations.
   */
  onClickEmail(includeAnnotations: boolean): void {
    this.logger.debug(
      'Email documents button was clicked.',
      includeAnnotations
    );
    const request: EmailInboxRequest = {
      includeAnnotations,
      documents: this.inboxFiles.map((inboxFile) => ({
        filename: `${inboxFile.filename}${inboxFile.fileType}`,
        inboxId: this.inboxesQuery.activeId,
      })),
    };
    this.gseService.launchApi.emailInboxDocument(request).subscribe({
      error: (error: UserFriendlyError) => this.notifications.error(error),
    });
  }

  /** Handler for the export documents click event. */
  onClickExport(): void {
    this.logger.debug('Export documents button was clicked.');
    const files: InboxFiles = this.inboxFiles.map((inboxFile) => ({
      dateCreated: inboxFile.dateCreated,
      dateModified: inboxFile.dateModified,
      fileType: inboxFile.fileType,
      filename: inboxFile.filename,
      permissions: inboxFile.permissions,
    }));
    this.inboxesService.api
      .exportDocument(this.inboxesQuery.activeId, files)
      .subscribe();
  }

  /** Click event for the email documents menu button. */
  onClickIndex(): void {
    this.logger.debug('Index documents button was clicked.');
    const dialogData: DestinationSelectionDialogData = {
      hideInboxes: true,
    };
    const dialog = this.dialog.open(DestinationSelectDialogComponent, {
      minWidth: 400,
      data: dialogData,
    });

    dialog.afterClosed().subscribe((result: DestinationSelectionResult) => {
      if (!result) {
        this.logger.debug('Destination selection dialog was cancelled.');
        return;
      }

      this.logger.debug('Destination selection dialog was closed.', result);

      const archiveId = result.id;
      const inboxId = this.inboxesQuery.getActiveId() as number;

      if (this.applicationQuery.viewerUseInternalForImport) {
        const openDocumentsString = this.routerQuery.getParams('documentId');
        const viewIndex = this.routerQuery.getParams('viewIndex');
        const openDocuments =
          typeof openDocumentsString === 'undefined'
            ? this.inboxFiles.map((inboxFile) =>
                encodeURIComponent(`${inboxFile.filename}${inboxFile.fileType}`)
              )
            : (openDocumentsString as string).split(',');

        this.router.navigate(
          [
            'db',
            this.databasesQuery.activeId,
            'inbox',
            this.inbox.id,
            'document',
            openDocuments.join(),
            'view',
            viewIndex ? viewIndex : 0,
          ],
          {
            queryParams: { targetArchiveId: archiveId },
            queryParamsHandling: 'merge',
          }
        );

        return;
      }

      const session: InboxIndexToArchiveSession = {
        databaseId: this.databasesQuery.activeId,
        id: inboxId,
        archiveId,
        documents: this.inboxFiles.map((inboxFile) => ({
          filename: `${inboxFile.filename}${inboxFile.fileType}`,
          id: inboxId,
          archiveId,
        })),
      };
      this.viewer
        .createIndexFromInboxToArchiveSession(session)
        .subscribe((sessionId) => {
          this.viewer
            .openViewerSession(
              sessionId,
              this.applicationQuery.alwaysOpenNewTab
            )
            .subscribe();
        });
    });
  }

  /** Click event for the copy documents menu button. */
  onClickMerge(): void {
    this.logger.debug('Merge documents button was clicked.', this.inboxFiles);
    const inboxId = this.inboxesQuery.getActiveId() as number;
    const databaseId = this.databasesQuery.getActiveId() as number;
    const mergeDocuments = this.inboxFiles.map(
      (inboxFile) =>
        new InboxMergeDocument(
          inboxId,
          `${inboxFile.filename}${inboxFile.fileType}`
        )
    );
    const target: MergeTarget = {
      appendType: AppendType.toEnd,
      id: inboxId,
      mergeTargetType: MergeTargetType.inbox,
    };

    this.progressDialogService.openProgressDialog('MERGING_DOCUMENTS');
    this.inboxesService.api
      .mergeDocuments(
        databaseId,
        target,
        mergeDocuments[0],
        mergeDocuments.slice(1)
      )
      .pipe(finalize(() => this.progressDialogService.closeProgressDialog()))
      .subscribe({
        next: () => {
          this.logger.debug('Merge created a new document.');
          this.notifications.success('MERGE_SUCCESSFUL');
          this.refreshInbox.emit();
        },
        error: (error: UserFriendlyError) => this.notifications.error(error),
      });
  }

  /** Click event for the copy documents menu button. */
  onClickMove(): void {
    this.logger.debug('Move documents button was clicked.');
    this.transferDocuments(true);
  }

  /**
   * Click event for the open documents menu button.
   *
   * @param forceExternalViewer If the documents should be opened only with the external viewer.
   */
  onClickOpenDocuments(forceExternalViewer = false): void {
    this.logger.debug('Open documents button was clicked.');
    this.openSelectedDocuments.emit({
      forceExternalViewer,
      inboxFiles: this.inboxFiles,
    });
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   * @param inboxFiles InboxFiles to target.
   */
  openMenu(mouseEvent: MouseEvent, inboxFiles: InboxFiles): void {
    // Set the row nodes to act on
    if (inboxFiles) this.inboxFiles = inboxFiles;
    this.open(mouseEvent);
  }

  private deleteSelectedDocuments(): void {
    const inboxId = this.inboxesQuery.getActiveId() as number;
    let erroredDeletesCount = 0;
    const deletes$ = this.inboxFiles.map((inboxFile) => {
      const filenameWithExtension = `${inboxFile.filename}${inboxFile.fileType}`;
      return this.inboxesService.api
        .deleteDocument(inboxId, filenameWithExtension)
        .pipe(
          catchError((error: UserFriendlyError) => {
            error.i18n = 'INBOX_DELETE_ERROR';
            error.i18nParameters = { filename: filenameWithExtension };
            this.notifications.error(error);
            erroredDeletesCount++;
            return EMPTY;
          })
        );
    });

    forkJoin(deletes$)
      .pipe(finalize(() => this.onDocumentsDeleteFinaize(erroredDeletesCount)))
      .subscribe();
  }

  private handleTransferError(
    error: UserFriendlyError,
    filename: string
  ): Observable<never> {
    error.description = `${filename} file failed to transfer.`;
    this.notifications.error(error);
    return EMPTY;
  }

  private onDestinationDialogClosed(
    result: DestinationSelectionResult,
    inboxId: number,
    deleteOriginal: boolean
  ): void {
    if (!result) {
      this.logger.debug('Destination selection dialog was cancelled.');
      return;
    }

    if (result.type !== DestinationSelectionType.inbox) {
      this.notifications.error(
        'Inbox documents can only be copied to other inboxes.'
      );
      return;
    }

    const transfers$: Observable<InboxDocumentTransferResult>[] =
      this.inboxFiles.map((inboxFile) =>
        this.inboxesService.api
          .transferDocument(
            inboxId,
            `${inboxFile.filename}${inboxFile.fileType}`,
            result.id,
            deleteOriginal
          )
          .pipe(
            catchError((error: UserFriendlyError) =>
              this.handleTransferError(error, inboxFile.filename)
            )
          )
      );
    forkJoin(transfers$)
      .pipe(
        finalize(() => {
          this.notifications.info('Document transfer job finished.');
        })
      )
      .subscribe();
  }

  private onDocumentsDeleteFinaize(erroredDeletesCount: number): void {
    const translatedMessage = this.translate.translate(
      'DOCUMENT_DELETE_FINISHED'
    );

    if (erroredDeletesCount === 0) {
      this.notifications.success(translatedMessage);
    } else {
      this.notifications.info(translatedMessage);
      this.notifications.error(
        `${erroredDeletesCount} document(s) failed to delete.` // todo add translation once we support parameters in the keys
      );
    }

    this.refreshInbox.emit({
      filenames: this.inboxFiles.map(
        (inboxFile) => inboxFile.filename + inboxFile.fileType
      ),
    });
  }

  private transferDocuments(deleteOriginal: boolean): void {
    const inboxId = this.inboxesQuery.getActiveId() as number;
    const dialogData: DestinationSelectionDialogData = {
      hideArchives: true,
    };
    const dialog = this.dialog.open(DestinationSelectDialogComponent, {
      minWidth: 400,
      data: dialogData,
    });
    dialog
      .afterClosed()
      .subscribe((result: DestinationSelectionResult) =>
        this.onDestinationDialogClosed(result, inboxId, deleteOriginal)
      );
  }
}
