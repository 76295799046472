<div
  matRipple
  *ngIf="(taskSearch?.archives?.length ?? 0) > 0; else emptyTaskSearch"
  class="highlight-hover sidebar-group-item"
  [routerLink]="[
    'db',
    dbId,
    'archive',
    taskSearch.archives[0].id,
    'task',
    taskSearch.id,
  ]"
  [ngClass]="{ active: isActive }"
  matTooltip="{{ taskSearch.title }}"
  matTooltipShowDelay="1000"
  matTooltipPosition="right"
  [matTooltipTouchGestures]="(isHandset$ | async) ? 'off' : 'auto'"
>
  <span class="title">{{ taskSearch.title }}</span>
  <span class="spacer"></span>
  <div class="badge">{{ taskSearch.processCount }}</div>
  @if (isHandset$ | async) {
    <button mat-icon-button [matMenuTriggerFor]="mobileMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  } @else {
    <span class="right-side-clear"></span>
  }
</div>

<!-- When a task search contains no matches, it will have no archive(s), there is nothing to show. -->
<ng-template #emptyTaskSearch>
  <div
    class="sidebar-group-item disabled"
    matTooltip="{{ taskSearch.title }}"
    matTooltipShowDelay="1000"
    matTooltipPosition="right"
  >
    {{ taskSearch.title }}
  </div>
</ng-template>

<!-- This only exists to show the task search name on mobile. -->
<!-- If additionally menu items are needed, this should be refactored to work more like the nav archive actions menu. -->
<mat-menu #mobileMenu>
  <!-- This ng template lazy loads the menu. -->
  <ng-template matMenuContent>
    <button mat-menu-item disabled>{{ taskSearch.title }}</button>
  </ng-template>
</mat-menu>
