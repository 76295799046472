import { ComputeOptions, computeValue, getOperator } from "../../core";
import { assert, ensureArray, filterMissing, has, isArray, isBoolean, isEmpty, isNumber, isObject, isOperator, isString, merge, removeValue, resolve, resolveGraph, setValue } from "../../util";
const $project = (collection, expr, options) => {
  if (isEmpty(expr)) return collection;
  validateExpression(expr, options);
  return collection.map(createHandler(expr, ComputeOptions.init(options)));
};
function createHandler(expr, options, isRoot = true) {
  const idKey = options.idKey;
  const expressionKeys = Object.keys(expr);
  const excludedKeys = new Array();
  const includedKeys = new Array();
  const handlers = {};
  for (const key of expressionKeys) {
    const subExpr = expr[key];
    if (isNumber(subExpr) || isBoolean(subExpr)) {
      if (subExpr) {
        includedKeys.push(key);
      } else {
        excludedKeys.push(key);
      }
    } else if (isArray(subExpr)) {
      handlers[key] = o => subExpr.map(v => computeValue(o, v, null, options.update(o)) ?? null);
    } else if (isObject(subExpr)) {
      const subExprKeys = Object.keys(subExpr);
      const operator = subExprKeys.length == 1 ? subExprKeys[0] : "";
      const projectFn = getOperator("projection", operator, options);
      if (projectFn) {
        const foundSlice = operator === "$slice";
        if (foundSlice && !ensureArray(subExpr[operator]).every(isNumber)) {
          handlers[key] = o => computeValue(o, subExpr, key, options.update(o));
        } else {
          handlers[key] = o => projectFn(o, subExpr[operator], key, options.update(o));
        }
      } else if (isOperator(operator)) {
        handlers[key] = o => computeValue(o, subExpr[operator], operator, options);
      } else {
        validateExpression(subExpr, options);
        handlers[key] = o => {
          if (!has(o, key)) return computeValue(o, subExpr, null, options);
          if (isRoot) options.update(o);
          const target = resolve(o, key);
          const fn = createHandler(subExpr, options, false);
          if (isArray(target)) return target.map(fn);
          if (isObject(target)) return fn(target);
          return fn(o);
        };
      }
    } else {
      handlers[key] = isString(subExpr) && subExpr[0] === "$" ? o => computeValue(o, subExpr, key, options) : _ => subExpr;
    }
  }
  const handlerKeys = Object.keys(handlers);
  const idKeyExcluded = excludedKeys.includes(idKey);
  const idKeyOnlyExcluded = isRoot && idKeyExcluded && excludedKeys.length === 1 && !includedKeys.length && !handlerKeys.length;
  if (idKeyOnlyExcluded) {
    return o => {
      const newObj = {
        ...o
      };
      delete newObj[idKey];
      return newObj;
    };
  }
  const idKeyImplicit = isRoot && !idKeyExcluded && !includedKeys.includes(idKey);
  const opts = {
    preserveMissing: true
  };
  return o => {
    const newObj = {};
    if (excludedKeys.length && !includedKeys.length) {
      merge(newObj, o);
      for (const k of excludedKeys) {
        removeValue(newObj, k, {
          descendArray: true
        });
      }
    }
    for (const k of includedKeys) {
      const pathObj = resolveGraph(o, k, opts) ?? {};
      merge(newObj, pathObj);
    }
    if (includedKeys.length) filterMissing(newObj);
    for (const k of handlerKeys) {
      const value = handlers[k](o);
      if (value === void 0) {
        removeValue(newObj, k, {
          descendArray: true
        });
      } else {
        setValue(newObj, k, value);
      }
    }
    if (idKeyImplicit && has(o, idKey)) {
      newObj[idKey] = resolve(o, idKey);
    }
    return newObj;
  };
}
function validateExpression(expr, options) {
  let exclusions = false;
  let inclusions = false;
  for (const [k, v] of Object.entries(expr)) {
    assert(!k.startsWith("$"), "Field names may not start with '$'.");
    assert(!k.endsWith(".$"), "Positional projection operator '$' is not supported.");
    if (k === options?.idKey) continue;
    if (v === 0 || v === false) {
      exclusions = true;
    } else if (v === 1 || v === true) {
      inclusions = true;
    }
    assert(!(exclusions && inclusions), "Projection cannot have a mix of inclusion and exclusion.");
  }
}
export { $project };