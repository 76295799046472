import { CdkDragDrop, CdkDragSortEvent } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { assert, assertExists, assertTypeByKey } from 'common';
import { UserFriendlyError } from 'models';
import { NGXLogger } from 'ngx-logger';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { PDFDocument, degrees } from 'pdf-lib';
import {
  EMPTY,
  Observable,
  defer,
  finalize,
  from,
  lastValueFrom,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { moveOrCopy } from 'src/app/common/utility';
import { WindowService } from 'src/app/services/window.service';
import { PDF_SCAN_PROVIDER, PdfThumbnail } from '../../models';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../confirmation-dialog/confirmation-dialog.component';
import {
  PdfThumbnailerContextMenuComponent,
  ThumbnailPasteEvent,
} from '../pdf-thumbnailer-context-menu/pdf-thumbnailer-context-menu.component';

/** Burst event. */
export interface BurstEvent {
  /** Byte array of the document bursted. */
  newDocumentBytes: Uint8Array;
  /** Number of pages in the bursted document. */
  pageCount: number;
  /** Whether the bursted pages were deleted from the source. */
  pagesDeletedFromSource: boolean;
}

/** Error event. */
export interface ErrorEvent {
  /** User friendly error that will be used to display the error to the user. */
  error: UserFriendlyError;
}

export interface PdfModifiedEvent {
  /** The modified PDF bytes. */
  modifiedPdfBytes: Uint8Array;
}

/** Describes the event that requests the consumer navigate to a page. */
export interface GoToPageEvent {
  /** The page number with which to navigate. */
  pageNumber: number;
}

type NoThumbnailBeingDragged = undefined;
const NO_THUMBNAIL_BEING_DRAGGED = undefined;
type NoClipboardMode = undefined;
const NO_CLIPBOARD_MODE = undefined;
type ClipboardMode = 'cut' | 'copy' | NoClipboardMode;

@Component({
  selector: 'app-pdf-thumbnail-viewer',
  templateUrl: './pdf-thumbnail-viewer.component.html',
  styleUrls: ['./pdf-thumbnail-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PdfThumbnailViewerComponent implements AfterViewInit {
  /** Context menu component reference. */
  @ViewChild('contextMenu')
  contextmenu: PdfThumbnailerContextMenuComponent;
  /** File input reference. */
  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;
  /** Thumbnail viewport reference. */
  @ViewChild('thumbnailVirtalScrollViewPort')
  thumbnailViewPort: CdkVirtualScrollViewport;

  /** Emits when adding pages fails. */
  addPagesError = output<ErrorEvent>();
  /** Emits when a burst is finished successfully. */
  burstCompleted = output<BurstEvent>();
  /** Emits when a burst fails. */
  burstError = output<ErrorEvent>();
  /**
   * Does the PDF contain annotations.
   *
   * TODO this must be removed when proper annotations support is implemented.
   */
  containsAnnotations = computed(() =>
    this.thumbnails().some((t) => t.hasAnnotations)
  );
  /** Should drag be enabled. */
  dragDisabled = computed(
    () =>
      !this.hasModifyPermissions() ||
      this.modifyInProgress() ||
      this.containsAnnotations()
  );
  /** Emits when the consumer should display a page. */
  goToPage = output<GoToPageEvent>();
  /** Whether the user has modify permissions. */
  hasModifyPermissions = input.required<boolean>();
  /** Is a modify operation in progress. */
  modifyInProgress = signal(false);
  /** Pdf bytes of the PDF. */
  pdfBytes = input.required<Uint8Array>();
  /** Emits when the PDF is modified. */
  pdfModified = output<PdfModifiedEvent>();
  /** Is scan available. */
  scanIsAvailable: Signal<boolean>;
  /** Should actions that require thumbnail selections be disabled. */
  selectionActionsDisabled = computed(
    () =>
      this.selectedThumbnails().length < 1 ||
      !this.hasModifyPermissions() ||
      this.modifyInProgress() ||
      this.containsAnnotations()
  );
  /** Array of selected thumbnails. */
  selectedThumbnails = computed(() =>
    this.sortedThumbnails().filter((t) => t.isSelected)
  );
  /** Array of thumbnails as sorted in the rendered thumbnailer. */
  sortedThumbnails = signal<PdfThumbnail[]>([]);
  /** Thumbnail currently being dragged or undefined if no thumbnail is being dragged. */
  thumbnailBeingDragged = signal<PdfThumbnail | NoThumbnailBeingDragged>(
    NO_THUMBNAIL_BEING_DRAGGED
  );
  /** Emits when the selected thumbnails change and contains all selected thumbnails. */
  thumbnailSelectionChange = output<PdfThumbnail[]>();
  /** Array of thumbnails to display. */
  thumbnails = input.required<PdfThumbnail[]>();
  /** Array of thumbnails currently on the clipboard. */
  thumbnailsOnClipboard = computed(() =>
    this.sortedThumbnails().filter((t) => t.isOnClipboard)
  );

  private changeDetectorReference = inject(ChangeDetectorRef);
  private clipboardMode: ClipboardMode = NO_CLIPBOARD_MODE;
  private dialog = inject(MatDialog);
  private lastClickedThumbnail: PdfThumbnail | undefined;
  private logger = inject(NGXLogger);
  private pdfScan = inject(PDF_SCAN_PROVIDER);
  private renderedThumbnailIndices: number[] = [];
  private windowService = inject(WindowService);

  constructor() {
    this.scanIsAvailable = toSignal(this.pdfScan.isAvailable$(), {
      initialValue: false,
    });
    effect(() => {
      this.logger.debug('GSE Scan Available:', this.scanIsAvailable());
    });
    // Ensure that sorted thumbnails are always replaced with the thumbnails provided as an input.
    explicitEffect([this.thumbnails], ([thumbnails]) => {
      this.sortedThumbnails.set(thumbnails);
    });

    // Disable scrolling when a thumbnail is being dragged.
    effect(() => {
      const thumbnailBeingDragged = this.thumbnailBeingDragged();
      if (thumbnailBeingDragged) {
        this.windowService.disableScroll();
      } else {
        this.windowService.enableScroll();
      }
    });
  }

  ngAfterViewInit(): void {
    // Listen to the rendered page stream and store the range of indices currently in the view port;
    this.thumbnailViewPort.renderedRangeStream.subscribe((range) => {
      this.logger.debug('Range changed', range);
      // Grab all the page indices.
      const pageIndicies = this.sortedThumbnails().map((t) => t.pageIndex);
      /**
       * Angular's renderedRangeStream can emit ranges that exist outside the bounds of the thumbnails loaded into it
       * This is a workaround to detect that the range is outside the bounds of the array and to ensure we only set real
       * page indices as renderedThumbnailIndices.
       */
      const rangeOffset = range.end - (pageIndicies.length - 1);
      if (rangeOffset > 0) {
        this.logger.debug(
          `Range offset is ${rangeOffset} and requires a correction to rendered range.`
        );
      }
      const rangeStart = Math.max(0, range.start - rangeOffset);
      const rangeEnd = Math.min(pageIndicies.length - 1, range.end);
      const newPageIndices: number[] = [];
      for (const pageIndex of pageIndicies) {
        if (pageIndex >= rangeStart && pageIndex <= rangeEnd) {
          newPageIndices.push(pageIndex);
        }
      }
      this.renderedThumbnailIndices = newPageIndices;
      this.logger.debug(
        'Displayed page indices: ',
        this.renderedThumbnailIndices
      );
    });
  }

  /** Handler for the add pages button click event. */
  async onClickAddPages(): Promise<void> {
    this.modifyInProgress.set(true);

    // Convert observable confirmation into promise here to better integrate into this function's code.
    const actionConfirmed = await lastValueFrom(this.getConfirmation$());
    if (!actionConfirmed) {
      this.modifyInProgress.set(false);
      this.logger.warn('User cancelled adding pages.');
      return;
    }

    const fileInputElement = this.fileInput.nativeElement;
    // Insert the files after the last selected page or at the beginning if no page is selected.
    const insertIndex = this.lastClickedThumbnail
      ? this.lastClickedThumbnail.pageIndex + 1
      : 0;

    const fileChangeHandler = async () => {
      const files = fileInputElement.files;
      this.logger.debug('Add pages button clicked.', files);
      if (files) {
        try {
          const addedPageIndices: number[] = [];
          let targetIndex = insertIndex;
          const pdfDocument = await PDFDocument.load(this.pdfBytes());
          for (const file of files) {
            if (file.type !== 'application/pdf') {
              throw new UserFriendlyError(
                'Only PDF files can be added.',
                `The file ${file.name} is not a PDF file.`,
                'ERROR_ADDING_NON_PDF_PAGES'
              );
            }
            const fileArrayBuffer = await file.arrayBuffer();
            const bytes = new Uint8Array(fileArrayBuffer);
            const pdfDocumentToInsert = await PDFDocument.load(bytes);
            const pageIndices = pdfDocumentToInsert.getPageIndices();
            const copiedPages = await pdfDocument.copyPages(
              pdfDocumentToInsert,
              pageIndices
            );
            for (const pdfPage of copiedPages) {
              pdfDocument.insertPage(targetIndex, pdfPage);
              addedPageIndices.push(targetIndex);
              targetIndex++;
            }
          }

          const pdfBytes = await pdfDocument.save();

          this.pdfModified.emit({
            modifiedPdfBytes: pdfBytes,
          });
        } catch (error) {
          // Create a user friendly error from the error if it is not already one.
          if (!(error as object).hasOwnProperty('i18n')) {
            error = new UserFriendlyError(
              error,
              'Failed to add pages.',
              'ERROR_ADDING_PDF_PAGES'
            );
          }

          assertTypeByKey<UserFriendlyError>(error, 'i18n', 'string');
          this.addPagesError.emit({ error });
        } finally {
          this.modifyInProgress.set(false);
        }
      }

      // Prevent duplicate event listeners from being attached.
      fileInputElement.removeEventListener('change', fileChangeHandler);
    };
    fileInputElement.addEventListener('change', fileChangeHandler);
    fileInputElement.click();
  }

  /**
   * Handler for the burst button click event.
   *
   * @param deletePagesFromSource Whether the burst should delete the pages from the source document.
   */
  onClickBurst(deletePagesFromSource: boolean) {
    // Prompt for user confirmation if there are annotations otherwise just treat the confirmation as confirmed.
    this.getConfirmation$().subscribe((confirmed) => {
      if (!confirmed) {
        this.logger.warn('User cancelled burst due to annotations.');
        return;
      }
      this.runBurst(deletePagesFromSource);
    });
  }

  /**
   * Handler for the burst URL button click event.
   *
   * @params event Mouse event.
   * @param url URL to open.
   */
  onClickBurstUrl(event: MouseEvent, url: string): void {
    window.open(url, '_blank');
    event.stopImmediatePropagation();
  }

  /** Handler for the delete pages click event. */
  onClickDeletePages(): void {
    this.logger.debug('Delete pages button clicked.');
    this.modifyInProgress.set(true);
    const pageIndexes = this.thumbnails()
      .filter((thumbnail) => thumbnail.isSelected)
      .map((t) => t.pageIndex);
    const pdfDocument$ = defer(() => PDFDocument.load(this.pdfBytes()));
    const modifiedDocumentBytes$ = this.getConfirmation$().pipe(
      switchMap((confirmed) => {
        if (!confirmed) {
          this.logger.warn('User cancelled delete.');
          return EMPTY;
        }

        return pdfDocument$.pipe(
          switchMap((pdfDocument) => {
            let indexOffset = 0;
            for (const pageIndex of pageIndexes) {
              pdfDocument.removePage(pageIndex - indexOffset);
              indexOffset++;
            }

            return pdfDocument.save();
          })
        );
      })
    );

    modifiedDocumentBytes$
      .pipe(finalize(() => this.modifyInProgress.set(false)))
      .subscribe((modifiedPdfBytes) => {
        this.pdfModified.emit({ modifiedPdfBytes });
      });
  }

  /** Handler for the deselect all thumbnails button click event. */
  onClickDeselectAllThumbnails(): void {
    this.lastClickedThumbnail = undefined;
    this.sortedThumbnails.update((thumbnails) => {
      for (const thumbnail of thumbnails) {
        thumbnail.isSelected = false;
      }
      return [...thumbnails];
    });
    this.emitSelectionChange();
  }

  /**
   * Rotates the selected pages.
   * @param angle Angle in degrees.
   */
  onClickRotatePages(angle: number) {
    this.modifyInProgress.set(true);
    const modifiedDocumentBytes$ = defer(async () => {
      const pdfDocument = await PDFDocument.load(this.pdfBytes());
      const selectedThumbnails = this.selectedThumbnails();
      for (const thumbnail of selectedThumbnails) {
        const pdfPage = await pdfDocument.getPage(thumbnail.pageIndex);
        // We need to get the current rotation to add/subtract from it. Otherwise, more than one rotation won't work.
        const rotation = pdfPage.getRotation();
        pdfPage.setRotation(degrees(rotation.angle + angle));
      }

      return pdfDocument.save();
    });

    const confirmation$ = this.getConfirmation$();
    confirmation$
      .pipe(
        finalize(() => this.modifyInProgress.set(false)),
        switchMap((confirmed) => {
          if (!confirmed) {
            this.logger.warn('User cancelled rotation.');
            // Return EMPTY to stop the pipe.
            return EMPTY;
          }
          return modifiedDocumentBytes$;
        })
      )
      .subscribe({
        next: (modifiedPdfBytes) => {
          this.pdfModified.emit({ modifiedPdfBytes });
        },
      });
  }

  /** Handler for the scan pages button click event. */
  onClickScanPages(): void {
    this.logger.debug('Scan pages button clicked.');
    this.modifyInProgress.set(true);
    // Insert the scan after the last selected page or at the beginning if no page is selected.
    const insertIndex = this.lastClickedThumbnail
      ? this.lastClickedThumbnail.pageIndex + 1
      : 0;
    this.getConfirmation$()
      .pipe(
        switchMap((confirmed) => {
          if (!confirmed) {
            this.modifyInProgress.set(false);
            this.logger.warn('User cancelled scan.');
            return EMPTY;
          }
          return this.pdfScan.scan();
        })
      )
      .subscribe((scanByteResult) => {
        assert(
          scanByteResult.fileType === '.pdf',
          'Scanned file must be a PDF.'
        );
        const pdfModifiedEvent$: Observable<PdfModifiedEvent> =
          this.insertPages(
            this.pdfBytes(),
            scanByteResult.fileBytes,
            insertIndex
          ).pipe(
            finalize(() => this.modifyInProgress.set(false)),
            switchMap((result) => {
              return defer(() => result.updatedPdfDocument.save()).pipe(
                map((modifiedPdfBytes) => ({
                  modifiedPdfBytes,
                  addedPages: result.addedPageIndices,
                }))
              );
            })
          );

        pdfModifiedEvent$.subscribe((pdfModifiedEvent) => {
          this.pdfModified.emit(pdfModifiedEvent);
        });
      });
  }

  /** Handler for the select all thumbnails button click event. */
  onClickSelectAllThumbnails(): void {
    // Ensure there is no last clicked thumbnail because select all negates that concept.
    this.lastClickedThumbnail = undefined;
    this.sortedThumbnails.update((thumbnails) => {
      for (const thumbnail of thumbnails) {
        thumbnail.isSelected = true;
      }
      return [...thumbnails];
    });
    this.emitSelectionChange();
  }

  /**
   * Handler for the click event of a thumbnail.
   *
   * @param selectedThumbnail Selected thumbnail.
   * @param event Mouse click event.
   */
  onClickThumbnail(selectedThumbnail: PdfThumbnail, event: MouseEvent) {
    if ((event as PointerEvent).pointerType === 'touch') {
      this.logger.debug('Thumbnail was touched.', selectedThumbnail);
      // Touch devices ONLY toggle the touched thumbnail.
      this.sortedThumbnails.update((thumbnails) => {
        const thumbnail = thumbnails.find(
          (t) => t.pageNumber === selectedThumbnail.pageNumber
        );
        assertExists(thumbnail);
        thumbnail.toggleSelected();
        if (thumbnail.isSelected) {
          this.goToPage.emit({
            pageNumber: selectedThumbnail.pageNumber,
          });
        }
        return [...thumbnails];
      });
      this.lastClickedThumbnail = selectedThumbnail;
      this.emitSelectionChange();
      return;
    }

    if (event.altKey) {
      // Alt only requests that the viewer goes to the page and does nothing with selection.
      this.goToPage.emit({ pageNumber: selectedThumbnail.pageNumber });
      return;
    }

    if (event.shiftKey && this.lastClickedThumbnail) {
      this.sortedThumbnails.update((thumbnails) => {
        assertExists(this.lastClickedThumbnail);
        const lastClickedIndex = thumbnails.indexOf(this.lastClickedThumbnail);
        const targetIndex = thumbnails.indexOf(selectedThumbnail);
        const minIndex = Math.min(targetIndex, lastClickedIndex);
        const maxIndex = Math.max(lastClickedIndex, targetIndex) + 1;
        const thumbnailsInRange = thumbnails.slice(minIndex, maxIndex);
        for (const inRangeThumbnail of thumbnailsInRange) {
          inRangeThumbnail.isSelected = true;
        }
        return [...thumbnails];
      });
    } else if (event.ctrlKey) {
      // Toggle selection state of only the clicked thumbnail without affecting other selections.
      this.sortedThumbnails.update((thumbnails) => {
        const thumbnail = thumbnails.find(
          (t) => t.pageNumber === selectedThumbnail.pageNumber
        );
        assertExists(thumbnail);
        thumbnail.toggleSelected();
        return [...thumbnails];
      });
    } else {
      this.sortedThumbnails.update((thumbnails) => {
        for (const thumbnail of thumbnails) {
          // Deselect all thumbnails that are not the one that was clicked.
          if (thumbnail.pageNumber === selectedThumbnail.pageNumber) {
            thumbnail.toggleSelected();
            if (thumbnail.isSelected) {
              this.goToPage.emit({
                pageNumber: selectedThumbnail.pageNumber,
              });
            }
          } else {
            thumbnail.isSelected = false;
          }
        }

        return [...thumbnails];
      });
    }
    this.lastClickedThumbnail = selectedThumbnail;
    this.emitSelectionChange();
  }

  /** Handler for the context menu event. */
  onContextMenu(event: MouseEvent): void {
    // Only open the context menu if there is at least one selected thumbnail.
    const selectedThumbnails = this.selectedThumbnails();
    if (
      selectedThumbnails.length < 1 ||
      !this.hasModifyPermissions() ||
      this.containsAnnotations()
    ) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.contextmenu.open(event);
  }

  /** Handler for the copy pages event. */
  onCopyPages(): void {
    this.clipboardMode = 'copy';
    this.setSelectedThumbnailsToClipboard();
  }

  /** Handler for the cut pages event. */
  onCutPages(): void {
    this.clipboardMode = 'cut';
    this.setSelectedThumbnailsToClipboard();
  }

  /**
   * Handles the drop event for the thumbail drag event.
   *
   * @param event CdkDragDrop event.
   */
  onDropThumbnail(event: CdkDragDrop<PdfThumbnail[]>): void {
    this.logger.debug('Thumbnail drop event raised.', event);
    // The indexes in the event are relative to the rendered pages so we need to get the real indexes.
    const realPreviousIndex =
      this.renderedThumbnailIndices[event.previousIndex];
    const realCurrentIndex = this.renderedThumbnailIndices[event.currentIndex];
    if (realPreviousIndex === realCurrentIndex) {
      this.logger.debug('The dragged thumbnail was dropped on itself.');
      return;
    }
    this.modifyInProgress.set(true);
    this.createReorderedPdf(realPreviousIndex, realCurrentIndex)
      .pipe(
        switchMap((pdfDocument) => from(pdfDocument.save())),
        finalize(() => this.modifyInProgress.set(false))
      )
      .subscribe((bytes) => {
        this.pdfModified.emit({
          modifiedPdfBytes: bytes,
        });
      });
  }

  /** Handles the file input cancel event. */
  onFileInputCancel(): void {
    this.modifyInProgress.set(false);
  }

  /** Handler for the paste evente. */
  onPastePages(event: ThumbnailPasteEvent): void {
    assert(
      this.selectedThumbnails().length === 1,
      'Exactly one page must be selected to paste.'
    );
    assertExists(
      this.lastClickedThumbnail,
      'Last clicked thumbnail must exist.'
    );
    const thumbnailsOnClipboard = this.thumbnailsOnClipboard();
    this.logger.debug('Last clicked thumbnail: ', this.lastClickedThumbnail);
    const startingTargetPageIndex = this.lastClickedThumbnail.pageIndex;
    let targetIndex = startingTargetPageIndex;
    this.logger.debug('New page order position: ', event.position);
    this.modifyInProgress.set(true);
    const clipboardPageIndices = thumbnailsOnClipboard.map((p) => p.pageIndex);
    defer(async () => {
      const pdfDocument = await PDFDocument.load(this.pdfBytes());
      if (this.clipboardMode === 'cut') {
        const pageIndices = pdfDocument.getPageIndices();
        moveOrCopy(
          pageIndices,
          clipboardPageIndices,
          targetIndex,
          event.position === 'below_selection',
          true
        );
        this.logger.debug('New page order', pageIndices);
        this.reorderPages(pdfDocument, pageIndices);
      } else if (this.clipboardMode === 'copy') {
        if (event.position === 'below_selection') {
          targetIndex++;
        }
        const copiedPages = await pdfDocument.copyPages(
          pdfDocument,
          clipboardPageIndices
        );
        for (const page of copiedPages) {
          pdfDocument.insertPage(targetIndex, page);
          targetIndex++;
        }
      }

      return pdfDocument.save();
    })
      .pipe(finalize(() => this.modifyInProgress.set(true)))
      .subscribe({
        next: (pdfBytes) => {
          this.sortedThumbnails.update((thumbnails) => {
            for (const thumbnail of thumbnails) {
              thumbnail.isOnClipboard = false;
            }

            return [...thumbnails];
          });
          this.clipboardMode = NO_CLIPBOARD_MODE;
          this.pdfModified.emit({
            modifiedPdfBytes: pdfBytes,
          });
        },
      });
  }

  /**
   * Handles the cdk drag sort event for the thumbnails.
   *
   * @param event CdkDragSortEvent event.
   */
  onThumbnailDragSort(event: CdkDragSortEvent): void {
    const thumbnails = this.sortedThumbnails();
    // The indexes in the event are relative to the rendered pages so we need to get the real indexes.
    const realPreviousIndex =
      this.renderedThumbnailIndices[event.previousIndex];
    const realCurrentIndex = this.renderedThumbnailIndices[event.currentIndex];
    const thumbnail = thumbnails.splice(realPreviousIndex, 1)[0];
    thumbnail.pageIndex = realCurrentIndex;
    thumbnail.pageNumber = realCurrentIndex + 1;
    thumbnails.splice(realCurrentIndex, 0, thumbnail);
    this.sortedThumbnails.set(thumbnails);
    // We need to force a recheck to update the values stored for the thumbnails.
    this.changeDetectorReference.markForCheck();
  }

  /**
   * Refreshes the component.
   */
  refresh(): void {
    this.changeDetectorReference.markForCheck();
  }

  /**
   * Track function used by the virtual repeat
   *
   * @param index Index supplied by virtual repeat
   * @param item PdfThumbnail.
   */
  trackByFn(index: number, item: PdfThumbnail) {
    return item.pageIndex;
  }

  private createReorderedPdf(
    currentPageIndex: number,
    targetPageIndex: number
  ): Observable<PDFDocument> {
    const pdfBytes = this.pdfBytes();

    const reorderPages$ = defer(async () => {
      const pdfDocument = await PDFDocument.load(pdfBytes);

      const pageIndices = pdfDocument.getPageIndices();
      pageIndices.splice(currentPageIndex, 1);
      pageIndices.splice(targetPageIndex, 0, currentPageIndex);

      this.reorderPages(pdfDocument, pageIndices);
      return pdfDocument;
    });

    return reorderPages$;
  }

  /**
   * Handles deleting pages after a burst.
   *
   * This will also emit the pdfModified event with the modified PDF once
   * the returned observable runs.
   *
   * @param pdfDocument The PDF document from which to remove the pages.
   * @param pageIndexes Page indexes that should be removed from the PDF.
   * @returns An observable byte array of the modified PDF.
   */
  private deleteBurstedPages(
    pdfDocument: PDFDocument,
    pageIndexes: number[]
  ): Observable<Uint8Array> {
    /**
     * Removing a page causes all the indexes to shift by 1.
     * This number gets + 1 whenever we remove a page and it is subtracted
     * from the page index when we try to delete another page.
     *
     */
    let indexOffset = 0;
    for (const pageIndex of pageIndexes) {
      pdfDocument.removePage(pageIndex - indexOffset);

      indexOffset += 1;
    }

    return defer(() => pdfDocument.save()).pipe(
      tap((modifiedPdfBytes) => {
        this.pdfModified.emit({ modifiedPdfBytes });
      })
    );
  }

  private emitSelectionChange(): void {
    const selectedThumbnails = this.selectedThumbnails();
    this.thumbnailSelectionChange.emit(selectedThumbnails);
  }

  private getConfirmation$(): Observable<boolean> {
    if (this.thumbnails().some((thumbnail) => thumbnail.hasAnnotations)) {
      const dialog = this.dialog.open<
        ConfirmationDialogComponent,
        ConfirmationDialogData,
        boolean
      >(ConfirmationDialogComponent, {
        data: {
          cancelActionText: 'NO',
          confirmActionText: 'YES',
          contents: 'BURST_OR_MOVE_WITH_ANNOTATIONS_WARNING',
          title: 'ANNOTATIONS_PRESENT',
        },
      });
      return dialog.afterClosed().pipe(map((result) => !!result));
    }

    return of(true);
  }

  private insertPages(
    pdfDocumentOrBytes: Uint8Array | PDFDocument,
    pdfDocumentOrBytesToInsert: Uint8Array | PDFDocument,
    insertIndex: number
  ): Observable<{
    updatedPdfDocument: PDFDocument;
    addedPageIndices: number[];
  }> {
    const insertPages$ = defer(async () => {
      const pdfDocument =
        pdfDocumentOrBytes instanceof Uint8Array
          ? await PDFDocument.load(pdfDocumentOrBytes)
          : pdfDocumentOrBytes;

      const pdfDocumentToInsert =
        pdfDocumentOrBytesToInsert instanceof Uint8Array
          ? await PDFDocument.load(pdfDocumentOrBytesToInsert)
          : pdfDocumentOrBytesToInsert;

      const pageIndicesToCopy = pdfDocumentToInsert.getPageIndices();
      const copiedPages = await pdfDocument.copyPages(
        pdfDocumentToInsert,
        pageIndicesToCopy
      );

      let targetIndex = insertIndex;
      const addedPageIndices = [];
      for (const page of copiedPages) {
        pdfDocument.insertPage(targetIndex, page);
        addedPageIndices.push(targetIndex);
        // we need to increase index once for the iteration and once to account for the newly added page.
        targetIndex = targetIndex + 1;
      }

      return {
        updatedPdfDocument: pdfDocument,
        addedPageIndices,
      };
    });

    return insertPages$;
  }

  private reorderPages(pdfDocument: PDFDocument, newPageOrder: number[]) {
    const pages = pdfDocument.getPages();
    for (
      let currentPage = 0;
      currentPage < newPageOrder.length;
      currentPage++
    ) {
      pdfDocument.removePage(currentPage);
      pdfDocument.insertPage(currentPage, pages[newPageOrder[currentPage]]);
    }
  }

  private runBurst(deletePagesFromSource: boolean) {
    this.modifyInProgress.set(true);
    const selectedThumbnails = this.thumbnails().filter(
      (thumbnail) => thumbnail.isSelected
    );
    const sourcePdfBytes = this.pdfBytes();

    const burstedDocumentResult$ = defer(async () => {
      let pdfDocument: PDFDocument;
      let targetPdfDocument: PDFDocument;
      let pageIndexes: number[];
      let pageCount: number;
      let targetPdfBytes: Uint8Array;
      try {
        // Load document
        pdfDocument = await PDFDocument.load(sourcePdfBytes);
        // Create a new target library
        targetPdfDocument = await PDFDocument.create();

        pageIndexes = selectedThumbnails.map((t) => t.pageIndex);
        const copiedPages = await targetPdfDocument.copyPages(
          pdfDocument,
          pageIndexes
        );
        for (const page of copiedPages) {
          targetPdfDocument.addPage(page);
        }

        pageCount = targetPdfDocument.getPageCount();
        targetPdfBytes = await targetPdfDocument.save();
      } catch (error) {
        throw new UserFriendlyError(
          error,
          'Failed to burst pages.',
          'ERROR_BURST_FAILED'
        );
      }

      if (deletePagesFromSource) {
        // We fire off the delete separately from the burst. If it fails the user could still delete the pages themselves.
        this.deleteBurstedPages(pdfDocument, pageIndexes).subscribe({
          error: (error) => {
            // An error here means that we succeeded in creating the bursted document so we error but ensure the burstedDocumentResult$ observable completes.
            // The user could still delete the pages themselves if the error is recoverable.
            const friendlyError = new UserFriendlyError(
              error,
              'Failed to delete bursted pages from source document.',
              'ERROR_BURST_FAILED_TO_DELETE_SOURCE_PAGES'
            );

            this.burstError.emit({ error: friendlyError });
          },
        });
      }

      return { pageCount, targetPdfBytes };
    });

    burstedDocumentResult$
      .pipe(finalize(() => this.modifyInProgress.set(false)))
      .subscribe({
        next: (result) => {
          this.burstCompleted.emit({
            newDocumentBytes: result.targetPdfBytes,
            pageCount: result.pageCount,
            pagesDeletedFromSource: deletePagesFromSource,
          });
        },
        error: (error: UserFriendlyError) => {
          this.burstError.emit({ error });
        },
      });
  }

  private setSelectedThumbnailsToClipboard(): void {
    const selectedThumbnails = this.selectedThumbnails();
    this.sortedThumbnails.update((thumbnails) => {
      for (const thumbnail of thumbnails) {
        // Mark thumbnail as on clipboard if it is currently selected. Otherwise ensure it is not on the clipboard.
        thumbnail.isOnClipboard = !!selectedThumbnails.find(
          (t) => t.pageIndex === thumbnail.pageIndex
        );
      }

      return [...thumbnails];
    });
  }
}
