import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef as MatDialogReference } from '@angular/material/dialog';

import { Command } from '../models/command';
import { CommandService } from '../services/command.service';

/**
 * Command pallete component.
 */
@Component({
  selector: 'app-command-palette',
  templateUrl: './command-palette.component.html',
  styleUrls: ['./command-palette.component.scss'],
  standalone: false,
})
export class CommandPaletteComponent {
  /** Selection list element for filtered commands. */
  @ViewChild('commandSelect', { read: ElementRef }) commandSelect: ElementRef;

  /** Command query. */
  commandQuery = '';
  /** Available commands. */
  commands = this.commandService.commands;

  constructor(
    private dialogReference: MatDialogReference<CommandPaletteComponent>,
    private commandService: CommandService
  ) {}

  /**
   * Event for selecting the first matching command.
   *
   * @param event Event.
   */
  clickFirst(event: Event) {
    event.preventDefault();
    this.getFirstOption()?.click();
  }

  /**
   * Execute a command.
   *
   * @param command Command.
   */
  executeCommand(command: Command) {
    this.dialogReference.afterClosed().subscribe(() => {
      command.action();
    });
    this.dialogReference.close();
  }

  /**
   * Move focus to the command selection list.
   */
  focusFirst() {
    this.getFirstOption()?.focus();
  }

  /**
   * Get the first matching commands element.
   *
   * @returns DOM element for first matched command.
   */
  getFirstOption() {
    return this.commandSelect.nativeElement.children[0];
  }
}
