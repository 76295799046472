import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ArchiveViewComponent } from './components/archive-view/archive-view.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DatabaseSelectComponent } from './components/database-select/database-select.component';
import { DirectAccessComponent } from './components/direct-access/direct-access.component';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { InboxViewComponent } from './components/inbox-view/inbox-view.component';
import { LoginViewComponent } from './components/login-view/login-view.component';
import { NewDocumentViewComponent } from './components/new-document-view/new-document-view.component';
import { SearchByNameRedirectViewComponent } from './components/search-by-name-redirect-view/search-by-name-redirect-view.component';
import { TaskViewComponent } from './components/task-view/task-view.component';
import { AuthGuard } from './helpers/auth.guard';
import { dirtyCheckGuard } from './helpers/dirty-check.guard';
import { DirtyComponent } from './models';

const routes: Routes = [
  {
    path: '', // Database select view.
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: DatabaseSelectComponent,
  },
  {
    path: 'db/:dbId',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '', // Database view.
        component: DashboardComponent,
      },
      {
        path: 'new',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: NewDocumentViewComponent,
          },
          {
            path: ':editId',
            component: NewDocumentViewComponent,
          },
        ],
      },
      {
        path: 'archive/:archiveId',
        children: [
          {
            path: 'direct/:type',
            component: DirectAccessComponent,
          },
          {
            path: '', // Default search archive view.
            pathMatch: 'full',
            component: ArchiveViewComponent,
          },
          {
            path: 'document/:documentId',
            children: [
              // Specific document(s) in a search, within the path's archive.
              {
                path: '',
                redirectTo: 'view/0',
                pathMatch: 'full',
              },
              // Specify the document index for multiple documents provided to the path.
              {
                path: 'view/:viewIndex',
                component: DocumentViewComponent,
                canDeactivate: [
                  (component: DirtyComponent) => dirtyCheckGuard(component),
                ],
              },
            ],
          },
          {
            path: 'search-by-name/:searchName',
            component: SearchByNameRedirectViewComponent,
          },
          {
            path: 'search/:searchId', // Specific search view.
            children: [
              {
                path: '', // Default search view
                pathMatch: 'full',
                component: ArchiveViewComponent,
                canDeactivate: [
                  (component: DirtyComponent) => dirtyCheckGuard(component),
                ],
              },
              {
                path: 'document/:documentId',
                children: [
                  // Specific document(s) in a search, within the path's archive.
                  { path: '', redirectTo: 'view/0', pathMatch: 'full' },
                  // Specify the document index for multiple documents provided to the path.
                  {
                    path: 'view/:viewIndex',
                    component: DocumentViewComponent,
                    canDeactivate: [
                      (component: DirtyComponent) => dirtyCheckGuard(component),
                    ],
                  },
                ],
              },
            ],
          },
          // archives/import
          {
            path: 'import/:documentId',
            children: [
              // Specific document(s) in a search, within the path's archive.
              { path: '', redirectTo: 'view/0', pathMatch: 'full' },
              // Specify the document index for multiple documents provided to the path.
              {
                path: 'view/:viewIndex',
                component: DocumentViewComponent,
                canDeactivate: [
                  (component: DirtyComponent) => dirtyCheckGuard(component),
                ],
                data: { isImport: true },
              },
            ],
          },
          {
            path: 'document/session/:sessionId',
            children: [
              {
                path: '',
                redirectTo: 'view/0',
                pathMatch: 'full',
              },
              {
                path: 'view/:viewIndex',
                component: DocumentViewComponent,
                canDeactivate: [
                  (component: DirtyComponent) => dirtyCheckGuard(component),
                ],
              },
            ],
          },
          {
            path: 'task/:taskId', // Task view
            children: [
              {
                path: '',
                component: TaskViewComponent,
              },
              {
                path: 'document/:documentId',
                children: [
                  {
                    path: '',
                    redirectTo: 'view/0',
                    pathMatch: 'full',
                  },
                  {
                    path: 'view/:viewIndex',
                    component: DocumentViewComponent,
                    canDeactivate: [
                      (component: DirtyComponent) => dirtyCheckGuard(component),
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // While these elements don't depend on a specific database, they are more useful when one is known.
      {
        path: 'inbox/:inboxId', // Inbox view.
        children: [
          {
            path: '', // Default inbox view
            pathMatch: 'full',
            component: InboxViewComponent,
          },
          {
            path: 'document/:documentId',
            children: [
              // Specific document(s) in a search, within the path's archive.
              { path: '', redirectTo: 'view/0', pathMatch: 'full' },
              // Specify the document index for multiple documents provided to the path.
              {
                path: 'view/:viewIndex',
                component: DocumentViewComponent,
                canDeactivate: [
                  (component: DirtyComponent) => dirtyCheckGuard(component),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'login', // Login view.
    component: LoginViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
